var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { comparingLocalizable } from 'app/utils/comparator';
import { AddNew } from 'app/components/common/searchResults';
import { anyMatching, limit } from 'yti-common-ui/utils/array';
import { lowerCase, upperCaseFirst } from 'change-case';
import { ifChanged } from 'app/utils/angular';
import { Concept } from 'app/entities/vocabulary';
import { filterAndSortSearchResults, defaultLabelComparator } from 'app/components/filter/util';
import { allStatuses } from 'yti-common-ui/entities/status';
var limitQueryResults = 1000;
var EntityCreation = /** @class */ (function () {
    function EntityCreation(conceptId, entity) {
        this.conceptId = conceptId;
        this.entity = entity;
    }
    return EntityCreation;
}());
export { EntityCreation };
var NewConceptData = /** @class */ (function () {
    function NewConceptData(label, vocabulary) {
        this.label = label;
        this.vocabulary = vocabulary;
    }
    return NewConceptData;
}());
var AddWithoutConceptData = /** @class */ (function () {
    function AddWithoutConceptData(label) {
        this.label = label;
    }
    return AddWithoutConceptData;
}());
var SearchConceptModal = /** @class */ (function () {
    function SearchConceptModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchConceptModal.prototype.open = function (vocabularies, model, type, allowSuggestions, newEntityCreation, initialSearch) {
        return this.$uibModal.open({
            template: require('./searchConceptModal.html'),
            size: 'lg',
            controller: SearchConceptController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                vocabularies: function () { return vocabularies; },
                model: function () { return model; },
                type: function () { return type; },
                newEntityCreation: function () { return newEntityCreation; },
                initialSearch: function () { return initialSearch; },
                allowSuggestions: function () { return allowSuggestions; }
            }
        }).result;
    };
    SearchConceptModal.prototype.openSelection = function (vocabularies, model, allowSuggestions, type) {
        return this.open(vocabularies, model, type || null, allowSuggestions, false, '');
    };
    SearchConceptModal.prototype.openNewEntityCreation = function (vocabularies, model, type, initialSearch) {
        return this.open(vocabularies, model, type, true, true, initialSearch);
    };
    return SearchConceptModal;
}());
export { SearchConceptModal };
function isConcept(obj) {
    return obj instanceof Concept;
}
function isNewConceptData(obj) {
    return obj instanceof NewConceptData;
}
function isAddWithoutConceptData(obj) {
    return obj instanceof AddWithoutConceptData;
}
var SearchConceptController = /** @class */ (function () {
    function SearchConceptController($scope, $uibModalInstance, $q, languageService, type, initialSearch, newEntityCreation, allowSuggestions, vocabularies, model, vocabularyService, gettextCatalog) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$q = $q;
        this.languageService = languageService;
        this.type = type;
        this.newEntityCreation = newEntityCreation;
        this.allowSuggestions = allowSuggestions;
        this.model = model;
        this.vocabularyService = vocabularyService;
        this.gettextCatalog = gettextCatalog;
        this.selection = null;
        this.searchText = '';
        this.submitError = null;
        this.contentExtractors = [function (concept) { return concept.label; }];
        this.searchFilters = [];
        this.editInProgress = function () { return _this.$scope.form.editing && _this.$scope.form.$dirty; };
        this.localizer = languageService.createLocalizer(model);
        this.defineConceptTitle = type ? "Define concept for the " + (newEntityCreation ? 'new ' : '') + type : 'Search concept';
        this.buttonTitle = (newEntityCreation ? 'Create new ' + type : 'Use');
        this.labelTitle = type ? upperCaseFirst(type) + " label" : '';
        this.searchText = initialSearch;
        this.vocabularies = vocabularies.slice();
        this.vocabularies.sort(this.vocabularyComparator);
        this.loadingResults = false;
        this.addFilter(function (vocabulary) {
            return !_this.showStatus || vocabulary.item.status === _this.showStatus;
        });
        $scope.$watch(function () { return _this.searchText; }, function () { return _this.query(_this.searchText).then(function () { return _this.search(); }); });
        $scope.$watch(function () { return _this.selectedVocabulary; }, ifChanged(function () { return _this.query(_this.searchText).then(function () { return _this.search(); }); }));
        $scope.$watch(function () { return _this.localizer.language; }, ifChanged(function () { return _this.query(_this.searchText).then(function () { return _this.search(); }); }));
        $scope.$watch(function () { return _this.showStatus; }, ifChanged(function () { return _this.search(); }));
    }
    SearchConceptController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    Object.defineProperty(SearchConceptController.prototype, "items", {
        get: function () {
            return this.queryResults;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptController.prototype, "vocabularyComparator", {
        get: function () {
            return comparingLocalizable(this.localizer, function (vocabulary) { return vocabulary.title; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptController.prototype, "statuses", {
        get: function () {
            return allStatuses;
        },
        enumerable: true,
        configurable: true
    });
    SearchConceptController.prototype.isSelectionConcept = function () {
        return isConcept(this.selection);
    };
    SearchConceptController.prototype.isSelectionNewConceptData = function () {
        return isNewConceptData(this.selection);
    };
    SearchConceptController.prototype.isSelectionAddWithoutConceptData = function () {
        return isAddWithoutConceptData(this.selection);
    };
    SearchConceptController.prototype.query = function (searchText) {
        var _this = this;
        this.loadingResults = true;
        if (searchText) {
            return this.vocabularyService.searchConcepts(searchText, this.selectedVocabulary ? this.selectedVocabulary : undefined)
                .then(function (results) {
                var resultsWithReferencedVocabularies = results.filter(function (concept) {
                    return anyMatching(_this.vocabularies, function (v) { return v.id.equals(concept.vocabulary.id); });
                });
                _this.queryResults = limit(resultsWithReferencedVocabularies, limitQueryResults);
                _this.loadingResults = false;
            });
        }
        else {
            this.loadingResults = false;
            return this.$q.when(this.queryResults = []);
        }
    };
    SearchConceptController.prototype.search = function () {
        var _this = this;
        if (this.queryResults) {
            var suggestText = this.gettextCatalog.getString('suggest') + " '" + this.searchText + "'";
            var toVocabularyText = "" + this.gettextCatalog.getString('to vocabulary');
            var addNewText = suggestText + ' ' + toVocabularyText;
            var addWithoutConcept = this.gettextCatalog.getString('Create new ' + this.type + ' without referencing concept');
            this.searchResults = [
                new AddNewConcept(addNewText, function () { return _this.canAddNew(); }),
                new AddWithoutConcept(addWithoutConcept, function () { return _this.newEntityCreation; })
            ].concat(filterAndSortSearchResults(this.queryResults, this.searchText, this.contentExtractors, this.searchFilters, defaultLabelComparator(this.localizer)));
        }
        else {
            this.searchResults = [];
        }
    };
    SearchConceptController.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.submitError = null;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        if (item instanceof AddNewConcept) {
            this.$scope.form.editing = true;
            this.selection = new NewConceptData(lowerCase(this.searchText, this.localizer.language), this.resolveInitialVocabulary());
        }
        else if (item instanceof AddWithoutConcept) {
            this.selection = new AddWithoutConceptData(this.searchText);
        }
        else {
            this.selection = null;
            this.vocabularyService.getConcept(item.id).then(function (concept) { return _this.selection = concept; });
        }
    };
    SearchConceptController.prototype.loadingSelection = function (item) {
        if (item instanceof AddNew || item !== this.selectedItem) {
            return false;
        }
        else {
            if (!this.selection) {
                return true;
            }
            else {
                var searchResult = item;
                var selection = this.selection;
                return isConcept(selection) && !searchResult.id.equals(selection.id);
            }
        }
    };
    SearchConceptController.prototype.resolveInitialVocabulary = function () {
        return this.vocabularies[0];
    };
    SearchConceptController.prototype.canAddNew = function () {
        return this.allowSuggestions && !!this.searchText && this.vocabularies.length > 0;
    };
    SearchConceptController.prototype.confirm = function () {
        this.$uibModalInstance.close(this.resolveResult());
    };
    SearchConceptController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    SearchConceptController.prototype.resolveResult = function () {
        var _this = this;
        var selection = this.selection;
        var language = this.languageService.getModelLanguage(this.model);
        if (isNewConceptData(selection)) {
            var conceptSuggestionId = this.vocabularyService.createConceptSuggestion(selection.vocabulary, selection.label, selection.definition, language, this.model);
            if (this.newEntityCreation) {
                return conceptSuggestionId
                    .then(function (csId) { return new EntityCreation(csId, { label: selection.label }); });
            }
            else {
                return conceptSuggestionId
                    .then(function (csId) { return _this.vocabularyService.getConcept(csId); });
            }
        }
        else if (isAddWithoutConceptData(selection)) {
            if (!this.newEntityCreation) {
                throw new Error('Must be new entity creation');
            }
            return this.$q.when(new EntityCreation(null, { label: selection.label }));
        }
        else if (isConcept(selection)) {
            if (this.newEntityCreation) {
                return this.$q.when(new EntityCreation(selection.id, { label: selection.label[language] }));
            }
            else {
                return this.$q.when(selection);
            }
        }
        else {
            throw new Error('Unsupported selection ' + selection);
        }
    };
    return SearchConceptController;
}());
var AddNewConcept = /** @class */ (function (_super) {
    __extends(AddNewConcept, _super);
    function AddNewConcept(label, show) {
        var _this = _super.call(this, label, show) || this;
        _this.label = label;
        _this.show = show;
        return _this;
    }
    return AddNewConcept;
}(AddNew));
var AddWithoutConcept = /** @class */ (function (_super) {
    __extends(AddWithoutConcept, _super);
    function AddWithoutConcept(label, show) {
        var _this = _super.call(this, label, show) || this;
        _this.label = label;
        _this.show = show;
        return _this;
    }
    return AddWithoutConcept;
}(AddNew));
