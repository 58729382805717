import { isDefined } from 'yti-common-ui/utils/object';
var modelLanguageKey = 'modelLanguage';
var uiLanguageKey = 'UILanguage';
var selectionWidthKey = 'selectionWidth';
var visualizationFocus = 'visualizationFocus';
var sortAlphabetically = 'sortAlphabetically';
var showName = 'showName';
export var FocusLevel;
(function (FocusLevel) {
    FocusLevel[FocusLevel["DEPTH1"] = 1] = "DEPTH1";
    FocusLevel[FocusLevel["DEPTH2"] = 2] = "DEPTH2";
    FocusLevel[FocusLevel["DEPTH3"] = 3] = "DEPTH3";
    FocusLevel[FocusLevel["INFINITE_DEPTH"] = 4] = "INFINITE_DEPTH";
    FocusLevel[FocusLevel["ALL"] = 5] = "ALL";
})(FocusLevel || (FocusLevel = {}));
export var NameType;
(function (NameType) {
    NameType[NameType["LABEL"] = 0] = "LABEL";
    NameType[NameType["ID"] = 1] = "ID";
    NameType[NameType["LOCAL_ID"] = 2] = "LOCAL_ID";
})(NameType || (NameType = {}));
var SessionService = /** @class */ (function () {
    function SessionService() {
    }
    SessionService.prototype.get = function (key) {
        var value = window.sessionStorage.getItem(key);
        return isDefined(value) ? JSON.parse(value) : null;
    };
    SessionService.prototype.set = function (key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
    Object.defineProperty(SessionService.prototype, "UILanguage", {
        get: function () {
            return this.get(uiLanguageKey);
        },
        set: function (lang) {
            this.set(uiLanguageKey, lang);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "modelLanguage", {
        get: function () {
            return this.get(modelLanguageKey);
        },
        set: function (lang) {
            this.set(modelLanguageKey, lang);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "selectionWidth", {
        get: function () {
            return this.get(selectionWidthKey);
        },
        set: function (value) {
            this.set(selectionWidthKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "visualizationFocus", {
        get: function () {
            return this.get(visualizationFocus);
        },
        set: function (value) {
            this.set(visualizationFocus, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "sortAlphabetically", {
        get: function () {
            return this.get(sortAlphabetically);
        },
        set: function (value) {
            this.set(sortAlphabetically, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "showName", {
        get: function () {
            return this.get(showName);
        },
        set: function (value) {
            this.set(showName, value);
        },
        enumerable: true,
        configurable: true
    });
    return SessionService;
}());
export { SessionService };
