import { ResourceStore } from './resourceStore';
import { analyze } from 'app/components/filter/textAnalyzer';
import { isDefined, requireDefined } from 'yti-common-ui/utils/object';
var InteractiveHelpVocabularyService = /** @class */ (function () {
    function InteractiveHelpVocabularyService($q, entityCreatorService) {
        'ngInject';
        this.$q = $q;
        this.entityCreatorService = entityCreatorService;
        this.conceptIndex = 0;
        this.vocabularyStore = new ResourceStore();
        this.conceptStore = new ResourceStore();
    }
    InteractiveHelpVocabularyService.prototype.reset = function () {
        this.vocabularyStore.clear();
        this.conceptIndex = 0;
        this.conceptStore.clear();
        return this.$q.when();
    };
    InteractiveHelpVocabularyService.prototype.getAllVocabularies = function () {
        return this.$q.resolve(this.vocabularyStore.values());
    };
    InteractiveHelpVocabularyService.prototype.searchConcepts = function (searchText, vocabulary) {
        return this.$q.resolve(this.conceptStore.findAll(function (c) {
            var analysis = analyze(searchText, c, [function (x) { return x.label; }, function (x) { return x.definition; }]);
            return isDefined(analysis.matchScore) && (!vocabulary || vocabulary.id.equals(c.vocabulary.id));
        }));
    };
    InteractiveHelpVocabularyService.prototype.createConceptSuggestion = function (vocabulary, label, comment, lang) {
        var _this = this;
        return this.entityCreatorService.createConcept({
            vocabulary: vocabulary,
            index: ++this.conceptIndex,
            label: (_a = {}, _a[lang] = label, _a),
            definition: (_b = {}, _b[lang] = comment, _b)
        }).then(function (concept) { return _this.conceptStore.add(concept); })
            .then(function (concept) { return concept.id; });
        var _a, _b;
    };
    InteractiveHelpVocabularyService.prototype.getConcept = function (id) {
        return this.$q.when(requireDefined(this.conceptStore.findFirst(function (cs) { return cs.id.equals(id); })));
    };
    InteractiveHelpVocabularyService.prototype.createVocabulary = function (vocabulary) {
        var _this = this;
        return this.entityCreatorService.createVocabulary(vocabulary)
            .then(function (v) { return _this.vocabularyStore.add(v); });
    };
    InteractiveHelpVocabularyService.prototype.createConcept = function (vocabulary, concept) {
        var _this = this;
        return this.entityCreatorService.createConcept({
            vocabulary: vocabulary,
            index: ++this.conceptIndex,
            label: concept.label,
            definition: concept.definition
        }).then(function (c) { return _this.conceptStore.add(c); });
    };
    return InteractiveHelpVocabularyService;
}());
export { InteractiveHelpVocabularyService };
