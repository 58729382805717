import { SearchClassType } from 'app/types/component';
import { containsAny, arraysAreEqual, firstMatching } from 'yti-common-ui/utils/array';
import { collectIds } from './entity';
import { isDefined } from 'yti-common-ui/utils/object';
export function idExclusion(excludeId, excludeItem, dataSource, $q) {
    return function (id) {
        if (!id) {
            return $q.when(null);
        }
        var excludeIdReason = excludeId && excludeId(id);
        if (excludeIdReason) {
            return $q.when(excludeIdReason);
        }
        else if (excludeItem) {
            return dataSource(id.toString()).then(function (items) {
                var item = firstMatching(items, function (i) { return i.id.equals(id); });
                return item && excludeItem(item);
            });
        }
        else {
            return $q.when(null);
        }
    };
}
export function itemExclusion(excludeId, excludeItem) {
    return function (item) {
        return item && (excludeId && excludeId(item.id)) || (excludeItem && excludeItem(item));
    };
}
export function combineExclusions() {
    var excludes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        excludes[_i] = arguments[_i];
    }
    return function (item) {
        for (var _i = 0, excludes_1 = excludes; _i < excludes_1.length; _i++) {
            var exclude = excludes_1[_i];
            var result = exclude(item);
            if (result) {
                return result;
            }
        }
        return null;
    };
}
export function createSelfExclusion(self) {
    return function (item) {
        if (arraysAreEqual(self.type, item.type) && self.id.equals(item.id)) {
            return 'Self reference not allowed';
        }
        else {
            return null;
        }
    };
}
export function createDefinedByExclusion(model) {
    var modelIds = collectIds(model.importedNamespaces);
    modelIds.add(model.id.uri);
    return function (item) {
        if (isDefined(item.definedBy) && !modelIds.has(item.definedBy.id.uri)) {
            return 'Not imported by model';
        }
        else {
            return null;
        }
    };
}
export function createExistsExclusion(itemIds) {
    return function (item) {
        if (itemIds.has(item.id.toString())) {
            return 'Already added';
        }
        else {
            return null;
        }
    };
}
export function createClassTypeExclusion(searchClassType) {
    var showShapes = containsAny([SearchClassType.Shape, SearchClassType.SpecializedClass], [searchClassType]);
    var showClasses = containsAny([SearchClassType.Class, SearchClassType.SpecializedClass], [searchClassType]);
    return function (klass) {
        if (!showShapes && klass.isOfType('shape')) {
            return 'Shapes are not allowed';
        }
        else if (!showClasses && !klass.isOfType('shape')) {
            return 'Classes are not allowed';
        }
        else if (searchClassType === SearchClassType.SpecializedClass && !klass.isSpecializedClass()) {
            return 'Non specialized classes are not allowed';
        }
        else {
            return null;
        }
    };
}
