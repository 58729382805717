import { ErrorModal } from './errorModal';
import { DisplayItemFactory } from './displayItemFactory';
import { InputPopupComponent, InputPopupItemTranscludeDirective, InputPopupSelectItemDirective } from './inputPopup';
import { ErrorMessagesComponent } from './errorMessages';
import { NonEditableComponent } from './nonEditable';
import { EditableLabelComponent } from './editableLabel';
import { EditableComponent } from './editable';
import { EditableStateSelectComponent } from './editableStateSelect';
import { EditableTableComponent } from './editableTable';
import { AutocompleteComponent } from './autocomplete';
import { IowSelectComponent, IowSelectInputDirective, IowSelectSelectableItemTranscludeDirective, IowSelectSelectionTranscludeDirective } from './iowSelect';
import { LocalizedSelectComponent } from './localizedSelect';
import { EditableEntityButtonsComponent } from './editableEntityButtons';
import { CodeValueInputAutocompleteComponent } from './codeValueInputAutocomplete';
import { ModelLanguageChooserComponent } from './modelLanguageChooser';
import { ErrorPanelComponent } from './errorPanel';
import { SortByColumnHeaderComponent } from './sortByColumnHeader';
import { CodeValueInputDirective } from './codeValueInput';
import { ExcludeValidatorDirective } from './excludeValidator';
import { StringInputDirective } from './stringInput';
import { PrefixInputDirective } from './prefixInput';
import { NamespaceInputDirective } from './namespaceInput';
import { DataTypeInputDirective } from './dataTypeInput';
import { BootstrapInputDirective } from './bootstrapInput';
import { UriInputDirective } from './uriInput';
import { HrefDirective } from './href';
import { IdInputDirective } from './idInput';
import { LocalizedInputDirective } from './localizedInput';
import { LanguageInputDirective } from './languageInput';
import { RestrictDuplicatesDirective } from './restrictDuplicates';
import { MaxInputDirective } from './maxInput';
import { MinInputDirective } from './minInput';
import { IgnoreDirtyDirective } from './ignoreDirty';
import { IgnoreFormDirective } from './ignoreForm';
import { DragSortableDirective, DragSortableItemDirective } from './dragSortable';
import { ImplicitEditModeDirective } from './implicitEditMode';

import { componentDeclaration, directiveDeclaration } from 'app/utils/angular';
import { module as mod } from './module';
export { module } from './module';

mod.component('autocomplete', componentDeclaration(AutocompleteComponent));
mod.component('codeValueInputAutocomplete', componentDeclaration(CodeValueInputAutocompleteComponent));
mod.component('nonEditable', componentDeclaration(NonEditableComponent));
mod.component('editable', componentDeclaration(EditableComponent));
mod.component('editableLabel', componentDeclaration(EditableLabelComponent));
mod.component('editableEntityButtons', componentDeclaration(EditableEntityButtonsComponent));
mod.component('errorMessages', componentDeclaration(ErrorMessagesComponent));
mod.component('modelLanguageChooser', componentDeclaration(ModelLanguageChooserComponent));
mod.component('editableStateSelect', componentDeclaration(EditableStateSelectComponent));
mod.component('errorPanel', componentDeclaration(ErrorPanelComponent));
mod.component('editableTable', componentDeclaration(EditableTableComponent));
mod.component('iowSelect', componentDeclaration(IowSelectComponent));
mod.component('sortByColumnHeader', componentDeclaration(SortByColumnHeaderComponent));
mod.directive('iowSelectInput', IowSelectInputDirective);
mod.directive('iowSelectionTransclude', IowSelectSelectionTranscludeDirective);
mod.directive('iowSelectableItemTransclude', IowSelectSelectableItemTranscludeDirective);
mod.component('localizedSelect', componentDeclaration(LocalizedSelectComponent));
mod.component('inputPopup', componentDeclaration(InputPopupComponent));
mod.directive('inputPopupSelectItem', InputPopupSelectItemDirective);
mod.directive('inputPopupItemTransclude', InputPopupItemTranscludeDirective);

mod.directive('codeValueInput', CodeValueInputDirective);
mod.directive('excludeValidator', ExcludeValidatorDirective);
mod.directive('stringInput', StringInputDirective);
mod.directive('prefixInput', PrefixInputDirective);
mod.directive('namespaceInput', NamespaceInputDirective);
mod.directive('datatypeInput', DataTypeInputDirective);
mod.directive('input', BootstrapInputDirective);
mod.directive('uriInput', UriInputDirective);
mod.directive('ngHref', HrefDirective);
mod.directive('idInput', IdInputDirective);
mod.directive('localizedInput', LocalizedInputDirective);
mod.directive('languageInput', LanguageInputDirective);
mod.directive('restrictDuplicates', RestrictDuplicatesDirective);
mod.directive('maxInput', MaxInputDirective);
mod.directive('minInput', MinInputDirective);
mod.directive('ignoreDirty', IgnoreDirtyDirective);
mod.directive('ignoreForm', IgnoreFormDirective);
mod.directive('dragSortable', directiveDeclaration(DragSortableDirective));
mod.directive('dragSortableItem', DragSortableItemDirective);
mod.directive('implicitEditMode', ImplicitEditModeDirective);

mod.service('errorModal', ErrorModal);
mod.service('displayItemFactory', DisplayItemFactory);
