var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SessionService } from 'app/services/sessionService';
import { NgZone } from '@angular/core';
import { LegacyComponent } from 'app/utils/angular';
var modelPanelLeft = 350;
var minSelectionWidth = 520;
var normalSelectionWidth = 720;
var minVisualizationWidth = 321;
var DividerComponent = /** @class */ (function () {
    function DividerComponent($scope, $window, zone, sessionService) {
        'ngInject';
        this.$scope = $scope;
        this.$window = $window;
        this.zone = zone;
        this.sessionService = sessionService;
    }
    DividerComponent.prototype.$onInit = function () {
        var _this = this;
        this.initWidth();
        var onResize = function () {
            _this.initWidth();
            _this.$scope.$apply();
        };
        this.zone.runOutsideAngular(function () {
            _this.$window.addEventListener('resize', onResize);
        });
        this.$scope.$on('$destroy', function () { return _this.$window.removeEventListener('resize', onResize); });
    };
    DividerComponent.prototype.initWidth = function () {
        this.selectionWidth = Math.min(this.maxWidth - minVisualizationWidth, this.sessionService.selectionWidth || normalSelectionWidth);
    };
    Object.defineProperty(DividerComponent.prototype, "maxWidth", {
        get: function () {
            return this.$window.innerWidth - modelPanelLeft;
        },
        enumerable: true,
        configurable: true
    });
    DividerComponent.prototype.moveDivider = function (mouseDown) {
        var _this = this;
        mouseDown.preventDefault();
        var offset = mouseDown.clientX - this.selectionWidth;
        var maxWidth = this.maxWidth;
        var onMouseMove = function (event) {
            var newWidth = event.clientX - offset;
            if ((newWidth >= minSelectionWidth && newWidth < _this.selectionWidth)
                || (newWidth <= (maxWidth - minVisualizationWidth) && newWidth > _this.selectionWidth)) {
                _this.sessionService.selectionWidth = newWidth;
                _this.selectionWidth = newWidth;
                _this.$scope.$apply();
            }
        };
        var onMouseUp = function () {
            _this.$window.removeEventListener('mousemove', onMouseMove);
            _this.$window.removeEventListener('mouseup', onMouseUp);
        };
        this.zone.runOutsideAngular(function () {
            _this.$window.addEventListener('mousemove', onMouseMove);
            _this.$window.addEventListener('mouseup', onMouseUp);
        });
    };
    DividerComponent = __decorate([
        LegacyComponent({
            bindings: {
                selectionWidth: '='
            },
            template: "<div class=\"divider\" ng-mousedown=\"$ctrl.moveDivider($event)\"></div>"
        }),
        __metadata("design:paramtypes", [Object, Object, NgZone,
            SessionService])
    ], DividerComponent);
    return DividerComponent;
}());
export { DividerComponent };
