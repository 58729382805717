import * as moment from 'moment';
import { availableLanguages } from '../../types/language';
import { contains } from 'yti-common-ui/utils/array';
import { parse as parseUri } from 'uri-js';
export function arrayValidator(validator) {
    return function (input) {
        if (input) {
            for (var _i = 0, input_1 = input; _i < input_1.length; _i++) {
                var value = input_1[_i];
                if (!validator(value)) {
                    return false;
                }
            }
        }
        return true;
    };
}
export function arrayAsyncValidator($q, asyncValidator) {
    return function (input) {
        if (input) {
            return $q.all(input.map(function (value) { return asyncValidator(value); }));
        }
        else {
            return $q.resolve();
        }
    };
}
export var isValidPrefix = createRegexValidator(/^[a-z][a-z0-9_\-]*[a-z0-9]$/);
export var isValidClassIdentifier = createRegexValidator(/^[A-Z][a-zA-Z0-9_\-]*$/);
export var isValidPredicateIdentifier = createRegexValidator(/^[a-z][a-zA-Z0-9_\-]*$/);
export var isValidIdentifier = createRegexValidator(/^[a-zA-Z_\-][a-zA-Z0-9_\-]*$/);
export function isValidLabelLength(_label) {
    return true;
}
export function isValidModelLabelLength(_label) {
    return true;
}
export function isValidPrefixLength(prefix) {
    return !prefix || prefix.length <= 10;
}
export function isValidNamespace(str) {
    return !str || str.toString().endsWith('#') || str.toString().endsWith('/');
}
export function isValidUrl(url) {
    if (!url) {
        return true;
    }
    else {
        var parsed = parseUri(url.toString());
        return !parsed.error && !!parsed.scheme && !!parsed.host && !parsed.scheme.startsWith('urn');
    }
}
export function isValidNamespaceUrlOrUrn(str) {
    return isValidNamespace(str) && isValidUrl(str) || isValidUrn(str.toString());
}
export function isValidUri(uri, toleratedErrors) {
    if (toleratedErrors === void 0) { toleratedErrors = []; }
    if (!uri) {
        return true;
    }
    else {
        var parsed = parseUri(uri.toString());
        return !parsed.error || contains(toleratedErrors, parsed.error) && !!parsed.scheme;
    }
}
export function isValidUriStem(uri) {
    return isValidUri(uri, ['HTTP URIs must have a host.', 'URN is not strictly valid.']);
}
export function isValidLanguageCode(code) {
    if (!code) {
        return true;
    }
    else {
        for (var _i = 0, availableLanguages_1 = availableLanguages; _i < availableLanguages_1.length; _i++) {
            var language = availableLanguages_1[_i];
            if (language === code) {
                return true;
            }
        }
        return false;
    }
}
export function isValidBase64(base64) {
    if (!base64) {
        return true;
    }
    else {
        try {
            atob(base64);
            return true;
        }
        catch (e) {
            return false;
        }
    }
}
export var isValidString = createNopValidator();
export var isValidBoolean = createInValuesValidator('true', 'false');
export var isValidDecimal = createRegexValidator(/^[0-9]+\.?[0-9]*$/);
export var isValidNumber = createRegexValidator(/^[0-9]+$/);
export var isValidDate = createMomentValidator('YYYY-MM-DD');
export var isValidDateTime = createMomentValidator('YYYY-MM-DD H:mm:ss');
export var isValidTime = createMomentValidator('H:mm:ss');
export var isValidYear = createMomentValidator('YYYY');
export var isValidMonth = createMomentValidator('MM');
export var isValidDay = createMomentValidator('DD');
export var isValidHex = createRegexValidator(/^[0-9A-Fa-f]+$/);
export var isValidUrn = createRegexValidator(/^urn:[a-z0-9][a-z0-9-]{0,31}:[a-z0-9()+,\-.:=@;$_!*'%/?#]+/);
export function resolveValidator(dataType) {
    switch (dataType) {
        case 'xsd:string':
        case 'rdf:langString':
        case 'rdfs:Literal':
            return isValidString;
        case 'xsd:anyURI':
            return isValidUri;
        case 'xsd:boolean':
            return isValidBoolean;
        case 'xsd:decimal':
        case 'xsd:double':
        case 'xsd:float':
            return isValidDecimal;
        case 'xsd:integer':
        case 'xsd:long':
        case 'xsd:int':
            return isValidNumber;
        case 'xsd:date':
            return isValidDate;
        case 'xsd:dateTime':
            return isValidDateTime;
        case 'xsd:time':
            return isValidTime;
        case 'xsd:gYear':
            return isValidYear;
        case 'xsd:gMonth':
            return isValidMonth;
        case 'xsd:gDay':
            return isValidDay;
        case 'xsd:hexBinary':
            return isValidHex;
        case 'xsd:base64Binary':
            return isValidBase64;
        default:
            console.log('No validator for unknown data type: ' + dataType);
            return createNopValidator();
    }
}
function createNopValidator(format) {
    var validator = function () { return true; };
    validator.format = format;
    return validator;
}
function createInValuesValidator() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    function inValues(input) {
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            if (input === value) {
                return true;
            }
        }
        return false;
    }
    var validator = function (input) {
        return !input || inValues(input);
    };
    validator.format = values.join('/');
    return validator;
}
function createRegexValidator(regex) {
    function unescapedString() {
        var regexStr = regex.toString();
        return regexStr.substr(1, regexStr.length - 2).replace('^', '').replace('$', '');
    }
    var validator = function (input) {
        return !input || regex.test(input);
    };
    validator.format = unescapedString();
    return validator;
}
function createMomentValidator(format) {
    var validator = function (input) {
        return !input || moment(input, format, true).isValid();
    };
    validator.format = format;
    return validator;
}
