var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LegacyComponent } from 'app/utils/angular';
import { MessagingService } from '../../services/messaging-service';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { ErrorModal } from '../form/errorModal';
import { MassMigrateDatamodelResourceStatusesModalService } from 'app/components/model/mass-migrate-datamodel-resource-statuses-modal.component';
import { AuthorizationManagerService } from 'app/services/authorizationManagerService';
import { NewDatamodelVersionModalService } from 'app/components/model/new-datamodel-version-modal.component';
var ModelActionMenuComponent = /** @class */ (function () {
    function ModelActionMenuComponent(confirmationModalService, messagingService, errorModal, userService, authorizationManagerService, massMigrateDatamodelResourceStatusesModalService, newDatamodelVersionModalService) {
        'ngInject';
        this.confirmationModalService = confirmationModalService;
        this.messagingService = messagingService;
        this.errorModal = errorModal;
        this.userService = userService;
        this.authorizationManagerService = authorizationManagerService;
        this.massMigrateDatamodelResourceStatusesModalService = massMigrateDatamodelResourceStatusesModalService;
        this.newDatamodelVersionModalService = newDatamodelVersionModalService;
    }
    ModelActionMenuComponent.prototype.$onInit = function () {
        this.uri = this.entity.namespace.toString();
    };
    Object.defineProperty(ModelActionMenuComponent.prototype, "showMenu", {
        get: function () {
            return this.canSubscribe || this.showMassMigrateDatamodelStatuses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "canSubscribe", {
        get: function () {
            return this.isMessagingEnabled && this.userService.isLoggedIn();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "canAddSubscription", {
        get: function () {
            return this.canSubscribe && !this.hasSubscription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "canRemoveSubscription", {
        get: function () {
            return this.canSubscribe && this.hasSubscription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "canMassMigrateDatamodelStatuses", {
        get: function () {
            return this.authorizationManagerService.canEditModel(this.entity);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "showMassMigrateDatamodelStatuses", {
        get: function () {
            return this.canMassMigrateDatamodelStatuses && !this.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelActionMenuComponent.prototype, "showCreateNewDatamodelVersion", {
        get: function () {
            return this.authorizationManagerService.canEditModel(this.entity);
        },
        enumerable: true,
        configurable: true
    });
    ModelActionMenuComponent.prototype.addSubscription = function () {
        var _this = this;
        var uri = this.stripHashTagFromEndOfUrl(this.entity.namespace);
        var type = this.entity.normalizedType;
        if (uri && type) {
            this.confirmationModalService.open('ADD EMAIL SUBSCRIPTION TO RESOURCE REGARDING CHANGES?', undefined, '')
                .then(function () {
                _this.messagingService.addSubscription(uri, type).subscribe(function (success) {
                    if (success) {
                        _this.changeHasSubscription(true);
                    }
                    else {
                        _this.changeHasSubscription(false);
                        _this.errorModal.openSubmitError('Adding subscription failed.');
                    }
                });
            }, ignoreModalClose);
        }
    };
    ModelActionMenuComponent.prototype.removeSubscription = function () {
        var _this = this;
        var uri = this.stripHashTagFromEndOfUrl(this.entity.namespace);
        if (uri) {
            this.confirmationModalService.open('REMOVE EMAIL SUBSCRIPTION TO RESOURCE?', undefined, '')
                .then(function () {
                _this.messagingService.deleteSubscription(uri).subscribe(function (success) {
                    if (success) {
                        _this.changeHasSubscription(false);
                    }
                    else {
                        _this.changeHasSubscription(true);
                        _this.errorModal.openSubmitError('Subscription deletion failed.');
                    }
                });
            }, ignoreModalClose);
        }
    };
    ModelActionMenuComponent.prototype.stripHashTagFromEndOfUrl = function (url) {
        var uri = url.toString();
        if (uri.endsWith('#')) {
            return uri.substr(0, uri.length - 1);
        }
        return uri.toString();
    };
    ModelActionMenuComponent.prototype.massMigrateDatamodelStatuses = function () {
        this.massMigrateDatamodelResourceStatusesModalService.open(this.entity);
    };
    ModelActionMenuComponent.prototype.createNewDatamodelVersion = function () {
        this.newDatamodelVersionModalService.open(this.entity);
    };
    ModelActionMenuComponent = __decorate([
        LegacyComponent({
            bindings: {
                isMessagingEnabled: '<',
                hasSubscription: '<',
                changeHasSubscription: '&',
                entity: '<',
                context: '<',
                editing: '<'
            },
            template: require('./modelActionMenu.html')
        }),
        __metadata("design:paramtypes", [ConfirmationModalService,
            MessagingService,
            ErrorModal, Object, AuthorizationManagerService,
            MassMigrateDatamodelResourceStatusesModalService,
            NewDatamodelVersionModalService])
    ], ModelActionMenuComponent);
    return ModelActionMenuComponent;
}());
export { ModelActionMenuComponent };
