var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { normalizePredicateType } from 'app/utils/entity';
import { requireDefined } from 'yti-common-ui/utils/object';
import { resourceUrl } from 'app/utils/entity';
import { Concept } from './vocabulary';
import { init, serialize } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareList, entity, entityAwareOptional } from './serializer/entitySerializer';
import { localizableSerializer, dateSerializer, optional, identitySerializer } from './serializer/serializer';
import { normalizingDefinedBySerializer } from './serializer/common';
var AbstractPredicate = /** @class */ (function (_super) {
    __extends(AbstractPredicate, _super);
    function AbstractPredicate(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.normalizedType = requireDefined(normalizePredicateType(_this.type));
        _this.selectionType = 'predicate';
        init(_this, AbstractPredicate.abstractPredicateMappings);
        return _this;
    }
    AbstractPredicate.prototype.isClass = function () {
        return false;
    };
    AbstractPredicate.prototype.isPredicate = function () {
        return true;
    };
    AbstractPredicate.prototype.isAttribute = function () {
        return this.isOfType('attribute');
    };
    AbstractPredicate.prototype.isAssociation = function () {
        return this.isOfType('association');
    };
    AbstractPredicate.prototype.iowUrl = function () {
        return resourceUrl(requireDefined(this.definedBy.prefix), this.id);
    };
    AbstractPredicate.abstractPredicateMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        comment: { name: 'comment', serializer: localizableSerializer },
        definedBy: { name: 'isDefinedBy', serializer: normalizingDefinedBySerializer },
        status: { name: 'versionInfo', serializer: optional(identitySerializer()) },
        modifiedAt: { name: 'modified', serializer: optional(dateSerializer) }
    };
    return AbstractPredicate;
}(GraphNode));
export { AbstractPredicate };
var PredicateListItem = /** @class */ (function (_super) {
    __extends(PredicateListItem, _super);
    function PredicateListItem(graph, context, frame) {
        return _super.call(this, graph, context, frame) || this;
    }
    return PredicateListItem;
}(AbstractPredicate));
export { PredicateListItem };
var Predicate = /** @class */ (function (_super) {
    __extends(Predicate, _super);
    function Predicate(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.unsaved = false;
        _this.external = false;
        init(_this, Predicate.predicateMappings);
        return _this;
    }
    Object.defineProperty(Predicate.prototype, "inUnstableState", {
        get: function () {
            return this.status === 'INCOMPLETE' || this.status === 'DRAFT' || this.status === 'SUGGESTED';
        },
        enumerable: true,
        configurable: true
    });
    Predicate.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, Object.assign({}, AbstractPredicate.abstractPredicateMappings, Predicate.predicateMappings));
    };
    Predicate.predicateMappings = {
        subPropertyOf: { name: 'subPropertyOf', serializer: entityAwareOptional(uriSerializer) },
        subject: { name: 'subject', serializer: entityAwareOptional(entity(function () { return Concept; })) },
        equivalentProperties: { name: 'equivalentProperty', serializer: entityAwareList(uriSerializer) },
        version: { name: 'identifier', serializer: optional(identitySerializer()) },
        editorialNote: { name: 'editorialNote', serializer: localizableSerializer },
        createdAt: { name: 'created', serializer: optional(dateSerializer) }
    };
    return Predicate;
}(AbstractPredicate));
export { Predicate };
var Association = /** @class */ (function (_super) {
    __extends(Association, _super);
    function Association(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Association.associationMappings);
        return _this;
    }
    Association.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        var result = new Association(serialization['@graph'], serialization['@context'], this.frame);
        result.unsaved = this.unsaved;
        result.external = this.external;
        return result;
    };
    Association.prototype.serializationValues = function (inline, clone) {
        return Object.assign(_super.prototype.serializationValues.call(this, inline, clone), serialize(this, clone, Association.associationMappings));
    };
    Association.associationMappings = {
        valueClass: { name: 'range', serializer: entityAwareOptional(uriSerializer) }
    };
    return Association;
}(Predicate));
export { Association };
var Attribute = /** @class */ (function (_super) {
    __extends(Attribute, _super);
    function Attribute(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Attribute.attributeMappings);
        return _this;
    }
    Attribute.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        var result = new Attribute(serialization['@graph'], serialization['@context'], this.frame);
        result.unsaved = this.unsaved;
        result.external = this.external;
        return result;
    };
    Attribute.prototype.serializationValues = function (inline, clone) {
        return Object.assign(_super.prototype.serializationValues.call(this, inline, clone), serialize(this, clone, Attribute.attributeMappings));
    };
    Attribute.attributeMappings = {
        dataType: { name: 'range', serializer: optional(identitySerializer()) }
    };
    return Attribute;
}(Predicate));
export { Attribute };
