import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'yti-common-ui/services/user.service';
import { selectableStatuses, changeToRestrictedStatus, allowedTargetStatuses } from 'yti-common-ui/entities/status';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertModalService } from 'yti-common-ui/components/alert-modal.component';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { ModelServiceWrapper } from 'app/ajs-upgraded-providers';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { DatamodelConfirmationModalService } from 'app/services/confirmation-modal.service';
var MassMigrateDatamodelResourceStatusesModalComponent = /** @class */ (function () {
    function MassMigrateDatamodelResourceStatusesModalComponent(modal, userService, translateService, alertModalService, errorModalService, confirmationModal, modelServiceWrapper) {
        this.modal = modal;
        this.userService = userService;
        this.translateService = translateService;
        this.alertModalService = alertModalService;
        this.errorModalService = errorModalService;
        this.confirmationModal = confirmationModal;
        this.fromStatus$ = new BehaviorSubject(null);
        this.toStatus$ = new BehaviorSubject(null);
        this.fromStatusResourcesTotal = 0;
        this.loadingResourcesTotal = false;
        this.uploading = false;
        this.fromStatuses = ['INCOMPLETE', 'DRAFT', 'VALID', 'SUPERSEDED', 'RETIRED', 'INVALID'];
        this.toStatuses = ['INCOMPLETE', 'DRAFT', 'VALID', 'SUPERSEDED', 'RETIRED', 'INVALID'];
        this.enforceTransitionRulesForSuperUserToo = false;
        this.modelService = modelServiceWrapper.modelService;
    }
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reset();
        this.fromStatus$.subscribe(function (status) {
            _this.loadingResourcesTotal = true;
            if (status) {
                _this.modelService.getModelResourcesTotalCountByStatus(_this.model, status).then(function (resourcesTotal) {
                    _this.fromStatusResourcesTotal = resourcesTotal;
                    _this.loadingResourcesTotal = false;
                });
            }
            else {
                _this.fromStatusResourcesTotal = 0;
                _this.loadingResourcesTotal = false;
            }
        });
    };
    Object.defineProperty(MassMigrateDatamodelResourceStatusesModalComponent.prototype, "isSuperUser", {
        get: function () {
            return this.userService.user.superuser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MassMigrateDatamodelResourceStatusesModalComponent.prototype, "loading", {
        get: function () {
            return this.uploading;
        },
        enumerable: true,
        configurable: true
    });
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.close = function () {
        this.modal.dismiss('cancel');
    };
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.canSave = function () {
        return this.fromStatusResourcesTotal > 0 && this.toStatus$.value != null && (this.fromStatus$.value !== this.toStatus$.value);
    };
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.saveChanges = function () {
        var _this = this;
        var save = function () {
            var modalRef = _this.alertModalService.open('UPDATING_STATUSES_MESSAGE');
            _this.modelService.changeStatuses(_this.model, _this.fromStatus$.value, _this.toStatus$.value).then(function (result) {
                if (_this.fromStatusResourcesTotal === 1) {
                    modalRef.message = _this.translateService.instant('Status changed to one resource.');
                }
                else {
                    var messagePart1 = _this.translateService.instant('Status changed to ');
                    var messagePart2 = _this.translateService.instant(' resources.');
                    modalRef.message = messagePart1 + _this.fromStatusResourcesTotal + messagePart2;
                }
                modalRef.showOkButton = true;
                _this.modal.close(false);
            }, function (error) {
                _this.uploading = false;
                _this.errorModalService.openSubmitError(error);
                modalRef.cancel();
            });
        };
        if (changeToRestrictedStatus(this.fromStatus$.value, this.toStatus$.value)) {
            this.confirmationModal.openChangeToRestrictedStatus().then(function () { return save(); }, ignoreModalClose);
        }
        else {
            save();
        }
    };
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.toggleEnforceTransitionRulesForSuperUserToo = function () {
        this.enforceTransitionRulesForSuperUserToo = !this.enforceTransitionRulesForSuperUserToo;
        this.reset();
    };
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.reset = function () {
        var _this = this;
        this.toStatus$.next(null);
        this.fromStatus$.next(null);
        if (this.isSuperUser && !this.enforceTransitionRulesForSuperUserToo) {
            this.fromOptions = [null].concat(selectableStatuses).map(function (status) { return ({
                value: status,
                name: function () { return _this.translateService.instant(status ? status : 'Choose starting status'); },
                idIdentifier: function () { return status ? status : 'all_selected'; }
            }); });
            this.toOptions = [null].concat(selectableStatuses).map(function (stat) { return ({
                value: stat,
                name: function () { return _this.translateService.instant(stat ? stat : 'Choose target status'); },
                idIdentifier: function () { return stat ? stat : 'all_selected'; }
            }); });
        }
        else if ((this.isSuperUser && this.enforceTransitionRulesForSuperUserToo) || !this.isSuperUser) {
            this.fromOptions = [null].concat(this.fromStatuses).map(function (status) { return ({
                value: status,
                name: function () { return _this.translateService.instant(status ? status : 'Choose starting status'); },
                idIdentifier: function () { return status ? status : 'all_selected'; }
            }); });
            this.toOptions = [null].concat(this.toStatuses).map(function (stat) { return ({
                value: stat,
                name: function () { return _this.translateService.instant(stat ? stat : 'Choose target status'); },
                idIdentifier: function () { return stat ? stat : 'all_selected'; }
            }); });
        }
        combineLatest(this.fromStatus$, this.toStatus$).subscribe(function (_a) {
            var fromStatus = _a[0], toStatus = _a[1];
            var chosenFromStatus = fromStatus;
            var allowedToStatuses = chosenFromStatus ? allowedTargetStatuses(chosenFromStatus, false) : _this.toStatuses;
            if (!_this.isSuperUser || (_this.isSuperUser && _this.enforceTransitionRulesForSuperUserToo)) {
                _this.toOptions = [null].concat(allowedToStatuses).map(function (stat) { return ({
                    value: stat,
                    name: function () { return _this.translateService.instant(stat ? stat : 'Choose target status'); },
                    idIdentifier: function () { return stat ? stat : 'all_selected'; }
                }); });
            }
        });
    };
    MassMigrateDatamodelResourceStatusesModalComponent.prototype.showFromStatusResourcesTotal = function () {
        return this.fromStatus$.value !== null && !this.loadingResourcesTotal;
    };
    return MassMigrateDatamodelResourceStatusesModalComponent;
}());
export { MassMigrateDatamodelResourceStatusesModalComponent };
var MassMigrateDatamodelResourceStatusesModalService = /** @class */ (function () {
    function MassMigrateDatamodelResourceStatusesModalService(modalService) {
        this.modalService = modalService;
    }
    MassMigrateDatamodelResourceStatusesModalService.prototype.open = function (model) {
        var modalRef = this.modalService.open(MassMigrateDatamodelResourceStatusesModalComponent, { size: 'sm', backdrop: 'static', keyboard: false });
        var instance = modalRef.componentInstance;
        instance.model = model;
        return modalRef.result;
    };
    return MassMigrateDatamodelResourceStatusesModalService;
}());
export { MassMigrateDatamodelResourceStatusesModalService };
