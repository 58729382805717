<div class="content-box">
  <div *ngIf="!model">
    <app-ajax-loading-indicator></app-ajax-loading-indicator>
    <div class="bottom-hack-border"></div>
    <div class="bottom-hack-padding"></div>
  </div>
  <div *ngIf="model">
    <div class="header">
      <div class="row">
        <div class="nameButtonRow col-12">
          <h2><span class="mr-4">{{model!.label | translateValue}}</span></h2>
          <div *ngIf="hasSubscription" class="nameButtonRowButton subscription">
            <span class="subscription-icon icon-bell"
                  ngbTooltip="{{ 'You will receive an email notification when this resource changes.' | translate }}"></span>
          </div>
          <model-language-chooser class="nameButtonRowButton" [context]="model"></model-language-chooser>
          <export-component class="ml-2 nameButtonRowButton" [idPrefix]="'model'" [entity]="model" [context]="model"></export-component>
          <model-action-menu class="ml-2 nameButtonRowButton"
                             [isMessagingEnabled]="isMessagingEnabled"
                             [hasSubscription]="hasSubscription"
                             [entity]="model"
                             [context]="model"
                             [editing]="editing()"
                             (changeHasSubscription)="changeHasSubscription($event)">
          </model-action-menu>
        </div>
      </div>
      <div class="row">
        <div class="informationRow col-12">
          <app-status class="status" [status]="model!.status"></app-status>
          <span class="inRowTitle"><span translate>Information domain</span>:</span>
          <span class="information-domains">
            <span class="badge badge-light" *ngFor="let domain of model!.classifications">
              {{domain.label | translateValue:true}}
            </span>
          </span>
          <span class="inRowTitle"><span translate>Organization</span>:</span>
          <ul class="organizations dot-separated-list">
            <li class="organization" *ngFor="let contributor of model!.contributors">
              {{(contributor.parentOrg ? contributor.parentOrg.label : contributor.label) | translateValue:true}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ngb-tabset #tabs (tabChange)="onTabChange($event)">
      <ngb-tab id="dataTab" [title]="'Datamodel definition' | translate">
        <ng-template ngbTabContent>
          <model-page [parent]="editorContainer" [currentSelection]="currentModelAndSelection"
                      (makeSelection)="onSubSelection($event)" (updateNamespaces)="onNamespacesChange($event)"></model-page>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="modelDetailsTab" [title]="'Datamodel details' | translate">
        <ng-template ngbTabContent>
          <model-view [parent]="editorContainer" [model]="model" [id]="'model'"
                      (deleted)="onModelDeleted($event)" (updated)="onModelUpdated($event)"
                      [namespacesInUse]="namespacesInUse"></model-view>
          <div class="bottom-hack-border"></div>
          <div class="bottom-hack-padding"></div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="docTab" [title]="'Datamodel documentation' | translate">
        <ng-template ngbTabContent>
          <model-documentation
            id='modelDocumentation'
            [parent]="editorContainer"
            [model]="model"
            (updated)="onModelUpdated($event)"></model-documentation>
          <div class="bottom-hack-border"></div>
          <div class="bottom-hack-padding"></div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
