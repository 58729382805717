var AddEditLinkModal = /** @class */ (function () {
    function AddEditLinkModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    AddEditLinkModal.prototype.open = function (lang, linkToEdit) {
        return this.$uibModal.open({
            template: require('./addEditLinkModal.html'),
            size: 'sm',
            controller: AddEditLinkModalController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                lang: function () { return lang; },
                linkToEdit: function () { return linkToEdit; }
            }
        }).result;
    };
    AddEditLinkModal.prototype.openAdd = function (lang) {
        return this.open(lang, null);
    };
    AddEditLinkModal.prototype.openEdit = function (link, lang) {
        return this.open(lang, link);
    };
    return AddEditLinkModal;
}());
export { AddEditLinkModal };
var AddEditLinkModalController = /** @class */ (function () {
    function AddEditLinkModalController($uibModalInstance, modelService, lang, linkToEdit) {
        'ngInject';
        this.$uibModalInstance = $uibModalInstance;
        this.modelService = modelService;
        this.lang = lang;
        this.linkToEdit = linkToEdit;
        this.edit = !!linkToEdit;
        if (linkToEdit) {
            this.title = linkToEdit.title[lang];
            this.description = linkToEdit.description[lang];
            this.homepage = linkToEdit.homepage;
        }
    }
    Object.defineProperty(AddEditLinkModalController.prototype, "confirmLabel", {
        get: function () {
            return this.edit ? 'Edit' : 'Create new';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEditLinkModalController.prototype, "titleLabel", {
        get: function () {
            return this.edit ? 'Edit link' : 'Add link';
        },
        enumerable: true,
        configurable: true
    });
    AddEditLinkModalController.prototype.create = function () {
        var _this = this;
        if (this.edit) {
            this.linkToEdit.title[this.lang] = this.title;
            this.linkToEdit.description[this.lang] = this.description;
            this.linkToEdit.homepage = this.homepage;
            this.$uibModalInstance.close(this.linkToEdit);
        }
        else {
            this.modelService.newLink(this.title, this.description, this.homepage, this.lang)
                .then(function (link) { return _this.$uibModalInstance.close(link); });
        }
    };
    AddEditLinkModalController.prototype.cancel = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return AddEditLinkModalController;
}());
