import { EventEmitter, SimpleChanges } from '@angular/core';
import { Model } from '../../entities/model';
import { Predicate, PredicateListItem } from '../../entities/predicate';
import { GettextCatalogWrapper } from '../../ajs-upgraded-providers';
import { DisplayItemFactory } from '../form/displayItemFactory';
import { modalCancelHandler } from '../../utils/angular';
import { ShowPredicateInfoModal } from './showPredicateInfoModal';
import { TranslateService } from '@ngx-translate/core';
import { makeSimpleSearchRegexp } from 'yti-common-ui/utils/search';
var SearchPredicateTableModalContentComponent = /** @class */ (function () {
    function SearchPredicateTableModalContentComponent(gettextCatalogWrapper, translateService, displayItemFactory, showPredicateInfoModal) {
        this.gettextCatalogWrapper = gettextCatalogWrapper;
        this.translateService = translateService;
        this.displayItemFactory = displayItemFactory;
        this.showPredicateInfoModal = showPredicateInfoModal;
        this.searchResults = [];
        this.itemSelected = new EventEmitter();
        this.loadMore = new EventEmitter();
    }
    Object.defineProperty(SearchPredicateTableModalContentComponent.prototype, "infoLinkTitle", {
        get: function () {
            return this.translateService.instant("Show " + this.type + " information");
        },
        enumerable: true,
        configurable: true
    });
    SearchPredicateTableModalContentComponent.prototype.ngOnChanges = function (changes) {
        var searchChange = changes['searchText'];
        if (searchChange) {
            if (searchChange.currentValue && typeof searchChange.currentValue === 'string') {
                this.simpleSearchRegexp = makeSimpleSearchRegexp(searchChange.currentValue);
            }
            else {
                this.simpleSearchRegexp = undefined;
            }
        }
    };
    SearchPredicateTableModalContentComponent.prototype.isSelected = function (item) {
        return this.selectedItem === item;
    };
    SearchPredicateTableModalContentComponent.prototype.isLoadingSelection = function (item) {
        var selection = this.selection;
        return item === this.selectedItem && (!selection || (selection instanceof Predicate && !item.id.equals(selection.id)));
    };
    SearchPredicateTableModalContentComponent.prototype.searchResultID = function (item) {
        return item.id.toString() + "_search_class_link";
    };
    SearchPredicateTableModalContentComponent.prototype.itemTitle = function (item) {
        var disabledReason = this.exclude(item);
        if (!!disabledReason) {
            return this.gettextCatalogWrapper.gettextCatalog.getString(disabledReason);
        }
        else {
            return '';
        }
    };
    SearchPredicateTableModalContentComponent.prototype.showItemValue = function (value) {
        var _this = this;
        return this.displayItemFactory.create({
            context: function () { return _this.model; },
            value: function () { return value; }
        }).displayValue;
    };
    SearchPredicateTableModalContentComponent.prototype.showClassInfo = function () {
        return this.showPredicateInfoModal.open(this.model, this.selection).then(null, modalCancelHandler);
    };
    SearchPredicateTableModalContentComponent.prototype.predicateInfoLinkID = function (item) {
        return "show_predicate_info_" + item.id.toString() + "_link";
    };
    SearchPredicateTableModalContentComponent.prototype.glyphIconStyle = function (item) {
        var styles = Array.from(item.glyphIconClass);
        styles.push('pr-1');
        return styles;
    };
    SearchPredicateTableModalContentComponent.prototype.trackBy = function (index, item) {
        return item.id;
    };
    SearchPredicateTableModalContentComponent.prototype.scrollEnd = function (info) {
        if (info.endIndex === this.searchResults.length - 1) {
            this.loadMore.emit(info.endIndex);
        }
    };
    return SearchPredicateTableModalContentComponent;
}());
export { SearchPredicateTableModalContentComponent };
