var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { AuthorizationManagerServiceWrapper, ConfigServiceWrapper, ModelServiceWrapper } from "app/ajs-upgraded-providers";
import { LanguageService } from "app/services/languageService";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorModalService } from "yti-common-ui/components/error-modal.component";
var ModelDocumentationComponent = /** @class */ (function () {
    function ModelDocumentationComponent(configServiceWrapper, modelServiceWrapper, 
    // TODO: hybrid angular app problem: this should be a wrapper,
    // or the other services shouldn't be wrappers?
    languageService, authorizationManagerServiceWrapper, errorModalService) {
        this.configServiceWrapper = configServiceWrapper;
        this.languageService = languageService;
        this.errorModalService = errorModalService;
        this.modelInEdit = null;
        this.updated = new EventEmitter();
        this.editorMode = 'preview';
        this.canEdit = false; // permissions to edit?
        this.editing = false; // editor in edit mode
        this.persisting = false; // in process of saving
        this.options = {
            enablePreviewContentClick: true,
            markedjsOpt: {
                breaks: true
            }
        };
        this.destroy$ = new Subject();
        this.modelService = modelServiceWrapper.modelService;
        this.authorizationManagerService = authorizationManagerServiceWrapper.authorizationManagerService;
    }
    ModelDocumentationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.parent.registerView(this);
        this.configServiceWrapper.configService
            .getConfig()
            .then(function (config) {
            _this.config = config;
        });
        this.select(this.model);
        this.updateModelLanguage();
        this.languageService.modelLanguageChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            _this.updateModelLanguage();
        });
        this.canEdit = this.model ?
            this.authorizationManagerService.canEditModel(this.model) :
            false;
    };
    ModelDocumentationComponent.prototype.updateModelLanguage = function () {
        this.modelLanguage = this.fallbackModelLanguage = this.model ?
            this.languageService.getModelLanguage(this.model) :
            this.languageService.getModelLanguage();
        // if current language has no content, for viewing prefer a
        // language that has content
        if (this.modelInEdit &&
            (!this.modelInEdit.documentation[this.modelLanguage] ||
                !this.modelInEdit.documentation[this.modelLanguage].length)) {
            // take list of languages from model as potential fallbacks,
            // and sort them to prefer fi
            var fallbacks = (this.model && this.model.language && this.model.language.length) ?
                this.model.language : ['fi'];
            fallbacks.sort(function (a, b) { return b === 'fi' ? 1 : -1; });
            this.fallbackModelLanguage = fallbacks[0];
        }
        this.activeModelLanguage = this.editing ?
            this.activeModelLanguage :
            this.fallbackModelLanguage;
    };
    ModelDocumentationComponent.prototype.ngOnDestroy = function () {
        this.parent.deregisterView(this);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    ModelDocumentationComponent.prototype.ngOnChanges = function (changes) {
        if (changes.model) {
            this.authorizationManagerService.canEditModel(changes.model.currentValue);
        }
    };
    ModelDocumentationComponent.prototype.hidePreview = function () {
    };
    ModelDocumentationComponent.prototype.getEditable = function () {
        return this.model;
    };
    ModelDocumentationComponent.prototype.setEditable = function (model) {
        this.model = model;
    };
    ModelDocumentationComponent.prototype.select = function (model) {
        this.setEditable(model);
        this.modelInEdit = model ? model.clone() : null;
        if (model && model.unsaved) {
            this.edit();
        }
        else {
            this.cancelEditing();
        }
    };
    ModelDocumentationComponent.prototype.edit = function () {
        this.editorMode = 'editor';
        this.editing = true;
        this.activeModelLanguage = this.modelLanguage;
        this.setEnableContentClickOption();
    };
    ModelDocumentationComponent.prototype.cancel = function () {
        var editable = this.getEditable();
        this.select(!editable ? null : editable.unsaved ? null : editable);
        this.editorMode = 'preview';
        this.editing = false;
        this.activeModelLanguage = this.fallbackModelLanguage;
        this.setEnableContentClickOption();
    };
    ModelDocumentationComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var editable, modelInEdit, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editorMode = 'preview';
                        if (!this.modelInEdit) {
                            throw new Error('modelInEdit not set!');
                        }
                        editable = this.getEditable();
                        if (!editable) {
                            throw new Error('editable model not set!');
                        }
                        modelInEdit = this.modelInEdit;
                        this.persisting = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.modelService.updateModel(modelInEdit)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.persisting = false;
                        this.errorModalService.openSubmitError(error_1);
                        throw new Error(error_1);
                    case 4:
                        this.select(this.modelInEdit);
                        this.updated.emit(modelInEdit);
                        this.updateModelLanguage();
                        this.activeModelLanguage = this.fallbackModelLanguage;
                        this.persisting = false;
                        this.editing = false;
                        this.setEnableContentClickOption();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModelDocumentationComponent.prototype.isEditing = function () {
        return this.editing;
    };
    ModelDocumentationComponent.prototype.cancelEditing = function () {
        if (!this.isEditing()) {
            return;
        }
        this.editorMode = 'preview';
        this.editing = false;
        var editable = this.getEditable();
        this.select(!editable ? null : editable.unsaved ? null : editable);
        this.setEnableContentClickOption();
    };
    ModelDocumentationComponent.prototype.setEnableContentClickOption = function () {
        // During editing clicking links is disabled, 
        // because by accidental click all changes in the editor are lost
        if (this.editing) {
            this.options.enablePreviewContentClick = false;
        }
        else {
            this.options.enablePreviewContentClick = true;
        }
        this.options = Object.assign({}, this.options);
    };
    return ModelDocumentationComponent;
}());
export { ModelDocumentationComponent };
