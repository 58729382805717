var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Model } from './entities/model';
import { BehaviorSubject } from 'rxjs';
var ModelPageDirective = /** @class */ (function (_super) {
    __extends(ModelPageDirective, _super);
    function ModelPageDirective(elementRef, injector) {
        return _super.call(this, 'modelPage', elementRef, injector) || this;
    }
    return ModelPageDirective;
}(UpgradeComponent));
export { ModelPageDirective };
var ModelViewDirective = /** @class */ (function (_super) {
    __extends(ModelViewDirective, _super);
    function ModelViewDirective(elementRef, injector) {
        return _super.call(this, 'modelView', elementRef, injector) || this;
    }
    return ModelViewDirective;
}(UpgradeComponent));
export { ModelViewDirective };
var ModelLanguageChooserDirective = /** @class */ (function (_super) {
    __extends(ModelLanguageChooserDirective, _super);
    function ModelLanguageChooserDirective(elementRef, injector) {
        return _super.call(this, 'modelLanguageChooser', elementRef, injector) || this;
    }
    return ModelLanguageChooserDirective;
}(UpgradeComponent));
export { ModelLanguageChooserDirective };
var ExportDirective = /** @class */ (function (_super) {
    __extends(ExportDirective, _super);
    function ExportDirective(elementRef, injector) {
        return _super.call(this, 'export', elementRef, injector) || this;
    }
    return ExportDirective;
}(UpgradeComponent));
export { ExportDirective };
var ModelActionMenuDirective = /** @class */ (function (_super) {
    __extends(ModelActionMenuDirective, _super);
    function ModelActionMenuDirective(elementRef, injector) {
        return _super.call(this, 'modelActionMenu', elementRef, injector) || this;
    }
    return ModelActionMenuDirective;
}(UpgradeComponent));
export { ModelActionMenuDirective };
var SortByColumnHeaderDirective = /** @class */ (function (_super) {
    __extends(SortByColumnHeaderDirective, _super);
    function SortByColumnHeaderDirective(elementRef, injector) {
        return _super.call(this, 'sortByColumnHeader', elementRef, injector) || this;
    }
    return SortByColumnHeaderDirective;
}(UpgradeComponent));
export { SortByColumnHeaderDirective };
var HighlightDirective = /** @class */ (function (_super) {
    __extends(HighlightDirective, _super);
    function HighlightDirective(elementRef, injector) {
        return _super.call(this, 'highlight', elementRef, injector) || this;
    }
    return HighlightDirective;
}(UpgradeComponent));
export { HighlightDirective };
var NewDatamodelVersionPrefixModalFormDirective = /** @class */ (function (_super) {
    __extends(NewDatamodelVersionPrefixModalFormDirective, _super);
    function NewDatamodelVersionPrefixModalFormDirective(elementRef, injector) {
        return _super.call(this, 'newDatamodelVersionPrefixModalForm', elementRef, injector) || this;
    }
    return NewDatamodelVersionPrefixModalFormDirective;
}(UpgradeComponent));
export { NewDatamodelVersionPrefixModalFormDirective };
