var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GraphNode } from './graphNode';
import { localizableSerializer, stringSerializer } from './serializer/serializer';
import { init } from './mapping';
import { entityAwareList, uriSerializer } from './serializer/entitySerializer';
var Classification = /** @class */ (function (_super) {
    __extends(Classification, _super);
    function Classification(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Classification.classificationMappings);
        return _this;
    }
    Classification.classificationMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        identifier: { name: 'identifier', serializer: stringSerializer },
        order: { name: 'order', serializer: stringSerializer },
        hasPart: { name: 'hasPart', serializer: entityAwareList(uriSerializer) }
    };
    return Classification;
}(GraphNode));
export { Classification };
