var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LegacyComponent } from 'app/utils/angular';
var clipboardImage = require('../../../assets/clippy.svg');
var ClipboardComponent = /** @class */ (function () {
    function ClipboardComponent(gettextCatalog, $timeout) {
        'ngInject';
        this.gettextCatalog = gettextCatalog;
        this.$timeout = $timeout;
        this.showCopiedMessage = false;
        this.clipboardImage = clipboardImage;
    }
    ClipboardComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(ClipboardComponent.prototype, "copyInfo", {
        get: function () {
            return this.gettextCatalog.getString('Copy "{{text}}" to clipboard', { text: this.text });
        },
        enumerable: true,
        configurable: true
    });
    ClipboardComponent.prototype.onCopy = function () {
        var _this = this;
        this.showCopiedMessage = true;
        this.$timeout(function () { return _this.showCopiedMessage = false; }, 2000);
    };
    ClipboardComponent = __decorate([
        LegacyComponent({
            bindings: {
                text: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n    <img ng-src=\"{{$ctrl.clipboardImage}}\" class=\"svg-icon\"\n         ng-if=\"$ctrl.text && !$ctrl.isEditing()\" \n         uib-tooltip=\"{{$ctrl.copyInfo}}\"\n         uib-popover=\"{{'Copied' | translate}}\"\n         popover-is-open=\"$ctrl.showCopiedMessage\"\n         popover-trigger=\"none\"\n         ngclipboard \n         ngclipboard-success=\"$ctrl.onCopy()\"\n         data-clipboard-text=\"{{$ctrl.text}}\" />\n    "
        }),
        __metadata("design:paramtypes", [Object, Function])
    ], ClipboardComponent);
    return ClipboardComponent;
}());
export { ClipboardComponent };
