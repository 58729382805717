var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DisplayItemFactory } from './displayItemFactory';
import { LegacyComponent } from 'app/utils/angular';
import { isExternalLink } from 'app/components/form/href';
var NG_HIDE_CLASS = 'ng-hide';
var NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';
var EditableComponent = /** @class */ (function () {
    function EditableComponent($scope, $parse, $animate, $element, displayItemFactory) {
        'ngInject';
        this.$scope = $scope;
        this.$parse = $parse;
        this.$animate = $animate;
        this.$element = $element;
        this.displayItemFactory = displayItemFactory;
    }
    Object.defineProperty(EditableComponent.prototype, "value", {
        get: function () {
            return this.inputNgModelCtrl && this.inputNgModelCtrl.$modelValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableComponent.prototype, "inputId", {
        get: function () {
            return this.input && this.input.attr('id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableComponent.prototype, "required", {
        get: function () {
            return !this.disable && this.input && (this.input.attr('required') || (this.inputNgModelCtrl && 'requiredLocalized' in this.inputNgModelCtrl.$validators));
        },
        enumerable: true,
        configurable: true
    });
    EditableComponent.prototype.$onInit = function () {
        var _this = this;
        // we need to know if handler was set or not so parse ourselves instead of using scope '&'
        var clickHandler = this.$parse(this.onClick);
        var onClick = this.onClick ? function (value) { return clickHandler(_this.$scope.$parent, { value: value }); } : undefined;
        this.item = this.displayItemFactory.create({
            context: function () { return _this.context; },
            value: function () { return _this.value; },
            link: function () { return _this.link; },
            valueAsLocalizationKey: this.valueAsLocalizationKey,
            onClick: onClick
        });
    };
    EditableComponent.prototype.$postLink = function () {
        var _this = this;
        this.input = this.$element.find('[ng-model]');
        this.inputNgModelCtrl = this.input.controller('ngModel');
        this.$scope.$watch(function () { return _this.item.displayValue || _this.isEditing(); }, function (show) {
            _this.$animate[show ? 'removeClass' : 'addClass'](_this.$element, NG_HIDE_CLASS, {
                tempClasses: NG_HIDE_IN_PROGRESS_CLASS
            });
        });
        // move error messages element next to input
        this.input.after(this.$element.find('error-messages').detach());
        this.$scope.$watch(function () { return _this.isEditing(); }, function (currentEditing) {
            var shouldFocus = _this.autofocus && _this.$scope.$parent.$eval(_this.autofocus);
            if (shouldFocus && currentEditing) {
                setTimeout(function () { return _this.input.focus(); }, 0);
            }
        });
        // TODO: prevent hidden and non-editable fields participating validation with some more obvious mechanism
        this.$scope.$watchCollection(function () { return Object.keys(_this.inputNgModelCtrl.$error); }, function (keys) {
            if (!_this.isEditing()) {
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var key = keys_1[_i];
                    _this.inputNgModelCtrl.$setValidity(key, true);
                }
            }
        });
    };
    EditableComponent.prototype.isEditing = function () {
        return this.form && (this.form.editing || this.form.pendingEdit) && !this.disable;
    };
    EditableComponent.prototype.isExternalLink = function (link) {
        return isExternalLink(link);
    };
    EditableComponent = __decorate([
        LegacyComponent({
            bindings: {
                title: '@',
                link: '=',
                valueAsLocalizationKey: '@',
                disable: '=',
                context: '=',
                onClick: '@',
                clipboard: '=',
                autofocus: '@'
            },
            template: require('./editable.html'),
            transclude: true,
            require: {
                form: '?^form'
            }
        }),
        __metadata("design:paramtypes", [Object, Function, Object, Object, DisplayItemFactory])
    ], EditableComponent);
    return EditableComponent;
}());
export { EditableComponent };
