var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Uri } from '../../entities/uri';
import { LanguageService } from '../../services/languageService';
import { allMatching, anyMatching } from 'yti-common-ui/utils/array';
import { hasLocalization } from '../../utils/language';
import { Attribute, Predicate } from '../../entities/predicate';
import { LegacyComponent } from '../../utils/angular';
var PropertyViewComponent = /** @class */ (function () {
    function PropertyViewComponent($scope, $element, languageService, predicateService, $q) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$element = $element;
        this.languageService = languageService;
        this.predicateService = predicateService;
        this.$q = $q;
        this.valueClassExclude = function (valueClass) {
            return anyMatching(_this.class.properties, function (p) { return p !== _this.property && _this.property.predicateId.equals(p.predicateId) && valueClass.equals(p.valueClass); })
                ? 'Duplicate association target' : null;
        };
        this.comparablePropertiesDataSource = function (search) {
            var predicateCache = {};
            var arrayOfPromises = _this.comparableProperties.map(function (prop) { return prop.predicate; }).filter(function (predicate) {
                if (predicate instanceof Attribute) {
                    return !search || predicate.id.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }
                else if (predicate instanceof Uri) {
                    return !search || predicate.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }
                return false;
            }).map(function (predicate) {
                if (predicate instanceof Attribute) {
                    return _this.$q.resolve(predicate);
                }
                else if (predicate instanceof Uri) {
                    var str = predicate.toString();
                    if (!predicateCache[str]) {
                        predicateCache[str] =
                            _this.model.isNamespaceKnownToBeNotModel(predicate.namespace)
                                ? _this.predicateService.getExternalPredicate(predicate, _this.model)
                                : _this.predicateService.getPredicate(predicate, _this.model);
                    }
                    return predicateCache[str];
                }
                console.error('Invalid predicate: ' + predicate);
                return undefined;
            }).filter(function (x) { return !!x; });
            var promiseOfArray = _this.$q.all(arrayOfPromises);
            return promiseOfArray.then(function (array) { return array.filter(function (predicate) {
                var ret = !!predicate && predicate instanceof Attribute;
                if (!ret) {
                    console.log('Rejecting attribute candidate: "' + (!!predicate ? predicate.id.toString() : 'null') + '". Loading external attribute may have failed.');
                }
                return ret;
            }); });
        };
    }
    Object.defineProperty(PropertyViewComponent.prototype, "otherPropertyLabels", {
        get: function () {
            return this.otherProperties.map(function (property) { return property.label; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "otherAttributeLabels", {
        get: function () {
            return this.property.normalizedPredicateType === 'attribute' ? this.otherProperties.filter(function (property) { return property.normalizedPredicateType === 'attribute'; }).map(function (property) { return property.label; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "otherPropertyIdentifiers", {
        get: function () {
            return this.otherProperties.map(function (property) { return property.externalId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "otherPropertyResourceIdentifiers", {
        get: function () {
            return this.otherProperties.map(function (property) { return property.resourceIdentifier; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "showAdditionalInformation", {
        get: function () {
            return hasLocalization(this.property.editorialNote);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "propertyPairCapable", {
        get: function () {
            return this.property.normalizedPredicateType === 'attribute' && this.comparableProperties.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "comparableProperties", {
        get: function () {
            var self = this.property;
            if (self.normalizedPredicateType === 'attribute') {
                return this.class.properties.filter(function (p) {
                    // NOTE: Data type equivalence requirement could be added here, but dropping it allows saying, e.g., "this 32 bit integer must be less than this 64 bit integer"
                    return !self.internalId.equals(p.internalId)
                        && p.normalizedPredicateType === 'attribute';
                });
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "predicateName", {
        get: function () {
            var predicate = this.property.predicate;
            if (predicate instanceof Predicate) {
                return this.languageService.translate(predicate.label, this.model);
            }
            else if (predicate instanceof Uri) {
                return predicate.compact;
            }
            else {
                throw new Error('Unsupported predicate: ' + predicate);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "predicateNameCompact", {
        get: function () {
            var predicate = this.property.predicate;
            if (predicate instanceof Uri) {
                return predicate.compact;
            }
            else {
                throw new Error('Unsupported predicate: ' + predicate);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "propertyInformationLabel", {
        get: function () {
            switch (this.property.normalizedPredicateType) {
                case 'attribute':
                    return 'Attribute information';
                case 'association':
                    return 'Association information';
                default:
                    return 'Property information';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyViewComponent.prototype, "otherProperties", {
        get: function () {
            var _this = this;
            return this.class.properties.filter(function (property) { return property.internalId.notEquals(_this.property.internalId); });
        },
        enumerable: true,
        configurable: true
    });
    PropertyViewComponent.prototype.$onInit = function () {
        var _this = this;
        if (this.isOpen()) {
            this.scrollTo();
        }
        this.$scope.$watchCollection(function () { return _this.class && _this.class.properties; }, function (oldProperties) {
            var isPropertyAdded = allMatching(oldProperties, function (p) { return _this.property.internalId.notEquals(p.internalId); });
            if (_this.isOpen() && isPropertyAdded) {
                _this.scrollTo();
            }
        });
    };
    PropertyViewComponent.prototype.scrollTo = function (previousTop) {
        var _this = this;
        var scrollTop = this.$element.offset().top;
        if (!previousTop || scrollTop !== previousTop) {
            // wait for stabilization
            setTimeout(function () { return _this.scrollTo(scrollTop); }, 100);
        }
        else {
            jQuery('html, body').animate({ scrollTop: scrollTop - 105 }, 500);
        }
    };
    PropertyViewComponent.prototype.isOpen = function () {
        return this.classForm && this.classForm.openPropertyId === this.property.internalId.uuid;
    };
    PropertyViewComponent.prototype.isEditing = function () {
        return this.classForm && this.classForm.isEditing();
    };
    PropertyViewComponent.prototype.stemDatasource = function (_search) {
        return [
            new Uri('http://', {}),
            new Uri('https://', {}),
            new Uri('data:', {}),
            new Uri('mailto:', {}),
            new Uri('tel:', {}),
            new Uri('urn:', {})
        ];
    };
    PropertyViewComponent.prototype.removeProperty = function (property) {
        this.class.removeProperty(property);
    };
    PropertyViewComponent.prototype.linkToValueClass = function () {
        return this.model.linkToResource(this.property.valueClass);
    };
    PropertyViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '@',
                property: '=',
                class: '=',
                model: '='
            },
            require: {
                classForm: '^classForm'
            },
            template: require('./propertyView.html')
        }),
        __metadata("design:paramtypes", [Object, Object, LanguageService, Object, Function])
    ], PropertyViewComponent);
    return PropertyViewComponent;
}());
export { PropertyViewComponent };
