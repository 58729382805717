import * as moment from 'moment';
import { upperCaseFirst } from 'change-case';
import { assertNever, requireDefined } from 'yti-common-ui/utils/object';
import * as frames from '../entities/frames';
import { ImportedNamespace, Link, Model, ModelListItem } from '../entities/model';
import { apiEndpointWithName } from './config';
import { BehaviorSubject } from 'rxjs';
var DefaultModelService = /** @class */ (function () {
    function DefaultModelService($http, $q, frameService, defaultClassService, defaultPredicateService) {
        'ngInject';
        this.$http = $http;
        this.$q = $q;
        this.frameService = frameService;
        this.defaultClassService = defaultClassService;
        this.defaultPredicateService = defaultPredicateService;
        this.contentExpired$ = new BehaviorSubject(undefined);
    }
    DefaultModelService.prototype.getModels = function () {
        var _this = this;
        return this.$http.get(apiEndpointWithName('model'))
            .then(function (response) { return _this.deserializeModelList(response.data); });
    };
    DefaultModelService.prototype.getModelByUrn = function (urn) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('model'), { params: { id: urn.toString() } })
            .then(function (response) { return _this.deserializeModelById(response.data, urn); });
    };
    DefaultModelService.prototype.getModelByPrefix = function (prefix) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('model'), { params: { prefix: prefix } })
            .then(function (response) { return _this.deserializeModelByPrefix(response.data, prefix); });
    };
    DefaultModelService.prototype.createModel = function (model) {
        return this.$http.put(apiEndpointWithName('model'), model.serialize())
            .then(function (response) {
            model.unsaved = false;
            model.version = response.data.identifier;
            model.createdAt = moment().utc();
            model.modifiedAt = moment().utc();
        });
    };
    DefaultModelService.prototype.updateModel = function (model) {
        return this.$http.post(apiEndpointWithName('model'), model.serialize(), { params: { id: model.id.uri } })
            .then(function (response) {
            model.version = response.data.identifier;
            model.modifiedAt = moment().utc();
        });
    };
    DefaultModelService.prototype.deleteModel = function (id) {
        var modelId = id.uri;
        this.defaultClassService.clearCachedClasses(modelId);
        this.defaultPredicateService.clearCachedPredicates(modelId);
        return this.$http.delete(apiEndpointWithName('model'), { params: { id: modelId } });
    };
    DefaultModelService.prototype.newModel = function (prefix, label, classifications, organizations, lang, type, redirect) {
        var _this = this;
        function mapEndpoint() {
            switch (type) {
                case 'library':
                    return 'modelCreator';
                case 'profile':
                    return 'profileCreator';
                default:
                    return assertNever(type, 'Unknown type: ' + type);
            }
        }
        return this.$http.get(apiEndpointWithName(mapEndpoint()), {
            params: {
                prefix: prefix,
                label: upperCaseFirst(label),
                lang: lang[0],
                langList: lang.join(' '),
                redirect: redirect && redirect.uri,
                serviceList: classifications.join(' '),
                orgList: organizations.join(' ')
            }
        })
            .then(function (response) { return _this.deserializeModel(response.data); })
            .then(function (model) {
            model.unsaved = true;
            return model;
        });
    };
    DefaultModelService.prototype.newModelRequirement = function (model, resourceUri) {
        return this.$http.put(apiEndpointWithName('newModelRequirement'), model.serialize(), { params: { model: model.id.uri, resource: resourceUri } })
            .then(function (response) {
            // TODO: Handling of the returned model
        });
    };
    DefaultModelService.prototype.newLink = function (title, description, homepage, lang) {
        var graph = {
            title: (_a = {},
                _a[lang] = title,
                _a),
            description: (_b = {},
                _b[lang] = description,
                _b),
            homepage: homepage.url
        };
        var frameObject = frames.modelFrame({ '@graph': graph, '@context': {} });
        return this.$q.when(new Link(graph, {}, frameObject));
        var _a, _b;
    };
    DefaultModelService.prototype.getAllImportableNamespaces = function () {
        var _this = this;
        return this.$http.get(apiEndpointWithName('listNamespaces'))
            .then(function (response) { return _this.deserializeImportedNamespaces(response.data); });
    };
    DefaultModelService.prototype.newNamespaceImport = function (namespace, prefix, label, lang) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('modelRequirementCreator'), { params: { namespace: namespace, prefix: prefix, label: label, lang: lang } })
            .then(function (response) { return _this.deserializeImportedNamespace(response.data); });
    };
    DefaultModelService.prototype.changeStatuses = function (model, initialStatus, endStatus) {
        var _this = this;
        return this.$http.put(apiEndpointWithName('changeStatuses'), null, { params: { model: model.id.uri, initialStatus: initialStatus, endStatus: endStatus } })
            .then(function () {
            var modelId = model.id.uri;
            _this.defaultClassService.clearCachedClasses(modelId);
            _this.defaultPredicateService.clearCachedPredicates(modelId);
            _this.contentExpired$.next(modelId);
        });
    };
    DefaultModelService.prototype.getModelResourcesTotalCountByStatus = function (model, resourceStatus) {
        return this.$http.get(apiEndpointWithName('integration/resources'), { params: { container: model.id.uri, status: resourceStatus, pageSize: 0 } })
            .then(function (response) { return response.data.meta.totalResults; });
    };
    DefaultModelService.prototype.createNewModelVersion = function (newPrefix, uri) {
        return this.$http.post(apiEndpointWithName('modelVersion'), null, { params: { newPrefix: newPrefix, uri: uri } })
            .then(function (response) { return response.data.uri; });
    };
    DefaultModelService.prototype.deserializeModelList = function (data) {
        return this.frameService.frameAndMapArray(data, frames.modelListFrame(data), function () { return ModelListItem; });
    };
    DefaultModelService.prototype.deserializeModel = function (data) {
        return this.frameService.frameAndMap(data, false, frames.modelFrame(data), function () { return Model; }).then(requireDefined);
    };
    DefaultModelService.prototype.deserializeModelById = function (data, id) {
        return this.frameService.frameAndMap(data, true, frames.modelFrame(data, { id: id }), function () { return Model; }).then(requireDefined);
    };
    DefaultModelService.prototype.deserializeModelByPrefix = function (data, prefix) {
        return this.frameService.frameAndMap(data, true, frames.modelFrame(data, { prefix: prefix }), function () { return Model; }).then(requireDefined);
    };
    DefaultModelService.prototype.deserializeImportedNamespace = function (data) {
        return this.frameService.frameAndMap(data, false, frames.namespaceFrame(data), function () { return ImportedNamespace; }).then(requireDefined);
    };
    DefaultModelService.prototype.deserializeImportedNamespaces = function (data) {
        return this.frameService.frameAndMapArray(data, frames.namespaceFrame(data), function () { return ImportedNamespace; });
    };
    return DefaultModelService;
}());
export { DefaultModelService };
