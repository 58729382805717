import { comparingLocalizable } from 'app/utils/comparator';
var SearchClassificationModal = /** @class */ (function () {
    function SearchClassificationModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchClassificationModal.prototype.open = function (exclude) {
        return this.$uibModal.open({
            template: require('./searchClassificationModal.html'),
            size: 'md',
            resolve: {
                exclude: function () { return exclude; }
            },
            controller: SearchClassificationModalController,
            controllerAs: '$ctrl'
        }).result;
    };
    return SearchClassificationModal;
}());
export { SearchClassificationModal };
var SearchClassificationModalController = /** @class */ (function () {
    function SearchClassificationModalController($scope, $uibModalInstance, classificationService, languageService, exclude) {
        'ngInject';
        var _this = this;
        this.$uibModalInstance = $uibModalInstance;
        this.languageService = languageService;
        classificationService.getClassifications()
            .then(function (classifications) {
            _this.classifications = classifications.filter(function (c) { return !exclude(c); });
            _this.classifications.sort(comparingLocalizable(_this.languageService.createLocalizer(), function (c) { return c.label; }));
        });
    }
    Object.defineProperty(SearchClassificationModalController.prototype, "loading", {
        get: function () {
            return this.classifications == null;
        },
        enumerable: true,
        configurable: true
    });
    SearchClassificationModalController.prototype.select = function (classification) {
        this.$uibModalInstance.close(classification);
    };
    SearchClassificationModalController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return SearchClassificationModalController;
}());
