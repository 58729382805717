var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ConfirmationModal } from './common/confirmationModal';
import { LegacyComponent, modalCancelHandler, nextUrl } from 'app/utils/angular';
import { LocationService } from 'app/services/locationService';
import { ConfigService } from 'app/services/configService';
var versionInfo = require('!raw-loader!../../version.txt');
var ApplicationComponent = /** @class */ (function () {
    function ApplicationComponent($scope, $location, $uibModalStack, userService, confirmationModal, locationService, configService) {
        'ngInject';
        var _this = this;
        this.$location = $location;
        this.locationService = locationService;
        this.subscriptions = [];
        this.subscriptions.push(userService.user$.subscribe(function () { return _this.applicationInitialized = true; }));
        this.version = versionInfo;
        $scope.$watch(function () { return $location.path(); }, function (path) {
            _this.showFooter = !path.startsWith('/model');
        });
        $scope.$on('$locationChangeStart', function (event, next) {
            var modal = $uibModalStack.getTop();
            if (!!modal) {
                var modalScope_1 = modal.value.modalScope;
                event.preventDefault();
                confirmationModal.openCloseModal().then(function () {
                    modalScope_1.$dismiss('cancel');
                    $location.url(nextUrl($location, next));
                }, modalCancelHandler);
            }
        });
    }
    Object.defineProperty(ApplicationComponent.prototype, "location", {
        get: function () {
            return this.locationService.location;
        },
        enumerable: true,
        configurable: true
    });
    ApplicationComponent.prototype.$onDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    ApplicationComponent.prototype.navigateToInformation = function () {
        this.$location.url('/information');
    };
    ApplicationComponent = __decorate([
        LegacyComponent({
            template: require('./application.html'),
        }),
        __metadata("design:paramtypes", [Object, Object, Object, Object, ConfirmationModal,
            LocationService,
            ConfigService])
    ], ApplicationComponent);
    return ApplicationComponent;
}());
export { ApplicationComponent };
