var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createNotification } from 'app/help/contract';
import * as ModelPage from 'app/help/pages/model/modelPage.po';
import * as ModelView from 'app/help/pages/model/modelView.po';
import * as ClassView from 'app/help/pages/model/classView.po';
import { helpLibrary, helpProfile } from 'app/help/providers/data';
import { classIdFromPrefixAndName, predicateIdFromPrefixAndName } from 'app/help/utils/id';
import { isExpectedProperty } from 'app/help/utils/init';
import * as VisualizationView from 'app/help/pages/model/visualizationView.po';
import { modelUrl } from 'app/utils/entity';
function addModelNamespace(type, model) {
    return {
        title: 'Guide through requiring a namespace',
        description: 'This tutorial shows how to import new namespace to the model',
        items: function () { return [
            ModelPage.openModelDetails(type),
            ModelView.modifyModel(type)
        ].concat(ModelView.UseCases.addModelNamespace(model), [
            ModelView.saveModelChanges,
            createNotification({
                title: { key: 'Congratulations for completing namespace require!' }
            })
        ]); }
    };
}
function specializeClass(klass, lang) {
    return {
        title: 'Guide through specializing a class',
        description: 'This tutorial shows how to create a new shape from a class',
        items: function () { return ModelPage.UseCases.specializeClass(klass, lang).concat([
            createNotification({
                title: { key: 'Congratulations for completing specialize class!' }
            })
        ]); }
    };
}
function assignClass(klass, lang) {
    return {
        title: 'Guide through assigning class to a library',
        description: 'This tutorial shows how to add Class from existing library',
        items: function () { return ModelPage.UseCases.assignClass(klass, lang).concat([
            createNotification({
                title: { key: 'Congratulations for completing class assignation!' }
            })
        ]); }
    };
}
function addAttribute(prefix, klass, predicate, lang) {
    return {
        title: 'Guide through adding an attribute',
        description: 'This tutorial shows how to add new attribute',
        items: function () { return [
            ModelPage.selectClass(prefix, klass, lang),
            ClassView.modifyClass
        ].concat(ClassView.UseCases.addPropertyUsingExistingPredicate(predicate, lang), [
            ClassView.saveClassChanges,
            createNotification({
                title: { key: 'Congratulations for completing adding an attribute!' }
            })
        ]); }
    };
}
function createNewClass(klass, propertyByExistingPredicate, lang) {
    return {
        title: 'Guide through creating a class',
        description: 'This tutorial shows how to create a new Class',
        items: function () { return ModelPage.UseCases.createNewClass(klass, lang).concat(ClassView.UseCases.addPropertyUsingExistingPredicate(propertyByExistingPredicate, lang), [
            ClassView.saveClassChanges,
            createNotification({
                title: { key: 'Congratulations for completing new class creation!' }
            })
        ]); }
    };
}
function addAssociation(prefix, klass, lang, addAssociationUseCase) {
    return {
        title: 'Guide through adding an association',
        description: 'This tutorial shows how to add association to a Class',
        items: function () { return [
            ModelPage.selectClass(prefix, klass, lang),
            ClassView.modifyClass
        ].concat(addAssociationUseCase, [
            ClassView.saveClassChanges,
            VisualizationView.focusVisualization,
            createNotification({
                title: { key: 'Congratulations for completing adding an association!' }
            })
        ]); }
    };
}
var ModelPageHelpService = /** @class */ (function () {
    function ModelPageHelpService(helpBuilderService) {
        'ngInject';
        this.helpBuilderService = helpBuilderService;
    }
    ModelPageHelpService.prototype.getHelps = function (modelType, currentModelPrefix, lang) {
        var navigation = {
            onStart: modelUrl(modelType === 'profile' ? helpProfile.model.prefix : helpLibrary.model.prefix),
            onEnd: modelUrl(currentModelPrefix)
        };
        var helps = this.helpBuilderService.create(navigation);
        if (modelType === 'profile') {
            helps.add(addModelNamespace(modelType, helpProfile.importedLibrary), function (loader) {
                loader.createModel(__assign({}, helpProfile.model, { namespaces: [] }));
            });
            helps.add(createNewClass(helpProfile.newClass, helpProfile.newClass.property.name, lang), function (loader) {
                loader.createModel(helpProfile.model);
            });
            helps.add(specializeClass(helpProfile.specializedClass, lang), function (loader) {
                loader.createModel(helpProfile.model);
            });
            helps.add(addAttribute(helpProfile.model.prefix, helpProfile.newClass, helpProfile.newClass.property.name, lang), function (loader) {
                var model = loader.createModel(helpProfile.model);
                loader.createClass(model, {
                    label: helpProfile.newClass.label,
                    comment: helpProfile.newClass.comment
                });
            });
            helps.add(addAssociation(helpProfile.model.prefix, helpProfile.newClass, lang, ClassView.UseCases.addPropertyBasedOnSuggestion(helpProfile.newClass.property.produced, lang).concat(ClassView.UseCases.addAssociationTarget(helpProfile.newClass.property.produced.target, lang))), function (loader) {
                var model = loader.createModel(helpProfile.model);
                loader.createClass(model, {
                    label: helpProfile.newClass.label,
                    comment: helpProfile.newClass.comment,
                    properties: [
                        { predicate: predicateIdFromPrefixAndName(helpProfile.newClass.property.name.prefix, helpProfile.newClass.property.name.details.label.fi) }
                    ]
                });
                loader.specializeClass(model, {
                    class: classIdFromPrefixAndName(helpProfile.specializedClass.prefix, helpProfile.specializedClass.details.label.fi),
                    propertyFilter: isExpectedProperty(helpProfile.specializedClass.properties)
                });
            });
        }
        else {
            //
            helps.add(addModelNamespace(modelType, helpLibrary.importedLibrary), function (loader) {
                loader.createModel(__assign({}, helpLibrary.model, { namespaces: [] }));
            });
            helps.add(createNewClass(helpLibrary.newClass, helpLibrary.newClass.property.name, lang), function (loader) {
                loader.createModel(helpLibrary.model);
            });
            helps.add(assignClass(helpLibrary.person, lang), function (loader) {
                loader.createModel(helpLibrary.model);
            });
            helps.add(addAttribute(helpLibrary.model.prefix, helpLibrary.newClass, helpLibrary.newClass.property.name, lang), function (loader) {
                var model = loader.createModel(helpLibrary.model);
                loader.createClass(model, {
                    label: helpLibrary.newClass.label,
                    comment: helpLibrary.newClass.comment,
                    subClassOf: classIdFromPrefixAndName(helpLibrary.newClass.superClass.prefix, helpLibrary.newClass.superClass.details.label.fi)
                });
            });
            helps.add(addAssociation(helpLibrary.model.prefix, helpLibrary.newClass, lang, ClassView.UseCases.addPropertyBasedOnExistingConcept(helpLibrary.newClass.property.owner, lang).concat(ClassView.UseCases.addAssociationTarget(helpLibrary.newClass.property.owner.target, lang))), function (loader) {
                var model = loader.createModel(helpLibrary.model);
                var passengersAttribute = loader.createAttribute(model, helpLibrary.newClass.property.passengers);
                loader.createClass(model, {
                    label: helpLibrary.newClass.label,
                    comment: helpLibrary.newClass.comment,
                    subClassOf: classIdFromPrefixAndName(helpLibrary.newClass.superClass.prefix, helpLibrary.newClass.superClass.details.label.fi),
                    properties: [
                        { predicate: predicateIdFromPrefixAndName(helpLibrary.newClass.property.name.prefix, helpLibrary.newClass.property.name.details.label.fi) },
                        { predicate: passengersAttribute },
                    ]
                });
                loader.assignClass(model, classIdFromPrefixAndName(helpLibrary.person.prefix, helpLibrary.person.details.label.fi));
                loader.assignClass(model, classIdFromPrefixAndName(helpLibrary.address.prefix, helpLibrary.address.details.label.fi));
                loader.assignClass(model, classIdFromPrefixAndName(helpLibrary.contact.prefix, helpLibrary.contact.details.label.fi));
            });
        }
        return helps.helps;
    };
    return ModelPageHelpService;
}());
export { ModelPageHelpService };
