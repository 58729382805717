var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Layout, EventType } from 'webcola';
import * as Iterable from 'yti-common-ui/utils/iterable';
import { requireDefined } from 'yti-common-ui/utils/object';
var debugPerformance = false;
var SimpleColaLayout = /** @class */ (function (_super) {
    __extends(SimpleColaLayout, _super);
    function SimpleColaLayout(nodes, links, allNodes, ready) {
        var _this = _super.call(this) || this;
        _this.ready = ready;
        _this.tickCount = 0;
        if (debugPerformance) {
            console.time('initial');
        }
        _this.nodes(nodes);
        _this.links(links);
        // Due to performance issues, restrict the usage of avoidOverlaps and jaccardLinkLength properties
        _this.avoidOverlaps(nodes.length < 100);
        _this.handleDisconnected(true);
        _this.jaccardLinkLengths(allNodes || links.length > 200 ? 30 : 300);
        _this.convergenceThreshold(0.005);
        return _this;
    }
    SimpleColaLayout.prototype.trigger = function (e) {
        switch (e.type) {
            case EventType.end:
                this.ready();
                break;
            case EventType.start:
            case EventType.tick:
                break;
            default:
                throw new Error('Unknown event');
        }
    };
    SimpleColaLayout.prototype.kick = function () {
        var _this = this;
        if (debugPerformance && this.tickCount === 0) {
            console.timeEnd('initial');
            console.time('async');
        }
        window.requestAnimFrame(function () {
            _this.tickCount++;
            if (!_this.tick()) {
                _this.kick();
            }
            else if (debugPerformance) {
                console.timeEnd('async');
            }
        });
    };
    SimpleColaLayout.prototype.drag = function () {
    };
    SimpleColaLayout.prototype.on = function (e, listener) {
        return _super.prototype.on.call(this, e, listener);
    };
    return SimpleColaLayout;
}(Layout));
function index(items) {
    function withId(item) {
        return [item.id, item];
    }
    return new Map(items.map(withId));
}
// scaling is based on only manual testing to get results that seem sensible
// there is probably better way to get positions that are not too tight
var allElementScaleCorrection = 20;
function hash(str) {
    var result = 0;
    for (var i = 0; i < str.length; i++) {
        result = ((result << 5) - result) + str.charCodeAt(i);
    }
    return result % 47;
}
export function layout(graph, onlyNodeIds) {
    if (onlyNodeIds === void 0) { onlyNodeIds = []; }
    var nodes = new Map();
    var links = [];
    var jointElements = index(graph.getElements());
    var onlyNodeIdsSet = new Set(onlyNodeIds);
    Iterable.forEach(jointElements.values(), function (element) {
        nodes.set(element.id, {
            id: element.id,
            x: (element.attributes.position.x || hash('x' + element.id)),
            y: (element.attributes.position.y || hash('y' + element.id)),
            width: element.attributes.size.width / allElementScaleCorrection,
            height: element.attributes.size.height / allElementScaleCorrection,
            fixed: (onlyNodeIdsSet.size > 0 && !onlyNodeIdsSet.has(element.id)) ? 1 : 0
        });
    });
    for (var _i = 0, _a = graph.getLinks(); _i < _a.length; _i++) {
        var link = _a[_i];
        links.push({
            source: requireDefined(nodes.get(link.attributes.source.id)),
            target: requireDefined(nodes.get(link.attributes.target.id))
        });
    }
    function findMin(iterable) {
        var iterator = iterable[Symbol.iterator]();
        var minX = null;
        var minY = null;
        for (var next = iterator.next(); next.value !== undefined; next = iterator.next()) {
            if (!minX || next.value.x < minX) {
                minX = next.value.x;
            }
            if (!minY || next.value.y < minY) {
                minY = next.value.y;
            }
        }
        return { x: minX, y: minY };
    }
    return new Promise(function (resolve) {
        var colaLayout = new SimpleColaLayout(Array.from(nodes.values()), links, onlyNodeIds.length === 0, function () {
            if (onlyNodeIds.length > 0) {
                for (var _i = 0, onlyNodeIds_1 = onlyNodeIds; _i < onlyNodeIds_1.length; _i++) {
                    var nodeId = onlyNodeIds_1[_i];
                    var node = nodes.get(nodeId);
                    var element = jointElements.get(nodeId);
                    element.position(node.x, node.y);
                }
            }
            else {
                var min_1 = findMin(nodes.values());
                Iterable.forEach(nodes.values(), function (node) {
                    var element = jointElements.get(node.id);
                    var normalizedX = (node.x - min_1.x) * allElementScaleCorrection;
                    var normalizedY = (node.y - min_1.y) * allElementScaleCorrection;
                    element.position(normalizedX, normalizedY);
                });
            }
            resolve(colaLayout.tickCount);
        });
        colaLayout.start(30, 0, 30, 0);
    });
}
