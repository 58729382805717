var ExternalEntity = /** @class */ (function () {
    function ExternalEntity(language, label, type) {
        this.language = language;
        this.label = label;
        this.type = type;
    }
    Object.defineProperty(ExternalEntity.prototype, "normalizedType", {
        get: function () {
            return this.type;
        },
        enumerable: true,
        configurable: true
    });
    return ExternalEntity;
}());
export { ExternalEntity };
