var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { requireDefined } from 'yti-common-ui/utils/object';
import { idToIndexMap } from 'app/utils/entity';
import { comparingDate } from 'app/utils/comparator';
import { init } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareList, entity, entityAwareOptional } from './serializer/entitySerializer';
import { dateSerializer, optional, stringSerializer } from './serializer/serializer';
var Activity = /** @class */ (function (_super) {
    __extends(Activity, _super);
    function Activity(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Activity.activityMappings);
        _this.versions = _this.versions.sort(comparingDate(function (e) { return e.createdAt; }));
        _this.versionIndex = idToIndexMap(_this.versions);
        return _this;
    }
    Activity.prototype.getVersion = function (version) {
        var index = this.versionIndex.get(version.toString());
        return requireDefined(index ? this.versions[index] : null);
    };
    Object.defineProperty(Activity.prototype, "latest", {
        get: function () {
            return this.getVersion(this.latestVersion);
        },
        enumerable: true,
        configurable: true
    });
    Activity.activityMappings = {
        id: { name: '@id', serializer: uriSerializer },
        createdAt: { name: 'startedAtTime', serializer: dateSerializer },
        lastModifiedBy: { name: 'name', serializer: optional(stringSerializer) },
        versions: { name: 'generated', serializer: entityAwareList(entity(function () { return Entity; })) },
        latestVersion: { name: 'used', serializer: uriSerializer }
    };
    return Activity;
}(GraphNode));
export { Activity };
var Entity = /** @class */ (function (_super) {
    __extends(Entity, _super);
    function Entity(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Entity.entityMappings);
        return _this;
    }
    Entity.prototype.getPrevious = function (activity) {
        return this.previousVersion ? activity.getVersion(this.previousVersion) : null;
    };
    Entity.entityMappings = {
        id: { name: '@id', serializer: uriSerializer },
        createdAt: { name: 'generatedAtTime', serializer: dateSerializer },
        createdBy: { name: 'name', serializer: optional(stringSerializer) },
        previousVersion: { name: 'wasRevisionOf', serializer: entityAwareOptional(uriSerializer) }
    };
    return Entity;
}(GraphNode));
export { Entity };
