var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SearchClassModal } from 'app/components/editor/searchClassModal';
import { requireDefined } from 'yti-common-ui/utils/object';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
var EditableRootClassComponent = /** @class */ (function () {
    function EditableRootClassComponent(searchClassModal) {
        'ngInject';
        this.searchClassModal = searchClassModal;
    }
    EditableRootClassComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(EditableRootClassComponent.prototype, "href", {
        get: function () {
            return this.model.linkToResource(this.model.rootClass);
        },
        enumerable: true,
        configurable: true
    });
    EditableRootClassComponent.prototype.selectClass = function () {
        var _this = this;
        var exclude = function (klass) {
            if (requireDefined(klass.definedBy).id.notEquals(_this.model.id)) {
                return 'Can be selected only from this ' + _this.model.normalizedType;
            }
            else {
                return null;
            }
        };
        this.searchClassModal.openWithOnlySelection(this.model, true, exclude)
            .then(function (klass) { return _this.model.rootClass = klass.id; }, modalCancelHandler);
    };
    EditableRootClassComponent.prototype.removeClass = function () {
        this.model.rootClass = null;
    };
    EditableRootClassComponent = __decorate([
        LegacyComponent({
            bindings: {
                model: '='
            },
            require: {
                form: '?^form'
            },
            template: require('./editableRootClass.html')
        }),
        __metadata("design:paramtypes", [SearchClassModal])
    ], EditableRootClassComponent);
    return EditableRootClassComponent;
}());
export { EditableRootClassComponent };
