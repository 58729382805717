import { ModalService } from 'yti-common-ui/services/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelServiceWrapper } from 'app/ajs-upgraded-providers';
import { AlertModalService } from 'yti-common-ui/components/alert-modal.component';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { TranslateService } from '@ngx-translate/core';
export var rootScopeFactory = function (i) { return i.get('$rootScope'); };
var NewDatamodelVersionModalComponent = /** @class */ (function () {
    function NewDatamodelVersionModalComponent(modal, alertModalService, errorModalService, translateService, modelServiceWrapper) {
        this.modal = modal;
        this.alertModalService = alertModalService;
        this.errorModalService = errorModalService;
        this.translateService = translateService;
        this.uploading = false;
        this.modelService = modelServiceWrapper.modelService;
    }
    Object.defineProperty(NewDatamodelVersionModalComponent.prototype, "loading", {
        get: function () {
            return this.uploading;
        },
        enumerable: true,
        configurable: true
    });
    NewDatamodelVersionModalComponent.prototype.saveNewVersion = function (prefix) {
        var _this = this;
        var modalRef = this.alertModalService.open('CREATING_NEW_MODEL_VERSION_MESSAGE');
        this.modelService.createNewModelVersion(prefix, this.model.id.uri).then(function (newUri) {
            modalRef.message = _this.translateService.instant('New version of datamodel is created') + ': ';
            modalRef.link = newUri;
            modalRef.showOkButton = true;
            _this.modal.close(false);
        }, function (error) {
            _this.uploading = false;
            _this.errorModalService.openSubmitError(error);
            modalRef.cancel();
        });
    };
    ;
    NewDatamodelVersionModalComponent.prototype.close = function () {
        this.modal.dismiss('cancel');
    };
    return NewDatamodelVersionModalComponent;
}());
export { NewDatamodelVersionModalComponent };
var NewDatamodelVersionModalService = /** @class */ (function () {
    function NewDatamodelVersionModalService(modalService) {
        this.modalService = modalService;
    }
    NewDatamodelVersionModalService.prototype.open = function (model) {
        var modalRef = this.modalService.open(NewDatamodelVersionModalComponent, { size: 'sm', backdrop: 'static', keyboard: false });
        var instance = modalRef.componentInstance;
        instance.model = model;
        return modalRef.result;
    };
    return NewDatamodelVersionModalService;
}());
export { NewDatamodelVersionModalService };
