var Config = /** @class */ (function () {
    function Config(groupManagementUrl, terminologyUrl, codeListUrl, commentsUrl, messagingEnabled, dev, env) {
        this.groupManagementUrl = groupManagementUrl;
        this.terminologyUrl = terminologyUrl;
        this.codeListUrl = codeListUrl;
        this.commentsUrl = commentsUrl;
        this.messagingEnabled = messagingEnabled;
        this.dev = dev;
        this.env = env;
    }
    Object.defineProperty(Config.prototype, "showIncompleteFeature", {
        get: function () {
            return this.dev;
        },
        enumerable: true,
        configurable: true
    });
    Config.prototype.conceptUrl = function (concept) {
        if (!concept) {
            return '';
        }
        else {
            return ''; // TODO
        }
    };
    Object.defineProperty(Config.prototype, "isMessagingEnabled", {
        get: function () {
            return this.messagingEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Config.prototype.getEnvironmentIdentifier = function (style) {
        if (this.env !== 'prod') {
            var identifier = this.env.toUpperCase();
            if (!style) {
                return identifier;
            }
            else if (style === 'prefix') {
                return identifier + ' - ';
            }
            else if (style === 'postfix') {
                return ' - ' + identifier;
            }
        }
        return '';
    };
    Config.prototype.getUriWithEnv = function (uri) {
        if (uri && this.env !== 'prod') {
            return uri + '?env=' + this.env;
        }
        return uri ? uri : null;
    };
    return Config;
}());
export { Config };
