import { ControlValueAccessor } from '@angular/forms';
import { profileUseContexts, libraryUseContexts } from 'app/types/entity';
var UseContextDropdownComponent = /** @class */ (function () {
    function UseContextDropdownComponent() {
        this.placement = 'bottom-left';
        this.propagateChange = function () { };
    }
    Object.defineProperty(UseContextDropdownComponent.prototype, "options", {
        get: function () {
            return this.modelType === 'profile' ? profileUseContexts : libraryUseContexts;
        },
        enumerable: true,
        configurable: true
    });
    UseContextDropdownComponent.prototype.isSelected = function (option) {
        return this.selection === option;
    };
    UseContextDropdownComponent.prototype.select = function (option) {
        this.selection = option;
        this.propagateChange(option);
    };
    UseContextDropdownComponent.prototype.writeValue = function (obj) {
        this.selection = obj;
    };
    UseContextDropdownComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    UseContextDropdownComponent.prototype.registerOnTouched = function (fn) {
    };
    return UseContextDropdownComponent;
}());
export { UseContextDropdownComponent };
