var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ConfirmationModal } from './confirmationModal';
import { Uri } from 'app/entities/uri';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
var AddNew = /** @class */ (function () {
    function AddNew(label, show, glyphiconClass) {
        this.label = label;
        this.show = show;
        this.glyphiconClass = glyphiconClass;
        this.id = Uri.randomUUID();
    }
    AddNew.prototype.unwrap = function () {
        return this;
    };
    AddNew.prototype.isAddNew = function () {
        return true;
    };
    return AddNew;
}());
export { AddNew };
var SearchResult = /** @class */ (function () {
    function SearchResult(item, disabledReason) {
        this.item = item;
        this.disabledReason = disabledReason;
        this.disabled = !!disabledReason;
    }
    Object.defineProperty(SearchResult.prototype, "id", {
        get: function () {
            return this.item.id;
        },
        enumerable: true,
        configurable: true
    });
    SearchResult.prototype.unwrap = function () {
        return this.item;
    };
    SearchResult.prototype.isAddNew = function () {
        return false;
    };
    return SearchResult;
}());
var SearchResultsComponent = /** @class */ (function () {
    function SearchResultsComponent($scope, $element, gettextCatalog, confirmationModal) {
        'ngInject';
        this.$scope = $scope;
        this.$element = $element;
        this.gettextCatalog = gettextCatalog;
        this.confirmationModal = confirmationModal;
    }
    SearchResultsComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watchCollection(function () { return _this.items; }, function (items) {
            _this.$element.parents('.search-results').animate({ scrollTop: 0 }, 0);
            _this.searchResults = (items || []).map(function (item) {
                if (item instanceof AddNew) {
                    return item;
                }
                else {
                    var disabledReason = _this.exclude && _this.exclude(item);
                    return new SearchResult(item, disabledReason);
                }
            });
        });
    };
    SearchResultsComponent.prototype.isVisible = function (item) {
        if (item instanceof AddNew) {
            return item.show();
        }
        else {
            return true;
        }
    };
    SearchResultsComponent.prototype.isSelected = function (item) {
        return this.selected === item.unwrap();
    };
    SearchResultsComponent.prototype.selectItem = function (item) {
        var _this = this;
        var doSelection = function () {
            _this.selected = item.unwrap();
            _this.onSelect({ item: _this.selected });
        };
        if (this.editInProgress && this.editInProgress()) {
            this.confirmationModal.openEditInProgress().then(doSelection, modalCancelHandler);
        }
        else {
            doSelection();
        }
    };
    SearchResultsComponent.prototype.title = function (item) {
        if (item instanceof SearchResult && item.disabled) {
            return this.gettextCatalog.getString(item.disabledReason);
        }
        else {
            return null;
        }
    };
    SearchResultsComponent.prototype.generateSearchResultID = function (item) {
        return item.isAddNew() ? "" + 'create_new_' + labelNameToResourceIdIdentifier(item.label) + '_link'
            : "" + item.id.toString() + '_search_result_link';
    };
    SearchResultsComponent = __decorate([
        LegacyComponent({
            bindings: {
                items: '=',
                selected: '=',
                exclude: '=',
                onSelect: '&',
                editInProgress: '='
            },
            transclude: true,
            template: require('./searchResults.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Object, ConfirmationModal])
    ], SearchResultsComponent);
    return SearchResultsComponent;
}());
export { SearchResultsComponent };
export var SearchResultTranscludeDirective = function () {
    return {
        link: function ($scope, element, _attribute, _ctrl, transclude) {
            transclude(function (clone, transclusionScope) {
                transclusionScope.searchResult = $scope.searchResult.item;
                element.append(clone);
            });
        }
    };
};
