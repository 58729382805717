var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EditableEntityController } from 'app/components/form/editableEntityController';
import { SearchPredicateModal } from './searchPredicateModal';
import { DeleteConfirmationModal } from 'app/components/common/deleteConfirmationModal';
import { ErrorModal } from 'app/components/form/errorModal';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { AuthorizationManagerService } from 'app/services/authorizationManagerService';
import { changeToRestrictedStatus } from 'yti-common-ui/entities/status';
import { DatamodelConfirmationModalService } from 'app/services/confirmation-modal.service';
var ClassViewComponent = /** @class */ (function (_super) {
    __extends(ClassViewComponent, _super);
    function ClassViewComponent($scope, $log, $q, searchPredicateModal, datamodelConfirmationModalService, deleteConfirmationModal, errorModal, classService, userService, authorizationManagerService) {
        'ngInject';
        var _this = _super.call(this, $scope, $log, deleteConfirmationModal, errorModal, userService) || this;
        _this.$q = $q;
        _this.searchPredicateModal = searchPredicateModal;
        _this.datamodelConfirmationModalService = datamodelConfirmationModalService;
        _this.classService = classService;
        _this.authorizationManagerService = authorizationManagerService;
        return _this;
    }
    ClassViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.parent.registerView(this);
        var editableProperties = function () { return _this.getEditable() ? _this.getEditable().properties : false; };
        this.$scope.$watch(function () { return editableProperties(); }, function (props) { return _this.select(_this.getEditable()); });
    };
    ClassViewComponent.prototype.$onDestroy = function () {
        this.parent.deregisterView(this);
    };
    ClassViewComponent.prototype.addProperty = function () {
        var _this = this;
        this.searchPredicateModal.openAddProperty(this.model, this.editableInEdit)
            .then(function (property) {
            _this.editableInEdit.addProperty(property);
            _this.openPropertyId = property.internalId.uuid;
        }, modalCancelHandler);
    };
    ClassViewComponent.prototype.create = function (entity) {
        var _this = this;
        return this.classService.createClass(entity)
            .then(function () { return _this.modelController.selectionEdited(null, entity); });
    };
    ClassViewComponent.prototype.update = function (entity, oldEntity) {
        var _this = this;
        return this.classService.updateClass(entity, oldEntity.id, this.model).then(function () { return _this.modelController.selectionEdited(oldEntity, entity); });
    };
    ClassViewComponent.prototype.remove = function (entity) {
        var _this = this;
        return this.classService.deleteClass(entity.id, this.model).then(function () { return _this.modelController.selectionDeleted(entity); });
    };
    ClassViewComponent.prototype.rights = function () {
        var _this = this;
        return {
            edit: function () { return _this.authorizationManagerService.canEditClass(_this.model, _this.class); },
            remove: function () { return _this.authorizationManagerService.canRemoveClass(_this.model, _this.class); }
        };
    };
    ClassViewComponent.prototype.getEditable = function () {
        return this.class;
    };
    ClassViewComponent.prototype.setEditable = function (editable) {
        this.class = editable;
    };
    ClassViewComponent.prototype.isReference = function () {
        return this.class.definedBy.id.notEquals(this.model.id);
    };
    ClassViewComponent.prototype.getRemoveText = function () {
        var text = _super.prototype.getRemoveText.call(this);
        return !this.isReference() ? text : text + ' from this ' + this.model.normalizedType;
    };
    ClassViewComponent.prototype.openDeleteConfirmationModal = function () {
        var onlyDefinedInModel = this.isReference() ? this.model : null;
        return this.deleteConfirmationModal.open(this.getEditable(), this.getContext(), onlyDefinedInModel);
    };
    ClassViewComponent.prototype.getContext = function () {
        return this.model;
    };
    ClassViewComponent.prototype.confirmChangeToRestrictedStatus = function (entity, oldEntity) {
        return entity.status && oldEntity.status ? changeToRestrictedStatus(oldEntity.status, entity.status) : false;
    };
    ClassViewComponent.prototype.confirmChangeToRestrictedStatusDialog = function (entity, oldEntity) {
        var changeAtLeastOnePropertyStatusToRestricted = function () {
            var result = false;
            entity.properties.forEach(function (property) {
                var oldPropertyToUpdate = oldEntity.properties.find(function (prop) { return prop.internalId.uuid === property.internalId.uuid; });
                if (oldPropertyToUpdate) {
                    var toRestrictedStatus = changeToRestrictedStatus(oldPropertyToUpdate.status, property.status);
                    if (toRestrictedStatus) {
                        result = true;
                    }
                }
                else if (changeToRestrictedStatus('DRAFT', property.status)) {
                    result = true;
                }
            });
            return result;
        };
        var changeClassStatusToRestricted = (entity.status && oldEntity.status && changeToRestrictedStatus(oldEntity.status, entity.status));
        var showRestrictedStatusDialog = changeClassStatusToRestricted || changeAtLeastOnePropertyStatusToRestricted();
        return showRestrictedStatusDialog ? this.$q.when(this.datamodelConfirmationModalService.openChangeToRestrictedStatus()) : null;
    };
    ClassViewComponent.prototype.confirmDialog = function (entity, oldEntity) {
        // NOTE: This is not implemented or needed yet in ClassView.
        return null;
    };
    ClassViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '@',
                class: '=',
                model: '=',
                modelController: '<',
                parent: '<',
                openPropertyId: '='
            },
            template: require('./classView.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Function, SearchPredicateModal,
            DatamodelConfirmationModalService,
            DeleteConfirmationModal,
            ErrorModal, Object, Object, AuthorizationManagerService])
    ], ClassViewComponent);
    return ClassViewComponent;
}(EditableEntityController));
export { ClassViewComponent };
