var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { localizableSerializer, stringSerializer, optional } from './serializer/serializer';
import { DefinedBy } from './definedBy';
import { contextlessInternalUrl, normalizeReferrerType } from 'app/utils/entity';
import { init } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareOptional, entity, entityAwareList } from './serializer/entitySerializer';
var DefaultUsage = /** @class */ (function (_super) {
    __extends(DefaultUsage, _super);
    function DefaultUsage(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, DefaultUsage.defaultUsageMappings);
        return _this;
    }
    DefaultUsage.defaultUsageMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        definedBy: { name: 'isDefinedBy', serializer: entityAwareOptional(entity(function () { return DefinedBy; })) },
        referrers: { name: 'isReferencedBy', serializer: entityAwareList(entity(function () { return Referrer; })) }
    };
    return DefaultUsage;
}(GraphNode));
export { DefaultUsage };
var EmptyUsage = /** @class */ (function () {
    function EmptyUsage(editableEntity) {
        this.referrers = [];
        this.id = editableEntity.id;
        this.label = editableEntity.label;
    }
    return EmptyUsage;
}());
export { EmptyUsage };
var Referrer = /** @class */ (function (_super) {
    __extends(Referrer, _super);
    function Referrer(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.normalizedType = normalizeReferrerType(_this.type);
        init(_this, Referrer.referrerMappings);
        return _this;
    }
    Referrer.prototype.iowUrl = function () {
        return contextlessInternalUrl(this);
    };
    Referrer.referrerMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        prefix: { name: 'preferredXMLNamespacePrefix', serializer: optional(stringSerializer) },
        definedBy: { name: 'isDefinedBy', serializer: entityAwareOptional(entity(function () { return DefinedBy; })) }
    };
    return Referrer;
}(GraphNode));
export { Referrer };
