/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../model/use-context-dropdown.component.ngfactory";
import * as i3 from "../model/use-context-dropdown.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./use-context-input.component";
var styles_UseContextInputComponent = [];
var RenderType_UseContextInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UseContextInputComponent, data: {} });
export { RenderType_UseContextInputComponent as RenderType_UseContextInputComponent };
function View_UseContextInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.useContext)); _ck(_v, 1, 0, currVal_0); }); }
function View_UseContextInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-use-context-dropdown", [["id", "use_context_input_dropdown"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_UseContextDropdownComponent_0, i2.RenderType_UseContextDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i3.UseContextDropdownComponent, [], { id: [0, "id"], modelType: [1, "modelType"] }, null), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.UseContextDropdownComponent]), i0.ɵdid(3, 540672, null, 0, i4.FormControlDirective, [[8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlDirective]), i0.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "use_context_input_dropdown"; var currVal_8 = _co.modelType; _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.control; _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UseContextInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "label", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(3, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Use context"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UseContextInputComponent_2)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UseContextInputComponent_3)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.editing; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.editing; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_UseContextInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_UseContextInputComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UseContextInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-use-context-input", [], null, null, null, View_UseContextInputComponent_0, RenderType_UseContextInputComponent)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.UseContextInputComponent]), i0.ɵdid(2, 49152, null, 0, i6.UseContextInputComponent, [], null, null)], null, null); }
var UseContextInputComponentNgFactory = i0.ɵccf("app-use-context-input", i6.UseContextInputComponent, View_UseContextInputComponent_Host_0, { editing: "editing", modelType: "modelType" }, {}, []);
export { UseContextInputComponentNgFactory as UseContextInputComponentNgFactory };
