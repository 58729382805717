var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isDefined } from 'yti-common-ui/utils/object';
import { UsageService } from 'app/services/usageService';
import { ErrorModal } from 'app/components/form/errorModal';
import { glyphIconClassForType } from 'app/utils/entity';
import { Association, Attribute } from 'app/entities/predicate';
import { LegacyComponent } from 'app/utils/angular';
var PredicateFormComponent = /** @class */ (function () {
    function PredicateFormComponent(predicateService, usageService, errorModal) {
        'ngInject';
        this.predicateService = predicateService;
        this.usageService = usageService;
        this.errorModal = errorModal;
    }
    PredicateFormComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(PredicateFormComponent.prototype, "shouldAutofocus", {
        get: function () {
            return !isDefined(this.predicateView);
        },
        enumerable: true,
        configurable: true
    });
    PredicateFormComponent.prototype.linkToIdProperty = function () {
        return this.model.linkToResource(this.predicate.id);
    };
    PredicateFormComponent.prototype.linkToSuperProperty = function () {
        return this.model.linkToResource(this.predicate.subPropertyOf);
    };
    PredicateFormComponent.prototype.linkToValueClass = function () {
        var predicate = this.predicate;
        if (predicate instanceof Association) {
            return this.model.linkToResource(predicate.valueClass);
        }
        else {
            return '';
        }
    };
    Object.defineProperty(PredicateFormComponent.prototype, "changedType", {
        get: function () {
            return this.predicate instanceof Attribute ? 'association' : 'attribute';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PredicateFormComponent.prototype, "changeTypeIconClass", {
        get: function () {
            return glyphIconClassForType([this.changedType]);
        },
        enumerable: true,
        configurable: true
    });
    PredicateFormComponent.prototype.changeType = function () {
        var _this = this;
        this.usageService.getUsage(this.predicate).then(function (usage) {
            if (usage.referrers.length > 0) {
                _this.errorModal.openUsageError('Predicate in use', 'Predicate type cannot be changed because it is already used by following resources', usage, _this.model);
            }
            else {
                _this.predicateService.changePredicateType(_this.predicate, _this.changedType, _this.model)
                    .then(function (changedPredicate) { return _this.predicate = changedPredicate; });
            }
        });
    };
    PredicateFormComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '@',
                predicate: '=',
                oldPredicate: '=',
                model: '='
            },
            require: {
                predicateView: '?^predicateView',
                form: '?^form'
            },
            template: require('./predicateForm.html')
        }),
        __metadata("design:paramtypes", [Object, UsageService,
            ErrorModal])
    ], PredicateFormComponent);
    return PredicateFormComponent;
}());
export { PredicateFormComponent };
