var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from 'app/services/languageService';
import { TableDescriptor } from 'app/components/form/editableTable';
import { createExistsExclusion } from 'app/utils/exclusion';
import { collectIds } from 'app/utils/entity';
import { SearchReferenceDataModal } from './searchReferenceDataModal';
import { EditReferenceDataModal } from './editReferenceDataModal';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { TranslateService } from '@ngx-translate/core';
var ReferenceDatasViewComponent = /** @class */ (function () {
    function ReferenceDatasViewComponent($scope, searchReferenceDataModal, editReferenceDataModal, languageService, translateService) {
        'ngInject';
        this.$scope = $scope;
        this.searchReferenceDataModal = searchReferenceDataModal;
        this.editReferenceDataModal = editReferenceDataModal;
        this.languageService = languageService;
        this.translateService = translateService;
    }
    ReferenceDatasViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new ReferenceDataTableDescriptor(value, _this.context, _this.editReferenceDataModal, _this.languageService, _this.translateService);
        });
    };
    ReferenceDatasViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    ReferenceDatasViewComponent.prototype.addReferenceData = function () {
        var _this = this;
        var exclude = createExistsExclusion(collectIds(this.value.referenceDatas));
        this.searchReferenceDataModal.openSelectionForModel(this.context, exclude)
            .then(function (referenceData) {
            _this.value.addReferenceData(referenceData);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    ReferenceDatasViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                context: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n      <h4>\n        <span translate>Reference data</span>\n        <button id=\"add_reference_data_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.addReferenceData()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Add reference data</span>\n        </button>\n      </h4>\n      <editable-table id=\"'referenceData'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, SearchReferenceDataModal,
            EditReferenceDataModal,
            LanguageService,
            TranslateService])
    ], ReferenceDatasViewComponent);
    return ReferenceDatasViewComponent;
}());
export { ReferenceDatasViewComponent };
var ReferenceDataTableDescriptor = /** @class */ (function (_super) {
    __extends(ReferenceDataTableDescriptor, _super);
    function ReferenceDataTableDescriptor(value, context, editReferenceDataModal, languageService, translateService) {
        var _this = _super.call(this) || this;
        _this.value = value;
        _this.context = context;
        _this.editReferenceDataModal = editReferenceDataModal;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.localizer = _this.languageService.createLocalizer(_this.context);
        return _this;
    }
    ReferenceDataTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Reference data name', nameExtractor: function (referenceData) { return _this.localizer.translate(referenceData.title); }, hrefExtractor: function (referenceData) { return referenceData.id.uri; } },
            { headerName: 'Status', nameExtractor: function (referenceData) { return referenceData.status ? _this.translateService.instant(referenceData.status) : ''; } }
        ];
    };
    ReferenceDataTableDescriptor.prototype.values = function () {
        return this.value && this.value.referenceDatas;
    };
    ReferenceDataTableDescriptor.prototype.canEdit = function (referenceData) {
        return referenceData.isExternal();
    };
    ReferenceDataTableDescriptor.prototype.edit = function (value) {
        this.editReferenceDataModal.openEdit(value, this.context, this.localizer.language);
    };
    ReferenceDataTableDescriptor.prototype.canRemove = function (_referenceData) {
        return true;
    };
    ReferenceDataTableDescriptor.prototype.remove = function (referenceData) {
        this.value.removeReferenceData(referenceData);
    };
    ReferenceDataTableDescriptor.prototype.orderBy = function (referenceData) {
        return referenceData.identifier;
    };
    return ReferenceDataTableDescriptor;
}(TableDescriptor));
