import { getModelControllerFromInput } from './angular';
export function validInput(element) {
    return function () {
        var ngModel = getModelControllerFromInput(element());
        return ngModel.$valid && !ngModel.$pending;
    };
}
export function inputHasExactValue(element, value) {
    return function () {
        var ngModel = getModelControllerFromInput(element());
        return ngModel.$viewValue === value;
    };
}
export function elementExists(element) {
    return function () {
        var e = element();
        return e && e.length > 0;
    };
}
export function expectAll() {
    var expectations = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        expectations[_i] = arguments[_i];
    }
    return function () {
        for (var _i = 0, expectations_1 = expectations; _i < expectations_1.length; _i++) {
            var expectation = expectations_1[_i];
            if (!expectation()) {
                return false;
            }
        }
        return true;
    };
}
