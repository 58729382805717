import { OnInit } from '@angular/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { MessagingService } from '../../services/messaging-service';
import { ConfigServiceWrapper, DatamodelLocationServiceWrapper } from '../../ajs-upgraded-providers';
var UserDetailsComponent = /** @class */ (function () {
    function UserDetailsComponent(userService, datamodelLocationServiceWrapper, messagingService, configServiceWrapper) {
        this.userService = userService;
        this.datamodelLocationServiceWrapper = datamodelLocationServiceWrapper;
        this.messagingService = messagingService;
        this.configServiceWrapper = configServiceWrapper;
        this.APPLICATION_CODELIST = 'codelist';
        this.APPLICATION_TERMINOLOGY = 'terminology';
        this.APPLICATION_DATAMODEL = 'datamodel';
        this.APPLICATION_COMMENTS = 'comments';
        this.loading = true;
        this.messagingResources$ = new BehaviorSubject(null);
        datamodelLocationServiceWrapper.locationService.atUser();
    }
    UserDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configServiceWrapper.configService.getConfig()
            .then(function (config) {
            _this.config = config;
            if (_this.config.isMessagingEnabled && !_this.userService.user.anonymous) {
                _this.getUserSubscriptionData();
            }
            else {
                _this.loading = false;
            }
        });
    };
    UserDetailsComponent.prototype.getUserSubscriptionData = function () {
        var _this = this;
        this.loading = true;
        this.messagingService.getMessagingUserData().subscribe(function (messagingUserData) {
            if (messagingUserData) {
                var resources = new Map();
                var codelistMessagingResources_1 = [];
                var datamodelMessagingResources_1 = [];
                var terminologyMessagingResources_1 = [];
                var commentsMessagingResources_1 = [];
                messagingUserData.resources.forEach(function (resource) {
                    if (resource.application === _this.APPLICATION_CODELIST) {
                        codelistMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_DATAMODEL) {
                        datamodelMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_TERMINOLOGY) {
                        terminologyMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_COMMENTS) {
                        commentsMessagingResources_1.push(resource);
                    }
                });
                if (codelistMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_CODELIST, codelistMessagingResources_1);
                }
                if (datamodelMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_DATAMODEL, datamodelMessagingResources_1);
                }
                if (terminologyMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_TERMINOLOGY, terminologyMessagingResources_1);
                }
                if (commentsMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_COMMENTS, commentsMessagingResources_1);
                }
                if (resources.size > 0) {
                    _this.messagingResources = resources;
                }
                else {
                    _this.messagingResources = null;
                }
            }
            else {
                _this.messagingResources = null;
            }
            _this.loading = false;
        });
    };
    UserDetailsComponent.prototype.onTabChange = function (event) {
        if (event.nextId === 'user_details_info_tab') {
            this.getUserSubscriptionData();
        }
    };
    Object.defineProperty(UserDetailsComponent.prototype, "messagingResources", {
        get: function () {
            return this.messagingResources$.getValue();
        },
        set: function (value) {
            this.messagingResources$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    return UserDetailsComponent;
}());
export { UserDetailsComponent };
