var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as angular from 'angular';
import { routeConfig } from './routes';
import { module as commonModule } from './components/common';
import { module as editorModule } from './components/editor';
import { module as visualizationModule } from './components/visualization';
import { module as formModule } from './components/form';
import { module as modelModule } from './components/model';
import { module as navigationModule } from './components/navigation';
import { module as informationModule } from './components/information';
import { module as filterModule } from './components/filter';
import { module as componentsModule } from './components';
import { module as servicesModule } from './services';
import { module as helpModule } from './help';
import { Title } from '@angular/platform-browser';
import { downgradeComponent, downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { NgZone } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { availableUILanguages } from './types/language';
import { LoginModalService } from 'yti-common-ui/components/login-modal.component';
import { FooterComponent } from 'yti-common-ui/components/footer.component';
import { MenuComponent } from 'yti-common-ui/components/menu.component';
import { AjaxLoadingIndicatorComponent } from 'yti-common-ui/components/ajax-loading-indicator.component';
import { AjaxLoadingIndicatorSmallComponent } from 'yti-common-ui/components/ajax-loading-indicator-small.component';
import { FilterDropdownComponent } from 'yti-common-ui/components/filter-dropdown.component';
import { StatusComponent } from 'yti-common-ui/components/status.component';
import { DropdownComponent } from 'yti-common-ui/components/dropdown.component';
import { UseContextInputComponent } from './components/form/use-context-input.component';
import { apiEndpointWithName } from './services/config';
import { ExpandableTextComponent } from 'yti-common-ui/components/expandable-text.component';
import { DefaultAngularLocalizer, LanguageService } from './services/languageService';
import { HelpService } from './help/providers/helpService';
import { IndexSearchService } from './services/indexSearchService';
import { SearchClassTableModalContentComponent } from './components/editor/searchClassTableModalContent';
import { SearchPredicateTableModalContentComponent } from './components/editor/searchPredicateTableModalContent';
import { MessagingService } from './services/messaging-service';
import { UserDetailsSubscriptionsComponent } from './components/userdetails/user-details-subscriptions.component';
import { UserDetailsInformationComponent } from './components/userdetails/user-details-information.component';
import { UserDetailsComponent } from './components/userdetails/user-details.component';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { MassMigrateDatamodelResourceStatusesModalComponent } from './components/model/mass-migrate-datamodel-resource-statuses-modal.component';
import { MassMigrateDatamodelResourceStatusesModalService } from './components/model/mass-migrate-datamodel-resource-statuses-modal.component';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { AlertModalService } from 'yti-common-ui/components/alert-modal.component';
import { DatamodelConfirmationModalService } from './services/confirmation-modal.service';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { NewDatamodelVersionModalComponent } from './components/model/new-datamodel-version-modal.component';
import { NewDatamodelVersionModalService } from './components/model/new-datamodel-version-modal.component';
require('angular-gettext');
require('checklist-model');
require('ngclipboard');
function removeEmptyValues(obj) {
    var result = {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (!!value) {
            result[key] = value;
        }
    }
    return result;
}
export var localizationStrings = {};
for (var _i = 0, availableUILanguages_1 = availableUILanguages; _i < availableUILanguages_1.length; _i++) {
    var language = availableUILanguages_1[_i];
    localizationStrings[language] = __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/" + language + ".po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/" + language + ".po"))));
}
Object.freeze(localizationStrings);
export function resolveAuthenticatedUserEndpoint() {
    return apiEndpointWithName('user');
}
export function createTranslateLoader() {
    return {
        getTranslation: function (lang) {
            return of(localizationStrings[lang]);
        }
    };
}
export function createMissingTranslationHandler() {
    return {
        handle: function (params) {
            if (params.translateService.currentLang === 'en') {
                return params.key;
            }
            else {
                return '[MISSING]: ' + params.key;
            }
        }
    };
}
export function localizerFactory(languageService) {
    return new DefaultAngularLocalizer(languageService);
}
var AppModule = /** @class */ (function () {
    function AppModule(upgrade) {
        this.upgrade = upgrade;
    }
    AppModule.prototype.ngDoBootstrap = function () {
        this.upgrade.bootstrap(document.body, ['iow-ui'], { strictDi: true });
    };
    return AppModule;
}());
export { AppModule };
var mod = angular.module('iow-ui', [
    require('angular-animate'),
    require('angular-messages'),
    require('angular-route'),
    require('ui-bootstrap4'),
    'gettext',
    'checklist-model',
    'ngclipboard',
    commonModule.name,
    editorModule.name,
    visualizationModule.name,
    formModule.name,
    modelModule.name,
    navigationModule.name,
    informationModule.name,
    filterModule.name,
    componentsModule.name,
    servicesModule.name,
    helpModule.name
]);
mod.directive('appMenu', downgradeComponent({ component: MenuComponent }));
mod.directive('appFooter', downgradeComponent({
    component: FooterComponent,
    inputs: ['title'],
    outputs: ['informationClick']
}));
mod.directive('ajaxLoadingIndicator', downgradeComponent({ component: AjaxLoadingIndicatorComponent }));
mod.directive('ajaxLoadingIndicatorSmall', downgradeComponent({ component: AjaxLoadingIndicatorSmallComponent }));
mod.directive('appDropdown', downgradeComponent({ component: DropdownComponent }));
mod.directive('appExpandableText', downgradeComponent({ component: ExpandableTextComponent }));
mod.directive('appFilterDropdown', downgradeComponent({ component: FilterDropdownComponent }));
mod.directive('appStatus', downgradeComponent({ component: StatusComponent }));
mod.directive('appUseContextInput', downgradeComponent({ component: UseContextInputComponent }));
mod.directive('appSearchClassTableModalContent', downgradeComponent({ component: SearchClassTableModalContentComponent }));
mod.directive('appSearchPredicateTableModalContent', downgradeComponent({ component: SearchPredicateTableModalContentComponent }));
mod.directive('appUserDetails', downgradeComponent({ component: UserDetailsComponent }));
mod.directive('appUserDetailsInformation', downgradeComponent({ component: UserDetailsInformationComponent }));
mod.directive('appUserDetailsSubscriptions', downgradeComponent({ component: UserDetailsSubscriptionsComponent }));
mod.directive('appMassMigrateDatamodelResourceStatusesModalComponent', downgradeComponent({ component: MassMigrateDatamodelResourceStatusesModalComponent }));
mod.directive('appNewDatamodelVersionModalComponent', downgradeComponent({ component: NewDatamodelVersionModalComponent }));
mod.factory('translateService', downgradeInjectable(TranslateService));
mod.factory('loginModal', downgradeInjectable(LoginModalService));
mod.factory('localizationStrings', function () { return localizationStrings; });
mod.factory('zone', downgradeInjectable(NgZone));
mod.factory('titleService', downgradeInjectable(Title));
mod.factory('helpService', downgradeInjectable(HelpService));
mod.factory('indexSearchService', downgradeInjectable(IndexSearchService));
mod.factory('messagingService', downgradeInjectable(MessagingService));
mod.factory('confirmationModalService', downgradeInjectable(ConfirmationModalService));
mod.factory('massMigrateDatamodelResourceStatusesModalService', downgradeInjectable(MassMigrateDatamodelResourceStatusesModalService));
mod.factory('modalService', downgradeInjectable(ModalService));
mod.factory('alertModalService', downgradeInjectable(AlertModalService));
mod.factory('datamodelConfirmationModalService', downgradeInjectable(DatamodelConfirmationModalService));
mod.factory('errorModalService', downgradeInjectable(ErrorModalService));
mod.factory('newDatamodelVersionModalService', downgradeInjectable(NewDatamodelVersionModalService));
mod.config(routeConfig);
mod.config(function ($locationProvider, $logProvider, $compileProvider, $animateProvider, $uibTooltipProvider) {
    'ngInject';
    $locationProvider.html5Mode(true);
    $logProvider.debugEnabled(false);
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(|blob|https?|mailto):/);
    // enable angular-animate framework when 'ng-animate-enabled' class is added to animated element
    $animateProvider.classNameFilter(/ng-animate-enabled/);
    $uibTooltipProvider.options({ appendToBody: true });
    $uibTooltipProvider.setTriggers({ 'mouseenter': 'mouseleave click' });
});
mod.run(function (gettextCatalog) {
    gettextCatalog.debug = true;
    for (var _i = 0, availableUILanguages_2 = availableUILanguages; _i < availableUILanguages_2.length; _i++) {
        var language = availableUILanguages_2[_i];
        gettextCatalog.setStrings(language, localizationStrings[language]);
    }
});
