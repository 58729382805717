/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/ajax-loading-indicator.component.ngfactory";
import * as i3 from "yti-common-ui/components/ajax-loading-indicator.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./user-details-information.component.ngfactory";
import * as i6 from "./user-details-information.component";
import * as i7 from "../../ajs-upgraded-providers";
import * as i8 from "yti-common-ui/services/user.service";
import * as i9 from "../../services/languageService";
import * as i10 from "./user-details-subscriptions.component.ngfactory";
import * as i11 from "./user-details-subscriptions.component";
import * as i12 from "../../services/messaging-service";
import * as i13 from "yti-common-ui/components/confirmation-modal.component";
import * as i14 from "yti-common-ui/components/error-modal.component";
import * as i15 from "@ng-bootstrap/ng-bootstrap/tabset/tabset";
import * as i16 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/tabset/tabset.ngfactory";
import * as i17 from "@ng-bootstrap/ng-bootstrap/tabset/tabset-config";
import * as i18 from "@angular/common";
import * as i19 from "./user-details.component";
var styles_UserDetailsComponent = [i0.styles];
var RenderType_UserDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailsComponent, data: {} });
export { RenderType_UserDetailsComponent as RenderType_UserDetailsComponent };
function View_UserDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ajax-loading-indicator", [], null, null, null, i2.View_AjaxLoadingIndicatorComponent_0, i2.RenderType_AjaxLoadingIndicatorComponent)), i1.ɵdid(2, 49152, null, 0, i3.AjaxLoadingIndicatorComponent, [], null, null)], null, null); }
function View_UserDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["INFORMATION"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-details-information", [], null, null, null, i5.View_UserDetailsInformationComponent_0, i5.RenderType_UserDetailsInformationComponent)), i1.ɵdid(1, 180224, null, 0, i6.UserDetailsInformationComponent, [i7.LocationServiceWrapper, i8.UserService, i9.LanguageService, i7.UserRoleServiceWrapper, i7.OrganizationServiceWrapper, i7.GettextCatalogWrapper], null, null)], null, null); }
function View_UserDetailsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["SUBSCRIPTIONS"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDetailsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-details-subscriptions", [], null, null, null, i10.View_UserDetailsSubscriptionsComponent_0, i10.RenderType_UserDetailsSubscriptionsComponent)), i1.ɵdid(1, 114688, null, 0, i11.UserDetailsSubscriptionsComponent, [i9.LanguageService, i12.MessagingService, i7.ConfigServiceWrapper, i8.UserService, i13.ConfirmationModalService, i14.ErrorModalService], { tabSet: [0, "tabSet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent, 6); _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-tab", [["id", "user_details_subscriptions_tab"]], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[2, 4]], 2, i15.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 5, { titleTpls: 1 }), i1.ɵqud(603979776, 6, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserDetailsComponent_6)), i1.ɵdid(5, 16384, [[5, 4]], 0, i15.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserDetailsComponent_7)), i1.ɵdid(7, 16384, [[6, 4]], 0, i15.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "user_details_subscriptions_tab"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["User details"])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "ngb-tabset", [], null, [[null, "tabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChange" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_NgbTabset_0, i16.RenderType_NgbTabset)), i1.ɵdid(6, 2146304, [[1, 4], ["tabSet", 4]], 1, i15.NgbTabset, [i17.NgbTabsetConfig], null, { tabChange: "tabChange" }), i1.ɵqud(603979776, 2, { tabs: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 7, "ngb-tab", [["id", "user_details_info_tab"]], null, null, null, null, null)), i1.ɵdid(9, 2113536, [[2, 4]], 2, i15.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 3, { titleTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserDetailsComponent_3)), i1.ɵdid(13, 16384, [[3, 4]], 0, i15.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserDetailsComponent_4)), i1.ɵdid(15, 16384, [[4, 4]], 0, i15.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_5)), i1.ɵdid(17, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = "user_details_info_tab"; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.messagingResources; _ck(_v, 17, 0, currVal_2); }, null); }
export function View_UserDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tabSet: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_1)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_2)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UserDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-details", [], null, null, null, View_UserDetailsComponent_0, RenderType_UserDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i19.UserDetailsComponent, [i8.UserService, i7.DatamodelLocationServiceWrapper, i12.MessagingService, i7.ConfigServiceWrapper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailsComponentNgFactory = i1.ɵccf("app-user-details", i19.UserDetailsComponent, View_UserDetailsComponent_Host_0, {}, {}, []);
export { UserDetailsComponentNgFactory as UserDetailsComponentNgFactory };
