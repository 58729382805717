import { glyphIconClassForType } from 'app/utils/entity';
import { containsAny } from 'yti-common-ui/utils/array';
import { init } from './mapping';
import { typeSerializer } from './serializer/serializer';
var GraphNode = /** @class */ (function () {
    function GraphNode(graph, context, frame) {
        this.graph = graph;
        this.context = context;
        this.frame = frame;
        init(this, GraphNode.graphNodeMappings);
    }
    GraphNode.prototype.isOfType = function () {
        var type = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            type[_i] = arguments[_i];
        }
        return containsAny(this.type, type);
    };
    Object.defineProperty(GraphNode.prototype, "glyphIconClass", {
        get: function () {
            return glyphIconClassForType(this.type);
        },
        enumerable: true,
        configurable: true
    });
    GraphNode.prototype.serializationValues = function (_inline, _clone) {
        return {};
    };
    GraphNode.prototype.serialize = function (inline, clone) {
        if (inline === void 0) { inline = false; }
        if (clone === void 0) { clone = false; }
        var values = Object.assign(this.graph, this.serializationValues(inline, clone));
        for (var _i = 0, _a = Object.entries(values); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (value === null) {
                delete values[key];
            }
        }
        if (inline) {
            return values;
        }
        else {
            return {
                '@graph': values,
                '@context': this.context
            };
        }
    };
    GraphNode.graphNodeMappings = {
        type: { name: '@type', serializer: typeSerializer }
    };
    return GraphNode;
}());
export { GraphNode };
var GraphNodes = /** @class */ (function () {
    function GraphNodes(context, frame) {
        this.context = context;
        this.frame = frame;
    }
    GraphNodes.prototype.serialize = function (inline, clone) {
        if (inline === void 0) { inline = false; }
        if (clone === void 0) { clone = false; }
        var values = this.getNodes().map(function (node) { return node.serialize(true, clone); });
        if (inline) {
            return values;
        }
        else {
            return {
                '@graph': values,
                '@context': this.context
            };
        }
    };
    return GraphNodes;
}());
export { GraphNodes };
