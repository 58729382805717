var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LegacyComponent } from 'app/utils/angular';
import { requireDefined } from 'yti-common-ui/utils/object';
import { elementPositioning, resolveArrowClass } from 'app/help/utils/component';
var InteractiveHelpPopoverDimensionsCalculatorComponent = /** @class */ (function () {
    function InteractiveHelpPopoverDimensionsCalculatorComponent($scope, $element, gettextCatalog) {
        'ngInject';
        this.$scope = $scope;
        this.$element = $element;
        this.gettextCatalog = gettextCatalog;
        this.arrowClass = [];
    }
    InteractiveHelpPopoverDimensionsCalculatorComponent.prototype.$onInit = function () {
        var _this = this;
        this.helpController.registerPopoverDimensionsProvider(this);
        this.$scope.$watch(function () { return _this.item; }, function (item) { return _this.arrowClass = resolveArrowClass(item); });
    };
    Object.defineProperty(InteractiveHelpPopoverDimensionsCalculatorComponent.prototype, "localizedTitle", {
        get: function () {
            if (this.item) {
                return this.gettextCatalog.getString(this.item.title.key, this.item.title.context);
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpPopoverDimensionsCalculatorComponent.prototype, "localizedContent", {
        get: function () {
            if (this.item && this.item.content) {
                return this.gettextCatalog.getString(this.item.content.key, this.item.content.context);
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    InteractiveHelpPopoverDimensionsCalculatorComponent.prototype.getDimensions = function () {
        return requireDefined(elementPositioning(this.$element));
    };
    InteractiveHelpPopoverDimensionsCalculatorComponent = __decorate([
        LegacyComponent({
            bindings: {
                item: '<',
                helpController: '<'
            },
            template: "\n        <span ng-class=\"$ctrl.arrowClass\"></span>\n      \n        <div class=\"help-content-wrapper\">\n          <h3 ng-show=\"$ctrl.item.title\">{{$ctrl.localizedTitle}}</h3>\n          <p ng-show=\"$ctrl.item.content\">{{$ctrl.localizedContent}}</p>\n          <button ng-show=\"$ctrl.helpController.showPrevious\" class=\"small button help-navigate\" translate>previous</button>\n          <button ng-show=\"$ctrl.helpController.showNext\" class=\"small button help-navigate\" translate>next</button>\n          <button ng-show=\"$ctrl.helpController.showClose\" class=\"small button help-next\" translate>close</button>\n          <a class=\"help-close\">&times;</a>\n        </div>\n  "
        }),
        __metadata("design:paramtypes", [Object, Object, Object])
    ], InteractiveHelpPopoverDimensionsCalculatorComponent);
    return InteractiveHelpPopoverDimensionsCalculatorComponent;
}());
export { InteractiveHelpPopoverDimensionsCalculatorComponent };
