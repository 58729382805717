import { Uri } from 'app/entities/uri';
import { isString, isNumber, isBoolean } from 'yti-common-ui/utils/object';
import { isDifferentUrl } from 'app/utils/angular';
import * as moment from 'moment';
import { isLocalizable } from 'app/utils/language';
function isMoment(obj) {
    return moment.isMoment(obj);
}
var DisplayItem = /** @class */ (function () {
    function DisplayItem($location, languageService, gettextCatalog, config) {
        this.$location = $location;
        this.languageService = languageService;
        this.gettextCatalog = gettextCatalog;
        this.config = config;
        this.onClick = config.onClick;
    }
    Object.defineProperty(DisplayItem.prototype, "displayValue", {
        get: function () {
            var value = this.value;
            if (isMoment(value)) {
                return value.local().format(this.gettextCatalog.getString('date format'));
            }
            else if (value instanceof Uri) {
                return value.compact;
            }
            else if (isLocalizable(value)) {
                return this.languageService.translate(value, this.config.context());
            }
            else if (isString(value)) {
                if (this.config.valueAsLocalizationKey) {
                    return this.gettextCatalog.getString(value);
                }
                else {
                    return value;
                }
            }
            else if (isNumber(value)) {
                return value.toString();
            }
            else if (isBoolean(value)) {
                return this.gettextCatalog.getString(value ? 'Yes' : 'No');
            }
            else if (!value) {
                return '';
            }
            else {
                throw new Error('Cannot convert to display value: ' + value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisplayItem.prototype, "value", {
        get: function () {
            return this.config.value();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DisplayItem.prototype, "href", {
        get: function () {
            if (this.config.hideLinks && this.config.hideLinks()) {
                return null;
            }
            else {
                var link = this.config.link && this.config.link();
                if (!link || !isDifferentUrl(link, this.$location.url())) {
                    return null;
                }
                else {
                    return link;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return DisplayItem;
}());
export { DisplayItem };
var DisplayItemFactory = /** @class */ (function () {
    function DisplayItemFactory($location, languageService, gettextCatalog) {
        'ngInject';
        this.$location = $location;
        this.languageService = languageService;
        this.gettextCatalog = gettextCatalog;
    }
    DisplayItemFactory.prototype.create = function (config) {
        return new DisplayItem(this.$location, this.languageService, this.gettextCatalog, config);
    };
    return DisplayItemFactory;
}());
export { DisplayItemFactory };
