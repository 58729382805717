var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AddPropertiesFromClassModal } from './addPropertiesFromClassModal';
import { isDefined, requireDefined } from 'yti-common-ui/utils/object';
import { SearchPredicateModal } from './searchPredicateModal';
import { noExclude, SearchClassModal } from './searchClassModal';
import { SessionService } from 'app/services/sessionService';
import { LanguageService } from 'app/services/languageService';
import { comparingLocalizable } from 'app/utils/comparator';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
var ClassFormComponent = /** @class */ (function () {
    function ClassFormComponent($scope, classService, sessionService, languageService, searchPredicateModal, searchClassModal, addPropertiesFromClassModal) {
        'ngInject';
        this.$scope = $scope;
        this.classService = classService;
        this.sessionService = sessionService;
        this.languageService = languageService;
        this.searchPredicateModal = searchPredicateModal;
        this.searchClassModal = searchClassModal;
        this.addPropertiesFromClassModal = addPropertiesFromClassModal;
        this.onPropertyReorder = function (property, index) { return property.index = index; };
        this.superClassExclude = function (klass) { return klass.isOfType('shape') ? 'Super cannot be shape' : null; };
    }
    ClassFormComponent.prototype.$onInit = function () {
        var _this = this;
        var setProperties = function () {
            if (_this.isEditing() || !_this.sortAlphabetically) {
                _this.properties = _this.class.properties;
            }
            else {
                _this.properties = _this.class.properties.slice();
                _this.properties.sort(comparingLocalizable(_this.languageService.createLocalizer(_this.model), function (property) { return property.label; }));
            }
        };
        this.$scope.$watchGroup([
            function () { return _this.class; },
            function () { return _this.class.properties; },
            function () { return _this.languageService.getModelLanguage(_this.model); },
            function () { return _this.sortAlphabetically; },
            function () { return _this.isEditing(); }
        ], function () { return setProperties(); });
        this.addPropertyActions = [
            {
                name: 'Add property',
                apply: function () { return _this.addProperty(); }
            },
            {
                name: 'Copy properties from class',
                apply: function () { return _this.copyPropertiesFromClass(); }
            }
        ];
    };
    ClassFormComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(ClassFormComponent.prototype, "shouldAutoFocus", {
        get: function () {
            return !isDefined(this.classView);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassFormComponent.prototype, "sortAlphabetically", {
        get: function () {
            return this.sessionService.sortAlphabetically || false;
        },
        set: function (value) {
            this.sessionService.sortAlphabetically = value;
        },
        enumerable: true,
        configurable: true
    });
    ClassFormComponent.prototype.addProperty = function () {
        var _this = this;
        this.searchPredicateModal.openAddProperty(this.model, this.class)
            .then(function (property) {
            _this.class.addProperty(property);
            _this.openPropertyId = property.internalId.uuid;
        }, modalCancelHandler);
    };
    ClassFormComponent.prototype.copyPropertiesFromClass = function () {
        var _this = this;
        this.searchClassModal.openWithOnlySelection(this.model, false, noExclude, function (_klass) { return 'Copy properties'; })
            .then(function (selectedClass) { return _this.addPropertiesFromClass(selectedClass, 'class'); }, modalCancelHandler);
    };
    ClassFormComponent.prototype.addPropertiesFromClass = function (klass, classType) {
        var _this = this;
        if (klass && klass.properties.length > 0) {
            var existingPredicates_1 = new Set(this.class.properties.map(function (property) { return property.predicateId.uri; }));
            var exclude = function (property) { return existingPredicates_1.has(property.predicateId.uri); };
            this.addPropertiesFromClassModal.open(klass, classType, this.model, exclude)
                .then(function (properties) { return properties.forEach(function (property) { return _this.class.addProperty(property); }); });
        }
    };
    ClassFormComponent.prototype.addPropertiesFromClassId = function (id, classType) {
        var _this = this;
        this.classService.getInternalOrExternalClass(id, this.model)
            .then(function (klassOrNull) {
            var klass = requireDefined(klassOrNull); // TODO check if class can actually be null
            _this.addPropertiesFromClass(klass, classType);
        });
    };
    ClassFormComponent.prototype.linkToIdClass = function () {
        return this.model.linkToResource(this.class.id);
    };
    ClassFormComponent.prototype.linkToSuperclass = function () {
        return this.model.linkToResource(this.class.subClassOf);
    };
    ClassFormComponent.prototype.linkToScopeclass = function () {
        return this.model.linkToResource(this.class.scopeClass);
    };
    ClassFormComponent.prototype.formLabelNameToIdName = function (label) {
        return labelNameToResourceIdIdentifier(this.languageService.translate(label));
    };
    ClassFormComponent = __decorate([
        LegacyComponent({
            template: require('./classForm.html'),
            require: {
                classView: '?^classView',
                form: '?^form'
            },
            bindings: {
                id: '@',
                class: '=',
                oldClass: '=',
                model: '=',
                openPropertyId: '='
            }
        }),
        __metadata("design:paramtypes", [Object, Object, SessionService,
            LanguageService,
            SearchPredicateModal,
            SearchClassModal,
            AddPropertiesFromClassModal])
    ], ClassFormComponent);
    return ClassFormComponent;
}());
export { ClassFormComponent };
