var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var overlayOpenClass = 'overlay-open';
var OverlayService = /** @class */ (function () {
    function OverlayService($rootScope, $q, $document, $controller, $compile, $animate, $uibResolve) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$document = $document;
        this.$controller = $controller;
        this.$compile = $compile;
        this.$animate = $animate;
        this.$uibResolve = $uibResolve;
    }
    OverlayService.prototype.open = function (options) {
        var _this = this;
        if (!options.template) {
            throw new Error('template is required');
        }
        var body = this.$document.find('body').eq(0);
        var appendTo = options.appendTo || body;
        var instance = new DefaultOverlayInstance(this.$q, this.$animate, body);
        this.$uibResolve.resolve(options.resolve || {}).then(function (vars) {
            var parentScope = options.scope || _this.$rootScope;
            var scope = instance.createScope(parentScope);
            if (options.controller) {
                var locals = __assign({ $scope: scope, $overlayInstance: instance }, vars);
                // XXX: d.ts doesn't provide overload for this constructor anymore even though it exists at runtime
                var instantiator = _this.$controller(options.controller, locals, true);
                var ctrl = instantiator();
                if (options.controllerAs) {
                    scope[options.controllerAs] = ctrl;
                }
                if (typeof ctrl.$onInit === 'function') {
                    ctrl.$onInit();
                }
            }
            var elem = jQuery(options.template);
            if (options.disableScroll) {
                body.addClass(overlayOpenClass);
            }
            _this.$animate.enter(_this.$compile(elem)(scope), appendTo);
            instance.element = function () { return elem; };
            instance.openedDeferred.resolve(true);
        }, function (reason) {
            instance.openedDeferred.reject(reason);
            instance.resultDeferred.reject(reason);
        });
        return instance;
    };
    return OverlayService;
}());
export { OverlayService };
var DefaultOverlayInstance = /** @class */ (function () {
    function DefaultOverlayInstance($q, $animate, bodyElement) {
        this.$animate = $animate;
        this.bodyElement = bodyElement;
        this.resultDeferred = $q.defer();
        this.openedDeferred = $q.defer();
        this.closedDeferred = $q.defer();
        this.result = this.resultDeferred.promise;
        this.opened = this.openedDeferred.promise;
        this.closed = this.closedDeferred.promise;
    }
    /**
     * Create a scope for this instance. Note that we can't create the scope from constructor,
     * because this instance needs to be returned even if initialization fails. We only want
     * the scope to be created if constructor is successful.
     */
    DefaultOverlayInstance.prototype.createScope = function (parentScope) {
        var _this = this;
        var scope = parentScope.$new();
        scope.$close = this.close.bind(this);
        scope.$dismiss = this.dismiss.bind(this);
        scope.$on('$destroy', function () {
            if (!scope.$$overlayDestructionScheduled) {
                _this.dismiss('unscheduledDestruction');
            }
        });
        this.scope = function () { return scope; };
        return scope;
    };
    DefaultOverlayInstance.prototype.close = function (result) {
        this.closeOrDismiss(result, true);
    };
    DefaultOverlayInstance.prototype.dismiss = function (reason) {
        this.closeOrDismiss(reason, false);
    };
    DefaultOverlayInstance.prototype.closeOrDismiss = function (result, closing) {
        var _this = this;
        var scope = this.scope();
        if (scope.$broadcast('overlay.closing', result, closing).defaultPrevented) {
            return;
        }
        scope.$$overlayDestructionScheduled = true;
        if (closing) {
            this.resultDeferred.resolve(result);
        }
        else {
            this.resultDeferred.reject(result);
        }
        var elem = this.element();
        this.$animate.leave(elem).then(function () {
            _this.bodyElement.removeClass(overlayOpenClass);
            elem.remove();
            _this.closedDeferred.resolve();
        });
        scope.$destroy();
    };
    return DefaultOverlayInstance;
}());
