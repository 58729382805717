var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as _ from 'lodash';
import { requireDefined } from 'yti-common-ui/utils/object';
import { modelUrl, normalizeModelType, resourceUrl } from '../utils/entity';
import { Uri } from './uri';
import { containsAny, remove } from 'yti-common-ui/utils/array';
import { DefinedBy } from './definedBy';
import { Vocabulary } from './vocabulary';
import { ReferenceData } from './referenceData';
import { init, serialize } from './mapping';
import { GraphNode } from './graphNode';
import { entity, entityAwareList, entityAwareOptional, uriSerializer } from './serializer/entitySerializer';
import { dateSerializer, identitySerializer, languageSerializer, list, localizableSerializer, optional, stringSerializer, typeSerializer, valueOrDefault } from './serializer/serializer';
import { Organization } from './organization';
import { Classification } from './classification';
function normalizeType(type) {
    var normalizedType = requireDefined(normalizeModelType(type));
    if (normalizedType === 'model') {
        throw new Error('Model type must be known');
    }
    else {
        return normalizedType;
    }
}
var AbstractModel = /** @class */ (function (_super) {
    __extends(AbstractModel, _super);
    function AbstractModel(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.normalizedType = normalizeType(_this.type);
        init(_this, AbstractModel.abstractModelMappings);
        return _this;
    }
    AbstractModel.prototype.iowUrl = function () {
        return modelUrl(this.prefix);
    };
    AbstractModel.abstractModelMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        namespace: { name: 'preferredXMLNamespaceName', serializer: stringSerializer },
        prefix: { name: 'preferredXMLNamespacePrefix', serializer: stringSerializer }
    };
    return AbstractModel;
}(GraphNode));
export { AbstractModel };
var ModelListItem = /** @class */ (function (_super) {
    __extends(ModelListItem, _super);
    function ModelListItem(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ModelListItem.modelListItemMappings);
        return _this;
    }
    ModelListItem.modelListItemMappings = {
        comment: { name: 'comment', serializer: localizableSerializer },
        status: { name: 'versionInfo', serializer: identitySerializer() },
        classifications: { name: 'isPartOf', serializer: entityAwareList(entity(function () { return Classification; })) },
        contributors: { name: 'contributor', serializer: entityAwareList(entity(function () { return Organization; })) },
        useContext: { name: 'useContext', serializer: valueOrDefault(identitySerializer(), 'InformationDescription') }
    };
    return ModelListItem;
}(AbstractModel));
export { ModelListItem };
var Model = /** @class */ (function (_super) {
    __extends(Model, _super);
    function Model(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.unsaved = false;
        init(_this, Model.modelMappings);
        _this.copyNamespacesFromRequires();
        return _this;
    }
    Model.prototype.addVocabulary = function (vocabulary) {
        this.vocabularies.push(vocabulary);
    };
    Model.prototype.removeVocabulary = function (vocabulary) {
        remove(this.vocabularies, vocabulary);
    };
    Model.prototype.addImportedNamespace = function (ns) {
        this.importedNamespaces.push(ns);
        this.context[ns.prefix] = ns.namespace;
    };
    Model.prototype.removeImportedNamespace = function (ns) {
        if (ns.namespaceType !== NamespaceType.TECHNICAL) {
            delete this.context[ns.prefix];
        }
        remove(this.importedNamespaces, ns);
    };
    Model.prototype.addLink = function (link) {
        this.links.push(link);
    };
    Model.prototype.removeLink = function (link) {
        remove(this.links, link);
    };
    Model.prototype.addReferenceData = function (referenceData) {
        this.referenceDatas.push(referenceData);
    };
    Model.prototype.removeReferenceData = function (referenceData) {
        remove(this.referenceDatas, referenceData);
    };
    Model.prototype.getNamespaces = function () {
        var namespaces = [];
        var requiredNamespacePrefixes = new Set();
        namespaces.push({
            prefix: this.prefix,
            url: this.namespace,
            namespaceType: NamespaceType.MODEL
        });
        requiredNamespacePrefixes.add(this.prefix);
        for (var _i = 0, _a = this.importedNamespaces; _i < _a.length; _i++) {
            var require_1 = _a[_i];
            namespaces.push({
                prefix: require_1.prefix,
                url: require_1.namespace,
                namespaceType: require_1.namespaceType
            });
            requiredNamespacePrefixes.add(require_1.prefix);
        }
        for (var _b = 0, _c = Object.keys(this.context); _b < _c.length; _b++) {
            var prefix = _c[_b];
            if (!requiredNamespacePrefixes.has(prefix)) {
                var value = this.context[prefix];
                if (typeof value === 'string') {
                    namespaces.push({
                        prefix: prefix,
                        url: value,
                        namespaceType: NamespaceType.IMPLICIT_TECHNICAL
                    });
                }
            }
        }
        return namespaces;
    };
    Model.prototype.copyNamespacesFromRequires = function () {
        for (var _i = 0, _a = this.importedNamespaces; _i < _a.length; _i++) {
            var require_2 = _a[_i];
            // if overriding existing namespace remove previous prefix
            for (var _b = 0, _c = Object.keys(this.context); _b < _c.length; _b++) {
                var prefix = _c[_b];
                var value = this.context[prefix];
                if (value === require_2.namespace) {
                    delete this.context[prefix];
                }
            }
            this.context[require_2.prefix] = require_2.namespace;
        }
    };
    Model.prototype.expandContextWithKnownModels = function (context) {
        var models = {};
        for (var _i = 0, _a = this.getNamespaces(); _i < _a.length; _i++) {
            var namespace = _a[_i];
            if (namespace.namespaceType === NamespaceType.MODEL || namespace.namespaceType === NamespaceType.EXTERNAL) {
                models[namespace.prefix] = namespace.url;
            }
        }
        Object.assign(context, models);
    };
    Model.prototype.asDefinedBy = function () {
        return new DefinedBy({ '@id': this.id.uri, '@type': typeSerializer.serialize(this.type), label: this.label }, this.context, this.frame);
    };
    Model.prototype.namespaceAsDefinedBy = function (ns) {
        for (var _i = 0, _a = this.importedNamespaces; _i < _a.length; _i++) {
            var require_3 = _a[_i];
            if (ns === require_3.namespace) {
                return new DefinedBy({ '@id': ns, '@type': typeSerializer.serialize(require_3.type) }, this.context, this.frame);
            }
        }
        throw new Error('Namespace not found: ' + ns);
    };
    Model.prototype.isNamespaceKnownToBeNotModel = function (namespace) {
        return this.isNamespaceKnownAndOfType(namespace, [NamespaceType.EXTERNAL, NamespaceType.TECHNICAL, NamespaceType.IMPLICIT_TECHNICAL]);
    };
    Model.prototype.isNamespaceKnownToBeModel = function (namespace) {
        return this.isNamespaceKnownAndOfType(namespace, [NamespaceType.MODEL]);
    };
    Model.prototype.isNamespaceKnownAndOfType = function (namespace, types) {
        for (var _i = 0, _a = this.getNamespaces(); _i < _a.length; _i++) {
            var knownNamespace = _a[_i];
            if (namespace === knownNamespace.url && containsAny(types, [knownNamespace.namespaceType])) {
                return true;
            }
        }
        return false;
    };
    Model.prototype.linkToResource = function (id) {
        if (id && !id.isUrn()) {
            if (typeof id.namespace === 'string') {
                if (!id.namespace.startsWith('http')) {
                    return null;
                }
            }
            if (this.isNamespaceKnownToBeModel(id.namespace)) {
                return resourceUrl(requireDefined(id.resolve()).prefix, id);
            }
            else {
                return id.url;
            }
        }
        else {
            return null;
        }
    };
    Model.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        var result = new Model(serialization['@graph'], serialization['@context'], this.frame);
        result.unsaved = this.unsaved;
        return result;
    };
    Model.prototype.serializationValues = function (_inline, clone) {
        this.copyNamespacesFromRequires();
        return serialize(this, clone, Object.assign({}, AbstractModel.abstractModelMappings, Model.modelMappings));
    };
    Model.prototype.addClassification = function (classification) {
        this.classifications.push(classification);
    };
    Model.prototype.removeClassification = function (classification) {
        remove(this.classifications, classification);
    };
    Model.prototype.addContributor = function (organization) {
        this.contributors.push(organization);
    };
    Model.prototype.removeContributor = function (organization) {
        remove(this.contributors, organization);
    };
    Model.modelMappings = {
        comment: { name: 'comment', serializer: localizableSerializer },
        status: { name: 'versionInfo', serializer: identitySerializer() },
        vocabularies: { name: 'references', serializer: entityAwareList(entity(function () { return Vocabulary; })) },
        importedNamespaces: { name: 'requires', serializer: entityAwareList(entity(function () { return ImportedNamespace; })) },
        links: { name: 'relations', serializer: entityAwareList(entity(function () { return Link; })) },
        referenceDatas: { name: 'codeLists', serializer: entityAwareList(entity(function () { return ReferenceData; })) },
        classifications: { name: 'isPartOf', serializer: entityAwareList(entity(function () { return Classification; })) },
        contributors: { name: 'contributor', serializer: entityAwareList(entity(function () { return Organization; })) },
        version: { name: 'identifier', serializer: optional(identitySerializer()) },
        rootClass: { name: 'rootResource', serializer: entityAwareOptional(uriSerializer) },
        language: { name: 'language', serializer: list(languageSerializer, ['fi', 'en', 'sv']) },
        modifiedAt: { name: 'modified', serializer: optional(dateSerializer) },
        createdAt: { name: 'created', serializer: optional(dateSerializer) },
        useContext: { name: 'useContext', serializer: valueOrDefault(identitySerializer(), 'InformationDescription') },
        contact: { name: 'contact', serializer: localizableSerializer },
        previousModel: { name: 'wasRevisionOf', serializer: entityAwareOptional(uriSerializer) },
        documentation: { name: 'documentation', serializer: localizableSerializer },
    };
    return Model;
}(AbstractModel));
export { Model };
var ImportedNamespace = /** @class */ (function (_super) {
    __extends(ImportedNamespace, _super);
    function ImportedNamespace(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ImportedNamespace.importedNamespaceMappings);
        return _this;
    }
    Object.defineProperty(ImportedNamespace.prototype, "namespaceType", {
        get: function () {
            if (this.isOfType('resource')) {
                return NamespaceType.EXTERNAL;
            }
            else if (this.isOfType('standard')) {
                return NamespaceType.TECHNICAL;
            }
            else if (this.isOfType('model', 'library', 'profile')) {
                return NamespaceType.MODEL;
            }
            else {
                throw new Error('Unsupported type for imported namespace: [' + this.type.join(',') + '] for ' + this.id.toString());
            }
        },
        enumerable: true,
        configurable: true
    });
    ImportedNamespace.prototype.convertAsTechnical = function () {
        this.type = ['standard'];
    };
    Object.defineProperty(ImportedNamespace.prototype, "external", {
        get: function () {
            return this.namespaceType === NamespaceType.EXTERNAL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "technical", {
        get: function () {
            return this.namespaceType === NamespaceType.TECHNICAL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "prefixModifiable", {
        get: function () {
            return this.external;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "namespaceModifiable", {
        get: function () {
            return this.external;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "labelModifiable", {
        get: function () {
            return this.external || this.technical;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "prefix", {
        get: function () {
            return this._prefix;
        },
        set: function (prefix) {
            this._prefix = prefix;
            this.id = new Uri(this.id.uri, (_a = {}, _a[prefix] = this.namespace, _a));
            var _a;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "namespace", {
        get: function () {
            return this._namespace;
        },
        set: function (ns) {
            this._namespace = ns;
            this.id = new Uri(_.trimEnd(ns, '#/'), (_a = {}, _a[this.prefix] = ns, _a));
            var _a;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportedNamespace.prototype, "url", {
        get: function () {
            return this.namespace;
        },
        enumerable: true,
        configurable: true
    });
    ImportedNamespace.prototype.serializationValues = function (inline, clone) {
        var onlyIdAndType = inline && !clone && this.namespaceType === NamespaceType.MODEL;
        var exclude = !onlyIdAndType ? [] : [
            ImportedNamespace.importedNamespaceMappings._namespace,
            ImportedNamespace.importedNamespaceMappings._prefix,
            ImportedNamespace.importedNamespaceMappings.label
        ];
        return serialize(this, clone, ImportedNamespace.importedNamespaceMappings, exclude);
    };
    ImportedNamespace.importedNamespaceMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        type: { name: '@type', serializer: typeSerializer },
        _prefix: { name: 'preferredXMLNamespacePrefix', serializer: stringSerializer },
        _namespace: { name: 'preferredXMLNamespaceName', serializer: identitySerializer() }
    };
    return ImportedNamespace;
}(GraphNode));
export { ImportedNamespace };
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Link.linkMappings);
        return _this;
    }
    Link.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, Link.linkMappings);
    };
    Link.linkMappings = {
        homepage: { name: 'homepage', serializer: uriSerializer },
        title: { name: 'title', serializer: localizableSerializer },
        description: { name: 'description', serializer: localizableSerializer }
    };
    return Link;
}(GraphNode));
export { Link };
export var NamespaceType;
(function (NamespaceType) {
    NamespaceType[NamespaceType["IMPLICIT_TECHNICAL"] = 0] = "IMPLICIT_TECHNICAL";
    NamespaceType[NamespaceType["TECHNICAL"] = 1] = "TECHNICAL";
    NamespaceType[NamespaceType["MODEL"] = 2] = "MODEL";
    NamespaceType[NamespaceType["EXTERNAL"] = 3] = "EXTERNAL";
})(NamespaceType || (NamespaceType = {}));
