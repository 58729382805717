var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as angular from 'angular';
import { LanguageService } from 'app/services/languageService';
import { LoginModalService } from 'yti-common-ui/components/login-modal.component';
import { HelpSelectionModal } from 'app/components/common/helpSelectionModal';
import { InteractiveHelpService } from 'app/help/services/interactiveHelpService';
import { identity } from 'yti-common-ui/utils/object';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { ImpersonationService } from 'app/services/impersonationService';
import { ConfigService } from 'app/services/configService';
import { HelpService } from '../../help/providers/helpService';
// const logo = require('../../../assets/logo.svg');
var NavigationBarComponent = /** @class */ (function () {
    function NavigationBarComponent($scope, $route, $location, languageService, userService, impersonationService, loginModal, helpService, interactiveHelpService, helpSelectionModal, configService) {
        'ngInject';
        var _this = this;
        this.languageService = languageService;
        this.userService = userService;
        this.loginModal = loginModal;
        this.interactiveHelpService = interactiveHelpService;
        this.helpSelectionModal = helpSelectionModal;
        // logo = logo;
        this.availableLanguages = [
            { code: 'fi', name: 'Suomeksi (FI)' },
            { code: 'sv', name: 'På svenska (SV)' },
            { code: 'en', name: 'In English (EN)' }
        ];
        this.fakeableUsers = [];
        this.subscriptions = [];
        impersonationService.getFakeableUsers()
            .then(function (users) { return _this.fakeableUsers = users; });
        this.subscriptions.push(helpService.helpProvider.subscribe(function (provider) { return _this.helpProvider = provider; }));
        // TODO: The following, was under watching 'helps' source before refactoring
        /*
              if ($route.current && $route.current!.params.hasOwnProperty('help')) {
                this.startHelp().then(() => {}, _err => {
                  $location.search('help', null as any);
                });
              }
        */
        configService.getConfig()
            .then(function (config) { return _this.config = config; });
    }
    NavigationBarComponent.prototype.$onDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(NavigationBarComponent.prototype, "groupManagementUrl", {
        get: function () {
            return this.config && this.config.groupManagementUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "terminologyUrl", {
        get: function () {
            return this.config && this.config.terminologyUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "codeListUrl", {
        get: function () {
            return this.config && this.config.codeListUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "commentsUrl", {
        get: function () {
            return this.config && this.config.commentsUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "environmentIdentifier", {
        get: function () {
            return this.config ? this.config.getEnvironmentIdentifier('postfix') : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "language", {
        get: function () {
            return this.languageService.UILanguage;
        },
        set: function (language) {
            this.languageService.UILanguage = language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "noMenuItemsAvailable", {
        get: function () {
            return !this.userService.isLoggedIn();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "helps", {
        get: function () {
            if (this.helpProvider) {
                return this.helpProvider.helps;
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.fakeUser = function (userEmail) {
        this.userService.updateLoggedInUser(userEmail).then(function () {
            window.location.reload();
        }).catch(function (reason) { return console.error('Fake login failed: ' + reason); });
    };
    NavigationBarComponent.prototype.isLoggedIn = function () {
        return !this.user.anonymous;
    };
    NavigationBarComponent.prototype.showGroupManagementLink = function () {
        return this.user.superuser || this.user.isInRoleInAnyOrganization('ADMIN');
    };
    NavigationBarComponent.prototype.logOut = function () {
        return this.userService.logout();
    };
    NavigationBarComponent.prototype.logIn = function () {
        this.loginModal.open();
    };
    NavigationBarComponent.prototype.canStartHelp = function () {
        return this.config && this.config.showIncompleteFeature && this.interactiveHelpService.isClosed() && this.helps.length > 0;
    };
    NavigationBarComponent.prototype.startHelp = function () {
        return this.helpSelectionModal.open(this.helps).then(identity, modalCancelHandler);
    };
    NavigationBarComponent = __decorate([
        LegacyComponent({
            template: require('./navigationBar.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Object, LanguageService, Object, ImpersonationService,
            LoginModalService,
            HelpService,
            InteractiveHelpService,
            HelpSelectionModal,
            ConfigService])
    ], NavigationBarComponent);
    return NavigationBarComponent;
}());
export { NavigationBarComponent };
