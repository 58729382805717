var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as _ from 'lodash';
import { isDefined } from 'yti-common-ui/utils/object';
import { collectIds } from '../../utils/entity';
import { comparingLocalizable } from '../../utils/comparator';
import { LanguageService } from '../../services/languageService';
import { ifChanged, LegacyComponent } from '../../utils/angular';
import { DefinedBy } from '../../entities/definedBy';
var ModelFilterComponent = /** @class */ (function () {
    function ModelFilterComponent($scope, languageService) {
        'ngInject';
        this.$scope = $scope;
        this.languageService = languageService;
        this.modelOptions = [];
        this.currentModelImportedNamespaceIds = new Set();
    }
    ModelFilterComponent.prototype.$onInit = function () {
        var _this = this;
        this.importedNamespacesOption = { id: this.model.id, optionType: 'importedNamespaces' };
        this.definedByThisOption = { id: this.model.id, optionType: 'definedByThis' };
        this.showModel = this.defaultShowOption;
        this.$scope.$watch(function () { return _this.model; }, function () {
            _this.currentModelImportedNamespaceIds = collectIds(_this.model.importedNamespaces);
            _this.searchController.search();
        });
        this.$scope.$watch(function () { return _this.searchController.items; }, function (items) { return _this.filterModelOptions(items, _this.modelType); });
        this.$scope.$watch(function () { return _this.modelType; }, function (modelType) { return _this.filterModelOptions(_this.searchController.items, modelType); });
        this.searchController.addFilter(function (item) {
            if (!_this.showModel) {
                return true;
            }
            else if (_this.isImportedNamespacesOption(_this.showModel)) {
                return _this.currentModelImportedNamespaceIds.has(item.item.definedBy.id.toString());
            }
            else {
                return isDefined(item.item.definedBy) && item.item.definedBy.id.equals(_this.showModel.id);
            }
        });
        this.$scope.$watch(function () { return _this.showModel; }, ifChanged(function () {
            return _this.searchController.search();
        }));
    };
    ModelFilterComponent.prototype.filterModelOptions = function (items, modelType) {
        var localizer = this.languageService.createLocalizer(this.model);
        var definedByFromClasses = _.chain(items)
            .map(function (item) { return item.definedBy; })
            .uniqBy(function (definedBy) { return definedBy.id.toString(); })
            .value()
            .sort(comparingLocalizable(localizer, function (definedBy) { return definedBy.label; }));
        var definedByFilteredByType = definedByFromClasses.filter(function (model) { return modelType ? model.isOfType(modelType) : true; });
        this.modelOptions = this.hideThisModel ? definedByFilteredByType
            : [this.importedNamespacesOption, this.definedByThisOption].concat(definedByFilteredByType);
    };
    ModelFilterComponent.prototype.isDefinedByThisOption = function (item) {
        return !(item instanceof DefinedBy) && item.optionType === 'definedByThis';
    };
    ModelFilterComponent.prototype.isImportedNamespacesOption = function (item) {
        return !(item instanceof DefinedBy) && item.optionType === 'importedNamespaces';
    };
    Object.defineProperty(ModelFilterComponent.prototype, "defaultShowOption", {
        get: function () {
            return this.defaultShow === 'definedByThis' ? this.definedByThisOption
                : this.defaultShow === 'importedNamespaces' ? this.importedNamespacesOption : this.defaultShow;
        },
        enumerable: true,
        configurable: true
    });
    ModelFilterComponent = __decorate([
        LegacyComponent({
            bindings: {
                searchController: '=',
                type: '@',
                model: '=',
                defaultShow: '=',
                hideThisModel: '=',
                modelType: '='
            },
            template: "\n      <select id=\"model\"\n              class=\"form-control\"\n              style=\"width: auto\"\n              ng-model=\"$ctrl.showModel\"\n              ng-options=\"$ctrl.isImportedNamespacesOption(model)\n                        ? ('Imported namespaces' | translate)\n                        : ($ctrl.isDefinedByThisOption(model) ? ('Defined by this model' | translate) : (model | translateLabel: $ctrl.model))\n                        for model in $ctrl.modelOptions\">\n        <option value=\"\" translate>All models</option>\n      </select>\n  "
        }),
        __metadata("design:paramtypes", [Object, LanguageService])
    ], ModelFilterComponent);
    return ModelFilterComponent;
}());
export { ModelFilterComponent };
