var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EditableEntityController } from 'app/components/form/editableEntityController';
import { DeleteConfirmationModal } from 'app/components/common/deleteConfirmationModal';
import { ErrorModal } from 'app/components/form/errorModal';
import { AuthorizationManagerService } from 'app/services/authorizationManagerService';
import { LegacyComponent } from 'app/utils/angular';
import { changeToRestrictedStatus } from 'yti-common-ui/entities/status';
import { DatamodelConfirmationModalService } from 'app/services/confirmation-modal.service';
var PredicateViewComponent = /** @class */ (function (_super) {
    __extends(PredicateViewComponent, _super);
    function PredicateViewComponent($scope, $log, $q, datamodelConfirmationModalService, deleteConfirmationModal, errorModal, predicateService, userService, authorizationManagerService) {
        'ngInject';
        var _this = _super.call(this, $scope, $log, deleteConfirmationModal, errorModal, userService) || this;
        _this.$q = $q;
        _this.datamodelConfirmationModalService = datamodelConfirmationModalService;
        _this.predicateService = predicateService;
        _this.authorizationManagerService = authorizationManagerService;
        return _this;
    }
    PredicateViewComponent.prototype.$onInit = function () {
        this.parent.registerView(this);
    };
    PredicateViewComponent.prototype.$onDestroy = function () {
        this.parent.deregisterView(this);
    };
    PredicateViewComponent.prototype.create = function (entity) {
        var _this = this;
        return this.predicateService.createPredicate(entity).then(function () { return _this.modelController.selectionEdited(null, entity); });
    };
    PredicateViewComponent.prototype.update = function (entity, oldEntity) {
        var _this = this;
        return this.predicateService.updatePredicate(entity, oldEntity.id, this.model).then(function () { return _this.modelController.selectionEdited(oldEntity, entity); });
    };
    PredicateViewComponent.prototype.remove = function (entity) {
        var _this = this;
        return this.predicateService.deletePredicate(entity.id, this.model).then(function () { return _this.modelController.selectionDeleted(entity); });
    };
    PredicateViewComponent.prototype.rights = function () {
        var _this = this;
        return {
            edit: function () { return _this.authorizationManagerService.canEditPredicate(_this.model, _this.predicate); },
            remove: function () { return _this.authorizationManagerService.canRemovePredicate(_this.model, _this.predicate); }
        };
    };
    PredicateViewComponent.prototype.getEditable = function () {
        return this.predicate;
    };
    PredicateViewComponent.prototype.setEditable = function (editable) {
        this.predicate = editable;
    };
    PredicateViewComponent.prototype.isReference = function () {
        return this.predicate.definedBy.id.notEquals(this.model.id);
    };
    PredicateViewComponent.prototype.getRemoveText = function () {
        var text = _super.prototype.getRemoveText.call(this);
        return !this.isReference() ? text : text + ' from this ' + this.model.normalizedType;
    };
    PredicateViewComponent.prototype.openDeleteConfirmationModal = function () {
        var onlyDefinedInModel = this.isReference() ? this.model : null;
        return this.deleteConfirmationModal.open(this.getEditable(), this.getContext(), onlyDefinedInModel);
    };
    PredicateViewComponent.prototype.getContext = function () {
        return this.model;
    };
    PredicateViewComponent.prototype.confirmChangeToRestrictedStatus = function (entity, oldEntity) {
        return entity.status && oldEntity.status ? changeToRestrictedStatus(oldEntity.status, entity.status) : false;
    };
    PredicateViewComponent.prototype.confirmChangeToRestrictedStatusDialog = function (entity, oldEntity) {
        return entity.status && oldEntity.status
            ? changeToRestrictedStatus(oldEntity.status, entity.status) ? this.$q.when(this.datamodelConfirmationModalService.openChangeToRestrictedStatus()) : null
            : null;
    };
    PredicateViewComponent.prototype.confirmDialog = function (entity, oldEntity) {
        // NOTE: This is not implemented or needed yet in PropertyView.
        return null;
    };
    PredicateViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '@',
                predicate: '=',
                model: '=',
                modelController: '<',
                parent: '<',
                width: '='
            },
            template: require('./predicateView.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Function, DatamodelConfirmationModalService,
            DeleteConfirmationModal,
            ErrorModal, Object, Object, AuthorizationManagerService])
    ], PredicateViewComponent);
    return PredicateViewComponent;
}(EditableEntityController));
export { PredicateViewComponent };
