var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { init, serialize } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareList, entity } from './serializer/entitySerializer';
import { optional, stringSerializer, localizableSerializer, identitySerializer } from './serializer/serializer';
var ReferenceDataServer = /** @class */ (function (_super) {
    __extends(ReferenceDataServer, _super);
    function ReferenceDataServer(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ReferenceDataServer.referenceDataServerMappings);
        return _this;
    }
    ReferenceDataServer.referenceDataServerMappings = {
        id: { name: '@id', serializer: uriSerializer },
        identifier: { name: 'identifier', serializer: optional(stringSerializer) },
        description: { name: 'description', serializer: localizableSerializer },
        title: { name: 'title', serializer: localizableSerializer }
    };
    return ReferenceDataServer;
}(GraphNode));
export { ReferenceDataServer };
var ReferenceDataGroup = /** @class */ (function (_super) {
    __extends(ReferenceDataGroup, _super);
    function ReferenceDataGroup(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ReferenceDataGroup.referenceDataGroupMappings);
        return _this;
    }
    ReferenceDataGroup.referenceDataGroupMappings = {
        id: { name: '@id', serializer: uriSerializer },
        title: { name: 'title', serializer: localizableSerializer }
    };
    return ReferenceDataGroup;
}(GraphNode));
export { ReferenceDataGroup };
var ReferenceData = /** @class */ (function (_super) {
    __extends(ReferenceData, _super);
    function ReferenceData(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ReferenceData.referenceDataMappings);
        return _this;
    }
    ReferenceData.prototype.isExternal = function () {
        return this.isOfType('externalReferenceData');
    };
    ReferenceData.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, ReferenceData.referenceDataMappings);
    };
    ReferenceData.referenceDataMappings = {
        id: { name: '@id', serializer: uriSerializer },
        title: { name: 'title', serializer: localizableSerializer },
        description: { name: 'description', serializer: localizableSerializer },
        creator: { name: 'creator', serializer: optional(stringSerializer) },
        identifier: { name: 'identifier', serializer: optional(stringSerializer) },
        groups: { name: 'isPartOf', serializer: entityAwareList(entity(function () { return ReferenceDataGroup; })) },
        status: { name: 'status', serializer: optional(identitySerializer()) }
    };
    return ReferenceData;
}(GraphNode));
export { ReferenceData };
var ReferenceDataCode = /** @class */ (function (_super) {
    __extends(ReferenceDataCode, _super);
    function ReferenceDataCode(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ReferenceDataCode.referenceDataCodeMappings);
        return _this;
    }
    ReferenceDataCode.referenceDataCodeMappings = {
        id: { name: '@id', serializer: uriSerializer },
        title: { name: 'title', serializer: localizableSerializer },
        identifier: { name: 'identifier', serializer: optional(stringSerializer) },
        status: { name: 'status', serializer: stringSerializer }
    };
    return ReferenceDataCode;
}(GraphNode));
export { ReferenceDataCode };
