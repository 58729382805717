var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as moment from 'moment';
import { LanguageService } from 'app/services/languageService';
import { Model } from 'app/entities/model';
import { Class } from 'app/entities/class';
import { apiEndpointWithName } from 'app/services/config';
import { LegacyComponent } from 'app/utils/angular';
var exportOptions = [
    { type: 'application/ld+json', extension: 'json' },
    { type: 'text/turtle', extension: 'ttl' },
    { type: 'application/rdf+xml', extension: 'rdf' },
    { type: 'application/xml', extension: 'xml', validTypes: [Model, Class] },
    { type: 'application/schema+json', extension: 'json', validTypes: [Model, Class] },
    { type: 'application/ld+json+context', extension: 'json' },
    { type: 'application/vnd+oai+openapi+json', extension: 'json', validTypes: [Model, Class] }
];
var UTF8_BOM = '\ufeff';
function formatFileName(entity, extension) {
    return entity.id.uri.substr('http://'.length) + "-" + moment().format('YYYY-MM-DD') + "." + extension;
}
function isValidType(entity, typeArray) {
    for (var _i = 0, typeArray_1 = typeArray; _i < typeArray_1.length; _i++) {
        var type = typeArray_1[_i];
        if (entity instanceof type) {
            return true;
        }
    }
    return false;
}
var ExportComponent = /** @class */ (function () {
    function ExportComponent($scope, $window, languageService) {
        'ngInject';
        this.$scope = $scope;
        this.$window = $window;
        this.languageService = languageService;
        this.idCleanerExpression = /[^a-zA-Z0-9_-]/g;
    }
    ExportComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watchGroup([function () { return _this.entity; }, function () { return _this.languageService.getModelLanguage(_this.context); }], function (_a) {
            var entity = _a[0], lang = _a[1];
            var hrefBase = entity instanceof Model ? apiEndpointWithName('exportModel') : apiEndpointWithName('exportResource');
            _this.downloads = exportOptions.filter(function (option) { return !option.validTypes || isValidType(entity, option.validTypes); }).map(function (option) {
                var href = hrefBase + "?graph=" + encodeURIComponent(entity.id.uri) + "&content-type=" + encodeURIComponent(option.type) + "&lang=" + lang;
                return {
                    name: option.type,
                    filename: formatFileName(entity, option.extension),
                    href: href,
                    hrefRaw: href + '&raw=true'
                };
            });
            if (Modernizr.bloburls) {
                var framedDataAsString = JSON.stringify({ '@graph': entity.graph, '@context': entity.context }, null, 2);
                var framedDataBlob_1 = new Blob([UTF8_BOM, framedDataAsString], { type: 'application/ld+json;charset=utf-8' });
                var framedDataBlobRaw = new Blob([UTF8_BOM, framedDataAsString], { type: 'text/plain;charset=utf-8' });
                if (_this.framedUrlObject) {
                    _this.$window.URL.revokeObjectURL(_this.framedUrlObject);
                }
                if (_this.framedUrlObjectRaw) {
                    _this.$window.URL.revokeObjectURL(_this.framedUrlObjectRaw);
                }
                if (_this.frameUrlObject) {
                    _this.$window.URL.revokeObjectURL(_this.frameUrlObject);
                }
                if (_this.frameUrlObjectRaw) {
                    _this.$window.URL.revokeObjectURL(_this.frameUrlObjectRaw);
                }
                _this.framedUrlObject = _this.$window.URL.createObjectURL(framedDataBlob_1);
                _this.framedUrlObjectRaw = _this.$window.URL.createObjectURL(framedDataBlobRaw);
                if (_this.entity.frame) {
                    var frameAsString = JSON.stringify(_this.entity.frame, null, 2);
                    var frameBlob_1 = new Blob([UTF8_BOM, frameAsString], { type: 'application/json;charset=utf-8' });
                    var frameBlobRaw = new Blob([UTF8_BOM, frameAsString], { type: 'text/plain;charset=utf-8' });
                    _this.frameUrlObject = _this.$window.URL.createObjectURL(frameBlob_1);
                    _this.frameUrlObjectRaw = _this.$window.URL.createObjectURL(frameBlobRaw);
                    _this.downloads.push({
                        name: 'ld+json frame',
                        filename: 'frame.json',
                        href: _this.frameUrlObject,
                        hrefRaw: _this.frameUrlObjectRaw,
                        onClick: function () {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(frameBlob_1, 'frame.json');
                            }
                        }
                    });
                }
                _this.downloads.push({
                    name: 'framed ld+json',
                    filename: formatFileName(_this.entity, 'json'),
                    href: _this.framedUrlObject,
                    hrefRaw: _this.framedUrlObjectRaw,
                    onClick: function () {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(framedDataBlob_1, formatFileName(_this.entity, 'json'));
                        }
                    }
                });
            }
        });
    };
    ExportComponent.prototype.getId = function (thing) {
        if (this.idPrefix) {
            return this.idPrefix + '_export_' + thing.replace(this.idCleanerExpression, '_');
        }
        return undefined;
    };
    ExportComponent = __decorate([
        LegacyComponent({
            bindings: {
                entity: '<',
                context: '<',
                idPrefix: '<'
            },
            template: require('./export.html')
        }),
        __metadata("design:paramtypes", [Object, Object, LanguageService])
    ], ExportComponent);
    return ExportComponent;
}());
export { ExportComponent };
