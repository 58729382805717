var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AddEditLinkModal } from './addEditLinkModal';
import { LanguageService } from 'app/services/languageService';
import { TableDescriptor } from 'app/components/form/editableTable';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
var LinksViewComponent = /** @class */ (function () {
    function LinksViewComponent($scope, addEditLinkModal, languageService) {
        'ngInject';
        this.$scope = $scope;
        this.addEditLinkModal = addEditLinkModal;
        this.languageService = languageService;
        this.expanded = false;
    }
    LinksViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new LinkTableDescriptor(_this.addEditLinkModal, value, _this.context, _this.languageService);
        });
    };
    LinksViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    LinksViewComponent.prototype.addLink = function () {
        var _this = this;
        this.addEditLinkModal.openAdd(this.languageService.getModelLanguage(this.context))
            .then(function (link) {
            _this.value.addLink(link);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    LinksViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                context: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n      <h4>\n        <span translate>Links</span> \n        <button id=\"add_link_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.addLink()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Add link</span>\n        </button>\n      </h4>\n      <editable-table id=\"'links'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, AddEditLinkModal,
            LanguageService])
    ], LinksViewComponent);
    return LinksViewComponent;
}());
export { LinksViewComponent };
var LinkTableDescriptor = /** @class */ (function (_super) {
    __extends(LinkTableDescriptor, _super);
    function LinkTableDescriptor(addEditLinkModal, value, context, languageService) {
        var _this = _super.call(this) || this;
        _this.addEditLinkModal = addEditLinkModal;
        _this.value = value;
        _this.context = context;
        _this.languageService = languageService;
        return _this;
    }
    LinkTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Title', nameExtractor: function (link) { return _this.languageService.translate(link.title, _this.context); }, hrefExtractor: function (link) { return link.homepage.toString(); } },
            { headerName: 'Description', nameExtractor: function (link) { return _this.languageService.translate(link.description, _this.context); } }
        ];
    };
    LinkTableDescriptor.prototype.values = function () {
        return this.value && this.value.links;
    };
    LinkTableDescriptor.prototype.hasOrder = function () {
        return true;
    };
    LinkTableDescriptor.prototype.edit = function (link) {
        this.addEditLinkModal.openEdit(link, this.languageService.getModelLanguage(this.context));
    };
    LinkTableDescriptor.prototype.remove = function (link) {
        this.value.removeLink(link);
    };
    LinkTableDescriptor.prototype.canEdit = function (_link) {
        return true;
    };
    LinkTableDescriptor.prototype.canRemove = function (_link) {
        return true;
    };
    return LinkTableDescriptor;
}(TableDescriptor));
