import { ControlValueAccessor, FormControl } from '@angular/forms';
var UseContextInputComponent = /** @class */ (function () {
    function UseContextInputComponent() {
        var _this = this;
        this.editing = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
    }
    Object.defineProperty(UseContextInputComponent.prototype, "useContext", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UseContextInputComponent.prototype, "show", {
        get: function () {
            return this.editing || this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    UseContextInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    UseContextInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    UseContextInputComponent.prototype.registerOnTouched = function (fn) {
    };
    return UseContextInputComponent;
}());
export { UseContextInputComponent };
