import { BehaviorSubject, of } from 'rxjs';
// TODO fix to align with yti-common-ui UserService
var InteractiveHelpUser = /** @class */ (function () {
    function InteractiveHelpUser() {
        this.name = 'Ohjekäyttäjä';
        this.anonymous = false;
        this.superuser = false;
    }
    InteractiveHelpUser.prototype.isInRole = function (role, organizationIds) {
        return true;
    };
    InteractiveHelpUser.prototype.isInOrganization = function (organizationIds, roles) {
        return true;
    };
    InteractiveHelpUser.prototype.isInRoleInAnyOrganization = function (role) {
        return true;
    };
    return InteractiveHelpUser;
}());
var InteractiveHelpUserService = /** @class */ (function () {
    function InteractiveHelpUserService($q) {
        'ngInject';
        this.$q = $q;
        this._user$ = new BehaviorSubject(new InteractiveHelpUser());
    }
    Object.defineProperty(InteractiveHelpUserService.prototype, "loggedIn$", {
        get: function () {
            return of(true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpUserService.prototype, "user", {
        get: function () {
            return this._user$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpUserService.prototype, "user$", {
        get: function () {
            return this._user$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    InteractiveHelpUserService.prototype.updateLoggedInUser = function (fakeLoginMail) {
        throw new Error();
    };
    InteractiveHelpUserService.prototype.register = function () {
        throw new Error();
    };
    InteractiveHelpUserService.prototype.login = function () {
        throw new Error();
    };
    InteractiveHelpUserService.prototype.isLoggedIn = function () {
        return true;
    };
    InteractiveHelpUserService.prototype.logout = function () {
        throw new Error('Should not be able to logout when in help');
    };
    InteractiveHelpUserService.prototype.reset = function () {
        return this.$q.when();
    };
    return InteractiveHelpUserService;
}());
export { InteractiveHelpUserService };
