import { RelatedClass } from '../../services/classService';
import { createDefinedByExclusion, combineExclusions, createClassTypeExclusion, createExistsExclusion } from '../../utils/exclusion';
import { Class } from '../../entities/class';
import { ExternalEntity } from '../../entities/externalEntity';
import { defaultLabelComparator, filterAndSortSearchResults } from '../../components/filter/util';
import { requireDefined } from 'yti-common-ui/utils/object';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { selectableStatuses } from 'yti-common-ui/entities/status';
import { ifChanged } from '../../utils/angular';
import { contains } from 'yti-common-ui/utils/array';
import { comparingLocalizable } from '../../utils/comparator';
import { infoDomainMatches } from '../../utils/entity';
import { SearchClassType } from '../../types/component';
export var noClassExclude = function (_item) { return null; };
export var defaultTextForSelection = function (_klass) { return 'Use class'; };
var SearchClassTableModal = /** @class */ (function () {
    function SearchClassTableModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchClassTableModal.prototype.open = function (model, exclude, filterExclude, textForSelection, classesAssignedToModel) {
        if (filterExclude === void 0) { filterExclude = exclude; }
        return this.openModal(model, exclude, filterExclude, false, false, textForSelection, classesAssignedToModel);
    };
    SearchClassTableModal.prototype.openWithOnlySelection = function (model, defaultToCurrentModel, exclude, filterExclude, textForSelection) {
        if (filterExclude === void 0) { filterExclude = exclude; }
        if (textForSelection === void 0) { textForSelection = defaultTextForSelection; }
        return this.openModal(model, exclude, filterExclude, defaultToCurrentModel, true, textForSelection);
    };
    SearchClassTableModal.prototype.openModal = function (model, exclude, filterExclude, defaultToCurrentModel, onlySelection, textForSelection, classesAssignedToModel) {
        return this.$uibModal.open({
            template: require('./searchClassTableModal.html'),
            size: 'xl',
            windowClass: 'modal-full-height',
            controller: SearchClassTableController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                model: function () { return model; },
                exclude: function () { return exclude; },
                filterExclude: function () { return filterExclude; },
                defaultToCurrentModel: function () { return defaultToCurrentModel; },
                onlySelection: function () { return onlySelection; },
                textForSelection: function () { return textForSelection; },
                classesAssignedToModel: function () { return classesAssignedToModel; }
            }
        }).result;
    };
    return SearchClassTableModal;
}());
export { SearchClassTableModal };
var SearchClassTableController = /** @class */ (function () {
    function SearchClassTableController($scope, $uibModalInstance, classService, languageService, model, exclude, filterExclude, defaultToCurrentModel, onlySelection, textForSelection, classesAssignedToModel, searchConceptModal, classificationService, showClassInfoModal, modelService) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.classService = classService;
        this.model = model;
        this.exclude = exclude;
        this.filterExclude = filterExclude;
        this.defaultToCurrentModel = defaultToCurrentModel;
        this.onlySelection = onlySelection;
        this.textForSelection = textForSelection;
        this.classesAssignedToModel = classesAssignedToModel;
        this.searchConceptModal = searchConceptModal;
        this.showClassInfoModal = showClassInfoModal;
        this.modelService = modelService;
        this.searchResults = [];
        this.searchText = '';
        this.showOnlyExternalClasses = false;
        this.contentMatchers = [
            { name: 'Label', extractor: function (klass) { return klass.label; } },
            { name: 'Description', extractor: function (klass) { return klass.comment; } },
            { name: 'Identifier', extractor: function (klass) { return klass.id.compact; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.searchFilters = [];
        this.classes = [];
        this.internalClasses = [];
        this.externalClasses = [];
        this.localizer = languageService.createLocalizer(model);
        this.loadingClasses = true;
        this.loadingExternalClasses = true;
        this.classTypes = ['class', 'shape'];
        this.modelTypes = ['library', 'profile'];
        this.showModelType = 'library';
        this.sortBy = {
            name: 'name',
            comparator: defaultLabelComparator(this.localizer, this.filterExclude),
            descOrder: false
        };
        var sortInfoDomains = function () {
            _this.infoDomains.sort(comparingLocalizable(_this.localizer, function (infoDomain) { return infoDomain.label; }));
        };
        classificationService.getClassifications().then(function (infoDomains) {
            modelService.getModels().then(function (models) {
                var modelCount = function (infoDomain) {
                    return models.filter(function (mod) { return infoDomainMatches(infoDomain, mod); }).length;
                };
                _this.infoDomains = infoDomains.filter(function (infoDomain) { return modelCount(infoDomain) > 0; });
                sortInfoDomains();
            });
        });
        var results = function (classes) {
            _this.internalClasses = classes;
            _this.search();
            _this.loadingClasses = false;
        };
        var externalResults = function (classes) {
            _this.externalClasses = classes;
            _this.search();
            _this.loadingExternalClasses = false;
        };
        classService.getAllClasses(model).then(results);
        if (model.isOfType('profile')) {
            classService.getExternalClassesForModel(model).then(externalResults);
        }
        $scope.$watch(function () { return _this.selection && _this.selection.id; }, function (selectionId) {
            if (selectionId && _this.selection instanceof ExternalEntity) {
                _this.externalClass = undefined;
                classService.getExternalClass(selectionId, model).then(function (klass) { return _this.externalClass = klass; });
            }
        });
        this.addFilter(function (classListItem) {
            return !_this.showStatus || classListItem.item.status === _this.showStatus;
        });
        this.addFilter(function (classListItem) {
            return !_this.showInfoDomain || contains(classListItem.item.definedBy.classifications.map(function (classification) { return classification.identifier; }), _this.showInfoDomain.identifier);
        });
        this.addFilter(function (classListItem) {
            return !_this.showClassType || classListItem.item.normalizedType === _this.showClassType;
        });
        this.addFilter(function (classListItem) {
            return !_this.showModelType || classListItem.item.definedBy.normalizedType === _this.showModelType;
        });
        $scope.$watch(function () { return _this.showStatus; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showClassType; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showModelType; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showInfoDomain; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.sortBy.name; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.sortBy.descOrder; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return languageService.getModelLanguage(model); }, ifChanged(function () {
            sortInfoDomains();
            _this.search();
        }));
        $scope.$watch(function () { return _this.showOnlyExternalClasses; }, ifChanged(function () {
            if (_this.showOnlyExternalClasses) {
                _this.showInfoDomain = null;
                _this.showClassType = null;
                _this.showModelType = null;
                _this.showStatus = null;
            }
            _this.search();
        }));
    }
    Object.defineProperty(SearchClassTableController.prototype, "loadingResults", {
        get: function () {
            if (this.showOnlyExternalClasses) {
                return this.loadingExternalClasses;
            }
            return this.loadingClasses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchClassTableController.prototype, "items", {
        get: function () {
            return this.classes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchClassTableController.prototype, "statuses", {
        get: function () {
            return selectableStatuses;
        },
        enumerable: true,
        configurable: true
    });
    SearchClassTableController.prototype.addFilter = function (searchFilter) {
        this.searchFilters.push(searchFilter);
    };
    SearchClassTableController.prototype.isSelectionExternalEntity = function () {
        return this.selection instanceof ExternalEntity;
    };
    SearchClassTableController.prototype.search = function () {
        this.removeSelection();
        if (this.showOnlyExternalClasses) {
            this.classes = this.externalClasses;
        }
        else {
            this.classes = this.internalClasses;
        }
        this.searchResults = filterAndSortSearchResults(this.classes, this.searchText, this.contentExtractors, this.searchFilters, this.sortBy.comparator, 0).slice();
    };
    SearchClassTableController.prototype.canAddNew = function () {
        return !this.onlySelection && !!this.searchText;
    };
    SearchClassTableController.prototype.canAddNewShape = function () {
        return this.canAddNew() && this.model.isOfType('profile');
    };
    SearchClassTableController.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.externalClass = undefined;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        this.cannotConfirm = this.exclude(item);
        if (this.model.isNamespaceKnownToBeNotModel(item.definedBy.id.toString())) {
            this.classService.getExternalClass(item.id, this.model).then(function (result) {
                _this.selection = requireDefined(result); // TODO check if result can actually be null
                _this.cannotConfirm = _this.exclude(requireDefined(result));
            });
        }
        else {
            this.classService.getClass(item.id, this.model).then(function (result) { return _this.selection = result; });
        }
    };
    SearchClassTableController.prototype.removeSelection = function () {
        this.selection = null;
        this.selectedItem = null;
        this.cannotConfirm = null;
    };
    SearchClassTableController.prototype.isExternalClassPending = function () {
        return this.isSelectionExternalEntity() && this.externalClass === undefined;
    };
    SearchClassTableController.prototype.confirm = function () {
        var selection = this.selection;
        if (selection instanceof Class) {
            this.$uibModalInstance.close(this.selection);
        }
        else if (selection instanceof ExternalEntity) {
            if (this.externalClass) {
                var exclude = this.exclude(this.externalClass);
                if (exclude) {
                    this.cannotConfirm = exclude;
                }
                else {
                    this.$uibModalInstance.close(this.externalClass);
                }
            }
            else {
                this.$uibModalInstance.close(selection);
            }
        }
        else {
            throw new Error('Unsupported selection: ' + selection);
        }
    };
    SearchClassTableController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    SearchClassTableController.prototype.createNewClass = function () {
        var _this = this;
        return this.searchConceptModal.openNewEntityCreation(this.model.vocabularies, this.model, 'class', this.searchText)
            .then(function (conceptCreation) { return _this.$uibModalInstance.close(conceptCreation); }, ignoreModalClose);
    };
    SearchClassTableController.prototype.createNewShape = function () {
        this.externalClass = undefined;
        this.cannotConfirm = null;
        this.$scope.form.$setPristine();
        this.selectedItem = null;
        this.$scope.form.editing = true;
        this.selection = new ExternalEntity(this.localizer.language, this.searchText, 'class');
    };
    SearchClassTableController.prototype.showActions = function (item) {
        return item && item instanceof Class ? !this.onlySelection && !item.isOfType('shape') && !item.definedBy.isOfType('standard') : false;
    };
    SearchClassTableController.prototype.copyClass = function (item) {
        this.$uibModalInstance.close(new RelatedClass(item.id, 'prov:wasDerivedFrom'));
    };
    SearchClassTableController.prototype.createSubClass = function (item) {
        this.$uibModalInstance.close(new RelatedClass(item.id, 'rdfs:subClassOf'));
    };
    SearchClassTableController.prototype.createSuperClass = function (item) {
        this.$uibModalInstance.close(new RelatedClass(item.id, 'iow:superClassOf'));
    };
    SearchClassTableController.prototype.addNamespaceToModel = function (item) {
        var _this = this;
        this.modelService.newModelRequirement(this.model, item.id.uri).then(function (response) {
            _this.modelService.getModelByPrefix(_this.model.prefix).then(function (model) {
                _this.model.importedNamespaces = model.importedNamespaces;
                _this.model.context = model.context;
                if (item.normalizedType === 'class' || item.normalizedType === 'shape') {
                    if (_this.model.isOfType('profile')) {
                        // profiles can create multiple shapes of single class so exists exclusion is not wanted
                        // profiles can create copy of shapes so type exclusion is not wanted
                        _this.exclude = createDefinedByExclusion(_this.model);
                    }
                    else {
                        _this.exclude = combineExclusions(createClassTypeExclusion(SearchClassType.Class), createDefinedByExclusion(_this.model), createExistsExclusion(_this.classesAssignedToModel));
                    }
                }
                _this.cannotConfirm = _this.exclude(item);
                _this.selectItem(item);
            });
        });
    };
    SearchClassTableController.prototype.isModelProfile = function () {
        return this.model.isOfType('profile');
    };
    SearchClassTableController.prototype.notDefinedByThisModel = function (item) {
        var definedByExclude = createDefinedByExclusion(this.model);
        return item && !!this.exclude(item) ? this.exclude(item) === definedByExclude(item) : false;
    };
    return SearchClassTableController;
}());
