var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AddNew } from 'app/components/common/searchResults';
import { glyphIconClassForType } from 'app/utils/entity';
import { collectProperties } from 'yti-common-ui/utils/array';
import { combineExclusions, createDefinedByExclusion, createExistsExclusion } from 'app/utils/exclusion';
import { Predicate, PredicateListItem } from 'app/entities/predicate';
import { ExternalEntity } from 'app/entities/externalEntity';
import { defaultLabelComparator, filterAndSortSearchResults } from 'app/components/filter/util';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { requireDefined } from 'yti-common-ui/utils/object';
var noExclude = function (_item) { return null; };
var ɵ0 = noExclude;
var SearchPredicateModal = /** @class */ (function () {
    function SearchPredicateModal($uibModal, choosePredicateTypeModal, classService) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.choosePredicateTypeModal = choosePredicateTypeModal;
        this.classService = classService;
    }
    SearchPredicateModal.prototype.openAddPredicate = function (model, type, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.openModal(model, type, undefined, exclude, false, false);
    };
    SearchPredicateModal.prototype.openAddProperty = function (model, klass) {
        var _this = this;
        var exclude = combineExclusions(createExistsExclusion(collectProperties(klass.properties.filter(function (p) { return p.isAttribute(); }), function (p) { return p.predicateId.uri; })), createDefinedByExclusion(model));
        return this.openModal(model, null, undefined, exclude, false, true).then(function (predicate) {
            if (predicate instanceof Predicate && predicate.normalizedType === 'property') {
                return _this.choosePredicateTypeModal.open().then(function (type) {
                    return _this.classService.newProperty(predicate, type, model);
                });
            }
            else {
                return _this.classService.newProperty(predicate, predicate.normalizedType, model);
            }
        });
    };
    SearchPredicateModal.prototype.openWithCustomDataSource = function (model, type, dataSource, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.openModal(model, type, dataSource, exclude, true, false);
    };
    SearchPredicateModal.prototype.openWithOnlySelection = function (model, type, exclude, requiredByInUse) {
        if (exclude === void 0) { exclude = noExclude; }
        if (requiredByInUse === void 0) { requiredByInUse = false; }
        return this.openModal(model, type, undefined, exclude, true, true, requiredByInUse);
    };
    SearchPredicateModal.prototype.openModal = function (model, type, customDataSource, exclude, onlySelection, allowExternal, requiredByInUse) {
        if (requiredByInUse === void 0) { requiredByInUse = false; }
        return this.$uibModal.open({
            template: require('./searchPredicateModal.html'),
            size: 'lg',
            controller: SearchPredicateController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                model: function () { return model; },
                type: function () { return type; },
                exclude: function () { return exclude; },
                onlySelection: function () { return onlySelection; },
                allowExternal: function () { return allowExternal; },
                customDataSource: function () { return customDataSource; },
                requiredByInUse: function () { return requiredByInUse; }
            }
        }).result;
    };
    return SearchPredicateModal;
}());
export { SearchPredicateModal };
var SearchPredicateController = /** @class */ (function () {
    function SearchPredicateController($scope, $uibModalInstance, model, type, customDataSource, exclude, onlySelection, allowExternal, requiredByInUse, predicateService, languageService, searchConceptModal, gettextCatalog) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.model = model;
        this.type = type;
        this.customDataSource = customDataSource;
        this.exclude = exclude;
        this.onlySelection = onlySelection;
        this.allowExternal = allowExternal;
        this.requiredByInUse = requiredByInUse;
        this.predicateService = predicateService;
        this.searchConceptModal = searchConceptModal;
        this.gettextCatalog = gettextCatalog;
        this.searchResults = [];
        this.searchText = '';
        this.excludeError = null;
        this.cannotConfirm = null;
        this.contentMatchers = [
            { name: 'Label', extractor: function (predicate) { return predicate.label; } },
            { name: 'Description', extractor: function (predicate) { return predicate.comment; } },
            { name: 'Identifier', extractor: function (predicate) { return predicate.id.compact; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.predicates = [];
        this.searchFilters = [];
        this.editInProgress = function () { return _this.$scope.form.editing && _this.$scope.form.$dirty; };
        this.localizer = languageService.createLocalizer(model);
        this.loadingResults = true;
        this.typeSelectable = !type;
        var appendResults = function (predicates) {
            _this.predicates = _this.predicates.concat(predicates);
            _this.search();
            _this.loadingResults = false;
        };
        if (!customDataSource) {
            if (this.requiredByInUse) {
                predicateService.getRequiredByPredicates(model).then(appendResults);
            }
            else {
                predicateService.getAllPredicates(model).then(appendResults);
            }
            if (this.canAddExternal()) {
                predicateService.getExternalPredicatesForModel(model).then(appendResults);
            }
        }
        else {
            customDataSource('').then(appendResults);
        }
        $scope.$watch(function () { return _this.selection && _this.selection.id; }, function (selectionId) {
            if (selectionId && _this.selection instanceof ExternalEntity) {
                _this.externalPredicate = undefined;
                predicateService.getExternalPredicate(selectionId, model).then(function (predicate) { return _this.externalPredicate = predicate; });
            }
        });
    }
    Object.defineProperty(SearchPredicateController.prototype, "items", {
        get: function () {
            return this.predicates;
        },
        enumerable: true,
        configurable: true
    });
    SearchPredicateController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    SearchPredicateController.prototype.canAddExternal = function () {
        return this.model.isOfType('profile') && this.allowExternal;
    };
    SearchPredicateController.prototype.isSelectionExternalEntity = function () {
        return this.selection instanceof ExternalEntity;
    };
    SearchPredicateController.prototype.isSelectionPredicate = function () {
        return this.selection instanceof Predicate;
    };
    SearchPredicateController.prototype.search = function () {
        var _this = this;
        this.searchResults = [
            new AddNewPredicate(this.gettextCatalog.getString('Create new attribute') + " '" + this.searchText + "'", this.isAttributeAddable.bind(this), 'attribute', false),
            new AddNewPredicate(this.gettextCatalog.getString('Create new association') + " '" + this.searchText + "'", this.isAssociationAddable.bind(this), 'association', false),
            new AddNewPredicate(this.gettextCatalog.getString('Create new predicate') + " " + this.gettextCatalog.getString('by referencing external uri'), function () { return _this.canAddExternal(); }, this.type, true)
        ].concat(filterAndSortSearchResults(this.predicates, this.searchText, this.contentExtractors, this.searchFilters, defaultLabelComparator(this.localizer, this.exclude)));
    };
    SearchPredicateController.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.externalPredicate = undefined;
        this.excludeError = null;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        if (item instanceof AddNewPredicate) {
            if (item.external) {
                this.$scope.form.editing = true;
                this.selection = new ExternalEntity(this.localizer.language, this.searchText, this.type || 'attribute');
            }
            else {
                this.createNew(item.type);
            }
        }
        else {
            this.cannotConfirm = this.exclude(item);
            if (this.model.isNamespaceKnownToBeNotModel(item.definedBy.id.toString())) {
                this.predicateService.getExternalPredicate(item.id, this.model).then(function (result) {
                    _this.selection = requireDefined(result); // TODO check if result can actually be null
                });
            }
            else {
                this.predicateService.getPredicate(item.id, this.model).then(function (result) { return _this.selection = result; });
            }
        }
    };
    SearchPredicateController.prototype.loadingSelection = function (item) {
        var selection = this.selection;
        if (item instanceof PredicateListItem) {
            return item === this.selectedItem && (!selection || (selection instanceof Predicate && !item.id.equals(selection.id)));
        }
        else {
            return false;
        }
    };
    SearchPredicateController.prototype.isExternalPredicatePending = function () {
        return this.isSelectionExternalEntity() && this.externalPredicate === undefined;
    };
    SearchPredicateController.prototype.confirm = function () {
        var _this = this;
        var selection = this.selection;
        if (selection instanceof Predicate) {
            if (selection.unsaved) {
                this.predicateService.createPredicate(selection)
                    .then(function () { return _this.$uibModalInstance.close(selection); }, function (err) { return _this.excludeError = err.data.errorMessage; });
            }
            else {
                this.$uibModalInstance.close(selection);
            }
        }
        else if (selection instanceof ExternalEntity) {
            if (this.externalPredicate) {
                var exclude = this.exclude(this.externalPredicate);
                if (exclude) {
                    this.excludeError = exclude;
                }
                else {
                    this.$uibModalInstance.close(this.externalPredicate);
                }
            }
            else {
                this.$uibModalInstance.close(selection);
            }
        }
        else {
            throw new Error('Unsupported selection: ' + selection);
        }
    };
    SearchPredicateController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    SearchPredicateController.prototype.createNew = function (type) {
        var _this = this;
        return this.searchConceptModal.openNewEntityCreation(this.model.vocabularies, this.model, type, this.searchText)
            .then(function (result) {
            if (!_this.typeSelectable) {
                _this.$uibModalInstance.close(result);
            }
            else {
                _this.predicateService.newPredicate(_this.model, result.entity.label, result.conceptId, type, _this.localizer.language)
                    .then(function (predicate) {
                    _this.cannotConfirm = null;
                    _this.selection = predicate;
                    _this.$scope.form.editing = true;
                });
            }
        }, ignoreModalClose);
    };
    SearchPredicateController.prototype.isEditing = function () {
        return this.$scope.form && this.$scope.form.editing;
    };
    SearchPredicateController.prototype.isAttributeAddable = function () {
        return !!this.searchText && !this.onlySelection && (this.typeSelectable || this.type === 'attribute');
    };
    SearchPredicateController.prototype.isAssociationAddable = function () {
        return !!this.searchText && !this.onlySelection && (this.typeSelectable || this.type === 'association');
    };
    return SearchPredicateController;
}());
export { SearchPredicateController };
var AddNewPredicate = /** @class */ (function (_super) {
    __extends(AddNewPredicate, _super);
    function AddNewPredicate(label, show, type, external) {
        var _this = _super.call(this, label, show, glyphIconClassForType(type ? [type] : [])) || this;
        _this.label = label;
        _this.show = show;
        _this.type = type;
        _this.external = external;
        return _this;
    }
    return AddNewPredicate;
}(AddNew));
export { ɵ0 };
