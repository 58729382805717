var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from 'app/services/languageService';
import { TableDescriptor } from 'app/components/form/editableTable';
import { SearchOrganizationModal } from './searchOrganizationModal';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
var ContributorsViewComponent = /** @class */ (function () {
    function ContributorsViewComponent($scope, languageService, searchOrganizationModal) {
        'ngInject';
        this.$scope = $scope;
        this.languageService = languageService;
        this.searchOrganizationModal = searchOrganizationModal;
    }
    ContributorsViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new ContributorsTableDescriptor(value, _this.context, _this.languageService);
        });
    };
    ContributorsViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    ContributorsViewComponent.prototype.addContributor = function () {
        var _this = this;
        var organizationIds = this.value.contributors.reduce(function (result, org) {
            if (org.parentOrg) {
                result.add(org.parentOrg.id.uri);
            }
            result.add(org.id.uri);
            return result;
        }, new Set());
        // Do not allow to add both child and parent organization as a contributor
        var exclude = function (organization) {
            if (organizationIds.has(organization.id.toString())) {
                return 'Already added';
            }
            else if (organization.parentOrg) {
                if (organizationIds.has(organization.parentOrg.id.toString())) {
                    return 'Already added';
                }
            }
            return null;
        };
        this.searchOrganizationModal.open(exclude)
            .then(function (organization) {
            _this.value.addContributor(organization);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    ContributorsViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                context: '=',
                required: '='
            },
            require: {
                form: '?^form'
            }, template: "\n      <h4>\n        <span translate>Contributors</span>\n        <button id=\"add_contributor_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.addContributor()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Add contributor</span>\n        </button>\n        <span ng-show=\"$ctrl.required && $ctrl.isEditing()\" class=\"fas fa-asterisk\" uib-tooltip=\"{{'Required' | translate}}\"></span>\n      </h4>\n      <editable-table id=\"'contributors'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, LanguageService,
            SearchOrganizationModal])
    ], ContributorsViewComponent);
    return ContributorsViewComponent;
}());
export { ContributorsViewComponent };
var ContributorsTableDescriptor = /** @class */ (function (_super) {
    __extends(ContributorsTableDescriptor, _super);
    function ContributorsTableDescriptor(value, context, languageService) {
        var _this = _super.call(this) || this;
        _this.value = value;
        _this.context = context;
        _this.languageService = languageService;
        return _this;
    }
    ContributorsTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Name', nameExtractor: function (c, isEditing) {
                    // show main organization's name in view mode
                    var label = c.parentOrg && !isEditing ? c.parentOrg.label : c.label;
                    // in editing mode show both child and parent organization
                    var parentOrgName = c.parentOrg && isEditing ? " (" + _this.languageService.translate(c.parentOrg.label, _this.context) + ")" : '';
                    return _this.languageService.translate(label, _this.context) + parentOrgName;
                }
            }
        ];
    };
    ContributorsTableDescriptor.prototype.values = function () {
        return this.value && this.value.contributors;
    };
    ContributorsTableDescriptor.prototype.canEdit = function (_organization) {
        return false;
    };
    ContributorsTableDescriptor.prototype.canRemove = function (organization) {
        return this.value.contributors.length > 0;
    };
    ContributorsTableDescriptor.prototype.remove = function (organization) {
        this.value.removeContributor(organization);
    };
    ContributorsTableDescriptor.prototype.hasOrder = function () {
        return true;
    };
    return ContributorsTableDescriptor;
}(TableDescriptor));
