/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/ajax-loading-indicator.component.ngfactory";
import * as i2 from "yti-common-ui/components/ajax-loading-indicator.component";
import * as i3 from "@angular/common";
import * as i4 from "../../ajs-upgraded-components";
import * as i5 from "./new-datamodel-version-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i7 from "yti-common-ui/components/alert-modal.component";
import * as i8 from "yti-common-ui/components/error-modal.component";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../ajs-upgraded-providers";
var styles_NewDatamodelVersionModalComponent = [];
var RenderType_NewDatamodelVersionModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewDatamodelVersionModalComponent, data: {} });
export { RenderType_NewDatamodelVersionModalComponent as RenderType_NewDatamodelVersionModalComponent };
function View_NewDatamodelVersionModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-ajax-loading-indicator", [], null, null, null, i1.View_AjaxLoadingIndicatorComponent_0, i1.RenderType_AjaxLoadingIndicatorComponent)), i0.ɵdid(2, 49152, null, 0, i2.AjaxLoadingIndicatorComponent, [], null, null)], null, null); }
export function View_NewDatamodelVersionModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewDatamodelVersionModalComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "new-datamodel-version-prefix-modal-form", [], null, [[null, "saveNewVersion"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveNewVersion" === en)) {
        var pd_0 = (_co.saveNewVersion($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 999424, null, 0, i4.NewDatamodelVersionPrefixModalFormDirective, [i0.ElementRef, i0.Injector], { model: [0, "model"] }, { saveNewVersion: "saveNewVersion", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.model; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); }); }
export function View_NewDatamodelVersionModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-new-datamodel-version-modal", [["class", "component"]], null, null, null, View_NewDatamodelVersionModalComponent_0, RenderType_NewDatamodelVersionModalComponent)), i0.ɵprd(5120, null, "$scope", i5.rootScopeFactory, ["$injector"]), i0.ɵdid(2, 49152, null, 0, i5.NewDatamodelVersionModalComponent, [i6.NgbActiveModal, i7.AlertModalService, i8.ErrorModalService, i9.TranslateService, i10.ModelServiceWrapper], null, null)], null, null); }
var NewDatamodelVersionModalComponentNgFactory = i0.ɵccf("app-new-datamodel-version-modal.component", i5.NewDatamodelVersionModalComponent, View_NewDatamodelVersionModalComponent_Host_0, {}, {}, []);
export { NewDatamodelVersionModalComponentNgFactory as NewDatamodelVersionModalComponentNgFactory };
