import { OnDestroy } from '@angular/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { LanguageService } from '../../services/languageService';
import { combineSets, hasAny } from 'yti-common-ui/utils/set';
import { comparingLocalizable } from '../../utils/comparator';
import { index } from 'yti-common-ui/utils/array';
import { GettextCatalogWrapper, LocationServiceWrapper, OrganizationServiceWrapper, UserRoleServiceWrapper } from '../../ajs-upgraded-providers';
var UserDetailsInformationComponent = /** @class */ (function () {
    function UserDetailsInformationComponent(locationServiceWrapper, userService, languageService, userRoleServiceWrapper, organizationServiceWrapper, gettextCatalogWrapper) {
        var _this = this;
        this.locationServiceWrapper = locationServiceWrapper;
        this.userService = userService;
        this.languageService = languageService;
        this.userRoleServiceWrapper = userRoleServiceWrapper;
        this.organizationServiceWrapper = organizationServiceWrapper;
        this.gettextCatalogWrapper = gettextCatalogWrapper;
        this.subscriptionToClean = [];
        this.selectedOrganization = null;
        this.requestsInOrganizations = new Map();
        this.subscriptionToClean.push(this.userService.loggedIn$.subscribe(function (loggedIn) {
            if (!loggedIn) {
                locationServiceWrapper.locationService.url('/');
            }
        }));
        this.organizationServiceWrapper.organizationService.getOrganizations().then(function (organizations) {
            _this.allOrganizations = organizations;
            _this.allOrganizationsById = index(organizations, function (org) { return org.id.uuid; });
            _this.reloadUserOrganizations();
            _this.reloadOrganizationOptions();
            _this.refreshRequests();
        });
    }
    UserDetailsInformationComponent.prototype.ngOnDestroy = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(UserDetailsInformationComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "loading", {
        get: function () {
            return !this.allOrganizations || !this.requestsInOrganizations;
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsInformationComponent.prototype.reloadUserOrganizations = function () {
        var _this = this;
        var organizationIds = new Set(Array.from(this.user.rolesInOrganizations.keys()).concat(Array.from(this.requestsInOrganizations.keys())));
        var result = Array.from(organizationIds.values()).map(function (organizationId) {
            return {
                organization: _this.allOrganizationsById.get(organizationId),
                roles: Array.from(_this.user.getRoles(organizationId)),
                requests: Array.from(_this.requestsInOrganizations.get(organizationId) || [])
            };
        });
        result.sort(comparingLocalizable(this.languageService.createLocalizer(), function (org) {
            return org.organization ? org.organization.label : {};
        }));
        this.userOrganizations = result;
    };
    UserDetailsInformationComponent.prototype.reloadOrganizationOptions = function () {
        var _this = this;
        var hasExistingRoleOrRequest = function (org) {
            var rolesOrRequests = combineSets([
                _this.user.getRoles(org.id.uuid),
                _this.requestsInOrganizations.get(org.id.uuid) || new Set()
            ]);
            return hasAny(rolesOrRequests, ['DATA_MODEL_EDITOR', 'ADMIN']);
        };
        var requestableOrganizations = this.allOrganizations.filter(function (organization) { return !hasExistingRoleOrRequest(organization); });
        this.organizationOptions = [null].concat(requestableOrganizations).map(function (org) {
            return {
                value: org,
                name: function () { return org ? _this.languageService.translate(org.label)
                    : _this.gettextCatalogWrapper.gettextCatalog.getString('Choose organization'); }
            };
        });
    };
    UserDetailsInformationComponent.prototype.sendRequest = function () {
        var _this = this;
        if (!this.selectedOrganization) {
            throw new Error('No organization selected for request');
        }
        this.userRoleServiceWrapper.userRoleService.sendUserRequest(this.selectedOrganization.id)
            .then(function () { return _this.refreshRequests(); });
    };
    UserDetailsInformationComponent.prototype.refreshRequests = function () {
        var _this = this;
        this.selectedOrganization = null;
        this.userRoleServiceWrapper.userRoleService.getUserRequests().then(function (userRequests) {
            _this.requestsInOrganizations.clear();
            for (var _i = 0, userRequests_1 = userRequests; _i < userRequests_1.length; _i++) {
                var userRequest = userRequests_1[_i];
                _this.requestsInOrganizations.set(userRequest.organizationId, new Set(userRequest.role));
            }
            _this.reloadUserOrganizations();
            _this.reloadOrganizationOptions();
        });
    };
    return UserDetailsInformationComponent;
}());
export { UserDetailsInformationComponent };
