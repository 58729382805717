var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { dataTypes } from 'app/entities/dataTypes';
import { LegacyComponent } from 'app/utils/angular';
var EditableRangeSelectComponent = /** @class */ (function () {
    function EditableRangeSelectComponent(gettextCatalog, $element) {
        'ngInject';
        this.gettextCatalog = gettextCatalog;
        this.$element = $element;
        this.ranges = dataTypes;
        this.displayNameFormatter = function (value, gettextCatalog) { return value ? gettextCatalog.getString(value) + " (" + value + ")" : ''; };
    }
    EditableRangeSelectComponent.prototype.$postLink = function () {
        var input = this.$element.find('[ng-model]');
        this.inputNgModelCtrl = input.controller('ngModel');
    };
    EditableRangeSelectComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(EditableRangeSelectComponent.prototype, "displayName", {
        get: function () {
            return this.range ? this.displayNameFormatter(this.range, this.gettextCatalog) : '';
        },
        enumerable: true,
        configurable: true
    });
    EditableRangeSelectComponent = __decorate([
        LegacyComponent({
            bindings: {
                range: '=',
                id: '@'
            },
            require: {
                form: '?^form'
            },
            template: "\n      <div class=\"editable-wrap form-group\">\n        <editable-label data-title=\"'Range'\" input-id=\"$ctrl.id\" required=\"true\"></editable-label>\n        \n        <div ng-show=\"$ctrl.isEditing()\">\n          <localized-select id=\"{{$ctrl.id}}\" values=\"$ctrl.ranges\" value=\"$ctrl.range\" display-name-formatter=\"$ctrl.displayNameFormatter\"></localized-select>\n        </div>\n      \n        <div ng-if=\"!$ctrl.isEditing()\" class=\"content\">\n          <span>{{$ctrl.displayName}}</span>\n        </div>\n        \n        <error-messages ng-model-controller=\"$ctrl.inputNgModelCtrl\"></error-messages>\n      </div>\n  "
        }),
        __metadata("design:paramtypes", [Object, Object])
    ], EditableRangeSelectComponent);
    return EditableRangeSelectComponent;
}());
export { EditableRangeSelectComponent };
