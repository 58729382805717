import { selectableStatuses, allowedTargetStatuses } from 'yti-common-ui/entities/status';
function isReference(model, resource) {
    return resource.definedBy.id.notEquals(model.id);
}
function isRemovableStatus(status) {
    return status === 'SUGGESTED' || status === 'DRAFT' || status === 'INCOMPLETE' || status === null;
}
var AuthorizationManagerService = /** @class */ (function () {
    function AuthorizationManagerService(userService) {
        'ngInject';
        this.userService = userService;
    }
    Object.defineProperty(AuthorizationManagerService.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationManagerService.prototype, "isSuperUser", {
        get: function () {
            return this.user.superuser;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationManagerService.prototype.canEditModel = function (model) {
        return this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.canRemoveModel = function (model) {
        return isRemovableStatus(model.status) && this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.getAllowedStatuses = function (originalStatus) {
        if (this.isSuperUser) {
            return selectableStatuses;
        }
        if (originalStatus) {
            return allowedTargetStatuses(originalStatus);
        }
        else {
            return selectableStatuses;
        }
    };
    AuthorizationManagerService.prototype.filterOrganizationsAllowedForUser = function (organizations) {
        var _this = this;
        return organizations.filter(function (org) {
            return _this.user.superuser || _this.user.isInRole(['ADMIN', 'DATA_MODEL_EDITOR'], org.id.uuid);
        });
    };
    AuthorizationManagerService.prototype.canEditPredicate = function (model, predicate) {
        return !isReference(model, predicate) && this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.canRemovePredicate = function (model, predicate) {
        return (isReference(model, predicate) || isRemovableStatus(predicate.status)) && this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.canEditClass = function (model, klass) {
        return !isReference(model, klass) && this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.canRemoveClass = function (model, klass) {
        return (isReference(model, klass) || isRemovableStatus(klass.status)) && this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.canSaveVisualization = function (model) {
        return this.hasRightToModifyModel(model);
    };
    AuthorizationManagerService.prototype.hasRightToModifyModel = function (model) {
        if (this.user.superuser) {
            return true;
        }
        var organizationIds = model.contributors.reduce(function (result, org) {
            if (org.parentOrg) {
                result.push(org.parentOrg.id.uuid);
            }
            result.push(org.id.uuid);
            return result;
        }, []);
        return this.user.isInRole(['ADMIN', 'DATA_MODEL_EDITOR'], organizationIds);
    };
    AuthorizationManagerService.prototype.canAddModel = function () {
        return this.user.isInRoleInAnyOrganization(['ADMIN', 'DATA_MODEL_EDITOR']);
    };
    return AuthorizationManagerService;
}());
export { AuthorizationManagerService };
