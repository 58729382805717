import * as moment from 'moment';
import { upperCaseFirst } from 'change-case';
import { reverseMapType } from '../utils/entity';
import { expandContextWithKnownModels } from '../utils/entity';
import { hasLocalization } from '../utils/language';
import { modelScopeCache } from '../components/form/cache';
import { requireDefined } from 'yti-common-ui/utils/object';
import * as frames from '../entities/frames';
import { ClassListItem, Class, Property } from '../entities/class';
import { ExternalEntity } from '../entities/externalEntity';
import { Attribute, Association } from '../entities/predicate';
import { flatten } from 'yti-common-ui/utils/array';
import { apiEndpointWithName } from './config';
var RelatedClass = /** @class */ (function () {
    function RelatedClass(oldClassId, relationType) {
        this.oldClassId = oldClassId;
        this.relationType = relationType;
    }
    return RelatedClass;
}());
export { RelatedClass };
var DefaultClassService = /** @class */ (function () {
    function DefaultClassService($http, $q, defaultPredicateService, frameService) {
        'ngInject';
        this.$http = $http;
        this.$q = $q;
        this.defaultPredicateService = defaultPredicateService;
        this.frameService = frameService;
        this.modelClassesCache = new Map();
    }
    DefaultClassService.prototype.getClass = function (id, model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('class'), { params: { id: id.toString() } })
            .then(function (response) { return _this.deserializeClass(response.data, false); })
            .then(function (klass) { return requireDefined(klass); });
    };
    DefaultClassService.prototype.getAllClasses = function (model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('class'))
            .then(function (response) { return _this.deserializeClassList(response.data); });
    };
    DefaultClassService.prototype.getRequiredByClasses = function (model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('class'), { params: { requiredBy: model.id.uri } })
            .then(function (response) { return _this.deserializeClassList(response.data); });
    };
    DefaultClassService.prototype.getClassesForModel = function (model) {
        return this.getAllClasses(model)
            .then(function (classes) { return classes.filter(function (klass) { return klass.id.resolves(); }); }); // if resolves, it is known namespace
    };
    DefaultClassService.prototype.getClassesForModelDataSource = function (modelProvider, requiredByInUse) {
        var _this = this;
        if (requiredByInUse === void 0) { requiredByInUse = false; }
        var cachedResultsProvider = modelScopeCache(modelProvider, function (model) {
            return _this.$q.all([
                requiredByInUse ? _this.getRequiredByClasses(model) : _this.getClassesForModel(model),
                _this.getExternalClassesForModel(model)
            ]).then(flatten);
        });
        return function () { return cachedResultsProvider(); };
    };
    DefaultClassService.prototype.getClassesAssignedToModel = function (model) {
        var _this = this;
        var classes = this.modelClassesCache.get(model.id.uri);
        if (classes) {
            return this.$q.when(classes);
        }
        else {
            return this.$http.get(apiEndpointWithName('class'), { params: { model: model.id.uri } })
                .then(function (response) { return _this.deserializeClassList(response.data); })
                .then(function (classList) {
                _this.modelClassesCache.set(model.id.uri, classList);
                return classList;
            });
        }
    };
    DefaultClassService.prototype.createClass = function (klass) {
        var _this = this;
        var requestParams = {
            id: klass.id.uri,
            model: requireDefined(klass.definedBy).id.uri
        };
        return this.$http.put(apiEndpointWithName('class'), klass.serialize(), { params: requestParams })
            .then(function (response) {
            _this.modelClassesCache.delete(requireDefined(klass.definedBy).id.uri);
            klass.unsaved = false;
            klass.version = response.data.identifier;
            klass.createdAt = moment().utc();
            klass.modifiedAt = moment().utc();
        });
    };
    DefaultClassService.prototype.updateClass = function (klass, originalId, model) {
        var _this = this;
        var requestParams = {
            id: klass.id.uri,
            model: requireDefined(klass.definedBy).id.uri
        };
        if (klass.id.notEquals(originalId)) {
            requestParams.oldid = originalId.uri;
        }
        model.expandContextWithKnownModels(klass.context);
        return this.$http.post(apiEndpointWithName('class'), klass.serialize(), { params: requestParams })
            .then(function (response) {
            _this.modelClassesCache.delete(requireDefined(klass.definedBy).id.uri);
            klass.version = response.data.identifier;
            klass.modifiedAt = moment().utc();
        });
    };
    DefaultClassService.prototype.deleteClass = function (id, model) {
        var _this = this;
        var requestParams = {
            id: id.uri,
            model: model.id.uri
        };
        return this.$http.delete(apiEndpointWithName('class'), { params: requestParams })
            .then(function () { return _this.modelClassesCache.delete(model.id.uri); });
    };
    DefaultClassService.prototype.assignClassToModel = function (classId, model) {
        var _this = this;
        var requestParams = {
            id: classId.uri,
            model: model.id.uri
        };
        return this.$http.post(apiEndpointWithName('class'), undefined, { params: requestParams })
            .then(function () { return _this.modelClassesCache.delete(model.id.uri); });
    };
    DefaultClassService.prototype.clearCachedClasses = function (modelId) {
        this.modelClassesCache.delete(modelId);
    };
    DefaultClassService.prototype.newClass = function (model, classLabel, conceptID, lang) {
        var _this = this;
        var params = {
            modelID: model.id.uri,
            classLabel: upperCaseFirst(classLabel),
            lang: lang
        };
        if (conceptID !== null) {
            params.conceptID = conceptID.uri;
        }
        return this.$http.get(apiEndpointWithName('classCreator'), { params: params })
            .then(expandContextWithKnownModels(model))
            .then(function (response) { return _this.deserializeClass(response.data, false); })
            .then(function (klass) {
            klass.definedBy = model.asDefinedBy();
            klass.unsaved = true;
            klass.external = model.isNamespaceKnownToBeNotModel(klass.definedBy.id.toString());
            return klass;
        });
    };
    DefaultClassService.prototype.newRelatedClass = function (model, relatedClass) {
        var _this = this;
        var params = {
            modelID: model.id.uri,
            oldClass: relatedClass.oldClassId.uri,
            relationType: relatedClass.relationType
        };
        return this.$http.get(apiEndpointWithName('relatedClassCreator'), { params: params })
            .then(expandContextWithKnownModels(model))
            .then(function (response) { return _this.deserializeClass(response.data, false); })
            .then(function (klass) {
            klass.definedBy = model.asDefinedBy();
            klass.unsaved = true;
            klass.external = model.isNamespaceKnownToBeNotModel(klass.definedBy.id.toString());
            return klass;
        });
    };
    DefaultClassService.prototype.newShape = function (classOrExternal, profile, external, lang) {
        var _this = this;
        var id = requireDefined(classOrExternal.id);
        var classPromise = (classOrExternal instanceof ExternalEntity) ? this.getExternalClass(id, profile) : this.$q.when(classOrExternal);
        return this.$q.all([
            classPromise,
            this.$http.get(apiEndpointWithName('shapeCreator'), { params: { profileID: profile.id.uri, classID: id.toString(), lang: lang } })
                .then(expandContextWithKnownModels(profile))
                .then(function (response) { return _this.deserializeClass(response.data, false); })
        ])
            .then(function (_a) {
            var klass = _a[0], shape = _a[1];
            shape.definedBy = profile.asDefinedBy();
            shape.unsaved = true;
            shape.external = external;
            if (!hasLocalization(shape.label)) {
                if (klass && klass.label) {
                    shape.label = klass.label;
                }
                else if (classOrExternal instanceof ExternalEntity) {
                    Object.assign(shape, { label: (_b = {}, _b[classOrExternal.language] = classOrExternal.label, _b) });
                }
            }
            for (var _i = 0, _c = shape.properties; _i < _c.length; _i++) {
                var property = _c[_i];
                property.status = 'DRAFT';
            }
            shape.status = 'DRAFT';
            return shape;
            var _b;
        });
    };
    DefaultClassService.prototype.newClassFromExternal = function (externalId, model) {
        return this.getExternalClass(externalId, model)
            .then(function (klass) {
            if (!klass) {
                var graph = {
                    '@id': externalId.uri,
                    '@type': reverseMapType('class'),
                    isDefinedBy: model.namespaceAsDefinedBy(externalId.namespace).serialize(true, false)
                };
                return new Class(graph, model.context, model.frame);
            }
            else {
                return klass;
            }
        });
    };
    DefaultClassService.prototype.getExternalClass = function (externalId, model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('externalClass'), { params: { model: model.id.uri, id: externalId.uri } })
            .then(function (response) { return _this.deserializeClass(response.data, true); })
            .then(function (klass) {
            if (klass) {
                klass.external = true;
            }
            return klass;
        });
    };
    DefaultClassService.prototype.getExternalClassesForModel = function (model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('externalClass'), { params: { model: model.id.uri } })
            .then(function (response) { return _this.deserializeClassList(response.data); });
    };
    DefaultClassService.prototype.newProperty = function (predicateOrExternal, type, model) {
        var _this = this;
        var id = requireDefined(predicateOrExternal.id);
        var predicatePromise = (predicateOrExternal instanceof ExternalEntity) ? this.defaultPredicateService.getExternalPredicate(id, model)
            : this.$q.when(predicateOrExternal);
        return this.$q.all([
            predicatePromise,
            this.$http.get(apiEndpointWithName('classProperty'), { params: { predicateID: id.toString(), type: reverseMapType(type) } })
                .then(expandContextWithKnownModels(model))
                .then(function (response) { return _this.deserializeProperty(response.data); })
        ])
            .then(function (_a) {
            var predicate = _a[0], property = _a[1];
            if (!hasLocalization(property.label)) {
                if (predicate && predicate.label) {
                    property.label = predicate.label;
                }
                else if (predicateOrExternal instanceof ExternalEntity) {
                    Object.assign(property, { label: (_b = {}, _b[predicateOrExternal.language] = predicateOrExternal.label, _b) });
                }
            }
            if (type === 'attribute' && !property.dataType) {
                property.dataType = (predicate instanceof Attribute) ? predicate.dataType : 'xsd:string';
            }
            else if (type === 'association' && !property.valueClass && predicate instanceof Association) {
                property.valueClass = predicate.valueClass;
            }
            property.status = 'DRAFT';
            return property;
            var _b;
        });
    };
    DefaultClassService.prototype.getInternalOrExternalClass = function (id, model) {
        return model.isNamespaceKnownToBeNotModel(id.namespace) ? this.getExternalClass(id, model) : this.getClass(id, model);
    };
    DefaultClassService.prototype.deserializeClassList = function (data) {
        return this.frameService.frameAndMapArray(data, frames.classListFrame(data), function () { return ClassListItem; });
    };
    DefaultClassService.prototype.deserializeClass = function (data, optional) {
        return this.frameService.frameAndMap(data, optional, frames.classFrame(data), function () { return Class; });
    };
    DefaultClassService.prototype.deserializeProperty = function (data) {
        return this.frameService.frameAndMap(data, false, frames.propertyFrame(data), function () { return Property; }).then(requireDefined);
    };
    return DefaultClassService;
}());
export { DefaultClassService };
