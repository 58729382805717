var SearchOrganizationModal = /** @class */ (function () {
    function SearchOrganizationModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchOrganizationModal.prototype.open = function (exclude) {
        return this.$uibModal.open({
            template: require('./searchOrganizationModal.html'),
            size: 'md',
            resolve: {
                exclude: function () { return exclude; }
            },
            controller: SearchOrganizationModalController,
            controllerAs: '$ctrl'
        }).result;
    };
    return SearchOrganizationModal;
}());
export { SearchOrganizationModal };
var SearchOrganizationModalController = /** @class */ (function () {
    function SearchOrganizationModalController($scope, $uibModalInstance, organizationService, authorizationManagerService, exclude) {
        'ngInject';
        var _this = this;
        this.$uibModalInstance = $uibModalInstance;
        organizationService.getOrganizations()
            .then(function (organizations) {
            return _this.organizations = authorizationManagerService.filterOrganizationsAllowedForUser(organizations.filter(function (c) { return !exclude(c); }));
        });
    }
    Object.defineProperty(SearchOrganizationModalController.prototype, "loading", {
        get: function () {
            return this.organizations == null;
        },
        enumerable: true,
        configurable: true
    });
    SearchOrganizationModalController.prototype.select = function (classification) {
        this.$uibModalInstance.close(classification);
    };
    SearchOrganizationModalController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return SearchOrganizationModalController;
}());
