var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { normalizeModelType } from 'app/utils/entity';
import { LegacyComponent } from 'app/utils/angular';
var DefinedByComponent = /** @class */ (function () {
    function DefinedByComponent() {
    }
    Object.defineProperty(DefinedByComponent.prototype, "definedByTitle", {
        get: function () {
            var type = normalizeModelType(this.entity && this.entity.definedBy && this.entity.definedBy.type || []);
            return 'Defined by' + (type ? ' ' + type : '');
        },
        enumerable: true,
        configurable: true
    });
    DefinedByComponent.prototype.linkTo = function () {
        return this.entity && this.model.linkToResource(this.entity.id);
    };
    DefinedByComponent = __decorate([
        LegacyComponent({
            bindings: {
                entity: '=',
                model: '='
            },
            template: require('./definedBy.html')
        })
    ], DefinedByComponent);
    return DefinedByComponent;
}());
export { DefinedByComponent };
