import * as _ from 'lodash';
import { assertNever, requireDefined } from 'yti-common-ui/utils/object';
import { enter, esc, tab } from 'yti-common-ui/utils/key-code';
import { isTargetElementInsideElement, nextUrl } from 'app/utils/angular';
import { createScrollWithDefault, scrollToTop } from 'app/help/contract';
import { arrowHeight, elementExists, elementPositioning, isClick, isFocusInElement, isNumberInMargin, isPositionInMargin, isVisible, stopEvent } from 'app/help/utils/component';
import { contains } from 'yti-common-ui/utils/array';
var focusableSelector = 'a[href], area[href], input:not([disabled]), ' +
    'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), ' +
    'iframe, object, embed, *[tabindex], *[contenteditable=true]';
var InteractiveHelpDisplay = /** @class */ (function () {
    function InteractiveHelpDisplay(overlayService, interactiveHelpService, gettextCatalog) {
        'ngInject';
        this.overlayService = overlayService;
        this.interactiveHelpService = interactiveHelpService;
        this.gettextCatalog = gettextCatalog;
    }
    InteractiveHelpDisplay.prototype.open = function (help) {
        var _this = this;
        if (!help) {
            throw new Error('No help defined');
        }
        if (this.interactiveHelpService.isOpen()) {
            throw new Error('Cannot open help when another help is already open');
        }
        var originalGettextCatalogDebug = this.gettextCatalog.debug;
        var stateInitialization = function () {
            _this.gettextCatalog.debug = false;
            return help.onInit ? help.onInit(_this.interactiveHelpService) : _this.interactiveHelpService.reset().then(function () { return false; });
        };
        this.interactiveHelpService.open();
        return this.overlayService.open({
            template: "\n          <help-popover ng-show=\"$ctrl.item\" item=\"$ctrl.item\" help-controller=\"$ctrl\" ng-style=\"$ctrl.popoverController.style()\"></help-popover>\n          <help-popover-dimensions-calculator ng-show=\"$ctrl.item\" item=\"$ctrl.item\" help-controller=\"$ctrl\"></help-popover-dimensions-calculator>\n          <help-backdrop item=\"$ctrl.item\" help-controller=\"$ctrl\"></help-backdrop>\n          <div ng-show=\"$ctrl.item.denyInteraction\" class=\"help-interaction-stopper\"></div>\n        ",
            controller: InteractiveHelpController,
            controllerAs: '$ctrl',
            resolve: {
                help: function () { return help; },
                stateInitialization: function () { return stateInitialization; }
            },
            disableScroll: true
        }).result.then(function (cancel) {
            _this.interactiveHelpService.close();
            _this.gettextCatalog.debug = originalGettextCatalogDebug;
            setTimeout(function () {
                if (cancel) {
                    if (help.onCancel) {
                        help.onCancel();
                    }
                }
                else {
                    if (help.onComplete) {
                        help.onComplete();
                    }
                }
            }, 500);
        });
    };
    return InteractiveHelpDisplay;
}());
export { InteractiveHelpDisplay };
var InteractiveHelpController = /** @class */ (function () {
    function InteractiveHelpController($scope, $overlayInstance, $document, $location, $uibModalStack, confirmationModal, help, stateInitialization, $window, zone) {
        'ngInject';
        this.$scope = $scope;
        this.$overlayInstance = $overlayInstance;
        this.$document = $document;
        this.$location = $location;
        this.$uibModalStack = $uibModalStack;
        this.confirmationModal = confirmationModal;
        this.help = help;
        this.stateInitialization = stateInitialization;
        this.$window = $window;
        this.zone = zone;
        this.activeIndex = 0;
        this.changingLocation = false;
        this.inTransition = false;
    }
    InteractiveHelpController.prototype.$onInit = function () {
        var _this = this;
        var continuing = false;
        this.stateInitialization().then(function (willChangeLocation) {
            continuing = willChangeLocation;
            // Reset expectation if navigation event happened before construction
            setTimeout(function () { return continuing = false; }, 500);
            _this.items = _this.help.storyLine.items();
            if (_this.items.length === 0) {
                throw new Error('No stories defined');
            }
            _this.showItem(0);
        });
        this.$scope.$on('$locationChangeStart', function (event, next) {
            if (!continuing) {
                event.preventDefault();
                // delay is needed so that click handler has time to modify flag
                setTimeout(function () {
                    if (_this.changingLocation) {
                        continuing = true;
                        _this.$scope.$apply(function () {
                            _this.$location.url(nextUrl(_this.$location, next));
                            _this.moveToNextItem();
                        });
                    }
                    else {
                        _this.confirmationModal.openCloseHelp().then(function () { return _this.close(true); });
                    }
                });
            }
            else {
                continuing = false;
            }
        });
        var debounceUpdatePositions = _.debounce(function () { return _this.updatePositions(); }, 200);
        this.$scope.$watch(function () { return _this.item; }, debounceUpdatePositions);
        var itemPopoverPositioning = function () { return (_this.item && _this.item.type === 'story') ? elementPositioning(_this.item.popover.element()) : null; };
        var itemFocusPositioning = function () { return _this.item && _this.item.type === 'story' && _this.item.focus ? elementPositioning(_this.item.focus.element()) : null; };
        var itemScrollPositioning = function () {
            if (!_this.item) {
                return null;
            }
            var scroll = InteractiveHelpController.resolveScroll(_this.item);
            if (scroll.type !== 'scroll-none') {
                return elementPositioning(scroll.element());
            }
            return null;
        };
        var keyDownHandler = function (event) { return _this.keyDownHandler(event); };
        var clickHandler = function (event) { return _this.clickHandler(event); };
        function updateIfNeeded(newPos, oldPos) {
            if (!isPositionInMargin(1, newPos, oldPos)) {
                debounceUpdatePositions();
            }
        }
        // Additional checks for sub-pixel fluctuation are needed for example because of float (fixed style)
        this.$scope.$watch(itemPopoverPositioning, updateIfNeeded, true);
        this.$scope.$watch(itemFocusPositioning, updateIfNeeded, true);
        this.$scope.$watch(itemScrollPositioning, updateIfNeeded, true);
        this.$scope.$watch(function () { return _this.getPopoverDimensions(); }, debounceUpdatePositions, true);
        this.zone.runOutsideAngular(function () {
            window.addEventListener('resize', debounceUpdatePositions);
            window.addEventListener('scroll', debounceUpdatePositions);
        });
        // Lazy initialization of listeners so that it doesn't intervene with help opening event
        setTimeout(function () {
            _this.$document.on('keydown', keyDownHandler);
            _this.$document.on('click', clickHandler);
        });
        this.$scope.$on('$destroy', function () {
            window.removeEventListener('resize', debounceUpdatePositions);
            window.removeEventListener('scroll', debounceUpdatePositions);
            _this.$document.off('keydown', keyDownHandler);
            _this.$document.off('click', clickHandler);
        });
    };
    InteractiveHelpController.prototype.showItem = function (index) {
        var _this = this;
        var item = this.items[index];
        var logItem = function (tryCount) {
            if (tryCount === void 0) { tryCount = 0; }
            var element;
            if (item.type === 'story') {
                element = item.popover.element();
            }
            else {
                element = null;
            }
            if (element && element.length === 0 && tryCount < 10) {
                setTimeout(function () { return logItem(tryCount++); }, 100);
            }
            else if (element === null) {
                console.log("[" + (index + 1) + "/" + _this.items.length + "]", item.type, item.title, 'no popover element');
            }
            else if (element === undefined) {
                console.log("[" + (index + 1) + "/" + _this.items.length + "]", item.type, item.title, 'popover element not found');
            }
            else {
                console.log("[" + (index + 1) + "/" + _this.items.length + "]", item.type, item.title, 'popover', element[0]);
            }
        };
        logItem();
        if (item.type === 'story' && item.initialize) {
            var tryToInitialize_1 = function (init, retryCount) {
                if (retryCount === void 0) { retryCount = 0; }
                var success = init();
                if (!success) {
                    if (retryCount > 10) {
                        console.log(item);
                        throw new Error('Cannot initialize');
                    }
                    else {
                        setTimeout(function () { return tryToInitialize_1(init, retryCount + 1); }, 100);
                    }
                }
            };
            tryToInitialize_1(item.initialize);
        }
        // show full backdrop if item has focus while waiting for debounce
        if (item && (item.type === 'notification' || item.focus)) {
            if (this.backdropController) {
                this.backdropController.setFullBackdrop();
            }
        }
        this.manageActiveElement(item);
        this.inTransition = true;
        this.currentScrollTop = undefined;
        this.item = item;
    };
    InteractiveHelpController.prototype.manageActiveElement = function (item) {
        var _this = this;
        function moveCursorToEnd(input) {
            if (contains(['INPUT', 'TEXTAREA'], input.prop('tagName'))) {
                var valueLength_1 = input.val().length;
                // ensures that cursor will be at the end of the input
                if (!contains(['checkbox', 'radio'], input.attr('type'))) {
                    setTimeout(function () { return input[0].setSelectionRange(valueLength_1, valueLength_1); });
                }
            }
        }
        // Handle focus off frame since it can cause duplicate digest
        setTimeout(function () {
            // Active element needs to be blurred because it can used for example for multiple interactive help activations
            document.activeElement.blur();
            if (item && item.type === 'story' && item.focus && !item.denyInteraction) {
                var focusElement = item.focus.element();
                if (focusElement.length > 0 && isVisible(focusElement[0])) {
                    var focusable = focusElement.find(focusableSelector).addBack(focusableSelector).eq(0);
                    focusable.focus();
                    moveCursorToEnd(focusable);
                }
                else {
                    setTimeout(function () { return _this.manageActiveElement(item); }, 100);
                }
            }
        });
    };
    InteractiveHelpController.prototype.updatePositions = function (retryCount) {
        var _this = this;
        if (retryCount === void 0) { retryCount = 0; }
        if (!this.item) {
            return;
        }
        var positioning = this.popoverController.calculatePositioning(this.item);
        if (positioning) {
            this.scrollTo(this.item, function () {
                _this.$scope.$apply(function () {
                    _this.inTransition = false;
                    _this.popoverController.setPositioning(positioning);
                    _this.backdropController.updatePosition();
                });
            });
        }
        else {
            if (retryCount > 10) {
                console.log(this.item);
                throw new Error('Popover element not found');
            }
            else {
                setTimeout(function () { return _this.updatePositions(retryCount + 1); }, 100);
            }
        }
    };
    InteractiveHelpController.resolveScroll = function (item) {
        return item.type === 'notification' ? scrollToTop : item.scroll || createScrollWithDefault(item.popover.element, 100);
    };
    InteractiveHelpController.prototype.scrollTo = function (item, cb) {
        var _this = this;
        var scroll = InteractiveHelpController.resolveScroll(item);
        if (scroll.type === 'scroll-none') {
            cb();
            return;
        }
        var scrollToElementPositioning = elementPositioning(scroll.element());
        var defaultScrollWithElement = jQuery('html, body');
        var calculatePopoverOffsetOnTopOfScrollToElement = function (story) {
            var popoverDimension = _this.getPopoverDimensions();
            switch (story.popover.position) {
                case 'left-up':
                case 'right-up':
                    return Math.min(0, popoverDimension.height + arrowHeight - scrollToElementPositioning.height);
                case 'top-right':
                case 'top-left':
                    return popoverDimension.height + arrowHeight;
                case 'left-down':
                case 'right-down':
                case 'bottom-right':
                case 'bottom-left':
                    return 0;
                default:
                    return assertNever(story.popover.position, 'Unsupported popover position');
            }
        };
        var resolveScrollWithElement = function () {
            switch (scroll.type) {
                case 'scroll-with-element':
                    return scroll.scrollElement();
                case 'scroll-with-default':
                    var topModal = _this.$uibModalStack.getTop();
                    return topModal ? topModal.value.modalDomEl.find('.modal-content') : defaultScrollWithElement;
                default:
                    assertNever(scroll, 'Unsupported popover scroll type');
            }
        };
        var scrollWithElement = resolveScrollWithElement();
        var scrollOffsetFromTop = scroll.offsetFromTop || 0;
        var scrollTop = scrollToElementPositioning.top - scrollOffsetFromTop;
        if (scrollWithElement !== defaultScrollWithElement) {
            var scrollWithElementOffsetFromTop = scrollWithElement.offset().top;
            var scrollWithElementScrollingPosition = scrollWithElement.scrollTop();
            scrollTop = scrollTop - scrollWithElementOffsetFromTop + scrollWithElementScrollingPosition;
        }
        if (item.type === 'story') {
            var popoverOffsetFromTop = calculatePopoverOffsetOnTopOfScrollToElement(item);
            if (popoverOffsetFromTop > scrollOffsetFromTop) {
                scrollTop -= popoverOffsetFromTop - scrollOffsetFromTop;
            }
        }
        if (!isNumberInMargin(10, this.currentScrollTop, scrollTop)) {
            var duration = 100;
            scrollWithElement.stop(); // stop previous animation
            scrollWithElement.animate({ scrollTop: scrollTop }, duration, cb);
        }
        else {
            cb();
        }
        this.currentScrollTop = scrollTop;
    };
    InteractiveHelpController.prototype.keyDownHandler = function (event) {
        var _this = this;
        if (!this.item) {
            stopEvent(event);
        }
        var moveToPreviousIfPossible = function () {
            if (_this.canMoveToPrevious()) {
                _this.$scope.$apply(function () { return _this.moveToPreviousItem(); });
            }
        };
        var moveToNextIfPossible = function () {
            if (_this.canMoveToNext()) {
                _this.$scope.$apply(function () { return _this.tryToMoveToNextItem(); });
            }
        };
        var loadFocusableElementList = function (item) {
            if (item.type === 'notification' || item.denyInteraction) {
                return [];
            }
            else if (!item.focus) {
                return null;
            }
            var focusableElements = item.focus.element().find(focusableSelector).addBack(focusableSelector);
            var result = [];
            focusableElements.each(function (_index, element) {
                if (isVisible(element) && (!element.tabIndex || element.tabIndex > 0)) {
                    result.push(element);
                }
            });
            return result;
        };
        var manageTabKeyFocus = function (item) {
            var focusableElements = loadFocusableElementList(item);
            var activeElementIsFocusable = function () {
                for (var _i = 0, _a = focusableElements || []; _i < _a.length; _i++) {
                    var focusableElement = _a[_i];
                    if (focusableElement === document.activeElement) {
                        return true;
                    }
                }
                return false;
            };
            if (focusableElements) {
                if (focusableElements.length > 0) {
                    var firstElement = focusableElements[0];
                    var lastElement = focusableElements[focusableElements.length - 1];
                    if (event.shiftKey) {
                        if (isFocusInElement(event, firstElement)) {
                            moveToPreviousIfPossible();
                            stopEvent(event);
                        }
                    }
                    else {
                        if (isFocusInElement(event, lastElement)) {
                            moveToNextIfPossible();
                            stopEvent(event);
                        }
                    }
                    // prevent input focus breaking from item focusable area
                    if (!activeElementIsFocusable()) {
                        firstElement.focus();
                        stopEvent(event);
                    }
                }
                else {
                    if (event.shiftKey) {
                        moveToPreviousIfPossible();
                    }
                    else {
                        moveToNextIfPossible();
                    }
                    stopEvent(event);
                }
            }
            else {
                // free focus, don't stop event
            }
        };
        switch (event.which) {
            case tab:
                manageTabKeyFocus(this.item);
                break;
            case enter:
                moveToNextIfPossible();
                stopEvent(event);
                break;
            case esc:
                this.$scope.$apply(function () { return _this.close(true); });
                break;
        }
    };
    InteractiveHelpController.prototype.moveToNextItemAfterElementDisappeared = function (element) {
        var _this = this;
        var tryCount = 0;
        var waitForElementToDisappear = function () {
            if (elementExists(element())) {
                if (++tryCount < 100) {
                    setTimeout(waitForElementToDisappear, 10);
                }
            }
            else {
                _this.$scope.$apply(function () { return _this.moveToNextItem(); });
            }
        };
        waitForElementToDisappear();
    };
    InteractiveHelpController.prototype.clickHandler = function (event) {
        var _this = this;
        var markLocationChange = function () {
            _this.changingLocation = true;
            setTimeout(function () { return _this.changingLocation = false; }, 3000);
        };
        var item = this.item;
        if (item && item.type === 'story' && isClick(item.nextCondition) && this.isValid()) {
            var continueToNextElement = item.nextCondition.element();
            if (elementExists(continueToNextElement)) {
                if (isTargetElementInsideElement(event, continueToNextElement[0])) {
                    if (item.nextCondition.type === 'modifying-click') {
                        this.moveToNextItemAfterElementDisappeared(item.nextCondition.element);
                    }
                    else if (item.nextCondition.type === 'navigating-click') {
                        markLocationChange();
                    }
                    else {
                        this.$scope.$apply(function () { return _this.moveToNextItem(); });
                    }
                }
            }
            else if (item.nextCondition.type === 'modifying-click') {
                this.$scope.$apply(function () { return _this.moveToNextItem(); });
            }
            else if (item.nextCondition.type === 'navigating-click') {
                markLocationChange();
            }
            else {
                console.log(this.item);
                throw new Error('Next condition element not found');
            }
        }
    };
    InteractiveHelpController.prototype.getPopoverDimensions = function () {
        if (!this.popoverDimensionsProvider) {
            return { width: 0, height: 0 };
        }
        return this.popoverDimensionsProvider.getDimensions();
    };
    InteractiveHelpController.prototype.registerPopover = function (popover) {
        this.popoverController = popover;
    };
    InteractiveHelpController.prototype.registerPopoverDimensionsProvider = function (provider) {
        this.popoverDimensionsProvider = provider;
    };
    InteractiveHelpController.prototype.registerBackdrop = function (backdrop) {
        this.backdropController = backdrop;
        if (!this.item) {
            this.backdropController.setFullBackdrop();
        }
    };
    InteractiveHelpController.prototype.canMoveToNext = function () {
        return !this.inTransition && this.isValid();
    };
    InteractiveHelpController.prototype.canMoveToPrevious = function () {
        var previous = this.peekPrevious();
        function isImplicitlyReversible(condition) {
            switch (condition.type) {
                case 'explicit':
                    return true;
                case 'click':
                case 'modifying-click':
                case 'navigating-click':
                case 'expected-state':
                    return false;
                default:
                    return assertNever(condition, 'Unsupported next condition');
            }
        }
        function isReversible(item) {
            if (item.type === 'notification') {
                return true;
            }
            else if (item.reversible) {
                return item.reversible;
            }
            else {
                return isImplicitlyReversible(item.nextCondition);
            }
        }
        return !this.inTransition && !!previous && isReversible(previous);
    };
    Object.defineProperty(InteractiveHelpController.prototype, "showNext", {
        get: function () {
            return !!this.item && !this.isCurrentLastItem() && (this.item.type === 'notification' || !isClick(this.item.nextCondition) || !this.item.nextCondition.ambiguous);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpController.prototype, "showClose", {
        get: function () {
            return !!this.item && this.isCurrentLastItem() && (this.item.type === 'notification' || !isClick(this.item.nextCondition) || !this.item.nextCondition.ambiguous);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpController.prototype, "showPrevious", {
        get: function () {
            return !this.isCurrentFirstItem();
        },
        enumerable: true,
        configurable: true
    });
    InteractiveHelpController.prototype.peekPrevious = function () {
        if (this.isCurrentFirstItem()) {
            return null;
        }
        else {
            return this.items[this.activeIndex - 1];
        }
    };
    InteractiveHelpController.prototype.isValid = function () {
        if (!this.item) {
            return false;
        }
        switch (this.item.type) {
            case 'story':
                switch (this.item.nextCondition.type) {
                    case 'explicit':
                    case 'click':
                    case 'navigating-click':
                    case 'modifying-click':
                        return true;
                    case 'expected-state':
                        return this.item.nextCondition.valid();
                    default:
                        return assertNever(this.item.nextCondition, 'Unknown next condition');
                }
            case 'notification':
                return true;
            default:
                return assertNever(this.item, 'Unknown item type');
        }
    };
    InteractiveHelpController.prototype.tryToMoveToNextItem = function () {
        var item = requireDefined(this.item);
        if (item.type === 'notification') {
            this.moveToNextItem();
        }
        else {
            var nextCondition_1 = item.nextCondition;
            if (isClick(nextCondition_1)) {
                if (!nextCondition_1.ambiguous) {
                    // off frame so multiple digests are prevented
                    setTimeout(function () { return nextCondition_1.element().click(); });
                }
            }
            else {
                this.moveToNextItem();
            }
        }
    };
    InteractiveHelpController.prototype.moveToNextItem = function () {
        if (this.isCurrentLastItem()) {
            this.close(false);
        }
        else {
            this.showItem(++this.activeIndex);
        }
    };
    InteractiveHelpController.prototype.moveToPreviousItem = function () {
        if (this.isCurrentFirstItem()) {
            this.close(true);
        }
        else {
            this.showItem(--this.activeIndex);
        }
    };
    InteractiveHelpController.prototype.isCurrentFirstItem = function () {
        return this.activeIndex === 0;
    };
    InteractiveHelpController.prototype.isCurrentLastItem = function () {
        return this.activeIndex === this.items.length - 1;
    };
    InteractiveHelpController.prototype.close = function (cancel) {
        this.$overlayInstance.close(cancel);
    };
    return InteractiveHelpController;
}());
export { InteractiveHelpController };
