import { Uri } from 'app/entities/uri';
import { layout as colaLayout } from './colaLayout';
import * as joint from 'jointjs';
export function layoutGraph($q, graph, directed, onlyNodeIds) {
    if (directed && onlyNodeIds.length === 0) {
        // TODO directed doesn't support incremental layout
        return $q.when(joint.layout.DirectedGraph.layout(graph, {
            nodeSep: 100,
            edgeSep: 150,
            rankSep: 500,
            rankDir: 'LR'
        }));
    }
    else {
        return $q(function (resolve, reject) {
            colaLayout(graph, onlyNodeIds.map(function (id) { return id.uri; }))
                .then(function () { return resolve(); }, function (err) { return reject(err); });
        });
    }
}
export function adjustElementLinks(paper, element, alreadyAdjusted, modelPositions, vertexAction) {
    var graph = paper.model;
    var connectedLinks = graph.getConnectedLinks(element);
    var _loop_1 = function (link) {
        if (!alreadyAdjusted.has(link.id) && !!link.get('source').id && !!link.get('target').id) {
            var siblings = connectedLinks.filter(function (connectedLink) { return isSiblingLink(link, connectedLink); });
            adjustSiblingLinks(paper, siblings, alreadyAdjusted, modelPositions, vertexAction);
        }
    };
    for (var _i = 0, connectedLinks_1 = connectedLinks; _i < connectedLinks_1.length; _i++) {
        var link = connectedLinks_1[_i];
        _loop_1(link);
    }
}
export var VertexAction;
(function (VertexAction) {
    VertexAction[VertexAction["Reset"] = 0] = "Reset";
    VertexAction[VertexAction["KeepAllButLoops"] = 1] = "KeepAllButLoops";
    VertexAction[VertexAction["KeepAll"] = 2] = "KeepAll";
    VertexAction[VertexAction["KeepPersistent"] = 3] = "KeepPersistent";
})(VertexAction || (VertexAction = {}));
function adjustSiblingLinks(paper, siblings, alreadyAdjusted, modelPositions, vertexAction) {
    var graph = paper.model;
    var first = siblings[0];
    var firstSource = first.get('source');
    var loop = isLoop(first);
    function getLinkPositionVertices(link) {
        var sourcePosition = modelPositions.getClass(new Uri(link.get('source').id, {}));
        return sourcePosition.getAssociationProperty(new Uri(link.get('internalId'), {})).vertices;
    }
    function getPersistedVertices(link) {
        if (vertexAction === VertexAction.Reset || (vertexAction === VertexAction.KeepAllButLoops && loop)) {
            return null;
        }
        else {
            var vertices = getLinkPositionVertices(link);
            return vertexAction === VertexAction.KeepPersistent && vertices.length === 0 ? null : vertices;
        }
    }
    for (var i = 0; i < siblings.length; i++) {
        var link = siblings[i];
        var source = graph.getCell(link.get('source').id);
        var target = graph.getCell(link.get('target').id);
        var persistedVertices = getPersistedVertices(link);
        if (persistedVertices) {
            link.set('vertices', persistedVertices);
        }
        else if (loop) {
            link.set('vertices', calculateRecurseSiblingVertices(source, i));
        }
        else if (siblings.length > 1) {
            if (firstSource.id === source.id) {
                link.set('vertices', calculateNormalSiblingVertices(source, target, i));
            }
            else {
                link.set('vertices', calculateNormalSiblingVertices(target, source, i));
            }
        }
        else {
            link.unset('vertices');
        }
        if (!loop && siblings.length > 1) {
            var length_1 = paper.findViewByModel(link).getConnectionLength();
            link.prop('labels/0/position', calculateNormalSiblingLabelPosition(length_1, firstSource.id !== source.id, i));
        }
        else {
            link.prop('labels/0/position', 0.5);
        }
        alreadyAdjusted.add(link.id);
    }
}
export function calculateLabelPosition(paper, graph, link) {
    var sourceId = link.get('source').id;
    var targetId = link.get('target').id;
    var sourceElement = graph.getCell(sourceId);
    var links = graph.getConnectedLinks(sourceElement);
    var siblings = links.filter(function (l) { return l.get('target').id === targetId || l.get('source').id === targetId; });
    if (!isLoop(link) && siblings.length > 1) {
        var firstSourceId = siblings[0].get('source').id;
        var indexInSiblings = siblings.indexOf(link);
        var length_2 = paper.findViewByModel(link).getConnectionLength();
        return calculateNormalSiblingLabelPosition(length_2, firstSourceId !== sourceId, indexInSiblings);
    }
    else {
        return 0.5;
    }
}
function calculateNormalSiblingLabelPosition(linkLength, inverseDirection, siblingIndex) {
    var sign = siblingIndex % 2 ? 1 : -1;
    var gapBetweenSiblings = 20;
    return (linkLength / 2) + (sign * (inverseDirection ? -1 : 1) * Math.ceil(siblingIndex / 2) * gapBetweenSiblings) + (inverseDirection ? 10 : 0);
}
function calculateNormalSiblingVertices(source, target, siblingIndex) {
    var gapBetweenSiblings = 25;
    var srcCenter = source.getBBox().center();
    var trgCenter = target.getBBox().center();
    var midPoint = joint.g.line(srcCenter, trgCenter).midpoint();
    var theta = srcCenter.theta(trgCenter);
    var offset = gapBetweenSiblings * Math.ceil(siblingIndex / 2);
    var sign = siblingIndex % 2 ? 1 : -1;
    var angle = joint.g.toRad(theta + sign * 90);
    var vertex = joint.g.point.fromPolar(offset, angle, midPoint);
    return [vertex];
}
function calculateRecurseSiblingVertices(element, siblingIndex) {
    var bbox = element.getBBox();
    var offset = 50;
    var signX = 1;
    var signY = 1;
    var center = joint.g.point(bbox.x + bbox.width / 2 - (signX * siblingIndex * 10), bbox.y + bbox.height / 2 - (signY * siblingIndex * 10));
    var corner = joint.g.point(center).offset(bbox.width / 2 * signX, bbox.height / 2 * signY);
    var scale = (siblingIndex + 1) * 0.5;
    return [
        joint.g.point(corner).offset(-signX * bbox.width / 4, signY * (offset * scale)),
        joint.g.point(corner).offset(signX * (offset * scale), signY * (offset * scale)),
        joint.g.point(corner).offset(signX * (offset * scale), -signY * bbox.height / 4)
    ];
}
function isSiblingLink(lhs, rhs) {
    var lhsSource = lhs.get('source').id;
    var lhsTarget = lhs.get('target').id;
    var rhsSource = rhs.get('source').id;
    var rhsTarget = rhs.get('target').id;
    return (lhsSource === rhsSource && lhsTarget === rhsTarget) || (lhsSource === rhsTarget && lhsTarget === rhsSource);
}
function isLoop(link) {
    return link.get('source').id === link.get('target').id;
}
