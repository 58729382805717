var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Uri } from 'app/entities/uri';
import { LocationService } from 'app/services/locationService';
import { remove } from 'yti-common-ui/utils/array';
import { ErrorModal } from 'app/components/form/errorModal';
import { LegacyComponent } from 'app/utils/angular';
var NewModelPageComponent = /** @class */ (function () {
    function NewModelPageComponent($scope, $location, modelService, locationService, errorModal) {
        'ngInject';
        var _this = this;
        this.$location = $location;
        this.modelService = modelService;
        this.locationService = locationService;
        this.errorModal = errorModal;
        this.classifications = [];
        this.contributors = [];
        this.vocabularies = [];
        this.referenceDatas = [];
        this.importedNamespaces = [];
        this.links = [];
        this.languages = ['fi', 'en', 'sv'];
        this.useContext = 'InformationDescription';
        this.context = {
            id: new Uri('http://newModel', {}),
            language: this.languages
        };
        this.persisting = false;
        this.namespacesInUse = new Set();
        this.importedPrefixes = function () {
            if (_this.importedNamespaces) {
                return _this.importedNamespaces.map(function (ns) { return ns.prefix; });
            }
            return [];
        };
    }
    NewModelPageComponent.prototype.$onInit = function () {
        this.locationService.atNewModel(this.type);
    };
    NewModelPageComponent.prototype.$postLink = function () {
        this.form.editing = true;
    };
    Object.defineProperty(NewModelPageComponent.prototype, "allowProfiles", {
        get: function () {
            return this.type === 'profile';
        },
        enumerable: true,
        configurable: true
    });
    NewModelPageComponent.prototype.isValid = function () {
        return this.form.$valid && this.classifications.length > 0 && this.contributors.length > 0;
    };
    NewModelPageComponent.prototype.addClassification = function (classification) {
        this.classifications.push(classification);
    };
    NewModelPageComponent.prototype.removeClassification = function (classification) {
        remove(this.classifications, classification);
    };
    NewModelPageComponent.prototype.addContributor = function (organization) {
        this.contributors.push(organization);
    };
    NewModelPageComponent.prototype.removeContributor = function (organization) {
        remove(this.contributors, organization);
    };
    NewModelPageComponent.prototype.addVocabulary = function (vocabulary) {
        this.vocabularies.push(vocabulary);
    };
    NewModelPageComponent.prototype.removeVocabulary = function (vocabulary) {
        remove(this.vocabularies, vocabulary);
    };
    NewModelPageComponent.prototype.addReferenceData = function (referenceData) {
        this.referenceDatas.push(referenceData);
    };
    NewModelPageComponent.prototype.removeReferenceData = function (referenceData) {
        remove(this.referenceDatas, referenceData);
    };
    NewModelPageComponent.prototype.addImportedNamespace = function (namespace) {
        this.importedNamespaces.push(namespace);
    };
    NewModelPageComponent.prototype.removeImportedNamespace = function (namespace) {
        remove(this.importedNamespaces, namespace);
    };
    NewModelPageComponent.prototype.addLink = function (link) {
        this.links.push(link);
    };
    NewModelPageComponent.prototype.removeLink = function (link) {
        remove(this.links, link);
    };
    NewModelPageComponent.prototype.save = function () {
        var _this = this;
        this.persisting = true;
        var orgIds = this.contributors.map(function (o) { return o.id.uuid; });
        var classificationIds = this.classifications.map(function (c) { return c.identifier; });
        this.modelService.newModel(this.prefix, this.label, classificationIds, orgIds, this.languages, this.type)
            .then(function (model) {
            // XXX: should comment or contact go to model creator api?
            model.comment = (_a = {}, _a[_this.languages[0]] = _this.comment, _a);
            model.useContext = _this.useContext;
            model.contact = (_b = {}, _b[_this.languages[0]] = _this.contact, _b);
            _this.vocabularies.forEach(function (v) { return model.addVocabulary(v); });
            _this.referenceDatas.forEach(function (r) { return model.addReferenceData(r); });
            _this.importedNamespaces.forEach(function (ns) { return model.addImportedNamespace(ns); });
            _this.links.forEach(function (l) { return model.addLink(l); });
            // remove parent organization from the list as it is already included in child organization
            // TODO: can this be accomplished by framing?
            var parentOrganizationContributors = model.contributors
                .filter(function (contributor) { return !_this.contributors.map(function (selectedContributor) { return selectedContributor.id.uuid; }).includes(contributor.id.uuid); });
            parentOrganizationContributors.forEach(function (parentContributor) { return model.removeContributor(parentContributor); });
            _this.modelService.createModel(model).then(function () {
                _this.$location.url(model.iowUrl());
            }, function () { return _this.persisting = false; });
            var _a, _b;
        }, function (err) {
            _this.errorModal.openSubmitError((err.data && err.data.errorMessage) || 'Unexpected error');
            _this.persisting = false;
        });
    };
    NewModelPageComponent.prototype.cancel = function () {
        this.$location.url('/');
    };
    NewModelPageComponent = __decorate([
        LegacyComponent({
            bindings: {
                type: '='
            },
            template: require('./newModelPage.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Object, LocationService,
            ErrorModal])
    ], NewModelPageComponent);
    return NewModelPageComponent;
}());
export { NewModelPageComponent };
