var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { requireDefined, assertNever } from 'yti-common-ui/utils/object';
import { resourceUrl, glyphIconClassForType, glyphIconClassUnknown } from '../utils/entity';
import { normalizeClassType, mapType, reverseMapType } from '../utils/entity';
import { Uri } from './uri';
import { containsAny, remove, removeMatching } from 'yti-common-ui/utils/array';
import { ReferenceData } from './referenceData';
import { hasLocalization } from '../utils/language';
import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { Predicate, Attribute, Association } from './predicate';
import { init, serialize, initSingle } from './mapping';
import { Concept } from './vocabulary';
import { GraphNode } from './graphNode';
import { uriSerializer, entity, entityAwareValueOrDefault, entityAwareList, entityAwareOptional, entityOrId } from './serializer/entitySerializer';
import { localizableSerializer, dateSerializer, optional, identitySerializer, typeSerializer, createSerializer, stringSerializer, list, valueOrDefault, booleanSerializer } from './serializer/serializer';
import { normalizingDefinedBySerializer } from './serializer/common';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
var AbstractClass = /** @class */ (function (_super) {
    __extends(AbstractClass, _super);
    function AbstractClass(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.selectionType = 'class';
        _this.normalizedType = requireDefined(normalizeClassType(_this.type));
        init(_this, AbstractClass.abstractClassMappings);
        return _this;
    }
    AbstractClass.prototype.isClass = function () {
        return true;
    };
    AbstractClass.prototype.isPredicate = function () {
        return false;
    };
    AbstractClass.prototype.iowUrl = function () {
        return resourceUrl(requireDefined(requireDefined(this.definedBy).prefix), this.id);
    };
    AbstractClass.prototype.isSpecializedClass = function () {
        return requireDefined(this.definedBy).isOfType('profile');
    };
    AbstractClass.abstractClassMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'name', serializer: localizableSerializer },
        comment: { name: 'description', serializer: localizableSerializer },
        definedBy: { name: 'isDefinedBy', serializer: normalizingDefinedBySerializer },
        status: { name: 'versionInfo', serializer: optional(identitySerializer()) },
        modifiedAt: { name: 'modified', serializer: optional(dateSerializer) }
    };
    return AbstractClass;
}(GraphNode));
export { AbstractClass };
var ClassListItem = /** @class */ (function (_super) {
    __extends(ClassListItem, _super);
    function ClassListItem(graph, context, frame) {
        return _super.call(this, graph, context, frame) || this;
    }
    return ClassListItem;
}(AbstractClass));
export { ClassListItem };
var Class = /** @class */ (function (_super) {
    __extends(Class, _super);
    function Class(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.resolved = true;
        _this.unsaved = false;
        _this.external = false;
        init(_this, Class.classMappings);
        _this.properties.sort(comparingPrimitive(function (property) { return property.index; }));
        // normalize indices
        for (var i = 0; i < _this.properties.length; i++) {
            _this.properties[i].index = i;
        }
        return _this;
    }
    Object.defineProperty(Class.prototype, "inUnstableState", {
        get: function () {
            return this.status === 'INCOMPLETE' || this.status === 'DRAFT' || this.status === 'SUGGESTED';
        },
        enumerable: true,
        configurable: true
    });
    Class.prototype.addProperty = function (property) {
        property.index = this.properties.length;
        this.properties.push(property);
    };
    Class.prototype.removeProperty = function (property) {
        remove(this.properties, property);
    };
    Object.defineProperty(Class.prototype, "associationPropertiesWithTarget", {
        get: function () {
            return this.properties.filter(function (property) { return property.isAssociation() && property.valueClass; });
        },
        enumerable: true,
        configurable: true
    });
    Class.prototype.hasAssociationTarget = function (id) {
        for (var _i = 0, _a = this.associationPropertiesWithTarget; _i < _a.length; _i++) {
            var association = _a[_i];
            if (association.valueClass.equals(id)) {
                return true;
            }
        }
        return false;
    };
    Class.prototype.copy = function (toProfile) {
        if (!toProfile.isOfType('profile')) {
            throw new Error('Class can be copied only to profile: ' + toProfile.id.toString());
        }
        var ctx = Object.assign({}, this.context, (_a = {}, _a[toProfile.prefix] = toProfile.namespace, _a));
        var clone = this.clone();
        clone.id = new Uri(toProfile.namespace + this.id.name, ctx);
        clone.context = ctx;
        clone.properties = clone.properties.map(function (p) { return p.copy(); });
        clone.unsaved = true;
        clone.definedBy = toProfile.asDefinedBy();
        return clone;
        var _a;
    };
    Class.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        var result = new Class(serialization['@graph'], serialization['@context'], this.frame);
        result.unsaved = this.unsaved;
        result.external = this.external;
        return result;
    };
    Class.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, Object.assign({}, GraphNode.graphNodeMappings, AbstractClass.abstractClassMappings, Class.classMappings));
    };
    Class.classMappings = {
        localName: { name: 'localName', serializer: optional(stringSerializer) },
        subClassOf: { name: 'subClassOf', serializer: entityAwareOptional(uriSerializer) },
        scopeClass: { name: 'targetClass', serializer: entityAwareOptional(uriSerializer) },
        properties: { name: 'property', serializer: entityAwareList(entity(function () { return Property; })) },
        subject: { name: 'subject', serializer: entityAwareOptional(entity(function () { return Concept; })) },
        equivalentClasses: { name: 'equivalentClass', serializer: entityAwareList(uriSerializer) },
        constraint: { name: 'constraint', serializer: entityAwareValueOrDefault(entity(function () { return Constraint; }), {}, function (constraint) { return constraint.items.length > 0 || hasLocalization(constraint.comment); }) },
        version: { name: 'identifier', serializer: optional(identitySerializer()) },
        editorialNote: { name: 'editorialNote', serializer: localizableSerializer },
        createdAt: { name: 'created', serializer: optional(dateSerializer) },
        minProperties: { name: 'minProperties', serializer: optional(identitySerializer()) },
        maxProperties: { name: 'maxProperties', serializer: optional(identitySerializer()) },
        deactivated: { name: 'deactivated', serializer: booleanSerializer },
        absolutePath: { name: 'absolutePath', serializer: optional(stringSerializer) }
    };
    return Class;
}(AbstractClass));
export { Class };
var Constraint = /** @class */ (function (_super) {
    __extends(Constraint, _super);
    function Constraint(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.comment = initSingle(_this, Constraint.constraintMappings.comment);
        var and = initSingle(_this, Constraint.constraintMappings.and);
        var or = initSingle(_this, Constraint.constraintMappings.or);
        var not = initSingle(_this, Constraint.constraintMappings.not);
        if (and.length > 0) {
            _this.constraint = 'and';
            _this.items = and;
        }
        else if (or.length > 0) {
            _this.constraint = 'or';
            _this.items = or;
        }
        else if (not.length > 0) {
            _this.constraint = 'not';
            _this.items = not;
        }
        else {
            _this.constraint = 'or';
            _this.items = [];
        }
        return _this;
    }
    Constraint.prototype.isVisible = function () {
        return this.items.length > 0 || hasLocalization(this.comment);
    };
    Constraint.prototype.addItem = function (shape) {
        var graph = {
            '@id': shape.id.uri,
            name: shape.label
        };
        this.items.push(new ConstraintListItem(graph, this.context, this.frame));
    };
    Constraint.prototype.removeItem = function (removedItem) {
        removeMatching(this.items, function (item) { return item === removedItem; });
    };
    Constraint.prototype.serializationValues = function (_inline, clone) {
        var result = serialize(this, clone, { comment: Constraint.constraintMappings.comment });
        switch (this.constraint) {
            case 'and':
                Object.assign(result, { '@type': 'sh:AbstractAndNodeConstraint' });
                Object.assign(result, serialize(this, clone, { items: Constraint.constraintMappings.and }));
                Object.assign(result, (_a = {}, _a[Constraint.constraintMappings.or.name] = null, _a));
                Object.assign(result, (_b = {}, _b[Constraint.constraintMappings.not.name] = null, _b));
                break;
            case 'or':
                Object.assign(result, { '@type': 'sh:AbstractOrNodeConstraint' });
                Object.assign(result, serialize(this, clone, { items: Constraint.constraintMappings.or }));
                Object.assign(result, (_c = {}, _c[Constraint.constraintMappings.and.name] = null, _c));
                Object.assign(result, (_d = {}, _d[Constraint.constraintMappings.not.name] = null, _d));
                break;
            case 'not':
                Object.assign(result, { '@type': 'sh:AbstractNotNodeConstraint' });
                Object.assign(result, serialize(this, clone, { items: Constraint.constraintMappings.not }));
                Object.assign(result, (_e = {}, _e[Constraint.constraintMappings.and.name] = null, _e));
                Object.assign(result, (_f = {}, _f[Constraint.constraintMappings.or.name] = null, _f));
                break;
            default:
                assertNever(this.constraint, 'Unsupported constraint: ' + this.constraint);
        }
        return result;
        var _a, _b, _c, _d, _e, _f;
    };
    Constraint.constraintListItemsSerializer = entityAwareList(entity(function () { return ConstraintListItem; }));
    Constraint.constraintMappings = {
        and: { name: 'andCond', serializer: Constraint.constraintListItemsSerializer },
        or: { name: 'orCond', serializer: Constraint.constraintListItemsSerializer },
        not: { name: 'notCond', serializer: Constraint.constraintListItemsSerializer },
        comment: { name: 'description', serializer: localizableSerializer }
    };
    return Constraint;
}(GraphNode));
export { Constraint };
var ConstraintListItem = /** @class */ (function (_super) {
    __extends(ConstraintListItem, _super);
    function ConstraintListItem(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ConstraintListItem.constraintListItemMapping);
        return _this;
    }
    ConstraintListItem.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, ConstraintListItem.constraintListItemMapping);
    };
    ConstraintListItem.constraintListItemMapping = {
        shapeId: { name: '@id', serializer: uriSerializer },
        label: { name: 'name', serializer: localizableSerializer }
    };
    return ConstraintListItem;
}(GraphNode));
export { ConstraintListItem };
var ɵ0 = function (data) { return reverseMapType(data); }, ɵ1 = function (data) {
    var predicateType = requireDefined(mapType(data));
    if (predicateType !== 'association' && predicateType !== 'attribute') {
        throw new Error('Unknown predicate type: ' + predicateType);
    }
    return predicateType;
};
var propertyTypeSerializer = createSerializer(ɵ0, ɵ1);
function resolvePredicateConstructor(framedData) {
    var types = typeSerializer.deserialize(framedData['@type']);
    if (containsAny(types, ['association'])) {
        return Association;
    }
    else if (containsAny(types, ['attribute'])) {
        return Attribute;
    }
    else {
        throw new Error('Incompatible predicate type: ' + types.join());
    }
}
var Property = /** @class */ (function (_super) {
    __extends(Property, _super);
    function Property(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.predicateType = null;
        init(_this, Property.propertyMapping);
        return _this;
    }
    Object.defineProperty(Property.prototype, "predicateId", {
        get: function () {
            var predicate = this.predicate;
            if (predicate instanceof Predicate) {
                return predicate.id;
            }
            else if (predicate instanceof Uri) {
                return predicate;
            }
            else {
                throw new Error('Unsupported predicate: ' + predicate);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Property.prototype, "inputType", {
        get: function () {
            if (this.dataType) {
                return this.dataType;
            }
            else {
                return 'xsd:anyURI';
            }
        },
        enumerable: true,
        configurable: true
    });
    Property.prototype.hasOptionalMetadata = function () {
        return this.externalId
            || this.example
            || this.in.length > 0
            || this.defaultValue
            || this.hasValue
            || this.pattern
            || this.minLength
            || this.maxLength
            || this.minCount
            || this.maxCount
            || this.referenceData.length > 0;
    };
    Property.prototype.hasTechnicalMetadata = function () {
        return this.resourceIdentifier
            || this.xmlWrapper
            || this.xmlAttribute
            || this.readOnlyValue
            || this.deactivated;
    };
    Property.prototype.hasPropertyPairs = function () {
        return this.equals.length > 0
            || this.disjoint.length > 0
            || this.lessThan.length > 0
            || this.lessThanOrEquals.length > 0;
    };
    Property.prototype.hasAssociationTarget = function () {
        return !!this.valueClass;
    };
    Property.prototype.isAssociation = function () {
        return this.normalizedPredicateType === 'association';
    };
    Property.prototype.isAttribute = function () {
        return this.normalizedPredicateType === 'attribute';
    };
    Object.defineProperty(Property.prototype, "inUnstableState", {
        get: function () {
            return this.status === 'INCOMPLETE' || this.status === 'DRAFT' || this.status === 'SUGGESTED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Property.prototype, "normalizedPredicateType", {
        get: function () {
            if (this.predicateType) {
                return this.predicateType;
            }
            else {
                var predicate = this.predicate;
                if (predicate instanceof Predicate) {
                    return predicate.normalizedType;
                }
                else if (this.dataType) {
                    return 'attribute';
                }
                else if (this.valueClass) {
                    return 'association';
                }
                else {
                    return null;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Property.prototype, "glyphIconClass", {
        get: function () {
            var type = this.normalizedPredicateType;
            if (type === 'association' && !this.hasAssociationTarget()) {
                return glyphIconClassUnknown;
            }
            else {
                return glyphIconClassForType(type ? [type] : []);
            }
        },
        enumerable: true,
        configurable: true
    });
    Property.prototype.copy = function () {
        var clone = this.clone();
        clone.internalId = Uri.randomUUID();
        return clone;
    };
    Property.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        return new Property(serialization['@graph'], serialization['@context'], this.frame);
    };
    Property.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, Property.propertyMapping);
    };
    Property.prototype.getIdIdentifierFromLabel = function (localizer) {
        return labelNameToResourceIdIdentifier(localizer.translate(this.label));
    };
    Property.propertyMapping = {
        internalId: { name: '@id', serializer: uriSerializer },
        externalId: { name: 'localName', serializer: optional(stringSerializer) },
        status: { name: 'versionInfo', serializer: valueOrDefault(identitySerializer(), 'DRAFT') },
        label: { name: 'name', serializer: localizableSerializer },
        comment: { name: 'description', serializer: localizableSerializer },
        example: { name: 'example', serializer: list(stringSerializer) },
        defaultValue: { name: 'defaultValue', serializer: optional(stringSerializer) },
        dataType: { name: 'datatype', serializer: optional(identitySerializer()) },
        languageIn: { name: 'languageIn', serializer: list(identitySerializer()) },
        valueClass: { name: 'node', serializer: entityAwareOptional(uriSerializer) },
        predicate: { name: 'path', serializer: entityOrId(entity(resolvePredicateConstructor)) },
        index: { name: 'order', serializer: identitySerializer() },
        minCount: { name: 'minCount', serializer: optional(identitySerializer()) },
        maxCount: { name: 'maxCount', serializer: optional(identitySerializer()) },
        minLength: { name: 'minLength', serializer: optional(identitySerializer()) },
        maxLength: { name: 'maxLength', serializer: optional(identitySerializer()) },
        in: { name: 'inValues', serializer: list(stringSerializer) },
        hasValue: { name: 'hasValue', serializer: optional(stringSerializer) },
        pattern: { name: 'pattern', serializer: optional(stringSerializer) },
        referenceData: { name: 'memberOf', serializer: entityAwareList(entity(function () { return ReferenceData; })) },
        classIn: { name: 'classIn', serializer: entityAwareList(uriSerializer) },
        stem: { name: 'stem', serializer: entityAwareOptional(uriSerializer) },
        editorialNote: { name: 'editorialNote', serializer: localizableSerializer },
        resourceIdentifier: { name: 'isResourceIdentifier', serializer: booleanSerializer },
        uniqueLang: { name: 'uniqueLang', serializer: booleanSerializer },
        predicateType: { name: 'type', serializer: optional(propertyTypeSerializer) },
        xmlWrapper: { name: 'isXmlWrapper', serializer: booleanSerializer },
        xmlAttribute: { name: 'isXmlAttribute', serializer: booleanSerializer },
        readOnlyValue: { name: 'readOnlyValue', serializer: booleanSerializer },
        deactivated: { name: 'deactivated', serializer: booleanSerializer },
        equals: { name: 'equals', serializer: entityAwareList(uriSerializer) },
        disjoint: { name: 'disjoint', serializer: entityAwareList(uriSerializer) },
        lessThan: { name: 'lessThan', serializer: entityAwareList(uriSerializer) },
        lessThanOrEquals: { name: 'lessThanOrEquals', serializer: entityAwareList(uriSerializer) }
    };
    return Property;
}(GraphNode));
export { Property };
export { ɵ0, ɵ1 };
