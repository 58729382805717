var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GraphNode } from './graphNode';
import { localizableSerializer, stringSerializer } from './serializer/serializer';
import { init } from './mapping';
import { entity, entityAwareOptional, uriSerializer } from './serializer/entitySerializer';
var Organization = /** @class */ (function (_super) {
    __extends(Organization, _super);
    function Organization(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Organization.organizationMappings);
        return _this;
    }
    Organization.organizationMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'prefLabel', serializer: localizableSerializer },
        description: { name: 'description', serializer: localizableSerializer },
        homepage: { name: 'homepage', serializer: stringSerializer },
        parentOrg: { name: 'parentOrganization', serializer: entityAwareOptional(entity(function () { return Organization; })) }
    };
    return Organization;
}(GraphNode));
export { Organization };
