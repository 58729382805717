var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LocationService } from '../services/locationService';
import { LanguageService } from '../services/languageService';
import { FrontPageHelpService } from '../help/providers/frontPageHelpService';
import { LegacyComponent } from '../utils/angular';
import { ClassificationService } from '../services/classificationService';
import { comparingLocalizable } from '../utils/comparator';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { fromIPromise } from '../utils/observable';
import { anyMatching } from 'yti-common-ui/utils/array';
import { profileUseContexts } from '../types/entity';
import { AuthorizationManagerService } from '../services/authorizationManagerService';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
import { debounceTime, skip, take } from 'rxjs/operators';
import { getDataModelingMaterialIcon, getInformationDomainSvgIcon } from 'yti-common-ui/utils/icons';
import { selectableStatuses } from 'yti-common-ui/entities/status';
import { HelpService } from '../help/providers/helpService';
import { IndexSearchService } from '../services/indexSearchService';
import { getInternalModelUrl, getInternalResourceUrl } from '../entities/index/indexEntities';
import { HttpErrorResponse } from '@angular/common/http';
var FrontPageComponent = /** @class */ (function () {
    function FrontPageComponent($scope, gettextCatalog, $location, locationService, modelService, languageService, frontPageHelpService, classificationService, organizationService, authorizationManagerService, helpService, indexSearchService, userService) {
        'ngInject';
        var _this = this;
        this.gettextCatalog = gettextCatalog;
        this.$location = $location;
        this.locationService = locationService;
        this.modelService = modelService;
        this.languageService = languageService;
        this.frontPageHelpService = frontPageHelpService;
        this.classificationService = classificationService;
        this.organizationService = organizationService;
        this.authorizationManagerService = authorizationManagerService;
        this.helpService = helpService;
        this.indexSearchService = indexSearchService;
        this.userService = userService;
        this.helps = [];
        this.organizationMap = {};
        this.informationDomainMap = {};
        this.search$ = new BehaviorSubject('');
        this.searchResources$ = new BehaviorSubject(true);
        this.informationDomain$ = new BehaviorSubject(null);
        this.modelType$ = new BehaviorSubject(null);
        this.useContext$ = new BehaviorSubject(null);
        this.organization$ = new BehaviorSubject(null);
        this.status$ = new BehaviorSubject(null);
        this.modelResults$ = new BehaviorSubject({
            totalHitCount: 0, pageSize: 0, pageFrom: 0, models: [], deepHits: {}
        });
        this.filteredModels = [];
        this.subscriptionsToClean = [];
        this.modelsLoaded = false;
        this.modelsSearchError = false;
        this.modelTypeIconDef = getDataModelingMaterialIcon;
        this.informationDomainIconSrc = getInformationDomainSvgIcon;
        $scope.$watch(function () { return languageService.UILanguage; }, function (lang) {
            _this.helps = frontPageHelpService.getHelps(lang);
        });
        this.modelTypes = [null, 'library', 'profile'].map(function (type) {
            return {
                value: type,
                name: function () { return gettextCatalog.getString(type ? type : 'All model types'); },
                idIdentifier: function () { return type ? type : 'all_selected'; }
            };
        });
        this.useContexts = [null].concat(profileUseContexts).map(function (type) {
            return {
                value: type,
                name: function () { return gettextCatalog.getString(type ? type : 'All use contexts'); },
                idIdentifier: function () { return type ? type : 'all_selected'; }
            };
        });
        this.statuses = [null].concat(selectableStatuses).map(function (status) { return ({
            value: status,
            name: function () { return gettextCatalog.getString(status ? status : 'All statuses'); },
            idIdentifier: function () { return status ? status : 'all_selected'; }
        }); });
    }
    Object.defineProperty(FrontPageComponent.prototype, "loading", {
        get: function () {
            return !this.modelsLoaded || !this.informationDomains || !this.organizations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrontPageComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrontPageComponent.prototype, "searchResources", {
        get: function () {
            return this.searchResources$.getValue();
        },
        set: function (value) {
            this.searchResources$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    FrontPageComponent.prototype.$onInit = function () {
        var _this = this;
        this.locationService.atFrontPage();
        this.helpService.registerProvider(this);
        var localizer = this.languageService.createLocalizer();
        var informationDomains$ = fromIPromise(this.classificationService.getClassifications());
        var organizations$ = fromIPromise(this.organizationService.getOrganizations());
        this.subscriptionsToClean.push(combineLatest(informationDomains$, organizations$, this.languageService.language$).subscribe(function (_a) {
            var informationDomains = _a[0], organizations = _a[1];
            _this.organizations = [null].concat(organizations).map(function (org) {
                return {
                    value: org,
                    name: function () { return org ? _this.languageService.translate(org.label) : _this.gettextCatalog.getString('All organizations'); },
                    idIdentifier: function () { return org ? labelNameToResourceIdIdentifier(_this.languageService.translate(org.label)) : 'all_selected'; },
                    parentOrg: org && org.parentOrg ? org.parentOrg : undefined
                };
            }).filter(function (org) { return org.parentOrg === undefined; });
            _this.organizations.sort(comparingLocalizable(localizer, function (c) {
                return c.value ? c.value.label : {};
            }));
            organizations.map(function (org) { return _this.organizationMap[org.id.toString()] = org; });
            informationDomains.forEach(function (domain) { return _this.informationDomainMap[domain.identifier] = domain; });
            _this.subscribeModels();
            _this.filterModels(informationDomains);
        }));
    };
    FrontPageComponent.prototype.$onDestroy = function () {
        this.helpService.unregisterProvider(this);
        for (var _i = 0, _a = this.subscriptionsToClean; _i < _a.length; _i++) {
            var subscription = _a[_i];
            subscription.unsubscribe();
        }
    };
    FrontPageComponent.prototype.isInformationDomainSelected = function (domain) {
        return this.informationDomain$.getValue() === domain;
    };
    FrontPageComponent.prototype.toggleInformationDomain = function (domain) {
        this.informationDomain$.next(this.isInformationDomainSelected(domain) ? null : domain);
    };
    FrontPageComponent.prototype.selectModel = function (model) {
        this.$location.url(getInternalModelUrl(model));
    };
    FrontPageComponent.prototype.selectResource = function (model, resource) {
        this.$location.url(getInternalResourceUrl(model, resource));
    };
    FrontPageComponent.prototype.canAddModel = function () {
        return this.authorizationManagerService.canAddModel();
    };
    FrontPageComponent.prototype.addLibrary = function () {
        this.addModel('library');
    };
    FrontPageComponent.prototype.addProfile = function () {
        this.addModel('profile');
    };
    FrontPageComponent.prototype.addModel = function (type) {
        this.$location.path('/newModel');
        this.$location.search({ type: type });
    };
    FrontPageComponent.prototype.informationDomainLabel = function (id) {
        var domain = this.informationDomainMap[id];
        if (domain) {
            return domain.label;
        }
        return { en: 'Domain not found' };
    };
    FrontPageComponent.prototype.organizationLabel = function (id) {
        var org = this.organizationMap['urn:uuid:' + id];
        if (org) {
            return org.label;
        }
        return { en: 'Organization not found' };
    };
    FrontPageComponent.prototype.allLanguagesLabel = function (label) {
        var exp = /<\/?b>/g;
        var keys = Object.keys(label);
        if (keys.length) {
            return keys.map(function (key) { return label[key].replace(exp, '') + ' (' + key + ')'; }).join('\n');
        }
        return undefined;
    };
    FrontPageComponent.prototype.subscribeModels = function () {
        var _this = this;
        var initialSearchText$ = this.search$.pipe(take(1));
        var debouncedSearchText$ = this.search$.pipe(skip(1), debounceTime(500));
        var combinedSearchText$ = concat(initialSearchText$, debouncedSearchText$);
        var searchConditions$ = combineLatest(combinedSearchText$, this.languageService.language$, this.searchResources$, this.userService.user$);
        this.subscriptionsToClean.push(searchConditions$.subscribe(function (_a) {
            var text = _a[0], language = _a[1], searchResources = _a[2], _user = _a[3];
            _this.indexSearchService.searchModels({
                query: text || undefined,
                searchResources: searchResources,
                sortLang: language,
                pageSize: 1000,
                pageFrom: 0
            }).subscribe(function (resp) {
                _this.modelsSearchError = false;
                _this.modelsLoaded = true;
                if (resp.totalHitCount != resp.models.length) {
                    console.error("Model search did not return all results. Got " + resp.models.length + " (start: " + resp.pageFrom + ", total hits: " + resp.totalHitCount + ")");
                }
                _this.modelResults$.next(resp);
            }, function (err) {
                if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500) {
                    _this.modelsSearchError = true;
                    _this.modelResults$.next({
                        totalHitCount: 0, pageSize: 0, pageFrom: 0, models: [], deepHits: {}
                    });
                }
                else {
                    console.error('Model search failed: ' + JSON.stringify(err));
                }
            });
        }));
    };
    FrontPageComponent.prototype.filterModels = function (informationDomains) {
        var _this = this;
        var localizer = this.languageService.createLocalizer();
        this.subscriptionsToClean.push(combineLatest(combineLatest(this.modelResults$, this.languageService.language$), combineLatest(this.search$, this.informationDomain$, this.modelType$, this.useContext$, this.organization$, this.status$))
            .subscribe(function (_a) {
            var _b = _a[0], modelResult = _b[0], language = _b[1], _c = _a[1], search = _c[0], informationDomain = _c[1], modelType = _c[2], useContext = _c[3], org = _c[4], status = _c[5];
            var ignoringInformationDomain = modelResult.models.filter(function (model) {
                return typeMatches(modelType, model) &&
                    useContextMatches(useContext, model) &&
                    organizationMatches(org, model) &&
                    statusMatches(status, model);
            });
            var modelCount = function (domain) { return ignoringInformationDomain.filter(function (model) { return informationDomainMatches(domain, model); }).length; };
            _this.informationDomains = informationDomains.map(function (domain) { return ({
                node: domain,
                count: modelCount(domain)
            }); }).filter(function (item) { return item.count > 0; });
            _this.informationDomains.sort(comparingLocalizable(localizer, function (item) { return item.node.label; }));
            _this.filteredModels = ignoringInformationDomain.filter(function (model) { return informationDomainMatches(informationDomain, model); });
            _this.filteredModels.sort(comparingLocalizable(localizer, function (m) { return m.label; }));
            _this.filteredModels.map(function (filteredModel) { return filteredModel.isPartOf.sort(comparingLocalizable(localizer, function (id) { return _this.informationDomainLabel(id); })); });
            _this.filteredModels.map(function (filteredModel) {
                // remove child organization and add parent if not already exist
                var parentIds = [];
                filteredModel.contributor.forEach(function (contributor, index) {
                    var org = _this.organizationMap['urn:uuid:' + contributor];
                    if (org && org.parentOrg) {
                        filteredModel.contributor.splice(index, 1);
                        parentIds.push(org.parentOrg.id.uuid);
                    }
                });
                (_a = filteredModel.contributor).push.apply(_a, parentIds);
                var _a;
            });
            _this.filteredDeepHits = {};
            if (modelResult.deepHits && Object.keys(modelResult.deepHits).length > 0) {
                var dhs_1 = modelResult.deepHits;
                _this.filteredModels.forEach(function (model) {
                    var hit = dhs_1[model.id];
                    if (hit) {
                        _this.filteredDeepHits[model.id] = hit;
                    }
                });
            }
        }));
    };
    FrontPageComponent.prototype.go = function (withIowUrl) {
        var url = withIowUrl.iowUrl();
        if (url) {
            this.$location.url(url);
        }
    };
    FrontPageComponent = __decorate([
        LegacyComponent({
            template: require('./frontPage.html'),
            require: {
                applicationCtrl: '^application'
            }
        }),
        __metadata("design:paramtypes", [Object, Object, Object, LocationService, Object, LanguageService,
            FrontPageHelpService,
            ClassificationService, Object, AuthorizationManagerService,
            HelpService,
            IndexSearchService, Object])
    ], FrontPageComponent);
    return FrontPageComponent;
}());
export { FrontPageComponent };
function informationDomainMatches(classification, model) {
    return !classification || anyMatching(model.isPartOf, function (domain) { return classification.identifier === domain; });
}
function typeMatches(type, model) {
    return !type || model.type === type;
}
function useContextMatches(uc, model) {
    return !uc || model.useContext === uc;
}
function organizationMatches(org, model) {
    return !org || anyMatching(model.contributor, function (modelOrgId) { return ('urn:uuid:' + modelOrgId) === org.id.toString(); });
}
function statusMatches(status, model) {
    return !status || model.status === status;
}
