var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from 'app/services/languageService';
import { isLocalizationDefined } from 'app/utils/language';
import { LegacyComponent } from 'app/utils/angular';
var ModelLanguageChooserComponent = /** @class */ (function () {
    function ModelLanguageChooserComponent($scope, languageService, gettextCatalog) {
        'ngInject';
        this.$scope = $scope;
        this.languageService = languageService;
        this.gettextCatalog = gettextCatalog;
    }
    ModelLanguageChooserComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watchCollection(function () { return _this.context && _this.context.language; }, function (languages) {
            if (languages && languages.indexOf(_this.languageService.getModelLanguage(_this.context)) === -1) {
                _this.languageService.setModelLanguage(_this.context, _this.context.language[0]);
            }
        });
        this.$scope.$watch(function () { return _this.languageService.UILanguage; }, function (language, previousLanguage) {
            if (language !== previousLanguage) {
                if (_this.context && _this.context.language.indexOf(language) !== -1) {
                    _this.languageService.setModelLanguage(_this.context, language);
                }
            }
        });
    };
    ModelLanguageChooserComponent.prototype.localizeLanguageName = function (language) {
        var key = 'data ' + language;
        var localization = this.gettextCatalog.getString(key);
        if (isLocalizationDefined(key, localization)) {
            return localization;
        }
        else {
            return this.gettextCatalog.getString('data language') + ': ' + language;
        }
    };
    Object.defineProperty(ModelLanguageChooserComponent.prototype, "language", {
        get: function () {
            return this.languageService.getModelLanguage(this.context);
        },
        set: function (language) {
            this.languageService.setModelLanguage(this.context, language);
        },
        enumerable: true,
        configurable: true
    });
    ModelLanguageChooserComponent = __decorate([
        LegacyComponent({
            bindings: {
                context: '<'
            },
            template: require('./modelLanguageChooser.html')
        }),
        __metadata("design:paramtypes", [Object, LanguageService, Object])
    ], ModelLanguageChooserComponent);
    return ModelLanguageChooserComponent;
}());
export { ModelLanguageChooserComponent };
