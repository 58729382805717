var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AddNew } from 'app/components/common/searchResults';
import { glyphIconClassForType } from 'app/utils/entity';
import { Class, ClassListItem } from 'app/entities/class';
import { ExternalEntity } from 'app/entities/externalEntity';
import { filterAndSortSearchResults, defaultLabelComparator } from 'app/components/filter/util';
import { requireDefined } from 'yti-common-ui/utils/object';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
export var noExclude = function (_item) { return null; };
export var defaultTextForSelection = function (_klass) { return 'Use class'; };
var SearchClassModal = /** @class */ (function () {
    function SearchClassModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchClassModal.prototype.openModal = function (model, exclude, defaultToCurrentModel, onlySelection, textForSelection, requiredByInUse) {
        if (requiredByInUse === void 0) { requiredByInUse = false; }
        return this.$uibModal.open({
            template: require('./searchClassModal.html'),
            size: 'lg',
            controller: SearchClassController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                model: function () { return model; },
                exclude: function () { return exclude; },
                defaultToCurrentModel: function () { return defaultToCurrentModel; },
                onlySelection: function () { return onlySelection; },
                textForSelection: function () { return textForSelection; },
                requiredByInUse: function () { return requiredByInUse; }
            }
        }).result;
    };
    SearchClassModal.prototype.open = function (model, exclude, textForSelection) {
        return this.openModal(model, exclude, false, false, textForSelection);
    };
    SearchClassModal.prototype.openWithOnlySelection = function (model, defaultToCurrentModel, exclude, textForSelection, requiredByInUse) {
        if (textForSelection === void 0) { textForSelection = defaultTextForSelection; }
        if (requiredByInUse === void 0) { requiredByInUse = false; }
        return this.openModal(model, exclude, defaultToCurrentModel, true, textForSelection, requiredByInUse);
    };
    return SearchClassModal;
}());
export { SearchClassModal };
var SearchClassController = /** @class */ (function () {
    function SearchClassController($scope, $uibModalInstance, classService, languageService, model, exclude, defaultToCurrentModel, onlySelection, textForSelection, requiredByInUse, searchConceptModal, gettextCatalog) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.classService = classService;
        this.model = model;
        this.exclude = exclude;
        this.defaultToCurrentModel = defaultToCurrentModel;
        this.onlySelection = onlySelection;
        this.textForSelection = textForSelection;
        this.requiredByInUse = requiredByInUse;
        this.searchConceptModal = searchConceptModal;
        this.gettextCatalog = gettextCatalog;
        this.classes = [];
        this.searchResults = [];
        this.searchText = '';
        this.excludeError = null;
        this.contentMatchers = [
            { name: 'Label', extractor: function (klass) { return klass.label; } },
            { name: 'Description', extractor: function (klass) { return klass.comment; } },
            { name: 'Identifier', extractor: function (klass) { return klass.id.compact; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.searchFilters = [];
        this.localizer = languageService.createLocalizer(model);
        this.loadingResults = true;
        var appendResults = function (classes) {
            _this.classes = _this.classes.concat(classes);
            _this.search();
            _this.loadingResults = false;
        };
        if (this.requiredByInUse) {
            classService.getRequiredByClasses(model).then(appendResults);
        }
        else {
            classService.getAllClasses(model).then(appendResults);
        }
        if (model.isOfType('profile')) {
            classService.getExternalClassesForModel(model).then(appendResults);
        }
        $scope.$watch(function () { return _this.selection && _this.selection.id; }, function (selectionId) {
            if (selectionId && _this.selection instanceof ExternalEntity) {
                _this.externalClass = undefined;
                classService.getExternalClass(selectionId, model).then(function (klass) { return _this.externalClass = klass; });
            }
        });
    }
    Object.defineProperty(SearchClassController.prototype, "items", {
        get: function () {
            return this.classes;
        },
        enumerable: true,
        configurable: true
    });
    SearchClassController.prototype.addFilter = function (searchFilter) {
        this.searchFilters.push(searchFilter);
    };
    SearchClassController.prototype.isSelectionExternalEntity = function () {
        return this.selection instanceof ExternalEntity;
    };
    SearchClassController.prototype.search = function () {
        var _this = this;
        this.searchResults = [
            new AddNewClass(this.gettextCatalog.getString('Create new class') + " '" + this.searchText + "'", this.canAddNew.bind(this), false),
            new AddNewClass(this.gettextCatalog.getString('Create new shape') + " " + this.gettextCatalog.getString('by referencing external uri'), function () { return _this.canAddNew() && _this.model.isOfType('profile'); }, true)
        ].concat(filterAndSortSearchResults(this.classes, this.searchText, this.contentExtractors, this.searchFilters, defaultLabelComparator(this.localizer, this.exclude)));
    };
    SearchClassController.prototype.canAddNew = function () {
        return !this.onlySelection && !!this.searchText;
    };
    SearchClassController.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.externalClass = undefined;
        this.excludeError = null;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        if (item instanceof AddNewClass) {
            if (item.external) {
                this.$scope.form.editing = true;
                this.selection = new ExternalEntity(this.localizer.language, this.searchText, 'class');
            }
            else {
                this.createNewClass();
            }
        }
        else {
            this.cannotConfirm = this.exclude(item);
            if (this.model.isNamespaceKnownToBeNotModel(item.definedBy.id.toString())) {
                this.classService.getExternalClass(item.id, this.model).then(function (result) {
                    _this.selection = requireDefined(result); // TODO check if result can actually be null
                });
            }
            else {
                this.classService.getClass(item.id, this.model).then(function (result) { return _this.selection = result; });
            }
        }
    };
    SearchClassController.prototype.loadingSelection = function (item) {
        var selection = this.selection;
        if (item instanceof ClassListItem) {
            return item === this.selectedItem && (!selection || (selection instanceof Class && !item.id.equals(selection.id)));
        }
        else {
            return false;
        }
    };
    SearchClassController.prototype.isExternalClassPending = function () {
        return this.isSelectionExternalEntity() && this.externalClass === undefined;
    };
    SearchClassController.prototype.confirm = function () {
        var selection = this.selection;
        if (selection instanceof Class) {
            this.$uibModalInstance.close(this.selection);
        }
        else if (selection instanceof ExternalEntity) {
            if (this.externalClass) {
                var exclude = this.exclude(this.externalClass);
                if (exclude) {
                    this.excludeError = exclude;
                }
                else {
                    this.$uibModalInstance.close(this.externalClass);
                }
            }
            else {
                this.$uibModalInstance.close(selection);
            }
        }
        else {
            throw new Error('Unsupported selection: ' + selection);
        }
    };
    SearchClassController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    SearchClassController.prototype.createNewClass = function () {
        var _this = this;
        return this.searchConceptModal.openNewEntityCreation(this.model.vocabularies, this.model, 'class', this.searchText)
            .then(function (conceptCreation) { return _this.$uibModalInstance.close(conceptCreation); }, ignoreModalClose);
    };
    return SearchClassController;
}());
var AddNewClass = /** @class */ (function (_super) {
    __extends(AddNewClass, _super);
    function AddNewClass(label, show, external) {
        var _this = _super.call(this, label, show, glyphIconClassForType(['class'])) || this;
        _this.label = label;
        _this.show = show;
        _this.external = external;
        return _this;
    }
    return AddNewClass;
}(AddNew));
