var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from '../../services/languageService';
import { TableDescriptor } from '../../components/form/editableTable';
import { AddEditNamespaceModal } from './addEditNamespaceModal';
import { SearchNamespaceModal } from './searchNamespaceModal';
import { combineExclusions } from '../../utils/exclusion';
import { NamespaceType } from '../../entities/model';
import { LegacyComponent, modalCancelHandler } from '../../utils/angular';
var ImportedNamespacesViewComponent = /** @class */ (function () {
    function ImportedNamespacesViewComponent($scope, searchNamespaceModal, addEditNamespaceModal, languageService) {
        'ngInject';
        this.$scope = $scope;
        this.searchNamespaceModal = searchNamespaceModal;
        this.addEditNamespaceModal = addEditNamespaceModal;
        this.languageService = languageService;
        this.expanded = false;
    }
    ImportedNamespacesViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new ImportedNamespaceTableDescriptor(_this.addEditNamespaceModal, value, function () { return _this.modelPrefix; }, function () { return _this.modelNamespace; }, _this.context, _this.languageService, _this.namespacesInUse);
        });
    };
    ImportedNamespacesViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    ImportedNamespacesViewComponent.prototype.importNamespace = function () {
        var _this = this;
        var existsExclude = function (ns) {
            for (var _i = 0, _a = _this.value.importedNamespaces; _i < _a.length; _i++) {
                var existingNs = _a[_i];
                if (existingNs.namespaceType !== NamespaceType.IMPLICIT_TECHNICAL && (existingNs.prefix === ns.prefix || existingNs.url === ns.namespace)) {
                    return 'Already added';
                }
            }
            return null;
        };
        var namespaceOfThisModel = this.value.id ? this.value.id.uri : '';
        var profileExclude = function (ns) { return (!_this.allowProfiles && ns.isOfType('profile')) ? 'Cannot import profile' : null; };
        var thisModelExclude = function (ns) { return (namespaceOfThisModel === ns.id.uri) ? 'Cannot import namespace of this model' : null; };
        var exclude = combineExclusions(existsExclude, profileExclude, thisModelExclude);
        var reservedPrefixes = [this.modelPrefix].concat(this.value.importedNamespaces.map(function (ns) { return ns.prefix; }));
        var usedNamespaces = [this.modelNamespace].concat(this.value.importedNamespaces.map(function (namespace) { return namespace.namespace; }));
        this.searchNamespaceModal.open(this.context, reservedPrefixes, usedNamespaces, exclude)
            .then(function (ns) {
            _this.value.addImportedNamespace(ns);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    ImportedNamespacesViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                context: '=',
                allowProfiles: '=',
                namespacesInUse: '=',
                modelPrefix: '<',
                modelNamespace: '<'
            },
            require: {
                form: '?^form'
            },
            template: "\n      <h4>\n        <span translate>Imported namespaces</span>\n        <button id=\"add_imported_namespace_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.importNamespace()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Import namespace</span>\n        </button>\n      </h4>\n      <editable-table id=\"'importedNamespaces'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, SearchNamespaceModal,
            AddEditNamespaceModal,
            LanguageService])
    ], ImportedNamespacesViewComponent);
    return ImportedNamespacesViewComponent;
}());
export { ImportedNamespacesViewComponent };
var ImportedNamespaceTableDescriptor = /** @class */ (function (_super) {
    __extends(ImportedNamespaceTableDescriptor, _super);
    function ImportedNamespaceTableDescriptor(addEditNamespaceModal, value, modelPrefix, modelNamespace, context, languageService, namespacesInUse) {
        var _this = _super.call(this) || this;
        _this.addEditNamespaceModal = addEditNamespaceModal;
        _this.value = value;
        _this.modelPrefix = modelPrefix;
        _this.modelNamespace = modelNamespace;
        _this.context = context;
        _this.languageService = languageService;
        _this.namespacesInUse = namespacesInUse;
        return _this;
    }
    ImportedNamespaceTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Prefix', nameExtractor: function (ns) { return ns.prefix; }, cssClass: 'prefix' },
            { headerName: 'Namespace label', nameExtractor: function (ns) { return _this.languageService.translate(ns.label, _this.context); } },
            { headerName: 'Namespace', nameExtractor: function (ns) { return ns.namespace; } }
        ];
    };
    ImportedNamespaceTableDescriptor.prototype.values = function () {
        return this.value && this.value.importedNamespaces;
    };
    ImportedNamespaceTableDescriptor.prototype.orderBy = function (ns) {
        return ns.prefix;
    };
    ImportedNamespaceTableDescriptor.prototype.edit = function (ns) {
        var reservedPrefixes = [this.modelPrefix()].concat(this.value.importedNamespaces.map(function (namespace) { return namespace.prefix; }).filter(function (prefix) { return ns.prefix !== prefix; }));
        var usedNamespaces = [this.modelNamespace()].concat(this.value.importedNamespaces.map(function (namespace) { return namespace.namespace; }).filter(function (namespace) { return ns.namespace !== namespace; }));
        this.addEditNamespaceModal.openEdit(this.context, ns, this.languageService.getModelLanguage(this.context), reservedPrefixes, usedNamespaces);
    };
    ImportedNamespaceTableDescriptor.prototype.remove = function (ns) {
        this.value.removeImportedNamespace(ns);
    };
    ImportedNamespaceTableDescriptor.prototype.canEdit = function (ns) {
        return ns.namespaceModifiable || ns.prefixModifiable || ns.labelModifiable;
    };
    ImportedNamespaceTableDescriptor.prototype.canRemove = function (ns) {
        if (this.namespacesInUse) {
            return !this.namespacesInUse.has(ns.id.uri);
        }
        return false;
    };
    return ImportedNamespaceTableDescriptor;
}(TableDescriptor));
