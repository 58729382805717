import { assertNever, isDefined } from 'yti-common-ui/utils/object';
import { firstMatching, contains } from 'yti-common-ui/utils/array';
export function init(instance, mappings) {
    var result = {};
    for (var _i = 0, _a = Object.entries(mappings); _i < _a.length; _i++) {
        var _b = _a[_i], propertyName = _b[0], mapping = _b[1];
        instance[propertyName] = initSingle(instance, mapping);
    }
    return result;
}
export function initSingle(instance, mapping) {
    function resolveValue(name) {
        if (Array.isArray(name)) {
            return firstMatching(name.map(function (n) { return instance.graph[n]; }), isDefined);
        }
        else {
            return instance.graph[name];
        }
    }
    try {
        switch (mapping.serializer.type) {
            case 'Normal':
                return mapping.serializer.deserialize(resolveValue(mapping.name));
            case 'EntityAware':
                return mapping.serializer.deserialize(resolveValue(mapping.name), instance);
            default:
                return assertNever(mapping.serializer, 'Unsupported serializer');
        }
    }
    catch (e) {
        console.log("Error while deserializing property " + mapping.name, instance);
        throw e;
    }
}
export function serialize(instance, clone, mappings, excludeMappings) {
    if (excludeMappings === void 0) { excludeMappings = []; }
    var result = {};
    var _loop_1 = function (propertyName, mapping) {
        if (!contains(excludeMappings, mapping)) {
            var propertyExtractor = function (i) { return i[propertyName]; };
            serializeSingle(result, instance, clone, propertyExtractor, mapping);
        }
    };
    for (var _i = 0, _a = Object.entries(mappings); _i < _a.length; _i++) {
        var _b = _a[_i], propertyName = _b[0], mapping = _b[1];
        _loop_1(propertyName, mapping);
    }
    return result;
}
export function serializeSingle(result, instance, clone, propertyExtractor, mapping) {
    if (Array.isArray(mapping.name)) {
        throw new Error('Cannot serialize unambiguous name: ' + mapping.name.join(','));
    }
    var value = propertyExtractor(instance);
    switch (mapping.serializer.type) {
        case 'Normal':
            result[mapping.name] = mapping.serializer.serialize(value);
            break;
        case 'EntityAware':
            result[mapping.name] = mapping.serializer.serialize(value, clone);
            break;
        default:
            assertNever(mapping.serializer, 'Unsupported serializer');
    }
}
