import * as angular from 'angular';
import { SearchNamespaceModal } from './searchNamespaceModal';
import { SearchVocabularyModal } from './searchVocabularyModal';
import { AddEditLinkModal } from './addEditLinkModal';
import { AddEditNamespaceModal } from './addEditNamespaceModal';
import { SearchReferenceDataModal } from './searchReferenceDataModal';
import { EditReferenceDataModal } from './editReferenceDataModal';
import { ViewReferenceDataModal } from './viewReferenceDataModal';
import { SearchClassificationModal } from './searchClassificationModal';
import { SearchOrganizationModal } from './searchOrganizationModal';
import { ModelFormComponent } from './modelForm';
import { ModelViewComponent } from './modelView';
import { ClassificationsViewComponent } from './classificationsView';
import { VocabulariesViewComponent } from './vocabulariesView';
import { ImportedNamespacesViewComponent } from './importedNamespacesView';
import { LinksViewComponent } from './linksView';
import { ReferenceDataViewComponent } from './referenceDataView';
import { ReferenceDatasViewComponent } from './referenceDatasView';
import { TechnicalNamespacesComponent } from './technicalNamespaces';
import { ContributorsViewComponent } from './contributorsView';
import { DividerComponent } from './divider';
import { EditableRootClassComponent } from './editableRootClass';
import { ConceptFormComponent } from './conceptForm';
import { ModelPageComponent } from './modelPage';
import { NewModelPageComponent } from './newModelPage';
import { NonEditableVocabularyComponent } from './nonEditableVocabulary';
import { NewDatamodelVersionPrefixModalFormComponent } from './newDatamodelVersionPrefixModalForm';

import { componentDeclaration } from 'app/utils/angular';
import { module as mod } from './module';
import { downgradeComponent } from '@angular/upgrade/static';
import { ModelMainComponent } from './modelMain';
import { ModelDocumentationComponent } from '../model-documentation/model-documentation.component';
export { module } from './module';

mod.component('editableRootClass', componentDeclaration(EditableRootClassComponent));
mod.component('conceptForm', componentDeclaration(ConceptFormComponent));
mod.component('divider', componentDeclaration(DividerComponent));
mod.component('modelPage', componentDeclaration(ModelPageComponent));
mod.component('newModelPage', componentDeclaration(NewModelPageComponent));
mod.component('modelForm', componentDeclaration(ModelFormComponent));
mod.component('modelView', componentDeclaration(ModelViewComponent));
mod.component('nonEditableVocabulary', componentDeclaration(NonEditableVocabularyComponent));
mod.component('vocabulariesView', componentDeclaration(VocabulariesViewComponent));
mod.component('importedNamespacesView', componentDeclaration(ImportedNamespacesViewComponent));
mod.component('linksView', componentDeclaration(LinksViewComponent));
mod.component('referenceDataView', componentDeclaration(ReferenceDataViewComponent));
mod.component('referenceDatasView', componentDeclaration(ReferenceDatasViewComponent));
mod.component('technicalNamespaces', componentDeclaration(TechnicalNamespacesComponent));
mod.component('classificationsView', componentDeclaration(ClassificationsViewComponent));
mod.component('contributorsView', componentDeclaration(ContributorsViewComponent));
mod.component('newDatamodelVersionPrefixModalForm', componentDeclaration(NewDatamodelVersionPrefixModalFormComponent));

mod.service('addEditLinkModal', AddEditLinkModal);
mod.service('addEditNamespaceModal', AddEditNamespaceModal);
mod.service('searchNamespaceModal', SearchNamespaceModal);
mod.service('searchVocabularyModal', SearchVocabularyModal);
mod.service('searchReferenceDataModal', SearchReferenceDataModal);
mod.service('editReferenceDataModal', EditReferenceDataModal);
mod.service('viewReferenceDataModal', ViewReferenceDataModal);
mod.service('searchClassificationModal', SearchClassificationModal);
mod.service('searchOrganizationModal', SearchOrganizationModal);

mod.directive('modelMain', downgradeComponent({component: ModelMainComponent}) as angular.IDirectiveFactory);
