import { v4 as uuid } from 'uuid';
import { isDefined } from 'yti-common-ui/utils/object';
var urnPrefix = 'urn:';
var uuidUrnPrefix = urnPrefix + 'uuid:';
var Uri = /** @class */ (function () {
    function Uri(value, context) {
        this.value = value;
        this.context = context;
        if (!value) {
            throw new Error('Empty uri');
        }
        if (!context) {
            throw new Error('Context not defined');
        }
        if (typeof value !== 'string') {
            throw new Error('Uri value must be string: \n' + JSON.stringify(value, null, 2));
        }
    }
    Uri.fromUUID = function (uuidStr) {
        return new Uri(uuidUrnPrefix + uuidStr, {});
    };
    Uri.randomUUID = function () {
        return new Uri(uuidUrnPrefix + uuid(), {});
    };
    Object.defineProperty(Uri.prototype, "uuid", {
        get: function () {
            if (this.isUuid()) {
                return this.value.substr(uuidUrnPrefix.length, this.value.length);
            }
            else {
                throw new Error('Uri is not an uuid urn: ' + this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Uri.prototype.isUuid = function () {
        return this.value.startsWith(uuidUrnPrefix);
    };
    Uri.prototype.isUrn = function () {
        return this.value.startsWith(urnPrefix);
    };
    Uri.prototype.isUrl = function () {
        return !this.isUrn();
    };
    Object.defineProperty(Uri.prototype, "curie", {
        get: function () {
            if (this.isUrn()) {
                throw new Error('URN can not be converted to curie: ' + this.value);
            }
            else {
                var resolved = this.resolve();
                if (resolved) {
                    return formatCurie(resolved);
                }
                else {
                    throw new Error('Cannot resolve curie: ' + this.value);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "name", {
        get: function () {
            if (this.isUrn()) {
                throw new Error('URN does not have name: ' + this.value);
            }
            else {
                var resolved = this.resolve();
                if (resolved) {
                    return resolved.name;
                }
                var nonResolvingNamespaceSplit = splitNamespace(this.value);
                if (nonResolvingNamespaceSplit) {
                    return nonResolvingNamespaceSplit.name;
                }
                throw new Error('Cannot resolve namespace: ' + this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "namespaceId", {
        get: function () {
            return this.namespace.replace(/[#/]$/, '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "namespace", {
        get: function () {
            if (this.isUrn()) {
                throw new Error('URN does not have namespace: ' + this.value);
            }
            else {
                var resolved = this.resolve();
                if (resolved) {
                    return this.context[resolved.prefix];
                }
                var nonResolvingNamespaceSplit = splitNamespace(this.value);
                if (nonResolvingNamespaceSplit) {
                    return nonResolvingNamespaceSplit.namespaceId + nonResolvingNamespaceSplit.separator;
                }
                throw new Error('Cannot resolve namespace: ' + this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "compact", {
        get: function () {
            var resolved = this.resolve();
            if (resolved) {
                return formatCurie(resolved);
            }
            else {
                return this.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "uri", {
        get: function () {
            var resolved = this.resolve();
            if (resolved) {
                return this.context[resolved.prefix].startsWith(urnPrefix) ? this.value : this.context[resolved.prefix] + resolved.name;
            }
            else {
                return this.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "url", {
        get: function () {
            if (this.isUrn()) {
                return null;
            }
            else {
                return this.uri;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Uri.prototype, "urn", {
        get: function () {
            if (this.isUrn()) {
                return this.value;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Uri.prototype.toString = function () {
        return this.uri;
    };
    Uri.prototype.withName = function (name) {
        if (this.isUrn()) {
            throw new Error('Cannot set name for urn: ' + this.value);
        }
        else {
            var resolved = this.resolve();
            if (resolved) {
                return new Uri(formatCurie({ prefix: resolved.prefix, name: name }), this.context);
            }
            var nonResolvingNamespaceSplit = splitNamespace(this.value);
            if (nonResolvingNamespaceSplit) {
                return new Uri(nonResolvingNamespaceSplit.namespaceId + nonResolvingNamespaceSplit.separator + name, this.context);
            }
            throw new Error('Uri does not resolve: ' + this.value);
        }
    };
    Uri.prototype.equals = function (other) {
        return !!other && this.uri === other.uri;
    };
    Uri.prototype.notEquals = function (other) {
        return !this.equals(other);
    };
    Uri.prototype.resolve = function () {
        if (this.isUrn()) {
            return null;
        }
        else {
            var split = splitCurie(this.value);
            // if used curie (e.g. ns:ClassName), the name cannot contain # separator
            if (split && this.context[split.prefix] && split.name.indexOf("#") === -1) {
                return split;
            }
            var ns = splitNamespace(this.value);
            if (ns) {
                for (var _i = 0, _a = Object.keys(this.context); _i < _a.length; _i++) {
                    var prefix = _a[_i];
                    var value = this.context[prefix];
                    if (ns && ns.namespaceId + ns.separator === value) {
                        return { prefix: prefix, name: ns.name };
                    }
                }
            }
            return null;
        }
    };
    Uri.prototype.resolves = function () {
        return isDefined(this.resolve());
    };
    return Uri;
}());
export { Uri };
function formatCurie(resolved) {
    return resolved.prefix + ':' + resolved.name;
}
function splitCurie(curie) {
    var parts = curie.split(':');
    if (parts.length === 2) {
        return { prefix: parts[0], name: parts[1] };
    }
    else {
        return null;
    }
}
function splitNamespace(id) {
    var hashParts = id.split('#');
    if (hashParts.length === 2) {
        return { namespaceId: hashParts[0], name: hashParts[1], separator: '#' };
    }
    else {
        var lastSlash = id.lastIndexOf('/');
        if (lastSlash !== -1) {
            return { namespaceId: id.substr(0, lastSlash), name: id.substr(lastSlash + 1, id.length - 1), separator: '/' };
        }
        else {
            return null;
        }
    }
}
