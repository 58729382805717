var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { contextlessInternalUrl } from 'app/utils/entity';
import { DefinedBy } from './definedBy';
import { init } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareOptional, entity } from './serializer/entitySerializer';
import { localizableSerializer, optional, stringSerializer } from './serializer/serializer';
var SearchResult = /** @class */ (function (_super) {
    __extends(SearchResult, _super);
    function SearchResult(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, SearchResult.searchResultMapping);
        return _this;
    }
    SearchResult.prototype.iowUrl = function () {
        return contextlessInternalUrl(this);
    };
    SearchResult.searchResultMapping = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        comment: { name: 'comment', serializer: localizableSerializer },
        prefix: { name: 'preferredXMLNamespacePrefix', serializer: optional(stringSerializer) },
        definedBy: { name: 'isDefinedBy', serializer: entityAwareOptional(entity(function () { return DefinedBy; })) }
    };
    return SearchResult;
}(GraphNode));
export { SearchResult };
