var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ifChanged, LegacyComponent } from 'app/utils/angular';
var ExcludedFilterComponent = /** @class */ (function () {
    function ExcludedFilterComponent($scope) {
        'ngInject';
        this.$scope = $scope;
    }
    ExcludedFilterComponent.prototype.$onInit = function () {
        var _this = this;
        this.searchController.addFilter(function (item) {
            return _this.showExcluded || !_this.exclude(item.item);
        });
        this.$scope.$watch(function () { return _this.exclude; }, ifChanged(function () { return _this.searchController.search(); }));
    };
    Object.defineProperty(ExcludedFilterComponent.prototype, "showExcluded", {
        get: function () {
            return !!this.searchText;
        },
        enumerable: true,
        configurable: true
    });
    ExcludedFilterComponent = __decorate([
        LegacyComponent({
            bindings: {
                searchController: '=',
                exclude: '=',
                searchText: '='
            }
        }),
        __metadata("design:paramtypes", [Object])
    ], ExcludedFilterComponent);
    return ExcludedFilterComponent;
}());
export { ExcludedFilterComponent };
