import { RelatedPredicate } from '../../services/predicateService';
import { infoDomainMatches } from '../../utils/entity';
import { contains } from 'yti-common-ui/utils/array';
import { combineExclusions, createExistsExclusion, createDefinedByExclusion } from '../../utils/exclusion';
import { PredicateListItem, Predicate } from '../../entities/predicate';
import { filterAndSortSearchResults, defaultLabelComparator } from '../../components/filter/util';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { ifChanged, modalCancelHandler } from '../../utils/angular';
import { comparingLocalizable } from '../../utils/comparator';
import { selectableStatuses } from 'yti-common-ui/entities/status';
export var noPredicateExclude = function (_item) { return null; };
var SearchPredicateTableModal = /** @class */ (function () {
    function SearchPredicateTableModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchPredicateTableModal.prototype.openModal = function (model, type, exclude, filterExclude, predicatesAssignedToModel) {
        return this.$uibModal.open({
            template: require('./searchPredicateTableModal.html'),
            size: 'xl',
            windowClass: 'modal-full-height',
            controller: SearchPredicateTableController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                model: function () { return model; },
                type: function () { return type; },
                exclude: function () { return exclude; },
                filterExclude: function () { return filterExclude; },
                predicatesAssignedToModel: function () { return predicatesAssignedToModel; }
            }
        }).result;
    };
    SearchPredicateTableModal.prototype.openAddPredicate = function (model, type, exclude, filterExclude, predicatesAssignedToModel) {
        if (exclude === void 0) { exclude = noPredicateExclude; }
        if (filterExclude === void 0) { filterExclude = exclude; }
        return this.openModal(model, type, exclude, filterExclude, predicatesAssignedToModel);
    };
    return SearchPredicateTableModal;
}());
export { SearchPredicateTableModal };
var SearchPredicateTableController = /** @class */ (function () {
    function SearchPredicateTableController($scope, $uibModalInstance, model, type, exclude, filterExclude, predicatesAssignedToModel, predicateService, languageService, searchConceptModal, gettextCatalog, displayItemFactory, classificationService, showPredicateInfoModal, modelService) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.model = model;
        this.type = type;
        this.exclude = exclude;
        this.filterExclude = filterExclude;
        this.predicatesAssignedToModel = predicatesAssignedToModel;
        this.predicateService = predicateService;
        this.searchConceptModal = searchConceptModal;
        this.gettextCatalog = gettextCatalog;
        this.displayItemFactory = displayItemFactory;
        this.showPredicateInfoModal = showPredicateInfoModal;
        this.modelService = modelService;
        this.predicates = [];
        this.searchResults = [];
        this.searchText = '';
        this.cannotConfirm = null;
        this.contentMatchers = [
            { name: 'Label', extractor: function (predicate) { return predicate.label; } },
            { name: 'Description', extractor: function (predicate) { return predicate.comment; } },
            { name: 'Identifier', extractor: function (predicate) { return predicate.id.compact; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.searchFilters = [];
        this.localizer = languageService.createLocalizer(model);
        this.loadingResults = true;
        this.typeSelectable = !type;
        this.modelTypes = ['library', 'profile'];
        this.showModelType = 'library';
        this.sortBy = {
            name: 'name',
            comparator: defaultLabelComparator(this.localizer, this.filterExclude),
            descOrder: false
        };
        var sortInfoDomains = function () {
            _this.infoDomains.sort(comparingLocalizable(_this.localizer, function (infoDomain) { return infoDomain.label; }));
        };
        classificationService.getClassifications().then(function (infoDomains) {
            modelService.getModels().then(function (models) {
                var modelCount = function (infoDomain) {
                    return models.filter(function (mod) { return infoDomainMatches(infoDomain, mod); }).length;
                };
                _this.infoDomains = infoDomains.filter(function (infoDomain) { return modelCount(infoDomain) > 0; });
                sortInfoDomains();
            });
        });
        var appendResults = function (predicates) {
            _this.predicates = _this.predicates.concat(predicates);
            _this.search();
            _this.loadingResults = false;
        };
        predicateService.getAllPredicates(model).then(appendResults);
        this.addFilter(function (predicateListItem) {
            return !_this.showInfoDomain || contains(predicateListItem.item.definedBy.classifications.map(function (classification) { return classification.identifier; }), _this.showInfoDomain.identifier);
        });
        this.addFilter(function (predicateListItem) {
            return !_this.showStatus || predicateListItem.item.status === _this.showStatus;
        });
        this.addFilter(function (predicateListItem) {
            return !_this.showModelType || predicateListItem.item.definedBy.normalizedType === _this.showModelType;
        });
        $scope.$watch(function () { return _this.showInfoDomain; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showModelType; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showStatus; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.sortBy.name; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.sortBy.descOrder; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return languageService.getModelLanguage(model); }, ifChanged(function () {
            sortInfoDomains();
            _this.search();
        }));
    }
    SearchPredicateTableController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    Object.defineProperty(SearchPredicateTableController.prototype, "items", {
        get: function () {
            return this.predicates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchPredicateTableController.prototype, "statuses", {
        get: function () {
            return selectableStatuses;
        },
        enumerable: true,
        configurable: true
    });
    SearchPredicateTableController.prototype.isSelectionPredicate = function () {
        return this.selection instanceof Predicate;
    };
    SearchPredicateTableController.prototype.search = function () {
        this.removeSelection();
        this.searchResults = filterAndSortSearchResults(this.predicates, this.searchText, this.contentExtractors, this.searchFilters, this.sortBy.comparator, 0).slice();
    };
    SearchPredicateTableController.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        this.cannotConfirm = this.exclude(item);
        this.predicateService.getPredicate(item.id, this.model).then(function (result) { return _this.selection = result; });
    };
    SearchPredicateTableController.prototype.removeSelection = function () {
        this.selection = null;
        this.selectedItem = null;
        this.cannotConfirm = null;
    };
    SearchPredicateTableController.prototype.isSelected = function (item) {
        return this.selectedItem === item;
    };
    SearchPredicateTableController.prototype.loadingSelection = function (item) {
        var selection = this.selection;
        if (item instanceof PredicateListItem) {
            return item === this.selectedItem && (!selection || (selection instanceof Predicate && !item.id.equals(selection.id)));
        }
        else {
            return false;
        }
    };
    SearchPredicateTableController.prototype.confirm = function () {
        var selection = this.selection;
        if (selection instanceof Predicate) {
            this.$uibModalInstance.close(selection);
        }
        else {
            throw new Error('Unsupported selection: ' + selection);
        }
    };
    SearchPredicateTableController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    SearchPredicateTableController.prototype.createNew = function (type) {
        var _this = this;
        return this.searchConceptModal.openNewEntityCreation(this.model.vocabularies, this.model, type, this.searchText)
            .then(function (result) {
            if (!_this.typeSelectable) {
                _this.$uibModalInstance.close(result);
            }
            else {
                _this.predicateService.newPredicate(_this.model, result.entity.label, result.conceptId, type, _this.localizer.language)
                    .then(function (predicate) {
                    _this.cannotConfirm = null;
                    _this.selection = predicate;
                    _this.$scope.form.editing = true;
                });
            }
        }, ignoreModalClose);
    };
    SearchPredicateTableController.prototype.isEditing = function () {
        return this.$scope.form && this.$scope.form.editing;
    };
    SearchPredicateTableController.prototype.isAttributeAddable = function () {
        return !!this.searchText && (this.typeSelectable || this.type === 'attribute');
    };
    SearchPredicateTableController.prototype.isAssociationAddable = function () {
        return !!this.searchText && (this.typeSelectable || this.type === 'association');
    };
    SearchPredicateTableController.prototype.canAddNew = function () {
        return this.isAttributeAddable() || this.isAssociationAddable();
    };
    SearchPredicateTableController.prototype.showItemValue = function (value) {
        var _this = this;
        return this.displayItemFactory.create({
            context: function () { return _this.model; },
            value: function () { return value; }
        }).displayValue;
    };
    SearchPredicateTableController.prototype.generateSearchResultID = function (item) {
        return "" + item.id.toString() + '_search_predicate_link';
    };
    SearchPredicateTableController.prototype.showPredicateInfo = function () {
        return this.showPredicateInfoModal.open(this.model, this.selection).then(null, modalCancelHandler);
    };
    SearchPredicateTableController.prototype.copyPredicate = function (item) {
        this.$uibModalInstance.close(new RelatedPredicate(item.id, 'prov:wasDerivedFrom'));
    };
    SearchPredicateTableController.prototype.createSubPredicate = function (item) {
        this.$uibModalInstance.close(new RelatedPredicate(item.id, 'rdfs:subPropertyOf'));
    };
    SearchPredicateTableController.prototype.createSuperPredicate = function (item) {
        this.$uibModalInstance.close(new RelatedPredicate(item.id, 'iow:superPropertyOf'));
    };
    SearchPredicateTableController.prototype.itemTitle = function (item) {
        var disabledReason = this.exclude(item);
        if (!!disabledReason) {
            return this.gettextCatalog.getString(disabledReason);
        }
        else {
            return null;
        }
    };
    SearchPredicateTableController.prototype.addNamespaceToModel = function (item) {
        var _this = this;
        this.modelService.newModelRequirement(this.model, item.id.uri).then(function (response) {
            _this.modelService.getModelByPrefix(_this.model.prefix).then(function (model) {
                _this.model.importedNamespaces = model.importedNamespaces;
                _this.model.context = model.context;
                _this.exclude = combineExclusions(createExistsExclusion(_this.predicatesAssignedToModel), createDefinedByExclusion(_this.model));
                _this.cannotConfirm = _this.exclude(item);
                _this.selectItem(item);
            });
        });
    };
    SearchPredicateTableController.prototype.notDefinedByThisModel = function (item) {
        var definedByExclude = createDefinedByExclusion(this.model);
        return item && !!this.exclude(item) ? this.exclude(item) === definedByExclude(item) : false;
    };
    return SearchPredicateTableController;
}());
