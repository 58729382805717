import { flatten } from 'yti-common-ui/utils/array';
import { requireDefined } from 'yti-common-ui/utils/object';
var ResourceStore = /** @class */ (function () {
    function ResourceStore() {
        this.resources = new Map();
    }
    ResourceStore.prototype.values = function () {
        return Array.from(this.resources.values());
    };
    ResourceStore.prototype.entries = function () {
        return Array.from(this.resources.entries());
    };
    ResourceStore.prototype.find = function (id) {
        return this.resources.get(id);
    };
    ResourceStore.prototype.get = function (id) {
        return requireDefined(this.find(id));
    };
    ResourceStore.prototype.add = function (resource) {
        this.resources.set(resource.id.uri, resource);
        return resource;
    };
    ResourceStore.prototype.delete = function (id) {
        return this.resources.delete(id);
    };
    ResourceStore.prototype.findFirst = function (predicate) {
        return Array.from(this.resources.values()).find(predicate);
    };
    ResourceStore.prototype.findAll = function (predicate) {
        return Array.from(this.resources.values()).filter(predicate);
    };
    ResourceStore.prototype.clear = function () {
        this.resources.clear();
    };
    return ResourceStore;
}());
export { ResourceStore };
var ModelResourceStore = /** @class */ (function () {
    function ModelResourceStore($q) {
        this.$q = $q;
        this.resources = new Map();
        this.assignedResources = new Map();
        this.externalResources = new Map();
    }
    ModelResourceStore.prototype.getResourcesForAllModels = function () {
        return ModelResourceStore.createMapFromEntries(this.getAllResourceEntries());
    };
    ModelResourceStore.prototype.resourceExistsInAnyModel = function (id) {
        return this.getResourcesForAllModels().has(id);
    };
    ModelResourceStore.prototype.getResourceValuesForAllModels = function () {
        return Array.from(this.getResourcesForAllModels().values());
    };
    ModelResourceStore.prototype.getAllResourceEntries = function () {
        return flatten(Array.from(this.resources.values()).map(function (s) { return s.entries(); }));
    };
    ModelResourceStore.prototype.getResourcesForModel = function (model) {
        var store = this.resources.get(model.id.uri);
        if (!store) {
            store = new ResourceStore();
            this.resources.set(model.id.uri, store);
        }
        return store;
    };
    ModelResourceStore.prototype.getExternalResourcesForNamespace = function (ns) {
        return requireDefined(this.externalResources.get(ns)).values();
    };
    ModelResourceStore.prototype.findExternalResource = function (id) {
        var uri = id.uri;
        for (var _i = 0, _a = Array.from(this.externalResources.entries()); _i < _a.length; _i++) {
            var _b = _a[_i], ns = _b[0], store = _b[1];
            if (uri.startsWith(ns)) {
                return store.get(uri);
            }
        }
        return null;
    };
    ModelResourceStore.prototype.getAssignedResourcesIdsForModel = function (model) {
        var resourceSet = this.assignedResources.get(model.id.uri);
        if (!resourceSet) {
            var newSet = new Set();
            this.assignedResources.set(model.id.uri, newSet);
            return newSet;
        }
        else {
            return resourceSet;
        }
    };
    ModelResourceStore.prototype.getAssignedResourcesForModel = function (model) {
        var assignedIds = this.getAssignedResourcesIdsForModel(model);
        var allResources = this.getAllResourceEntries();
        var assignedResourcesFromStore = ModelResourceStore.createMapFromEntries(allResources.filter(function (_a) {
            var id = _a[0];
            return assignedIds.has(id);
        }));
        return this.$q.when(assignedResourcesFromStore);
    };
    ModelResourceStore.prototype.getAllResourcesForModel = function (model) {
        var _this = this;
        return this.getAssignedResourcesForModel(model)
            .then(function (assignedResources) { return ModelResourceStore.createMapFromEntries(assignedResources.entries(), _this.getResourcesForModel(model).entries()); });
    };
    ModelResourceStore.prototype.getAllResourceValuesForModel = function (model) {
        return this.getAllResourcesForModel(model).then(function (resources) { return Array.from(resources.values()); });
    };
    ModelResourceStore.prototype.getResourceForAnyModelById = function (id) {
        return requireDefined(this.getResourcesForAllModels().get(id.toString()));
    };
    ModelResourceStore.prototype.getInternalOrExternalResource = function (id) {
        var internal = this.getResourcesForAllModels().get(id.toString());
        if (internal) {
            return internal;
        }
        else {
            return this.findExternalResource(id);
        }
    };
    ModelResourceStore.prototype.assignResourceToModel = function (model, id) {
        this.getAssignedResourcesIdsForModel(model).add(id);
    };
    ModelResourceStore.prototype.addResourceToModel = function (model, resource) {
        this.getResourcesForModel(model).add(resource);
    };
    ModelResourceStore.prototype.updateResourceInModel = function (model, resource, originalId) {
        this.deleteResourceFromModel(model, originalId);
        this.getResourcesForModel(model).add(resource);
    };
    ModelResourceStore.prototype.deleteResourceFromModel = function (model, resourceId) {
        this.getResourcesForModel(model).delete(resourceId);
        this.getAssignedResourcesIdsForModel(model).delete(resourceId);
    };
    ModelResourceStore.createMapFromEntries = function () {
        var entries = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            entries[_i] = arguments[_i];
        }
        return new Map(flatten(entries.map(function (e) { return Array.from(e); })));
    };
    ModelResourceStore.prototype.clear = function () {
        this.resources.clear();
        this.assignedResources.clear();
    };
    return ModelResourceStore;
}());
export { ModelResourceStore };
