var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LegacyComponent } from 'app/utils/angular';
import { assertNever, requireDefined } from 'yti-common-ui/utils/object';
import { arrowHeight, elementPositioning, isInWindow, isVisible, popupAnimationTimeInMs, resolveArrowClass } from 'app/help/utils/component';
var InteractiveHelpPopoverComponent = /** @class */ (function () {
    function InteractiveHelpPopoverComponent($scope, $document, gettextCatalog) {
        'ngInject';
        this.$scope = $scope;
        this.$document = $document;
        this.gettextCatalog = gettextCatalog;
        this.arrowClass = [];
    }
    InteractiveHelpPopoverComponent_1 = InteractiveHelpPopoverComponent;
    InteractiveHelpPopoverComponent.prototype.$onInit = function () {
        var _this = this;
        this.helpController.registerPopover(this);
        this.$scope.$watch(function () { return _this.item; }, function (item) { return _this.arrowClass = resolveArrowClass(item); });
    };
    Object.defineProperty(InteractiveHelpPopoverComponent.prototype, "localizedTitle", {
        get: function () {
            if (this.title) {
                return this.gettextCatalog.getString(this.title.key, this.title.context);
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveHelpPopoverComponent.prototype, "localizedContent", {
        get: function () {
            if (this.content) {
                return this.gettextCatalog.getString(this.content.key, this.content.context);
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    InteractiveHelpPopoverComponent.prototype.setPositioning = function (positioning) {
        var _this = this;
        var item = requireDefined(this.item);
        if (isInWindow(positioning)) {
            this.positioning = positioning;
            // apply positioning before applying content, content is applied in the middle of animation
            setTimeout(function () {
                _this.$scope.$apply(function () {
                    _this.title = item.title;
                    _this.content = item.content;
                    _this.showNext = _this.helpController.showNext;
                    _this.showPrevious = _this.helpController.showPrevious;
                    _this.showClose = _this.helpController.showClose;
                });
            }, popupAnimationTimeInMs / 2);
        }
    };
    InteractiveHelpPopoverComponent.prototype.style = function () {
        return this.positioning;
    };
    InteractiveHelpPopoverComponent.prototype.calculatePositioning = function (item) {
        var popoverDimensions = this.helpController.getPopoverDimensions();
        var documentDimensions = { width: this.$document.width(), height: this.$document.height() };
        switch (item.type) {
            case 'story':
                return InteractiveHelpPopoverComponent_1.calculateStoryPositioning(item, popoverDimensions, documentDimensions);
            case 'notification':
                return InteractiveHelpPopoverComponent_1.calculateNotificationPositioning(popoverDimensions);
            default:
                return assertNever(item, 'Unknown item type');
        }
    };
    InteractiveHelpPopoverComponent.calculateNotificationPositioning = function (popoverDimensions) {
        return InteractiveHelpPopoverComponent_1.calculateCenterPositioning(popoverDimensions);
    };
    InteractiveHelpPopoverComponent.calculateCenterPositioning = function (popoverDimensions) {
        return {
            top: window.innerHeight / 2 - popoverDimensions.height / 2,
            left: window.innerWidth / 2 - popoverDimensions.width / 2,
            width: popoverDimensions.width
        };
    };
    InteractiveHelpPopoverComponent.calculateStoryPositioning = function (story, popoverDimensions, documentDimensions) {
        var element = story.popover.element();
        if (!element || element.length === 0 || !isVisible(element[0])) {
            return null;
        }
        var popoverWidth = popoverDimensions.width;
        var popoverHeight = popoverDimensions.height;
        var destination = elementPositioning(element);
        var documentWidth = documentDimensions.width;
        var documentHeight = documentDimensions.height;
        function calculateUnrestricted() {
            switch (story.popover.position) {
                case 'left-down':
                    return { top: destination.top, left: destination.left - popoverWidth - arrowHeight, width: popoverWidth, height: popoverHeight };
                case 'left-up':
                    return { top: destination.bottom - popoverHeight, left: destination.left - popoverWidth - arrowHeight, width: popoverWidth, height: popoverHeight };
                case 'right-down':
                    return { top: destination.top, left: destination.right + arrowHeight, width: popoverWidth, height: popoverHeight };
                case 'right-up':
                    return { top: destination.bottom - popoverHeight, left: destination.right + arrowHeight, width: popoverWidth, height: popoverHeight };
                case 'top-right':
                    return { top: destination.top - popoverHeight - arrowHeight, left: destination.left, width: popoverWidth, height: popoverHeight };
                case 'top-left':
                    return { top: destination.top - popoverHeight - arrowHeight, left: destination.right - popoverWidth, width: popoverWidth, height: popoverHeight };
                case 'bottom-right':
                    return { top: destination.bottom + arrowHeight, left: destination.left, width: popoverWidth, height: popoverHeight };
                case 'bottom-left':
                    return { top: destination.bottom + arrowHeight, left: destination.right - popoverWidth, width: popoverWidth, height: popoverHeight };
                default:
                    return assertNever(story.popover.position, 'Unsupported popover position');
            }
        }
        function cropToWindow(position) {
            var newLeft = position.left;
            var newTop = position.top;
            var newWidth = position.width;
            var newHeight = position.height;
            if (newLeft < 0) {
                newWidth += newLeft;
                newLeft = 0;
                newHeight = undefined; // allow to expand
            }
            else if (newTop < 0) {
                newHeight += newTop;
                newTop = 0;
                newWidth = undefined; // allow to expand
            }
            if (newWidth) {
                var right = newLeft + newWidth;
                if (right > documentWidth) {
                    newWidth += documentWidth - right;
                    newLeft = documentWidth - newWidth;
                    newHeight = undefined; // allow to expand
                }
            }
            if (newHeight) {
                var bottom = newTop + newHeight;
                if (bottom > documentHeight) {
                    newHeight += documentHeight - bottom;
                    newTop = documentHeight - newHeight;
                    newWidth = undefined; // allow to expand
                }
            }
            return { left: newLeft, top: newTop, width: newWidth, height: newHeight };
        }
        return cropToWindow(calculateUnrestricted());
    };
    InteractiveHelpPopoverComponent = InteractiveHelpPopoverComponent_1 = __decorate([
        LegacyComponent({
            bindings: {
                item: '<',
                helpController: '<'
            },
            template: "\n        <span ng-class=\"$ctrl.arrowClass\"></span>\n      \n        <div class=\"help-content-wrapper\">\n          <h3 ng-show=\"$ctrl.title\">{{$ctrl.localizedTitle}}</h3>\n          <p ng-show=\"$ctrl.content\">{{$ctrl.localizedContent}}</p>\n          \n          <button id=\"help_popover_previous_button\"\n                  ng-show=\"$ctrl.showPrevious\"\n                  ng-disabled=\"!$ctrl.helpController.canMoveToPrevious()\"\n                  ng-click=\"$ctrl.helpController.moveToPreviousItem()\"\n                  class=\"small button help-navigate\" translate>previous</button>\n                  \n          <button id=\"help_popover_next_button\"\n                  ng-show=\"$ctrl.showNext\"\n                  ng-disabled=\"!$ctrl.helpController.canMoveToNext()\"\n                  ng-click=\"$ctrl.helpController.tryToMoveToNextItem()\"\n                  class=\"small button help-navigate\" translate>next</button>\n                  \n          <button id=\"help_popover_close_button\"\n                  ng-show=\"$ctrl.showClose\"\n                  ng-disabled=\"!$ctrl.helpController.canMoveToNext()\"\n                  ng-click=\"$ctrl.helpController.close(false)\"\n                  class=\"small button help-next\" translate>close</button>\n                  \n          <a id=\"help_popover_close_link\" ng-click=\"$ctrl.helpController.close(true)\" class=\"help-close\">&times;</a>\n        </div>\n  "
        }),
        __metadata("design:paramtypes", [Object, Object, Object])
    ], InteractiveHelpPopoverComponent);
    return InteractiveHelpPopoverComponent;
    var InteractiveHelpPopoverComponent_1;
}());
export { InteractiveHelpPopoverComponent };
