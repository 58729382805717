import { isDefined } from 'yti-common-ui/utils/object';
var technicalNamespaces = {
    dcap: 'http://purl.org/ws-mmi-dc/terms/',
    schema: 'http://schema.org/',
    void: 'http://rdfs.org/ns/void#',
    adms: 'http://www.w3.org/ns/adms#',
    owl: 'http://www.w3.org/2002/07/owl#',
    dcam: 'http://purl.org/dc/dcam/',
    skosxl: 'http://www.w3.org/2008/05/skos-xl#',
    xsd: 'http://www.w3.org/2001/XMLSchema#',
    afn: 'http://jena.hpl.hp.com/ARQ/function#',
    skos: 'http://www.w3.org/2004/02/skos/core#',
    rdfs: 'http://www.w3.org/2000/01/rdf-schema#',
    iow: 'http://uri.suomi.fi/datamodel/ns/iow#',
    sd: 'http://www.w3.org/ns/sparql-service-description#',
    at: 'http://publications.europa.eu/ontology/authority/',
    rdf: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#',
    sh: 'http://www.w3.org/ns/shacl#',
    dcterms: 'http://purl.org/dc/terms/',
    text: 'http://jena.apache.org/text#',
    prov: 'http://www.w3.org/ns/prov#',
    termed: 'http://termed.thl.fi/meta/',
    foaf: 'http://xmlns.com/foaf/0.1/',
    dc: 'http://purl.org/dc/elements/1.1/',
    ts: 'http://www.w3.org/2003/06/sw-vocab-status/ns#',
    httpv: 'http://www.w3.org/2011/http#'
};
var ɵ0 = function (prefix) { return !technicalNamespaces[prefix]; };
var forbiddenPrefixes = ['xsd', 'iow', 'text', 'sh', 'afn', 'schema', 'dcap', 'termed', 'abstract', 'and', 'andCond', 'class',
    'classIn', 'codeLists', 'comment', 'contributor', 'constraint', 'context', 'created', 'creator', 'datatype', 'defaultValue', 'definition',
    'description', 'editorialNote', 'equivalentClass', 'equivalentProperty', 'example', 'first', 'graph', 'hasPart', 'hasValue', 'homepage',
    'id', 'identifier', 'imports', 'inScheme', 'inValues', 'isDefinedBy', 'isPartOf', 'isResourceIdentifier', 'isXmlAttribute',
    'isXmlWrapper', 'last', 'label', 'language', 'languageIn', 'localName', 'maxCount', 'maxLength', 'memberOf', 'minCount', 'name', 'node',
    'nodeKind', 'not', 'notCond', 'or', 'order', 'orCond', 'path', 'pattern', 'pointXY', 'preferredXMLNamespaceName', 'preferredXMLNamespacePrefix',
    'prefLabel', 'property', 'predicate', 'range', 'readOnlyValue', 'references', 'relations', 'requires', 'rootResource', 'rest', 'stem',
    'subClassOf', 'subject', 'subPropertyOf', 'targetClass', 'title', 'type', 'uniqueLang', 'useContext', 'uri', 'versionInfo', 'vertexXY',
    'xor', 'urn', 'http', 'https'].filter(ɵ0);
var AddEditNamespaceModal = /** @class */ (function () {
    function AddEditNamespaceModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    AddEditNamespaceModal.prototype.openAdd = function (context, language, reservedPrefixes, usedNamespaces) {
        if (usedNamespaces === void 0) { usedNamespaces = []; }
        return this.open(context, language, null, this.concatPrefixes(reservedPrefixes), usedNamespaces);
    };
    AddEditNamespaceModal.prototype.openEdit = function (context, require, language, reservedPrefixes, usedNamespaces) {
        if (usedNamespaces === void 0) { usedNamespaces = []; }
        return this.open(context, language, require, this.concatPrefixes(reservedPrefixes), usedNamespaces);
    };
    AddEditNamespaceModal.prototype.concatPrefixes = function (reservedPrefixes) {
        if (reservedPrefixes) {
            return reservedPrefixes.concat(forbiddenPrefixes);
        }
        return forbiddenPrefixes;
    };
    AddEditNamespaceModal.prototype.open = function (context, language, namespaceToEdit, reservedPrefixes, usedNamespaces) {
        return this.$uibModal.open({
            template: require('./addEditNamespaceModal.html'),
            size: 'sm',
            controller: AddEditNamespaceController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                context: function () { return context; },
                language: function () { return language; },
                namespaceToEdit: function () { return namespaceToEdit; },
                reservedPrefixes: function () { return reservedPrefixes; },
                usedNamespaces: function () { return usedNamespaces; }
            }
        }).result;
    };
    return AddEditNamespaceModal;
}());
export { AddEditNamespaceModal };
var AddEditNamespaceController = /** @class */ (function () {
    function AddEditNamespaceController($uibModalInstance, $scope, context, language, namespaceToEdit, reservedPrefixes, usedNamespaces, modelService) {
        'ngInject';
        var _this = this;
        this.$uibModalInstance = $uibModalInstance;
        this.context = context;
        this.language = language;
        this.namespaceToEdit = namespaceToEdit;
        this.reservedPrefixes = reservedPrefixes;
        this.usedNamespaces = usedNamespaces;
        this.modelService = modelService;
        this.namespaceBeforeForced = null;
        this.prefixBeforeForced = null;
        this.edit = !!namespaceToEdit;
        if (namespaceToEdit) {
            this.namespace = namespaceToEdit.namespace;
            this.prefix = namespaceToEdit.prefix;
            this.label = namespaceToEdit.label[language];
        }
        $scope.$watch(function () { return _this.prefix; }, function () {
            if (_this.prefixModifiable()) {
                var namespaceOverrideWasOn = isDefined(_this.namespaceBeforeForced);
                var namespaceOverrideSwitchedOn = false;
                for (var _i = 0, _a = Object.entries(technicalNamespaces); _i < _a.length; _i++) {
                    var _b = _a[_i], prefix = _b[0], ns = _b[1];
                    if (prefix === _this.prefix) {
                        namespaceOverrideSwitchedOn = true;
                        _this.namespaceBeforeForced = _this.namespace || '';
                        _this.namespace = ns;
                    }
                }
                if (namespaceOverrideWasOn && !namespaceOverrideSwitchedOn) {
                    _this.namespace = _this.namespaceBeforeForced;
                    _this.namespaceBeforeForced = null;
                }
            }
        });
        $scope.$watch(function () { return _this.namespace; }, function () {
            if (_this.namespaceModifiable()) {
                var prefixOverrideWasOn = isDefined(_this.prefixBeforeForced);
                var prefixOverrideSwitchedOn = false;
                for (var _i = 0, _a = Object.entries(technicalNamespaces); _i < _a.length; _i++) {
                    var _b = _a[_i], prefix = _b[0], ns = _b[1];
                    if (ns === _this.namespace) {
                        prefixOverrideSwitchedOn = true;
                        _this.prefixBeforeForced = _this.prefix || '';
                        _this.prefix = prefix;
                    }
                }
                if (prefixOverrideWasOn && !prefixOverrideSwitchedOn) {
                    _this.prefix = _this.prefixBeforeForced;
                    _this.prefixBeforeForced = null;
                }
            }
        });
    }
    Object.defineProperty(AddEditNamespaceController.prototype, "confirmLabel", {
        get: function () {
            return this.edit ? 'Edit' : 'Create new';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEditNamespaceController.prototype, "titleLabel", {
        get: function () {
            return this.edit ? 'Edit namespace' : 'Import namespace';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEditNamespaceController.prototype, "confirmButtonID", {
        get: function () {
            return this.edit ? 'edit_namespace_confirm_button' : 'add_new_namespace_confirm_button';
        },
        enumerable: true,
        configurable: true
    });
    AddEditNamespaceController.prototype.labelModifiable = function () {
        return !this.edit || this.namespaceToEdit.labelModifiable;
    };
    AddEditNamespaceController.prototype.namespaceModifiable = function () {
        return (!this.edit || this.namespaceToEdit.namespaceModifiable) && !isDefined(this.namespaceBeforeForced);
    };
    AddEditNamespaceController.prototype.prefixModifiable = function () {
        return (!this.edit || this.namespaceToEdit.prefixModifiable) && !isDefined(this.prefixBeforeForced);
    };
    AddEditNamespaceController.prototype.create = function () {
        var _this = this;
        if (this.edit) {
            this.namespaceToEdit.namespace = this.namespace;
            this.namespaceToEdit.prefix = this.prefix;
            this.namespaceToEdit.label[this.language] = this.label;
            this.$uibModalInstance.close(this.mangleAsTechnicalIfNecessary(this.namespaceToEdit));
        }
        else {
            this.modelService.newNamespaceImport(this.namespace, this.prefix, this.label, this.language)
                .then(function (ns) {
                return _this.$uibModalInstance.close(_this.mangleAsTechnicalIfNecessary(ns));
            }, function (err) { return _this.submitError = err.data.errorMessage; });
        }
    };
    AddEditNamespaceController.prototype.cancel = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    // XXX: API should return as technical and shouldn't need mangling
    AddEditNamespaceController.prototype.mangleAsTechnicalIfNecessary = function (ns) {
        var isTechnical = isDefined(this.namespaceBeforeForced) || isDefined(this.prefixBeforeForced);
        if (isTechnical) {
            ns.convertAsTechnical();
        }
        return ns;
    };
    return AddEditNamespaceController;
}());
export { ɵ0 };
