var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TableDescriptor } from 'app/components/form/editableTable';
import { NamespaceType } from 'app/entities/model';
import { LegacyComponent } from 'app/utils/angular';
var TechnicalNamespacesComponent = /** @class */ (function () {
    function TechnicalNamespacesComponent($scope) {
        'ngInject';
        this.$scope = $scope;
        this.expanded = false;
    }
    TechnicalNamespacesComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.model; }, function (model) {
            _this.descriptor = new TechnicalNamespaceTableDescriptor(model);
        });
    };
    TechnicalNamespacesComponent = __decorate([
        LegacyComponent({
            bindings: {
                model: '='
            },
            template: "\n      <h4 translate>Technical namespaces</h4>\n      <editable-table id=\"'technicalNamespaces'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object])
    ], TechnicalNamespacesComponent);
    return TechnicalNamespacesComponent;
}());
export { TechnicalNamespacesComponent };
var TechnicalNamespaceTableDescriptor = /** @class */ (function (_super) {
    __extends(TechnicalNamespaceTableDescriptor, _super);
    function TechnicalNamespaceTableDescriptor(model) {
        var _this = _super.call(this) || this;
        _this.namespaces = model.getNamespaces().filter(function (ns) { return ns.namespaceType === NamespaceType.IMPLICIT_TECHNICAL; });
        return _this;
    }
    TechnicalNamespaceTableDescriptor.prototype.columnDescriptors = function () {
        return [
            { headerName: 'Prefix', nameExtractor: function (ns) { return ns.prefix; }, cssClass: 'prefix' },
            { headerName: 'Namespace', nameExtractor: function (ns) { return ns.url; } }
        ];
    };
    TechnicalNamespaceTableDescriptor.prototype.values = function () {
        return this.namespaces;
    };
    TechnicalNamespaceTableDescriptor.prototype.orderBy = function (ns) {
        return ns.prefix;
    };
    TechnicalNamespaceTableDescriptor.prototype.canEdit = function (_ns) {
        return false;
    };
    TechnicalNamespaceTableDescriptor.prototype.canRemove = function (_ns) {
        return false;
    };
    return TechnicalNamespaceTableDescriptor;
}(TableDescriptor));
