var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { localizableSerializer, optional, coordinateSerializer, list, typeSerializer } from './serializer/serializer';
import { createConstantLocalizable } from 'app/utils/language';
import { copyVertices, coordinatesAreEqual, copyCoordinate, indexById } from 'app/utils/entity';
import { arraysAreEqual } from 'yti-common-ui/utils/array';
import { areEqual, isDefined } from 'yti-common-ui/utils/object';
import * as Iterable from 'yti-common-ui/utils/iterable';
import { Property } from './class';
import { init, serialize } from './mapping';
import { GraphNode, GraphNodes } from './graphNode';
import { uriSerializer, entityAwareOptional, entityAwareList, entity, entityAwareMap } from './serializer/entitySerializer';
var DefaultVisualizationClass = /** @class */ (function (_super) {
    __extends(DefaultVisualizationClass, _super);
    function DefaultVisualizationClass(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.resolved = true;
        init(_this, DefaultVisualizationClass.defaultVisualizationClassMappings);
        return _this;
    }
    Object.defineProperty(DefaultVisualizationClass.prototype, "associationPropertiesWithTarget", {
        get: function () {
            return this.properties.filter(function (property) { return property.isAssociation() && property.valueClass; });
        },
        enumerable: true,
        configurable: true
    });
    DefaultVisualizationClass.prototype.hasAssociationTarget = function (id) {
        for (var _i = 0, _a = this.associationPropertiesWithTarget; _i < _a.length; _i++) {
            var association = _a[_i];
            if (association.valueClass.equals(id)) {
                return true;
            }
        }
        return false;
    };
    DefaultVisualizationClass.defaultVisualizationClassMappings = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'name', serializer: localizableSerializer },
        comment: { name: 'description', serializer: localizableSerializer },
        scopeClass: { name: 'targetClass', serializer: entityAwareOptional(uriSerializer) },
        properties: { name: 'property', serializer: entityAwareList(entity(function () { return Property; })) }
    };
    return DefaultVisualizationClass;
}(GraphNode));
export { DefaultVisualizationClass };
var ModelPositions = /** @class */ (function (_super) {
    __extends(ModelPositions, _super);
    function ModelPositions(graph, context, frame) {
        var _this = _super.call(this, context, frame) || this;
        _this.dirty = false;
        _this.listeners = [];
        _this.classes = indexById(entityAwareList(entity(function () { return ClassPosition; })).deserialize(graph, _this));
        _this.classes.forEach(function (c) { return c.parent = _this; });
        return _this;
    }
    ModelPositions.prototype.getNodes = function () {
        return Array.from(this.classes.values());
    };
    ModelPositions.prototype.addChangeListener = function (listener) {
        this.listeners.push(listener);
    };
    ModelPositions.prototype.setPristine = function () {
        this.dirty = false;
    };
    ModelPositions.prototype.setDirty = function () {
        var wasPristine = !this.dirty;
        this.dirty = true;
        if (wasPristine) {
            this.listeners.forEach(function (l) { return l(); });
        }
    };
    ModelPositions.prototype.isPristine = function () {
        return !this.dirty;
    };
    ModelPositions.prototype.clear = function () {
        Iterable.forEach(this.classes.values(), function (c) { return c.clear(); });
    };
    ModelPositions.prototype.resetWith = function (resetWithPosition) {
        var _this = this;
        Iterable.forEach(resetWithPosition.classes.values(), function (classPosition) {
            var klass = _this.classes.get(classPosition.id.toString());
            if (klass) {
                klass.resetWith(classPosition);
            }
        });
        Iterable.forEach(this.classes.values(), function (classPosition) {
            if (!resetWithPosition.classes.has(classPosition.id.toString())) {
                classPosition.clear();
            }
        });
        this.setPristine();
    };
    ModelPositions.prototype.changeClassId = function (oldClassId, newClassId) {
        var classPosition = this.getClass(oldClassId);
        classPosition.id = newClassId;
        this.classes.delete(oldClassId.uri);
        this.classes.set(newClassId.uri, classPosition);
    };
    ModelPositions.prototype.removeClass = function (classId) {
        this.getClass(classId).clear();
    };
    ModelPositions.prototype.isClassDefined = function (classId) {
        var classPosition = this.classes.get(classId.uri);
        return classPosition && classPosition.isDefined();
    };
    ModelPositions.prototype.getClass = function (classId) {
        var classPosition = this.classes.get(classId.uri);
        if (classPosition) {
            return classPosition;
        }
        else {
            return this.newClassPosition(classId);
        }
    };
    ModelPositions.prototype.getAssociationProperty = function (classId, associationPropertyInternalId) {
        return this.getClass(classId).getAssociationProperty(associationPropertyInternalId);
    };
    ModelPositions.prototype.newClassPosition = function (classId) {
        var position = new ClassPosition({ '@id': classId.uri, '@type': typeSerializer.serialize(['class']) }, this.context, this.frame);
        position.parent = this;
        this.classes.set(classId.uri, position);
        return position;
    };
    ModelPositions.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        return new ModelPositions(serialization['@graph'], serialization['@context'], this.frame);
    };
    return ModelPositions;
}(GraphNodes));
export { ModelPositions };
var ClassPosition = /** @class */ (function (_super) {
    __extends(ClassPosition, _super);
    function ClassPosition(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.changeListeners = [];
        init(_this, ClassPosition.classPositionMappings);
        _this.associationProperties.forEach(function (p) { return p.parent = _this; });
        return _this;
    }
    Object.defineProperty(ClassPosition.prototype, "coordinate", {
        get: function () {
            return this._coordinate;
        },
        enumerable: true,
        configurable: true
    });
    ClassPosition.prototype.setCoordinate = function (value, notify) {
        if (notify === void 0) { notify = true; }
        if (!areEqual(this.coordinate, value, coordinatesAreEqual)) {
            this.setDirty();
        }
        this._coordinate = value;
        if (notify) {
            this.changeListeners.forEach(function (l) { return l(value); });
        }
    };
    ClassPosition.prototype.setDirty = function () {
        if (this.parent) {
            this.parent.setDirty();
        }
    };
    ClassPosition.prototype.clear = function () {
        Iterable.forEach(this.associationProperties.values(), function (p) { return p.clear(); });
        this.setCoordinate(null, false);
    };
    ClassPosition.prototype.resetWith = function (resetWithPosition) {
        var _this = this;
        if (resetWithPosition.isDefined()) {
            Iterable.forEach(resetWithPosition.associationProperties.values(), function (associationPropertyPosition) {
                var association = _this.associationProperties.get(associationPropertyPosition.id.toString());
                if (association) {
                    association.resetWith(associationPropertyPosition);
                }
            });
            Iterable.forEach(this.associationProperties.values(), function (associationPropertyPosition) {
                if (!resetWithPosition.associationProperties.has(associationPropertyPosition.id.toString())) {
                    associationPropertyPosition.clear();
                }
            });
            this.setCoordinate(copyCoordinate(resetWithPosition.coordinate));
        }
    };
    ClassPosition.prototype.isDefined = function () {
        return isDefined(this.coordinate);
    };
    ClassPosition.prototype.getAssociationProperty = function (associationPropertyInternalId) {
        var associationPosition = this.associationProperties.get(associationPropertyInternalId.uri);
        if (associationPosition) {
            return associationPosition;
        }
        else {
            return this.newAssociationPosition(associationPropertyInternalId);
        }
    };
    ClassPosition.prototype.newAssociationPosition = function (associationPropertyInternalId) {
        var position = new AssociationPropertyPosition({ '@id': associationPropertyInternalId.uri }, this.context, this.frame);
        position.parent = this;
        this.associationProperties.set(associationPropertyInternalId.uri, position);
        return position;
    };
    ClassPosition.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, ClassPosition.classPositionMappings);
    };
    ClassPosition.classPositionMappings = {
        id: { name: '@id', serializer: uriSerializer },
        _coordinate: { name: 'pointXY', serializer: optional(coordinateSerializer) },
        associationProperties: { name: 'property', serializer: entityAwareMap(entity(function () { return AssociationPropertyPosition; })) }
    };
    return ClassPosition;
}(GraphNode));
export { ClassPosition };
var AssociationPropertyPosition = /** @class */ (function (_super) {
    __extends(AssociationPropertyPosition, _super);
    function AssociationPropertyPosition(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        _this.changeListeners = [];
        init(_this, AssociationPropertyPosition.associationPropertyPositionMappings);
        return _this;
    }
    Object.defineProperty(AssociationPropertyPosition.prototype, "vertices", {
        get: function () {
            return this._vertices;
        },
        enumerable: true,
        configurable: true
    });
    AssociationPropertyPosition.prototype.setVertices = function (value, notify) {
        if (notify === void 0) { notify = true; }
        if (!arraysAreEqual(this.vertices, value, coordinatesAreEqual)) {
            this.setDirty();
        }
        this._vertices = value;
        if (notify) {
            this.changeListeners.forEach(function (l) { return l(value); });
        }
    };
    AssociationPropertyPosition.prototype.setDirty = function () {
        if (this.parent) {
            this.parent.setDirty();
        }
    };
    AssociationPropertyPosition.prototype.clear = function () {
        this.setVertices([], false);
    };
    AssociationPropertyPosition.prototype.resetWith = function (resetWithPosition) {
        if (resetWithPosition.isDefined()) {
            this.setVertices(copyVertices(resetWithPosition.vertices));
        }
    };
    AssociationPropertyPosition.prototype.isDefined = function () {
        return this.vertices.length > 0;
    };
    AssociationPropertyPosition.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, AssociationPropertyPosition.associationPropertyPositionMappings);
    };
    AssociationPropertyPosition.associationPropertyPositionMappings = {
        id: { name: '@id', serializer: uriSerializer },
        _vertices: { name: 'vertexXY', serializer: list(coordinateSerializer) }
    };
    return AssociationPropertyPosition;
}(GraphNode));
export { AssociationPropertyPosition };
var AssociationTargetPlaceholderClass = /** @class */ (function () {
    function AssociationTargetPlaceholderClass(id, model) {
        this.id = id;
        this.comment = {};
        this.type = ['association'];
        this.properties = [];
        this.resolved = false;
        this.scopeClass = null;
        this.associationPropertiesWithTarget = [];
        this.label = createConstantLocalizable(id.compact, model.language);
    }
    AssociationTargetPlaceholderClass.prototype.hasAssociationTarget = function (_id) {
        return false;
    };
    return AssociationTargetPlaceholderClass;
}());
export { AssociationTargetPlaceholderClass };
