var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EditableEntityController } from 'app/components/form/editableEntityController';
import { DeleteConfirmationModal } from 'app/components/common/deleteConfirmationModal';
import { ErrorModal } from 'app/components/form/errorModal';
import { AuthorizationManagerService } from 'app/services/authorizationManagerService';
import { LegacyComponent } from 'app/utils/angular';
import { AlertModalService } from 'yti-common-ui/components/alert-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { changeToRestrictedStatus } from 'yti-common-ui/entities/status';
import { DatamodelConfirmationModalService } from 'app/services/confirmation-modal.service';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
var ModelViewComponent = /** @class */ (function (_super) {
    __extends(ModelViewComponent, _super);
    function ModelViewComponent($scope, $log, $q, modelService, deleteConfirmationModal, datamodelConfirmationModalService, errorModalService, errorModal, userService, authorizationManagerService, alertModalService, translateService) {
        'ngInject';
        var _this = _super.call(this, $scope, $log, deleteConfirmationModal, errorModal, userService) || this;
        _this.$q = $q;
        _this.modelService = modelService;
        _this.datamodelConfirmationModalService = datamodelConfirmationModalService;
        _this.errorModalService = errorModalService;
        _this.authorizationManagerService = authorizationManagerService;
        _this.alertModalService = alertModalService;
        _this.translateService = translateService;
        _this.statusChanged = false;
        _this.changeResourceStatusesToo = false;
        _this.statusResourcesTotal = 0;
        return _this;
    }
    ModelViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.parent.registerView(this);
        var editableStatus = function () { return _this.editableInEdit ? _this.editableInEdit.status : false; };
        this.$scope.$watch(function () { return editableStatus(); }, function (newStatus) {
            _this.statusChanged = newStatus && newStatus !== _this.getEditable().status;
            if (!_this.statusChanged) {
                _this.changeResourceStatusesToo = false;
            }
        });
        var modelStatus = function () { return _this.model ? _this.model.status : false; };
        this.$scope.$watch(function () { return modelStatus(); }, function (newStatus) {
            if (newStatus) {
                _this.modelService.getModelResourcesTotalCountByStatus(_this.model, newStatus).then(function (resourcesTotal) {
                    _this.statusResourcesTotal = resourcesTotal;
                });
            }
        });
    };
    ModelViewComponent.prototype.$onDestroy = function () {
        this.parent.deregisterView(this);
    };
    ModelViewComponent.prototype.create = function (model) {
        return this.modelService.createModel(model);
    };
    ModelViewComponent.prototype.update = function (model, _oldEntity) {
        var _this = this;
        var oldStatus = _oldEntity.status;
        var newStatus = model.status;
        var updateModel = function () {
            _this.changeResourceStatusesToo = false;
            _this.statusChanged = false;
            return _this.modelService.updateModel(model).then(function () { return _this.updated(model); });
        };
        if (this.changeResourceStatusesToo) {
            return this.changeResourceStatuses(model, oldStatus, newStatus).then(function () {
                return updateModel();
            });
        }
        else {
            return updateModel();
        }
    };
    ModelViewComponent.prototype.remove = function (model) {
        var _this = this;
        return this.modelService.deleteModel(model.id).then(function () { return _this.deleted(model); });
    };
    ModelViewComponent.prototype.rights = function () {
        var _this = this;
        return {
            edit: function () { return _this.authorizationManagerService.canEditModel(_this.model); },
            remove: function () { return _this.authorizationManagerService.canRemoveModel(_this.model); }
        };
    };
    ModelViewComponent.prototype.getEditable = function () {
        return this.model;
    };
    ModelViewComponent.prototype.setEditable = function (editable) {
        this.model = editable;
    };
    ModelViewComponent.prototype.getContext = function () {
        return this.model;
    };
    ModelViewComponent.prototype.changeResourceStatuses = function (model, oldStatus, newStatus) {
        var _this = this;
        var modalRef = this.alertModalService.open('UPDATING_STATUSES_MESSAGE');
        return this.modelService.changeStatuses(model, oldStatus, newStatus).then(function (result) {
            if (_this.statusResourcesTotal === 0) {
                modalRef.message = _this.translateService.instant('No resources were found with the starting status. No changes to resources statuses.');
            }
            else if (_this.statusResourcesTotal === 1) {
                modalRef.message = _this.translateService.instant('Status changed to one resource.');
            }
            else {
                var messagePart1 = _this.translateService.instant('Status changed to ');
                var messagePart2 = _this.translateService.instant(' resources.');
                modalRef.message = messagePart1 + _this.statusResourcesTotal + messagePart2;
            }
            modalRef.showOkButton = true;
        }, function (error) {
            _this.errorModalService.openSubmitError(error);
            modalRef.cancel();
        });
    };
    ModelViewComponent.prototype.confirmChangeToRestrictedStatusDialog = function (model, _oldEntity) {
        return changeToRestrictedStatus(_oldEntity.status, model.status) ? this.$q.when(this.datamodelConfirmationModalService.openChangeToRestrictedStatus()) : null;
    };
    ModelViewComponent.prototype.confirmDialog = function (model, _oldEntity) {
        var startStatusLocalized = this.translateService.instant(_oldEntity.status);
        var endStatusLocalized = this.translateService.instant(model.status);
        return this.changeResourceStatusesToo ? this.$q.when(this.datamodelConfirmationModalService.openChangeResourceStatusesAlsoAlongWithTheDatamodelStatus(startStatusLocalized, endStatusLocalized)) : null;
    };
    ModelViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '<',
                model: '<',
                parent: '<',
                deleted: '&',
                updated: '&',
                namespacesInUse: '<'
            },
            template: require('./modelView.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Function, Object, DeleteConfirmationModal,
            DatamodelConfirmationModalService,
            ErrorModalService,
            ErrorModal, Object, AuthorizationManagerService,
            AlertModalService,
            TranslateService])
    ], ModelViewComponent);
    return ModelViewComponent;
}(EditableEntityController));
export { ModelViewComponent };
