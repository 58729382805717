import { availableUILanguages } from 'app/types/language';
import { translate } from 'app/utils/language';
import { BehaviorSubject, Subject } from 'rxjs';
var LanguageService = /** @class */ (function () {
    function LanguageService(gettextCatalog /* AngularJS */, translateService /* Angular */, localizationStrings, sessionService) {
        'ngInject';
        var _this = this;
        this.gettextCatalog = gettextCatalog; /* AngularJS */
        this.translateService = translateService; /* Angular */
        this.localizationStrings = localizationStrings;
        this.sessionService = sessionService;
        this._modelLanguage = {};
        this.modelLanguageChange$ = new Subject();
        var translationDefaultLanguage = 'en';
        gettextCatalog.baseLanguage = translationDefaultLanguage;
        translateService.setDefaultLang(translationDefaultLanguage);
        this.language$ = new BehaviorSubject(sessionService.UILanguage || 'fi');
        this.language$.subscribe(function (lang) {
            _this.sessionService.UILanguage = lang;
            _this.gettextCatalog.setCurrentLanguage(lang);
            _this.translateService.use(lang);
        });
        this._modelLanguage = sessionService.modelLanguage || {};
    }
    Object.defineProperty(LanguageService.prototype, "UILanguage", {
        get: function () {
            return this.language$.getValue();
        },
        set: function (language) {
            this.language$.next(language);
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.getModelLanguage = function (context) {
        var _this = this;
        var getUILanguageOrFirst = function () {
            if (context.language.indexOf(_this.UILanguage) !== -1) {
                return _this.UILanguage;
            }
            else {
                return context.language[0];
            }
        };
        if (context) {
            var key = context.id.uri;
            var language = this._modelLanguage[key];
            return language ? language : getUILanguageOrFirst();
        }
        else {
            return this.UILanguage;
        }
    };
    LanguageService.prototype.setModelLanguage = function (context, language) {
        this._modelLanguage[context.id.uri] = language;
        this.sessionService.modelLanguage = this._modelLanguage;
        this.modelLanguageChange$.next(true);
    };
    LanguageService.prototype.translate = function (data, context) {
        return translate(data, this.getModelLanguage(context), context ? context.language : availableUILanguages);
    };
    LanguageService.prototype.translateToGivenLanguage = function (localizable, languageToUse) {
        if (!localizable || !languageToUse) {
            return '';
        }
        var primaryLocalization = localizable[languageToUse];
        if (primaryLocalization) {
            return primaryLocalization;
        }
        else {
            var fallbackValue = this.checkForFallbackLanguages(localizable);
            if (fallbackValue != null) {
                return fallbackValue;
            }
            for (var _i = 0, _a = Object.entries(localizable); _i < _a.length; _i++) {
                var _b = _a[_i], language = _b[0], value = _b[1];
                if (value) {
                    return value + " (" + language + ")";
                }
            }
            return '';
        }
    };
    LanguageService.prototype.checkForFallbackLanguages = function (localizable) {
        var fallbackLanguages = ['en', 'fi', 'sv'];
        for (var _i = 0, fallbackLanguages_1 = fallbackLanguages; _i < fallbackLanguages_1.length; _i++) {
            var language = fallbackLanguages_1[_i];
            if (this.hasLocalizationForLanguage(localizable, language)) {
                return this.fallbackLocalization(localizable, language);
            }
        }
        return null;
    };
    LanguageService.prototype.hasLocalizationForLanguage = function (localizable, language) {
        var value = localizable[language];
        return value != null && value !== '';
    };
    LanguageService.prototype.fallbackLocalization = function (localizable, language) {
        var value = localizable[language];
        return value + " (" + language + ")";
    };
    LanguageService.prototype.createLocalizer = function (context) {
        return new DefaultAngularJSLocalizer(this, context);
    };
    LanguageService.prototype.findLocalization = function (language, key) {
        var stringsForLang = this.localizationStrings[language];
        return stringsForLang ? stringsForLang[key] : null;
    };
    return LanguageService;
}());
export { LanguageService };
var DefaultAngularLocalizer = /** @class */ (function () {
    function DefaultAngularLocalizer(languageService) {
        this.languageService = languageService;
        this.translateLanguage$ = languageService.language$.asObservable();
    }
    DefaultAngularLocalizer.prototype.translate = function (localizable, useUILanguage) {
        // FIXME datamodel ui doesn't have concept of ui language boolean but language context
        return this.languageService.translate(localizable);
    };
    DefaultAngularLocalizer.prototype.translateToGivenLanguage = function (localizable, languageToUse) {
        return this.languageService.translateToGivenLanguage(localizable, languageToUse);
    };
    return DefaultAngularLocalizer;
}());
export { DefaultAngularLocalizer };
var DefaultAngularJSLocalizer = /** @class */ (function () {
    function DefaultAngularJSLocalizer(languageService, context) {
        this.languageService = languageService;
        this.context = context;
    }
    Object.defineProperty(DefaultAngularJSLocalizer.prototype, "language", {
        get: function () {
            return this.languageService.getModelLanguage(this.context);
        },
        enumerable: true,
        configurable: true
    });
    DefaultAngularJSLocalizer.prototype.translate = function (data) {
        return this.languageService.translate(data, this.context);
    };
    DefaultAngularJSLocalizer.prototype.getStringWithModelLanguageOrDefault = function (key, defaultLanguage) {
        var askedLocalization = this.languageService.findLocalization(this.language, key);
        if (askedLocalization) {
            return askedLocalization;
        }
        else {
            var defaultLocalization = this.languageService.findLocalization(defaultLanguage, key);
            if (!defaultLocalization) {
                console.log("Localization (" + key + ") not found for default language (" + defaultLanguage + ")");
                return '??? ' + key;
            }
            else {
                return defaultLocalization;
            }
        }
    };
    DefaultAngularJSLocalizer.prototype.allUILocalizationsForKey = function (localizationKey) {
        var result = [];
        for (var _i = 0, availableUILanguages_1 = availableUILanguages; _i < availableUILanguages_1.length; _i++) {
            var lang = availableUILanguages_1[_i];
            var localization = this.languageService.localizationStrings[lang][localizationKey];
            if (localization) {
                result.push(localization);
            }
        }
        return result;
    };
    return DefaultAngularJSLocalizer;
}());
export { DefaultAngularJSLocalizer };
