var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from '../../services/languageService';
import { TableDescriptor } from '../../components/form/editableTable';
import { createExistsExclusion } from '../../utils/exclusion';
import { collectProperties } from 'yti-common-ui/utils/array';
import { SearchClassificationModal } from './searchClassificationModal';
import { LegacyComponent, modalCancelHandler } from '../../utils/angular';
var ClassificationsViewComponent = /** @class */ (function () {
    function ClassificationsViewComponent($scope, languageService, searchClassificationModal) {
        'ngInject';
        this.$scope = $scope;
        this.languageService = languageService;
        this.searchClassificationModal = searchClassificationModal;
    }
    ClassificationsViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new ClassificationTableDescriptor(value, _this.languageService);
        });
    };
    ClassificationsViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    ClassificationsViewComponent.prototype.addClassification = function () {
        var _this = this;
        var classificationIds = collectProperties(this.value.classifications, function (c) { return c.id.uri; });
        var exclude = createExistsExclusion(classificationIds);
        this.searchClassificationModal.open(exclude)
            .then(function (classification) {
            _this.value.addClassification(classification);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    ClassificationsViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                required: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n      <h4>\n        <span translate>Classifications</span>\n        <button id=\"add_classification_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.addClassification()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Add classification</span>\n        </button>\n        <span ng-show=\"$ctrl.required && $ctrl.isEditing()\" class=\"fas fa-asterisk\" uib-tooltip=\"{{'Required' | translate}}\"></span>\n      </h4>\n      <editable-table id=\"'classifications'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, LanguageService,
            SearchClassificationModal])
    ], ClassificationsViewComponent);
    return ClassificationsViewComponent;
}());
export { ClassificationsViewComponent };
var ClassificationTableDescriptor = /** @class */ (function (_super) {
    __extends(ClassificationTableDescriptor, _super);
    function ClassificationTableDescriptor(value, languageService) {
        var _this = _super.call(this) || this;
        _this.value = value;
        _this.languageService = languageService;
        return _this;
    }
    ClassificationTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Name', nameExtractor: function (c) { return _this.languageService.translate(c.label); } }
        ];
    };
    ClassificationTableDescriptor.prototype.values = function () {
        return this.value && this.value.classifications;
    };
    ClassificationTableDescriptor.prototype.canEdit = function (_classification) {
        return false;
    };
    ClassificationTableDescriptor.prototype.canRemove = function (classification) {
        return this.value.classifications.length > 0;
    };
    ClassificationTableDescriptor.prototype.remove = function (classification) {
        this.value.removeClassification(classification);
    };
    ClassificationTableDescriptor.prototype.orderBy = function (c) {
        return this.languageService.translate(c.label);
    };
    return ClassificationTableDescriptor;
}(TableDescriptor));
