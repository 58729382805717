export var IgnoreFormDirective = function () {
    return {
        restrict: 'A',
        require: ['ngModel', '^?form'],
        link: function (_$scope, _element, _attributes, _a) {
            var modelController = _a[0], formController = _a[1];
            if (formController) {
                formController.$removeControl(modelController);
            }
        }
    };
};
