var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isDefined } from 'yti-common-ui/utils/object';
import { arrowDown, arrowUp, enter, esc, pageDown, pageUp, tab } from 'yti-common-ui/utils/key-code';
import { formatWithFormatters, LegacyComponent } from 'app/utils/angular';
import { limit } from 'yti-common-ui/utils/array';
// TODO: similarities with iowSelect
var AutocompleteComponent = /** @class */ (function () {
    function AutocompleteComponent($scope, $q, $element, $document) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$q = $q;
        this.$element = $element;
        this.$document = $document;
        this.popupItems = [];
        this.selectedSelectionIndex = -1;
        this.popupItemName = 'match';
        this.pendingShow = false;
        this.keyEventHandlers = (_a = {},
            _a[arrowDown] = function () { return _this.moveSelection(1); },
            _a[arrowUp] = function () { return _this.moveSelection(-1); },
            _a[pageDown] = function () { return _this.moveSelection(10); },
            _a[pageUp] = function () { return _this.moveSelection(-10); },
            _a[enter] = function () { return _this.selectSelection(); },
            _a[tab] = function () { return _this.selectSelection(); },
            _a[esc] = function () { return _this.clear(); },
            _a);
        var _a;
    }
    Object.defineProperty(AutocompleteComponent.prototype, "show", {
        get: function () {
            return this.popupItems.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.$postLink = function () {
        var _this = this;
        var inputElement = this.$element.find('input');
        var ngModel = inputElement.controller('ngModel');
        this.$scope.$watchCollection(function () { return ngModel.$formatters; }, function (formatters) { return _this.inputFormatter = formatters; });
        var keyDownHandler = function (event) { return _this.$scope.$apply(function () { return _this.keyPressed(event); }); };
        var focusHandler = function () {
            _this.$document.on('click', blurClickHandler);
            _this.$scope.$apply(function () { return _this.autocomplete(ngModel.$viewValue); });
        };
        var blurClickHandler = function (event) {
            var autocomplete = jQuery(event.target).closest('autocomplete');
            if (autocomplete[0] !== _this.$element[0]) {
                _this.pendingShow = false;
                _this.$scope.$apply(function () { return _this.clear(); });
                _this.$document.off('click', blurClickHandler);
            }
        };
        inputElement.on('keydown', keyDownHandler);
        inputElement.on('focus', focusHandler);
        this.$scope.$on('$destroy', function () {
            inputElement.off('keydown', keyDownHandler);
            inputElement.off('focus', focusHandler);
        });
        var ignoreNextViewChange = false;
        this.$scope.$watch(function () { return ngModel.$viewValue; }, function (viewValue) {
            if (ignoreNextViewChange) {
                ignoreNextViewChange = false;
            }
            else {
                // prevents initial triggering when user is not actually inputting anything
                if (inputElement.is(':focus')) {
                    _this.autocomplete(viewValue);
                }
            }
        });
        this.applyValue = function (value) {
            ignoreNextViewChange = ngModel.$viewValue !== value;
            ngModel.$setViewValue(value);
            ngModel.$render();
        };
        this.element = inputElement;
    };
    AutocompleteComponent.prototype.keyPressed = function (event) {
        var handler = this.keyEventHandlers[event.keyCode];
        if (handler) {
            var preventDefault = handler();
            if (!isDefined(preventDefault) || preventDefault === true) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    };
    AutocompleteComponent.prototype.setSelection = function (index) {
        this.selectedSelectionIndex = index;
    };
    AutocompleteComponent.prototype.isSelected = function (index) {
        return index === this.selectedSelectionIndex;
    };
    AutocompleteComponent.prototype.format = function (value) {
        if (!this.formatter) {
            return this.formatValue(value);
        }
        else {
            return this.formatter(value);
        }
    };
    AutocompleteComponent.prototype.match = function (search, value) {
        if (!this.matcher) {
            return this.format(value).toLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
        else {
            return this.matcher(search, value);
        }
    };
    AutocompleteComponent.prototype.formatValue = function (value) {
        return formatWithFormatters(this.extractValue(value), this.inputFormatter);
    };
    AutocompleteComponent.prototype.extractValue = function (value) {
        if (this.valueExtractor) {
            return this.valueExtractor(value);
        }
        else {
            return value;
        }
    };
    AutocompleteComponent.prototype.autocomplete = function (search) {
        var _this = this;
        this.pendingShow = true;
        this.$q.when(this.datasource(search)).then(function (data) {
            if (_this.pendingShow) {
                _this.pendingShow = false;
                var exclude_1 = _this.excludeProvider && _this.excludeProvider();
                var included = data.filter(function (item) { return !exclude_1 || !exclude_1(item); });
                if (search) {
                    _this.setMatches(included.filter(function (item) { return _this.match(search, item); }), true);
                }
                else {
                    _this.setMatches(included, false);
                }
            }
            else {
                _this.clear();
            }
        });
    };
    AutocompleteComponent.prototype.clear = function () {
        this.setMatches([], false);
    };
    AutocompleteComponent.prototype.setMatches = function (dataMatches, selectFirst) {
        this.selectedSelectionIndex = selectFirst ? 0 : -1;
        var maxMatches = this.maxMatches || 500;
        this.popupItems = maxMatches > 0 ? limit(dataMatches, maxMatches) : dataMatches;
    };
    AutocompleteComponent.prototype.selectSelection = function () {
        var value = this.selectedSelectionIndex >= 0 ? this.popupItems[this.selectedSelectionIndex] : null;
        if (value) {
            this.applyValue(this.formatValue(value));
        }
        this.clear();
        return !!value;
    };
    AutocompleteComponent.prototype.moveSelection = function (offset) {
        this.setSelection(Math.max(Math.min(this.selectedSelectionIndex + offset, this.popupItems.length - 1), -1));
    };
    AutocompleteComponent = __decorate([
        LegacyComponent({
            bindings: {
                datasource: '=',
                matcher: '=',
                formatter: '=',
                valueExtractor: '=',
                excludeProvider: '=?',
                maxMatches: '='
            },
            transclude: true,
            template: "\n      <div style=\"position: relative;\"><ajax-loading-indicator-small ng-if=\"$ctrl.pendingShow\" class=\"autocomplete-pending-indicator\"></ajax-loading-indicator-small></div>\n      <ng-transclude></ng-transclude>\n      <input-popup ctrl=\"$ctrl\"><span class=\"content\">{{::$ctrl.format(match)}}</span></input-popup>\n  "
        }),
        __metadata("design:paramtypes", [Object, Function, Object, Object])
    ], AutocompleteComponent);
    return AutocompleteComponent;
}());
export { AutocompleteComponent };
