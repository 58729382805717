<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div [hidden]="loading">
  <div class="modal-header">
    <h4 class="modal-title strong">
      <a><i id="close_modal_link" class="fa fa-times" (click)="close()"></i></a>
      <span translate>Update datamodel's resources' statuses</span>
    </h4>
  </div>

  <div class="modal-body">
    <div class="row" *ngIf="isSuperUser">
      <div class="col-md-4" style="line-height: 45px; white-space: nowrap">
        <div class="checkbox">
          <input id="enforce_status_transition_rules_checkbox" type="checkbox" (click)="toggleEnforceTransitionRulesForSuperUserToo()">
          <label style="vertical-align: 5%; padding-left: 5px;" for="enforce_status_transition_rules_checkbox" translate>Enforce status transition rules</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="highlight" for="migrate_resource_statuses_from_status_input_dropdown" translate>Starting status</label>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-auto">
        <app-filter-dropdown id="migrate_resource_statuses_from_status_input_dropdown"
                            [options]="fromOptions"
                            [filterSubject]="fromStatus$"></app-filter-dropdown>
      </div>
      <div class="col-12 col-md">
        <label *ngIf="showFromStatusResourcesTotal()">{{'Resources:' | translate}} {{fromStatusResourcesTotal}}</label>
        <app-ajax-loading-indicator-small *ngIf="loadingResourcesTotal"></app-ajax-loading-indicator-small>
      </div>
    </div>

    <br/>
    <label class="highlight" for="migrate_resource_statuses_to_status_input_dropdown" translate>Target status</label>
    <app-filter-dropdown id="migrate_resource_statuses_to_status_input_dropdown"
                         [options]="toOptions"
                         [filterSubject]="toStatus$"></app-filter-dropdown>

  </div>
  <div class="modal-footer">
    <div>
      <button id="migrate_resource_statuses_button"
              [disabled]="!canSave()"
              type="button"
              class="btn btn-action"
              (click)="saveChanges()"
              translate>Save</button>
      <button id="cancel_migrate_resource_statuses_button" type="button" class="btn btn-link" (click)="close()" translate>Cancel</button>
    </div>
  </div>
</div>
