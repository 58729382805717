var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SearchConceptModal } from './searchConceptModal';
import { LegacyComponent, modalCancelHandler } from '../../utils/angular';
var SubjectViewComponent = /** @class */ (function () {
    function SubjectViewComponent(searchConceptModal) {
        'ngInject';
        this.searchConceptModal = searchConceptModal;
    }
    SubjectViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    SubjectViewComponent.prototype.showChangeSubject = function () {
        return this.isEditing() && !this.changeConceptDisabled;
    };
    Object.defineProperty(SubjectViewComponent.prototype, "conceptLink", {
        get: function () {
            return this.entity.subject ? this.entity.subject.id.uri : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubjectViewComponent.prototype, "vocabularyLink", {
        get: function () {
            return this.entity.subject ? this.entity.subject.vocabulary.id.uri : '';
        },
        enumerable: true,
        configurable: true
    });
    SubjectViewComponent.prototype.changeSubject = function () {
        var _this = this;
        var normalizedType = this.entity.normalizedType;
        if (normalizedType === 'property') {
            throw new Error('Must be known predicate type');
        }
        this.searchConceptModal.openSelection(this.model.vocabularies, this.model, true, normalizedType)
            .then(function (concept) { return _this.entity.subject = concept; }, modalCancelHandler);
    };
    SubjectViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '@',
                entity: '=',
                model: '=',
                changeConceptDisabled: '='
            },
            require: {
                form: '?^form'
            },
            template: require('./subjectView.html')
        }),
        __metadata("design:paramtypes", [SearchConceptModal])
    ], SubjectViewComponent);
    return SubjectViewComponent;
}());
export { SubjectViewComponent };
