import * as jQuery from 'jquery';
import { IowClassElement, ShadowClass } from './diagram';
import * as joint from 'jointjs';
import * as Iterable from 'yti-common-ui/utils/iterable';
import { moveOrigin, scale } from './paperUtil';
var PaperHolder = /** @class */ (function () {
    function PaperHolder(element, listener, $window, zone) {
        this.element = element;
        this.listener = listener;
        this.$window = $window;
        this.zone = zone;
        this.cache = new Map();
    }
    PaperHolder.prototype.getPaper = function (model) {
        var cached = this.cache.get(model.id.uri);
        if (cached) {
            return cached.paper;
        }
        else {
            var newElement_1 = jQuery(document.createElement('div'));
            this.element.append(newElement_1);
            var newPaper_1 = null;
            this.zone.runOutsideAngular(function () {
                newPaper_1 = createPaper(newElement_1, new joint.dia.Graph);
            });
            if (!newPaper_1) {
                throw new Error();
            }
            var cleanable_1 = registerHandlers(newPaper_1, this.listener, this.$window, this.zone);
            this.cache.set(model.id.uri, { element: newElement_1, paper: newPaper_1, clean: function () { return cleanable_1.clean(); } });
            return newPaper_1;
        }
    };
    PaperHolder.prototype.setVisible = function (model) {
        Iterable.forEach(this.cache.entries(), function (_a) {
            var modelId = _a[0], value = _a[1];
            if (model.id.uri === modelId) {
                value.element.show();
            }
            else {
                value.element.hide();
            }
        });
    };
    PaperHolder.prototype.clean = function () {
        for (var _i = 0, _a = Array.from(this.cache.values()); _i < _a.length; _i++) {
            var cached = _a[_i];
            cached.clean();
        }
    };
    return PaperHolder;
}());
export { PaperHolder };
function createPaper(element, graph) {
    return new joint.dia.Paper({
        el: element,
        width: element.width() || 100,
        height: element.height() || 100,
        model: graph,
        linkPinning: false,
        snapLinks: false,
        perpendicularLinks: true
    });
}
function registerHandlers(paper, listener, $window, zone) {
    var paperElement = paper.$el;
    var movingElementOrVertex = false;
    var drag;
    var mouse;
    var showMenu;
    var startDragHandler = function () { return drag = mouse; };
    var stopDragHandler = function () {
        drag = null;
        movingElementOrVertex = false;
    };
    var dragMoveHandler = function (event) {
        mouse = { x: event.pageX, y: event.pageY };
        if (drag) {
            event.preventDefault();
            moveOrigin(paper, drag.x - mouse.x, drag.y - mouse.y);
            drag = mouse;
        }
    };
    var mouseWheelHandler = function (event) {
        event.preventDefault();
        scale(paper, (event.deltaY * event.deltaFactor / 500), event.offsetX, event.offsetY);
    };
    var startCellMoveHandler = function () { return movingElementOrVertex = true; };
    var classClickHandler = function (cellView) {
        var cell = cellView.model;
        if (cell instanceof joint.shapes.uml.Class && !(cell instanceof ShadowClass)) {
            listener.onClassClick(cell.id);
        }
    };
    var hoverHandler = function (cellView, event) {
        if (!drag && !movingElementOrVertex && event.target instanceof SVGElement) {
            var targetElement = jQuery(event.target);
            var targetParentElement = targetElement.parent();
            var x = event.pageX;
            var y = event.pageY;
            if (targetElement.prop('tagName') === 'tspan') {
                if (cellView.model instanceof IowClassElement && targetElement.attr('id').startsWith('urn:uuid')) {
                    listener.onPropertyHover(cellView.model.id, targetElement.attr('id'), { x: x, y: y });
                }
                else if (cellView.model instanceof joint.dia.Link && targetParentElement.attr('id').startsWith('urn:uuid')) {
                    listener.onPropertyHover(cellView.model.get('source').id, targetParentElement.attr('id'), { x: x, y: y });
                }
                else if (cellView.model instanceof IowClassElement && targetParentElement.hasClass('uml-class-name-text')) {
                    listener.onClassHover(cellView.model.id, { x: x, y: y });
                }
            }
        }
    };
    var hoverExitHandler = function () { return listener.onHoverExit(); };
    var prepareShowContextMenuHandler = function (e) {
        var location = clientToPaperLocation(paper, clientToOffsetCoordinate(paper, e));
        var views = paper.findViewsFromPoint(location);
        if (views.length > 0) {
            showMenu = views[0].model.id;
        }
        else {
            listener.onDismissContextMenu();
            showMenu = null;
        }
    };
    var cancelShowMenuHandler = function () { return showMenu = null; };
    var showContextMenuHandler = function (e) {
        if (showMenu) {
            listener.onClassContextMenu(showMenu, clientToOffsetCoordinate(paper, e));
        }
    };
    var hideContextMenuHandler = function () {
        listener.onDismissContextMenu();
        showMenu = null;
    };
    zone.runOutsideAngular(function () {
        paper.on('blank:pointerdown', startDragHandler);
        $window.addEventListener('mouseup', stopDragHandler);
        $window.addEventListener('mousemove', dragMoveHandler);
        jQuery(paperElement).mousewheel(mouseWheelHandler);
        paper.on('cell:pointerdown', startCellMoveHandler);
        paper.on('cell:pointerclick', classClickHandler);
        paper.on('cell:mouseover', hoverHandler);
        paper.on('cell:mouseout', hoverExitHandler);
        paperElement.on('contextmenu', prepareShowContextMenuHandler);
        paperElement.on('mousemove', cancelShowMenuHandler);
        paperElement.on('mouseup', showContextMenuHandler);
        paperElement.on('click', hideContextMenuHandler);
    });
    return {
        clean: function () {
            paper.remove();
            $window.removeEventListener('mouseup', stopDragHandler);
            $window.removeEventListener('mousemove', dragMoveHandler);
        }
    };
}
function clientToOffsetCoordinate(paper, event) {
    var paperOffset = paper.$el.offset();
    return { x: event.clientX - paperOffset.left, y: event.clientY - paperOffset.top };
}
function clientToPaperLocation(paper, offsetCoordinate) {
    var svgPoint = paper.svg.createSVGPoint();
    svgPoint.x = offsetCoordinate.x;
    svgPoint.y = offsetCoordinate.y;
    return svgPoint.matrixTransform(paper.viewport.getCTM().inverse());
}
