<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading">

  <div class="mb-3">
    <span translate>You have subscribed to be notified via email when the following resources change. To remove the subscription, click the icon next to the resource.</span>
  </div>

  <dl>
    <dt>
      <label translate>Email notifications</label>
      <app-information-symbol [infoText]="'INFO_TEXT_EMAIL_SUBSCRIPTIONS'"></app-information-symbol>
    </dt>
    <dd>
      <div class="checkbox">
        <input id="enable_subscription_checkbox"
               type="checkbox"
               (click)="toggleSubscription($event)"
               [checked]="isSubscriptionEnabled"/>
        <label translate>Daily</label>
      </div>
    </dd>
  </dl>

  <dl *ngIf="messagingResources != null && messagingResources.get(APPLICATION_CODELIST) != null &&
        messagingResources.get(APPLICATION_CODELIST).length > 0">
    <dt>
      <label translate>Code list resources</label>
    </dt>
    <dd>
      <div *ngFor="let resource of messagingResources.get(APPLICATION_CODELIST)">
        <a href="{{ getUriWithEnv(resource.uri) }}"
           target="_blank"
           rel="noopener noreferrer"><span>{{ resource.getDisplayName(languageService.createLocalizer()) }}</span></a>
        <button [id]="'remove_' + resource.uri + '_ref_button'"
                type="button"
                class="icon icon-trash"
                (click)="removeSubscription(resource)"></button>
      </div>
    </dd>
  </dl>

  <dl *ngIf="messagingResources != null && messagingResources.get(APPLICATION_TERMINOLOGY) != null &&
        messagingResources.get(APPLICATION_TERMINOLOGY).length > 0">
    <dt>
      <label translate>Terminology resources</label>
    </dt>
    <dd>
      <div *ngFor="let resource of messagingResources.get(APPLICATION_TERMINOLOGY)">
        <a href="{{ getUriWithEnv(resource.uri) }}"
           target="_blank"
           rel="noopener noreferrer"><span>{{ resource.getDisplayName(languageService.createLocalizer()) }}</span></a>
        <button [id]="'remove_' + resource.uri + '_ref_button'"
                type="button"
                class="icon icon-trash"
                (click)="removeSubscription(resource)"></button>
      </div>
    </dd>
  </dl>

  <dl *ngIf="messagingResources != null && messagingResources.get(APPLICATION_DATAMODEL) != null &&
        messagingResources.get(APPLICATION_DATAMODEL).length > 0">
    <dt>
      <label translate>Datamodel resources</label>
    </dt>
    <dd>
      <div *ngFor="let resource of messagingResources.get(APPLICATION_DATAMODEL)">
        <a href="{{ getUriWithEnv(resource.uri) }}"
           target="_blank"
           rel="noopener noreferrer"><span>{{ resource.getDisplayName(languageService.createLocalizer()) }}</span></a>
        <button [id]="'remove_' + resource.uri + '_ref_button'"
                type="button"
                class="icon icon-trash"
                (click)="removeSubscription(resource)"></button>
      </div>
    </dd>
  </dl>

  <dl *ngIf="messagingResources != null && messagingResources.get(APPLICATION_COMMENTS) != null &&
        messagingResources.get(APPLICATION_COMMENTS).length > 0">
    <dt>
      <label translate>Comments resources</label>
    </dt>
    <dd>
      <div *ngFor="let resource of messagingResources.get(APPLICATION_COMMENTS)">
        <a href="{{ getUriWithEnv(resource.uri) }}"
           target="_blank"
           rel="noopener noreferrer"><span>{{ resource.getDisplayName(languageService.createLocalizer()) }}</span></a>
        <button [id]="'remove_' + resource.uri + '_ref_button'"
                type="button"
                class="icon icon-trash"
                (click)="removeSubscription(resource)"></button>
      </div>
    </dd>
  </dl>
</div>
