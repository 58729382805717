var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as joint from 'jointjs';
import { formatClassName, formatAttributeNamesAndAnnotations, formatAssociationPropertyName, formatCardinality, allAttributePropertyNames, allClassNames } from './formatter';
import { requireDefined } from 'yti-common-ui/utils/object';
var zIndexAssociation = 5;
var zIndexClass = 10;
var LinkWithoutUnusedMarkup = /** @class */ (function (_super) {
    __extends(LinkWithoutUnusedMarkup, _super);
    function LinkWithoutUnusedMarkup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.markup = [
            '<path class="connection" stroke="black" d="M 0 0 0 0"/>',
            '<path class="marker-target" fill="black" stroke="black" d="M 0 0 0 0"/>',
            '<path class="connection-wrap" d="M 0 0 0 0"/>',
            '<g class="labels"/>',
            '<g class="marker-vertices"/>'
        ].join('');
        _this.toolMarkup = '';
        return _this;
    }
    return LinkWithoutUnusedMarkup;
}(joint.dia.Link));
export { LinkWithoutUnusedMarkup };
var classMarkup = function (shadow) {
    return "<g class=\"rotatable " + (shadow ? 'shadow' : '') + "\">\n            <g class=\"scalable\">\n              <rect class=\"uml-class-name-rect\"/> " + (shadow ? '' : '<rect class="uml-class-attrs-rect"/>') + "\n            </g>\n            <text class=\"uml-class-name-text\"/> " + (shadow ? '' : '<text class="uml-class-attrs-text"/>') + "\n          </g>";
};
var ɵ0 = classMarkup;
var ShadowClass = /** @class */ (function (_super) {
    __extends(ShadowClass, _super);
    function ShadowClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.markup = classMarkup(true);
        _this.updateModel = function () { };
        return _this;
    }
    return ShadowClass;
}(joint.shapes.uml.Class));
export { ShadowClass };
var IowClassElement = /** @class */ (function (_super) {
    __extends(IowClassElement, _super);
    function IowClassElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.markup = classMarkup(false);
        return _this;
    }
    return IowClassElement;
}(joint.shapes.uml.Class));
export { IowClassElement };
export function createClassElement(klass, optionsProvider) {
    var options = optionsProvider();
    var className = formatClassName(klass, options.showName, options.localizer);
    var _a = formatAttributeNamesAndAnnotations(klass.properties, options.showCardinality, options.showName, options.localizer), propertyNames = _a[0], propertyAnnotations = _a[1];
    var classConstructor = klass.resolved ? IowClassElement : ShadowClass;
    var classCell = new classConstructor({
        id: klass.id.uri,
        size: calculateElementDimensions(klass, options.showCardinality, options.localizer),
        name: className,
        attributes: propertyNames,
        attrs: {
            '.uml-class-name-text': {
                'ref': '.uml-class-name-rect', 'ref-y': 0.6, 'ref-x': 0.5, 'text-anchor': 'middle', 'y-alignment': 'middle'
            },
            '.uml-class-attrs-text': {
                'annotations': propertyAnnotations
            }
        },
        z: zIndexClass
    });
    classCell.updateModel = function () {
        var newOptions = optionsProvider();
        var _a = formatAttributeNamesAndAnnotations(klass.properties, newOptions.showCardinality, newOptions.showName, newOptions.localizer), newPropertyNames = _a[0], newPropertyAnnotations = _a[1];
        var newClassName = formatClassName(klass, newOptions.showName, newOptions.localizer);
        var previousPosition = classCell.position();
        var previousSize = classCell.getBBox();
        var newSize = calculateElementDimensions(klass, newOptions.showCardinality, newOptions.localizer);
        var xd = (newSize.width - previousSize.width) / 2;
        var yd = (newSize.height - previousSize.height) / 2;
        classCell.prop('name', newClassName);
        classCell.prop('attributes', newPropertyNames);
        classCell.attr({
            '.uml-class-attrs-text': {
                'annotations': newPropertyAnnotations
            }
        });
        classCell.prop('size', newSize);
        classCell.position(previousPosition.x - xd, previousPosition.y - yd);
    };
    return classCell;
}
export function createAssociationLink(klass, association, optionsProvider) {
    var options = optionsProvider();
    var associationCell = new LinkWithoutUnusedMarkup({
        source: { id: klass.id.uri },
        target: { id: requireDefined(association.valueClass).toString() },
        connector: { name: 'normal' },
        attrs: {
            '.marker-target': {
                d: 'M 10 0 L 0 5 L 10 10 L 3 5 z'
            }
        },
        internalId: association.internalId.uri,
        labels: [
            { position: 0.5, attrs: { text: { text: formatAssociationPropertyName(association, options.showName, options.localizer), id: association.internalId.toString() } } },
            { position: .9, attrs: { text: { text: options.showCardinality ? formatCardinality(association) : '' } } }
        ],
        z: zIndexAssociation
    });
    associationCell.updateModel = function () {
        var newOptions = optionsProvider();
        associationCell.prop('labels/0/attrs/text/text', formatAssociationPropertyName(association, newOptions.showName, newOptions.localizer));
        if (newOptions.showCardinality) {
            associationCell.prop('labels/1/attrs/text/text', formatCardinality(association));
        }
    };
    return associationCell;
}
function calculateElementDimensions(klass, showCardinality, localizer) {
    var attributeProperties = klass.properties.filter(function (p) { return p.isAttribute(); });
    var height = 12 * attributeProperties.length + 35;
    var length = 0;
    for (var _i = 0, _a = allClassNames(klass); _i < _a.length; _i++) {
        var className = _a[_i];
        if (className.length > length) {
            length = className.length;
        }
    }
    for (var _b = 0, attributeProperties_1 = attributeProperties; _b < attributeProperties_1.length; _b++) {
        var property = attributeProperties_1[_b];
        for (var _c = 0, _d = allAttributePropertyNames(property, showCardinality, localizer); _c < _d.length; _c++) {
            var attributePropertyName = _d[_c];
            if (attributePropertyName.length > length) {
                length = attributePropertyName.length;
            }
        }
    }
    return { width: Math.max(length * 6.5, 150), height: height };
}
export { ɵ0 };
