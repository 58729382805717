var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { dateSerializer, identitySerializer, localizableSerializer, optional } from './serializer/serializer';
import { glyphIconClassForType } from 'app/utils/entity';
import { init, serialize } from './mapping';
import { GraphNode } from './graphNode';
import { entity, entityAwareOptional, uriSerializer } from 'app/entities/serializer/entitySerializer';
var Vocabulary = /** @class */ (function (_super) {
    __extends(Vocabulary, _super);
    function Vocabulary(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Vocabulary.vocabularyMappings);
        return _this;
    }
    Vocabulary.vocabularyMappings = {
        id: { name: '@id', serializer: uriSerializer },
        status: { name: 'versionInfo', serializer: optional(identitySerializer()) },
        title: { name: 'prefLabel', serializer: localizableSerializer },
        description: { name: 'definition', serializer: localizableSerializer },
        modifiedAt: { name: 'modified', serializer: optional(dateSerializer) }
    };
    return Vocabulary;
}(GraphNode));
export { Vocabulary };
var Concept = /** @class */ (function (_super) {
    __extends(Concept, _super);
    function Concept(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, Concept.conceptMappings);
        return _this;
    }
    Object.defineProperty(Concept.prototype, "unsaved", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Concept.prototype, "normalizedType", {
        get: function () {
            return 'concept';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Concept.prototype, "glyphIconClass", {
        get: function () {
            return glyphIconClassForType(this.type);
        },
        enumerable: true,
        configurable: true
    });
    Concept.prototype.clone = function () {
        var serialization = this.serialize(false, true);
        return new Concept(serialization['@graph'], serialization['@context'], this.frame);
    };
    Concept.prototype.serializationValues = function (_inline, clone) {
        return serialize(this, clone, Concept.conceptMappings);
    };
    Concept.conceptMappings = {
        id: { name: '@id', serializer: uriSerializer },
        status: { name: 'versionInfo', serializer: optional(identitySerializer()) },
        label: { name: 'prefLabel', serializer: localizableSerializer },
        definition: { name: 'definition', serializer: localizableSerializer },
        modifiedAt: { name: 'modified', serializer: optional(dateSerializer) },
        vocabulary: { name: 'inScheme', serializer: entityAwareOptional(entity(function () { return ConceptVocabulary; })) } // TODO should be mandatory
    };
    return Concept;
}(GraphNode));
export { Concept };
var ConceptVocabulary = /** @class */ (function (_super) {
    __extends(ConceptVocabulary, _super);
    function ConceptVocabulary(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        init(_this, ConceptVocabulary.conceptVocabularyMappings);
        return _this;
    }
    ConceptVocabulary.conceptVocabularyMappings = {
        id: { name: '@id', serializer: uriSerializer },
        title: { name: 'prefLabel', serializer: localizableSerializer }
    };
    return ConceptVocabulary;
}(GraphNode));
export { ConceptVocabulary };
