import * as moment from 'moment';
import { ResourceStore } from './resourceStore';
import { requireDefined } from 'yti-common-ui/utils/object';
import { contains } from 'yti-common-ui/utils/array';
var InteractiveHelpModelService = /** @class */ (function () {
    function InteractiveHelpModelService($q, defaultModelService, classificationService, helpOrganizationService, entityCreatorService) {
        'ngInject';
        this.$q = $q;
        this.defaultModelService = defaultModelService;
        this.classificationService = classificationService;
        this.helpOrganizationService = helpOrganizationService;
        this.entityCreatorService = entityCreatorService;
        this.store = new ResourceStore();
    }
    InteractiveHelpModelService.prototype.reset = function () {
        this.store.clear();
        return this.$q.when();
    };
    InteractiveHelpModelService.prototype.getModels = function () {
        return this.$q.when(this.store.values());
    };
    InteractiveHelpModelService.prototype.getModelByUrn = function (urn) {
        return this.$q.when(this.store.get(urn.toString()));
    };
    InteractiveHelpModelService.prototype.getModelByPrefix = function (prefix) {
        return this.$q.when(requireDefined(this.store.findFirst(function (model) { return model.prefix === prefix; })));
    };
    InteractiveHelpModelService.prototype.createModel = function (model) {
        model.unsaved = false;
        model.createdAt = moment();
        this.store.add(model);
        return this.$q.when();
    };
    InteractiveHelpModelService.prototype.updateModel = function (model) {
        model.modifiedAt = moment();
        this.store.add(model);
        return this.$q.when();
    };
    InteractiveHelpModelService.prototype.deleteModel = function (id) {
        this.store.delete(id.uri);
        return this.$q.when();
    };
    InteractiveHelpModelService.prototype.newModel = function (prefix, label, classifications, organizations, lang, type, redirect) {
        var _this = this;
        var allClassificationsPromise = this.classificationService.getClassifications();
        var allOrganizationsPromise = this.helpOrganizationService.getOrganizations();
        var classificationsPromise = allClassificationsPromise.then(function (allClassifications) {
            return allClassifications.filter(function (c) { return contains(classifications, c.identifier); });
        });
        var organizationsPromise = allOrganizationsPromise.then(function (allOrganizations) {
            return allOrganizations.filter(function (o) { return contains(organizations, o.id.uri); });
        });
        return this.$q.all([classificationsPromise, organizationsPromise]).then(function (_a) {
            var cs = _a[0], os = _a[1];
            return _this.entityCreatorService.createModel({
                type: type,
                label: (_b = {}, _b[lang[0]] = label, _b),
                prefix: prefix,
                organizations: os,
                classifications: cs,
                languages: lang
            });
            var _b;
        });
    };
    InteractiveHelpModelService.prototype.newModelRequirement = function (model, resourceUri) {
        throw new Error('newModelRequirement is not yet supported operation in help');
    };
    InteractiveHelpModelService.prototype.newLink = function (title, description, homepage, lang) {
        throw new Error('newLink is not yet supported operation in help');
    };
    InteractiveHelpModelService.prototype.getAllImportableNamespaces = function () {
        return this.entityCreatorService.createImportedNamespaces(this.store.values());
    };
    InteractiveHelpModelService.prototype.newNamespaceImport = function (namespace, prefix, label, lang) {
        return this.entityCreatorService.createImportedNamespace({
            namespace: namespace,
            prefix: prefix,
            label: (_a = {}, _a[lang] = label, _a)
        });
        var _a;
    };
    InteractiveHelpModelService.prototype.changeStatuses = function (model, initialStatus, endStatus) {
        throw new Error('changeStatuses is not yet supported operation in help');
    };
    InteractiveHelpModelService.prototype.getModelResourcesTotalCountByStatus = function (model, resourceStatus) {
        throw new Error('getModelResourcesTotalCountByStatus is not yet supported operation in help');
    };
    InteractiveHelpModelService.prototype.createNewModelVersion = function (newPrefix, uri) {
        throw new Error('createNewModelVersion is not yet supported operation in help');
    };
    return InteractiveHelpModelService;
}());
export { InteractiveHelpModelService };
