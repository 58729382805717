import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { ifChanged, modalCancelHandler } from '../../utils/angular';
import { filterAndSortSearchResults } from '../../components/filter/util';
var noExclude = function (_ns) { return null; };
var ɵ0 = noExclude;
var SearchNamespaceModal = /** @class */ (function () {
    function SearchNamespaceModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchNamespaceModal.prototype.open = function (context, reservedPrefixes, usedNamespaces, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.$uibModal.open({
            template: require('./searchNamespaceModal.html'),
            size: 'md',
            controller: SearchNamespaceController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                exclude: function () { return exclude; },
                context: function () { return context; },
                reservedPrefixes: function () { return reservedPrefixes; },
                usedNamespaces: function () { return usedNamespaces; }
            }
        }).result;
    };
    return SearchNamespaceModal;
}());
export { SearchNamespaceModal };
var SearchNamespaceController = /** @class */ (function () {
    function SearchNamespaceController($scope, $uibModalInstance, exclude, context, reservedPrefixes, usedNamespaces, modelService, languageService, addEditNamespaceModal) {
        'ngInject';
        var _this = this;
        this.$uibModalInstance = $uibModalInstance;
        this.exclude = exclude;
        this.context = context;
        this.reservedPrefixes = reservedPrefixes;
        this.usedNamespaces = usedNamespaces;
        this.languageService = languageService;
        this.addEditNamespaceModal = addEditNamespaceModal;
        this.searchText = '';
        this.contentExtractors = [function (ns) { return ns.namespace; }, function (ns) { return ns.label; }];
        this.searchFilters = [];
        this.loadingResults = true;
        modelService.getAllImportableNamespaces().then(function (result) {
            _this.namespaces = result;
            _this.search();
            _this.loadingResults = false;
        });
        this.addFilter(function (ns) {
            return _this.showTechnical || !!_this.searchText || !ns.item.technical;
        });
        $scope.$watch(function () { return _this.showTechnical; }, ifChanged(function () { return _this.search(); }));
    }
    SearchNamespaceController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    Object.defineProperty(SearchNamespaceController.prototype, "items", {
        get: function () {
            return this.namespaces;
        },
        enumerable: true,
        configurable: true
    });
    SearchNamespaceController.prototype.search = function () {
        var _this = this;
        var comparator = comparingPrimitive(function (item) { return !!_this.exclude(item.item); })
            .andThen(comparingPrimitive(function (item) { return item.item.namespace; }));
        this.searchResults = filterAndSortSearchResults(this.namespaces, this.searchText, this.contentExtractors, this.searchFilters, comparator);
    };
    SearchNamespaceController.prototype.textFilter = function (ns) {
        var search = this.searchText.toLowerCase();
        function contains(text) {
            return (text || '').toLowerCase().includes(search);
        }
        return !this.searchText || contains(this.languageService.translate(ns.label, this.context)) || contains(ns.namespace);
    };
    SearchNamespaceController.prototype.selectItem = function (ns) {
        if (!this.exclude(ns)) {
            this.$uibModalInstance.close(ns);
        }
    };
    SearchNamespaceController.prototype.createNew = function () {
        var _this = this;
        var language = this.languageService.getModelLanguage(this.context);
        this.addEditNamespaceModal.openAdd(this.context, language, this.reservedPrefixes, this.usedNamespaces)
            .then(function (ns) { return _this.$uibModalInstance.close(ns); }, modalCancelHandler);
    };
    SearchNamespaceController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return SearchNamespaceController;
}());
export { ɵ0 };
