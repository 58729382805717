var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LegacyComponent } from 'app/utils/angular';
import { assertNever } from 'yti-common-ui/utils/object';
import { elementPositioning } from 'app/help/utils/component';
var InteractiveHelpBackdropComponent = /** @class */ (function () {
    function InteractiveHelpBackdropComponent($document) {
        'ngInject';
        this.$document = $document;
    }
    InteractiveHelpBackdropComponent_1 = InteractiveHelpBackdropComponent;
    InteractiveHelpBackdropComponent.prototype.$onInit = function () {
        this.helpController.registerBackdrop(this);
    };
    InteractiveHelpBackdropComponent.prototype.setFullBackdrop = function () {
        this.regions = InteractiveHelpBackdropComponent_1.fullBackdrop;
    };
    InteractiveHelpBackdropComponent.prototype.updatePosition = function () {
        if (this.item) {
            this.regions = this.resolveRegions(this.item);
        }
    };
    InteractiveHelpBackdropComponent.prototype.resolveRegions = function (item) {
        switch (item.type) {
            case 'story':
                return InteractiveHelpBackdropComponent_1.calculateRegions(item, this.$document.width());
            case 'notification':
                return InteractiveHelpBackdropComponent_1.fullBackdrop;
            default:
                return assertNever(item, 'Unknown item type');
        }
    };
    InteractiveHelpBackdropComponent.calculateRegions = function (story, documentWidth) {
        var positioning = InteractiveHelpBackdropComponent_1.calculateFocusPositioning(story);
        if (!positioning) {
            return null;
        }
        return {
            top: {
                left: 0,
                top: 0,
                right: 0,
                height: positioning.top - window.pageYOffset
            },
            right: {
                left: positioning.left + positioning.width,
                top: positioning.top - window.pageYOffset,
                width: documentWidth - positioning.left - positioning.width,
                height: positioning.height
            },
            bottom: {
                left: 0,
                top: positioning.top + positioning.height - window.pageYOffset,
                right: 0,
                bottom: 0
            },
            left: {
                left: 0,
                top: positioning.top - window.pageYOffset,
                width: positioning.left,
                height: positioning.height
            },
            focus: {
                left: positioning.left,
                top: positioning.top - window.pageYOffset,
                width: positioning.width,
                height: positioning.height
            }
        };
    };
    InteractiveHelpBackdropComponent.calculateFocusPositioning = function (story) {
        if (!story || !story.focus) {
            return null;
        }
        var focusTo = story.focus;
        var focusToElementPositioning = elementPositioning(story.focus.element());
        if (!focusToElementPositioning) {
            return null;
        }
        var marginTop = focusTo.margin && focusTo.margin.top || 0;
        var marginRight = focusTo.margin && focusTo.margin.right || 0;
        var marginBottom = focusTo.margin && focusTo.margin.bottom || 0;
        var marginLeft = focusTo.margin && focusTo.margin.left || 0;
        return {
            width: focusToElementPositioning.width + marginLeft + marginRight,
            height: focusToElementPositioning.height + marginTop + marginBottom,
            left: focusToElementPositioning.left - marginLeft,
            top: focusToElementPositioning.top - marginTop
        };
    };
    InteractiveHelpBackdropComponent.fullBackdrop = {
        top: { left: 0, top: 0, right: 0, bottom: 0 },
        right: { left: 0, top: 0, width: 0, height: 0 },
        bottom: { left: 0, top: 0, width: 0, height: 0 },
        left: { left: 0, top: 0, width: 0, height: 0 },
        focus: { left: 0, top: 0, width: 0, height: 0 }
    };
    InteractiveHelpBackdropComponent = InteractiveHelpBackdropComponent_1 = __decorate([
        LegacyComponent({
            bindings: {
                item: '<',
                helpController: '<'
            },
            template: "\n        <div ng-if=\"$ctrl.regions\" class=\"help-backdrop\" ng-style=\"$ctrl.regions.top\"></div>\n        <div ng-if=\"$ctrl.regions\" class=\"help-backdrop\" ng-style=\"$ctrl.regions.right\"></div>\n        <div ng-if=\"$ctrl.regions\" class=\"help-backdrop\" ng-style=\"$ctrl.regions.bottom\"></div>\n        <div ng-if=\"$ctrl.regions\" class=\"help-backdrop\" ng-style=\"$ctrl.regions.left\"></div>\n  "
        }),
        __metadata("design:paramtypes", [Object])
    ], InteractiveHelpBackdropComponent);
    return InteractiveHelpBackdropComponent;
    var InteractiveHelpBackdropComponent_1;
}());
export { InteractiveHelpBackdropComponent };
