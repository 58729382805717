var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { comparingLocalizable } from 'app/utils/comparator';
import { AddNew } from 'app/components/common/searchResults';
import { anyMatching, allMatching } from 'yti-common-ui/utils/array';
import * as _ from 'lodash';
import { ifChanged } from 'app/utils/angular';
import { ReferenceData } from 'app/entities/referenceData';
import { filterAndSortSearchResults, defaultTitleComparator } from 'app/components/filter/util';
import { regularStatuses } from 'yti-common-ui/entities/status';
var noExclude = function (_referenceData) { return null; };
var ɵ0 = noExclude;
var SearchReferenceDataModal = /** @class */ (function () {
    function SearchReferenceDataModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchReferenceDataModal.prototype.open = function (model, context, exclude) {
        return this.$uibModal.open({
            template: require('./searchReferenceDataModal.html'),
            size: 'lg',
            controller: SearchReferenceDataModalController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                model: function () { return model; },
                context: function () { return context; },
                exclude: function () { return exclude; }
            }
        }).result;
    };
    SearchReferenceDataModal.prototype.openSelectionForModel = function (context, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.open(null, context, exclude);
    };
    SearchReferenceDataModal.prototype.openSelectionForProperty = function (model, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.open(model, model, exclude);
    };
    return SearchReferenceDataModal;
}());
export { SearchReferenceDataModal };
var SearchReferenceDataModalController = /** @class */ (function () {
    function SearchReferenceDataModalController($scope, $uibModalInstance, model, context, referenceDataService, languageService, gettextCatalog, exclude) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.model = model;
        this.context = context;
        this.referenceDataService = referenceDataService;
        this.gettextCatalog = gettextCatalog;
        this.exclude = exclude;
        this.searchText = '';
        this.loadingResults = true;
        this.submitError = null;
        this.contentMatchers = [
            { name: 'Label', extractor: function (referenceData) { return referenceData.title; } },
            { name: 'Description', extractor: function (referenceData) { return referenceData.description; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.searchFilters = [];
        this.localizer = languageService.createLocalizer(context);
        var init = function (referenceDatas) {
            _this.referenceDatas = referenceDatas;
            _this.referenceDataGroups = _.chain(_this.referenceDatas)
                .map(function (referenceData) { return referenceData.groups; })
                .flatten()
                .uniqBy(function (group) { return group.id.uri; })
                .value()
                .sort(comparingLocalizable(_this.localizer, function (group) { return group.title; }));
            if (_this.showGroup && allMatching(_this.referenceDataGroups, function (group) { return !group.id.equals(_this.showGroup.id); })) {
                _this.showGroup = null;
            }
            _this.search();
            _this.loadingResults = false;
        };
        if (model != null) {
            init(model.referenceDatas);
        }
        else {
            var serversPromise_1 = referenceDataService.getReferenceDataServers().then(function (servers) { return _this.referenceDataServers = servers; });
            $scope.$watch(function () { return _this.showServer; }, function (server) {
                _this.loadingResults = true;
                serversPromise_1
                    .then(function (servers) { return referenceDataService.getReferenceDatasForServers(server ? [server] : servers); })
                    .then(init);
            });
        }
        this.addFilter(function (referenceData) {
            return !_this.showGroup || anyMatching(referenceData.item.groups, function (group) { return group.id.equals(_this.showGroup.id); });
        });
        this.addFilter(function (referenceData) {
            return !_this.showStatus || referenceData.item.status === _this.showStatus;
        });
        $scope.$watch(function () { return _this.showGroup; }, ifChanged(function () { return _this.search(); }));
        $scope.$watch(function () { return _this.showStatus; }, ifChanged(function () { return _this.search(); }));
    }
    SearchReferenceDataModalController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    Object.defineProperty(SearchReferenceDataModalController.prototype, "items", {
        get: function () {
            return this.referenceDatas;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchReferenceDataModalController.prototype, "showExcluded", {
        get: function () {
            return !!this.searchText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchReferenceDataModalController.prototype, "statuses", {
        get: function () {
            return regularStatuses;
        },
        enumerable: true,
        configurable: true
    });
    SearchReferenceDataModalController.prototype.search = function () {
        if (this.referenceDatas) {
            this.searchResults = [
                new AddNewReferenceData(this.gettextCatalog.getString('Create new reference data') + " '" + this.searchText + "'", this.canAddNew.bind(this))
            ].concat(filterAndSortSearchResults(this.referenceDatas, this.searchText, this.contentExtractors, this.searchFilters, defaultTitleComparator(this.localizer, this.exclude)));
        }
    };
    SearchReferenceDataModalController.prototype.selectItem = function (item) {
        this.selectedItem = item;
        this.submitError = null;
        this.$scope.form.editing = false;
        this.$scope.form.$setPristine();
        if (item instanceof AddNewReferenceData) {
            this.$scope.form.editing = true;
            this.selection = new AddNewReferenceDataFormData();
        }
        else if (item instanceof ReferenceData) {
            this.cannotConfirm = this.exclude(item);
            if (!this.cannotConfirm) {
                this.selection = item;
            }
        }
        else {
            throw new Error('Unsupported item: ' + item);
        }
    };
    SearchReferenceDataModalController.prototype.confirm = function () {
        var _this = this;
        var selection = this.selection;
        if (selection instanceof AddNewReferenceDataFormData) {
            this.referenceDataService.newReferenceData(selection.uri, selection.label, selection.description, this.localizer.language)
                .then(function (referenceData) { return _this.$uibModalInstance.close(referenceData); }, function (err) { return _this.submitError = err.data.errorMessage; });
        }
        else {
            this.$uibModalInstance.close(selection);
        }
    };
    SearchReferenceDataModalController.prototype.loadingSelection = function (item) {
        var selection = this.selection;
        if (item instanceof ReferenceData) {
            return item === this.selectedItem && (!selection || (!this.isSelectionFormData() && !item.id.equals(selection.id)));
        }
        else {
            return false;
        }
    };
    SearchReferenceDataModalController.prototype.isSelectionFormData = function () {
        return this.selection instanceof AddNewReferenceDataFormData;
    };
    SearchReferenceDataModalController.prototype.canAddNew = function () {
        return !!this.searchText && !this.model;
    };
    SearchReferenceDataModalController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return SearchReferenceDataModalController;
}());
export { SearchReferenceDataModalController };
var AddNewReferenceDataFormData = /** @class */ (function () {
    function AddNewReferenceDataFormData() {
    }
    return AddNewReferenceDataFormData;
}());
var AddNewReferenceData = /** @class */ (function (_super) {
    __extends(AddNewReferenceData, _super);
    function AddNewReferenceData(label, show) {
        var _this = _super.call(this, label, show) || this;
        _this.label = label;
        _this.show = show;
        return _this;
    }
    return AddNewReferenceData;
}(AddNew));
export { ɵ0 };
