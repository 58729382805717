
    <dl *ngIf="show">
      <dt>
        <label translate>Use context</label>
      </dt>
      <dd>
        <span *ngIf="!editing">{{useContext | translate}}</span>
        <app-use-context-dropdown id="use_context_input_dropdown"
                                  *ngIf="editing"
                                  [modelType]="modelType"
                                  [formControl]="control"></app-use-context-dropdown>
      </dd>
    </dl>
  