var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ImportedNamespace, Model } from 'app/entities/model';
import { dateSerializer, typeSerializer } from 'app/entities/serializer/serializer';
import { uriSerializer } from 'app/entities/serializer/entitySerializer';
import { Uri } from 'app/entities/uri';
import { classIdFromNamespaceId, modelIdFromPrefix, predicateIdFromNamespaceId, vocabularyIdFromPrefix } from 'app/help/utils/id';
import { upperCaseFirst } from 'change-case';
import { Concept, Vocabulary } from 'app/entities/vocabulary';
import * as moment from 'moment';
import { Association, Attribute } from 'app/entities/predicate';
import { Class, Property } from 'app/entities/class';
import { Organization } from 'app/entities/organization';
export var technicalNamespaces = {
    'schema': 'http://schema.org/',
    'dcap': 'http://purl.org/ws-mmi-dc/terms/',
    'void': 'http://rdfs.org/ns/void#',
    'adms': 'http://www.w3.org/ns/adms#',
    'skosxl': 'http://www.w3.org/2008/05/skos-xl#',
    'dcam': 'http://purl.org/dc/dcam/',
    'owl': 'http://www.w3.org/2002/07/owl#',
    'afn': 'http://jena.hpl.hp.com/ARQ/function#',
    'xsd': 'http://www.w3.org/2001/XMLSchema#',
    'skos': 'http://www.w3.org/2004/02/skos/core#',
    'rdfs': 'http://www.w3.org/2000/01/rdf-schema#',
    'iow': 'http://uri.suomi.fi/datamodel/ns/iow#',
    'sd': 'http://www.w3.org/ns/sparql-service-description#',
    'at': 'http://publications.europa.eu/ontology/authority/',
    'sh': 'http://www.w3.org/ns/shacl#',
    'rdf': 'http://www.w3.org/1999/02/22-rdf-syntax-ns#',
    'dcterms': 'http://purl.org/dc/terms/',
    'text': 'http://jena.apache.org/text#',
    'termed': 'http://termed.thl.fi/meta/',
    'prov': 'http://www.w3.org/ns/prov#',
    'foaf': 'http://xmlns.com/foaf/0.1/',
    'ts': 'http://www.w3.org/2003/06/sw-vocab-status/ns#',
    'dc': 'http://purl.org/dc/elements/1.1/'
};
var terminologyContext = {
    prefLabel: { '@id': 'http://www.w3.org/2004/02/skos/core#prefLabel' },
    definition: { '@id': 'http://www.w3.org/2004/02/skos/core#definition' },
    description: { '@id': 'http://purl.org/dc/terms/description' },
    inScheme: { '@id': 'http://www.w3.org/2004/02/skos/core#inScheme', '@type': '@id' },
};
// XXX: currently only for help/entity loader usage, but it should be investigated if creator apis could be replaced completely
var EntityCreatorService = /** @class */ (function () {
    function EntityCreatorService($q) {
        'ngInject';
        this.$q = $q;
    }
    EntityCreatorService.prototype.createModel = function (details) {
        var namespace = "http://uri.suomi.fi/datamodel/ns/" + details.prefix + "#";
        var context = __assign({ 'rest': {
                '@id': 'http://www.w3.org/1999/02/22-rdf-syntax-ns#rest',
                '@type': '@id'
            }, 'first': {
                '@id': 'http://www.w3.org/1999/02/22-rdf-syntax-ns#first'
            }, 'identifier': {
                '@id': 'http://purl.org/dc/terms/identifier'
            }, 'label': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#label'
            }, 'prefLabel': {
                '@id': 'http://www.w3.org/2004/02/skos/core#prefLabel'
            }, 'preferredXMLNamespacePrefix': {
                '@id': 'http://purl.org/ws-mmi-dc/terms/preferredXMLNamespacePrefix'
            }, 'modified': {
                '@id': 'http://purl.org/dc/terms/modified',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'created': {
                '@id': 'http://purl.org/dc/terms/created',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'contributor': {
                '@id': 'http://purl.org/dc/terms/contributor',
                '@type': '@id'
            }, 'isPartOf': {
                '@id': 'http://purl.org/dc/terms/isPartOf',
                '@type': '@id'
            }, 'preferredXMLNamespaceName': {
                '@id': 'http://purl.org/ws-mmi-dc/terms/preferredXMLNamespaceName'
            }, 'language': {
                '@id': 'http://purl.org/dc/terms/language',
                '@type': '@id'
            }, 'versionInfo': {
                '@id': 'http://www.w3.org/2002/07/owl#versionInfo'
            } }, technicalNamespaces, (_a = {}, _a[details.prefix] = namespace, _a));
        var currentTime = dateSerializer.serialize(moment());
        var graph = {
            '@id': modelIdFromPrefix(details.prefix),
            '@type': details.type === 'profile' ? ['owl:Ontology', 'dcap:DCAP'] : ['owl:Ontology', 'dcap:MetadataVocabulary'],
            'contributor': details.organizations.map(function (o) { return o.serialize(true, false); }),
            'created': currentTime,
            'isPartOf': details.classifications.map(function (c) { return c.serialize(true, false); }),
            'dcterms:language': details.languages,
            'modified': currentTime,
            'preferredXMLNamespaceName': namespace,
            'preferredXMLNamespacePrefix': details.prefix,
            'label': __assign({}, details.label),
            'versionInfo': 'DRAFT'
        };
        return this.$q.when(new Model(graph, context, null));
        var _a;
    };
    EntityCreatorService.prototype.createImportedNamespaces = function (detailsList) {
        var _this = this;
        return this.$q.all(detailsList.map(function (details) { return _this.createImportedNamespace(details); }));
    };
    EntityCreatorService.prototype.createImportedNamespace = function (details) {
        var context = __assign({ 'preferredXMLNamespacePrefix': {
                '@id': 'http://purl.org/ws-mmi-dc/terms/preferredXMLNamespacePrefix'
            }, 'preferredXMLNamespaceName': {
                '@id': 'http://purl.org/ws-mmi-dc/terms/preferredXMLNamespaceName'
            }, 'label': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#label'
            } }, technicalNamespaces);
        if (details instanceof Model) {
            details.expandContextWithKnownModels(context);
        }
        else {
            context[details.prefix] = details.namespace;
        }
        var graph = {
            '@id': details instanceof Model ? uriSerializer.serialize(details.id, false) : details.namespace,
            '@type': details instanceof Model ? typeSerializer.serialize(details.type) : 'dcterms:Standard',
            'label': __assign({}, details.label),
            'preferredXMLNamespaceName': details.namespace,
            'preferredXMLNamespacePrefix': details.prefix
        };
        return this.$q.when(new ImportedNamespace(graph, context, null));
    };
    EntityCreatorService.prototype.createOrganization = function (details) {
        var context = {
            'homepage': {
                '@id': 'http://xmlns.com/foaf/0.1/homepage'
            },
            'prefLabel': {
                '@id': 'http://www.w3.org/2004/02/skos/core#prefLabel'
            },
            'description': {
                '@id': 'http://purl.org/dc/terms/description'
            }
        };
        var graph = {
            '@id': details.id.uri,
            '@type': 'foaf:Organization',
            'prefLabel': __assign({}, details.label)
        };
        return this.$q.when(new Organization(graph, context, null));
    };
    EntityCreatorService.prototype.createPredicate = function (details) {
        var context = __assign({ 'versionInfo': {
                '@id': 'http://www.w3.org/2002/07/owl#versionInfo'
            }, 'modified': {
                '@id': 'http://purl.org/dc/terms/modified',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'created': {
                '@id': 'http://purl.org/dc/terms/created',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'label': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#label'
            }, 'isDefinedBy': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#isDefinedBy',
                '@type': '@id'
            } }, technicalNamespaces);
        details.model.expandContextWithKnownModels(context);
        var currentTime = dateSerializer.serialize(moment());
        var graph = {
            '@id': predicateIdFromNamespaceId(details.model.id.uri, details.label),
            '@type': typeSerializer.serialize([details.type]),
            created: currentTime,
            modified: currentTime,
            subject: details.concept ? details.concept.serialize(true, false) : null,
            label: (_a = {}, _a[details.lang] = upperCaseFirst(details.label), _a),
            comment: details.concept ? __assign({}, details.concept.definition) : {},
            isDefinedBy: details.model.asDefinedBy().serialize(true, false),
            versionInfo: 'DRAFT'
        };
        if (details.type === 'attribute') {
            graph.range = 'xsd:string';
        }
        return this.$q.when(details.type === 'attribute' ? new Attribute(graph, context, null)
            : new Association(graph, context, null));
        var _a;
    };
    EntityCreatorService.prototype.createClass = function (details) {
        var context = __assign({ 'subject': {
                '@id': 'http://purl.org/dc/terms/subject',
                '@type': '@id'
            }, 'description': {
                '@id': 'http://www.w3.org/ns/shacl#description'
            }, 'name': {
                '@id': 'http://www.w3.org/ns/shacl#name'
            }, 'isDefinedBy': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#isDefinedBy',
                '@type': '@id'
            }, 'created': {
                '@id': 'http://purl.org/dc/terms/created',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'modified': {
                '@id': 'http://purl.org/dc/terms/modified',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            }, 'versionInfo': {
                '@id': 'http://www.w3.org/2002/07/owl#versionInfo'
            }, 'prefLabel': {
                '@id': 'http://www.w3.org/2004/02/skos/core#prefLabel'
            }, 'label': {
                '@id': 'http://www.w3.org/2000/01/rdf-schema#label'
            }, 'definition': {
                '@id': 'http://www.w3.org/2004/02/skos/core#definition'
            }, 'inScheme': {
                '@id': 'http://www.w3.org/2004/02/skos/core#inScheme',
                '@type': '@id'
            }, 'graph': {
                '@id': 'http://termed.thl.fi/meta/graph'
            }, 'id': {
                '@id': 'http://termed.thl.fi/meta/id'
            } }, technicalNamespaces);
        details.model.expandContextWithKnownModels(context);
        var currentTime = dateSerializer.serialize(moment());
        var graph = {
            '@id': classIdFromNamespaceId(details.model.id.uri, details.label),
            '@type': 'rdfs:Class',
            created: currentTime,
            modified: currentTime,
            subject: details.concept ? details.concept.serialize(true, false) : null,
            name: (_a = {}, _a[details.lang] = upperCaseFirst(details.label), _a),
            description: details.concept ? __assign({}, details.concept.definition) : {},
            isDefinedBy: details.model.asDefinedBy().serialize(true, false),
            versionInfo: 'DRAFT'
        };
        return this.$q.when(new Class(graph, context, null));
        var _a;
    };
    EntityCreatorService.prototype.createShape = function (klass, profile) {
        var shape = klass.clone();
        var currentTime = moment();
        shape.context[profile.prefix] = profile.namespace;
        shape.id = new Uri(profile.namespace + shape.id.name, shape.context);
        shape.definedBy = profile.asDefinedBy();
        shape.unsaved = true;
        shape.createdAt = currentTime;
        shape.modifiedAt = currentTime;
        shape.scopeClass = klass.id;
        for (var _i = 0, _a = shape.properties; _i < _a.length; _i++) {
            var property = _a[_i];
            property.status = 'DRAFT';
        }
        shape.status = 'DRAFT';
        return this.$q.when(shape);
    };
    EntityCreatorService.prototype.createProperty = function (details) {
        var currentTime = dateSerializer.serialize(moment());
        var context = __assign({ 'name': {
                '@id': 'http://www.w3.org/ns/shacl#name'
            }, 'localName': {
                '@id': 'http://uri.suomi.fi/datamodel/ns/iow#localName'
            }, 'datatype': {
                '@id': 'http://www.w3.org/ns/shacl#datatype',
                '@type': '@id'
            }, 'description': {
                '@id': 'http://www.w3.org/ns/shacl#description'
            }, 'path': {
                '@id': 'http://www.w3.org/ns/shacl#path',
                '@type': '@id'
            }, 'equivalentProperty': {
                '@id': 'http://www.w3.org/2002/07/owl#equivalentProperty',
                '@type': '@id'
            }, 'type': {
                '@id': 'http://purl.org/dc/terms/type',
                '@type': '@id'
            }, 'created': {
                '@id': 'http://purl.org/dc/terms/created',
                '@type': 'http://www.w3.org/2001/XMLSchema#dateTime'
            } }, technicalNamespaces, details.predicate.context);
        var graph = {
            '@id': Uri.randomUUID().toString(),
            '@type': 'sh:PropertyShape',
            created: currentTime,
            type: typeSerializer.serialize([details.type])[0],
            name: __assign({}, details.predicate.label),
            description: __assign({}, details.predicate.comment),
            path: details.predicate.id.curie
        };
        function predicateIsAssociation(predicate) {
            return predicate.isOfType('association');
        }
        function predicateIsAttribute(predicate) {
            return predicate.isOfType('attribute');
        }
        if (details.type === 'attribute') {
            if (!predicateIsAttribute(details.predicate)) {
                throw new Error('Predicate must be attribute');
            }
            graph.datatype = details.predicate.dataType || 'xsd:string';
        }
        else {
            if (!predicateIsAssociation(details.predicate)) {
                throw new Error('Predicate must be association');
            }
            graph.node = details.predicate.valueClass && details.predicate.valueClass.curie;
        }
        return this.$q.when(new Property(graph, context, null));
    };
    EntityCreatorService.prototype.createVocabulary = function (details) {
        var id = vocabularyIdFromPrefix(details.prefix);
        var graph = {
            '@id': id,
            '@type': 'skos:ConceptScheme',
            type: 'TerminologicalVocabulary',
            prefLabel: __assign({}, details.label),
            description: __assign({}, details.description)
        };
        return this.$q.when(new Vocabulary(graph, terminologyContext, null));
    };
    EntityCreatorService.prototype.createConcept = function (details) {
        var vocabularyId = details.vocabulary.id.uri;
        var id = vocabularyId + 'concept-' + details.index;
        var graph = {
            '@id': id,
            '@type': 'skos:Concept',
            inScheme: details.vocabulary.serialize(true, false),
            definition: __assign({}, details.definition),
            prefLabel: __assign({}, details.label)
        };
        return this.$q.when(new Concept(graph, terminologyContext, null));
    };
    return EntityCreatorService;
}());
export { EntityCreatorService };
