var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from 'app/services/languageService';
import { TableDescriptor } from 'app/components/form/editableTable';
import { SearchVocabularyModal } from './searchVocabularyModal';
import { createExistsExclusion } from 'app/utils/exclusion';
import { collectProperties } from 'yti-common-ui/utils/array';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
import { TranslateService } from '@ngx-translate/core';
import { DisplayItemFactory } from 'app/components/form/displayItemFactory';
var VocabulariesViewComponent = /** @class */ (function () {
    function VocabulariesViewComponent($scope, searchVocabularyModal, languageService, translateService, displayItemFactory) {
        'ngInject';
        this.$scope = $scope;
        this.searchVocabularyModal = searchVocabularyModal;
        this.languageService = languageService;
        this.translateService = translateService;
        this.displayItemFactory = displayItemFactory;
    }
    VocabulariesViewComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.value; }, function (value) {
            _this.descriptor = new VocabularyTableDescriptor(value, _this.context, _this.languageService, _this.translateService, _this.displayItemFactory);
        });
    };
    VocabulariesViewComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    VocabulariesViewComponent.prototype.addVocabulary = function () {
        var _this = this;
        var vocabularies = collectProperties(this.value.vocabularies, function (vocabulary) { return vocabulary.id.uri; });
        var exclude = createExistsExclusion(vocabularies);
        this.searchVocabularyModal.open(this.context, exclude)
            .then(function (vocabulary) {
            _this.value.addVocabulary(vocabulary);
            _this.expanded = true;
        }, modalCancelHandler);
    };
    VocabulariesViewComponent = __decorate([
        LegacyComponent({
            bindings: {
                value: '=',
                context: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n      <h4>\n        <span translate>Terminologies</span>\n        <button id=\"add_vocabulary_button\" type=\"button\" class=\"btn btn-link btn-xs pull-right\" ng-click=\"$ctrl.addVocabulary()\" ng-show=\"$ctrl.isEditing()\">\n          <span translate>Add vocabulary</span>\n        </button>\n      </h4>\n      <editable-table id=\"'vocabularies'\" descriptor=\"$ctrl.descriptor\" expanded=\"$ctrl.expanded\"></editable-table>\n  "
        }),
        __metadata("design:paramtypes", [Object, SearchVocabularyModal,
            LanguageService,
            TranslateService,
            DisplayItemFactory])
    ], VocabulariesViewComponent);
    return VocabulariesViewComponent;
}());
export { VocabulariesViewComponent };
var VocabularyTableDescriptor = /** @class */ (function (_super) {
    __extends(VocabularyTableDescriptor, _super);
    function VocabularyTableDescriptor(value, context, languageService, translateService, displayItemFactory) {
        var _this = _super.call(this) || this;
        _this.value = value;
        _this.context = context;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.displayItemFactory = displayItemFactory;
        return _this;
    }
    VocabularyTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        return [
            { headerName: 'Vocabulary name', nameExtractor: function (vocabulary) { return _this.languageService.translate(vocabulary.title, _this.context); }, hrefExtractor: function (vocabulary) { return vocabulary.id.uri; } },
            { headerName: 'Status', nameExtractor: function (vocabulary) { return vocabulary.status ? _this.translateService.instant(vocabulary.status) : ''; } },
            { headerName: 'Modified at', nameExtractor: function (vocabulary) { return vocabulary.modifiedAt ? _this.showItemValue(vocabulary.modifiedAt) : ''; } }
        ];
    };
    VocabularyTableDescriptor.prototype.values = function () {
        return this.value && this.value.vocabularies;
    };
    VocabularyTableDescriptor.prototype.canEdit = function (_vocabulary) {
        return false;
    };
    VocabularyTableDescriptor.prototype.canRemove = function (vocabulary) {
        return true;
    };
    VocabularyTableDescriptor.prototype.remove = function (vocabulary) {
        this.value.removeVocabulary(vocabulary);
    };
    VocabularyTableDescriptor.prototype.orderBy = function (vocabulary) {
        return vocabulary.id;
    };
    VocabularyTableDescriptor.prototype.showItemValue = function (value) {
        var _this = this;
        return this.displayItemFactory.create({
            context: function () { return _this.context; },
            value: function () { return value; }
        }).displayValue;
    };
    return VocabularyTableDescriptor;
}(TableDescriptor));
