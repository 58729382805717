var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LegacyComponent } from 'app/utils/angular';
var ModelFormComponent = /** @class */ (function () {
    function ModelFormComponent() {
    }
    Object.defineProperty(ModelFormComponent.prototype, "allowProfiles", {
        get: function () {
            return this.model.isOfType('profile');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelFormComponent.prototype, "previousModelLink", {
        get: function () {
            return this.model.previousModel ? this.model.previousModel.uri : null;
        },
        enumerable: true,
        configurable: true
    });
    ModelFormComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    ModelFormComponent.prototype.showChangeResourceStatusesCheckbox = function () {
        return this.form.editing && this.statusChanged;
    };
    ModelFormComponent = __decorate([
        LegacyComponent({
            bindings: {
                id: '=',
                model: '=',
                namespacesInUse: '<',
                statusChanged: '=',
                changeResourceStatusesToo: '='
            },
            require: {
                form: '?^form'
            },
            template: require('./modelForm.html')
        })
    ], ModelFormComponent);
    return ModelFormComponent;
}());
export { ModelFormComponent };
