import { isValidLabelLength, isValidModelLabelLength, isValidString } from './validators';
import { allLocalizations, hasLocalization } from 'app/utils/language';
export var LocalizedInputDirective = function (languageService) {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            context: '='
        },
        require: 'ngModel',
        link: function ($scope, element, attributes, ngModel) {
            var localized;
            function setPlaceholder() {
                element.attr('placeholder', languageService.translate(localized, $scope.context));
            }
            function removePlaceholder() {
                element.removeAttr('placeholder');
            }
            $scope.$watch(function () { return languageService.getModelLanguage($scope.context); }, function (lang) {
                var val = localized[lang];
                if (!val) {
                    setPlaceholder();
                }
                element.val(val);
            });
            ngModel.$parsers.push(function (viewValue) {
                localized = Object.assign(localized, (_a = {},
                    _a[languageService.getModelLanguage($scope.context)] = viewValue,
                    _a));
                if (viewValue) {
                    removePlaceholder();
                }
                else {
                    setPlaceholder();
                }
                return localized;
                var _a;
            });
            ngModel.$formatters.push(function (modelValue) {
                localized = modelValue || {};
                var val = localized[languageService.getModelLanguage($scope.context)];
                if (!val) {
                    setPlaceholder();
                }
                return val;
            });
            if (attributes.localizedInput !== 'free') {
                ngModel.$validators['string'] = function (modelValue) { return allLocalizations(isValidString, modelValue); };
            }
            switch (attributes.localizedInput) {
                case 'required':
                    ngModel.$validators['requiredLocalized'] = hasLocalization;
                    break;
                case 'label':
                    ngModel.$validators['requiredLocalized'] = hasLocalization;
                    ngModel.$validators['length'] = function (modelValue) { return allLocalizations(isValidLabelLength, modelValue); };
                    break;
                case 'modelLabel':
                    ngModel.$validators['requiredLocalized'] = hasLocalization;
                    ngModel.$validators['length'] = function (modelValue) { return allLocalizations(isValidModelLabelLength, modelValue); };
                    break;
            }
        }
    };
};
