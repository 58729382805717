var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { arrowDown, arrowUp, enter, pageDown, pageUp } from 'yti-common-ui/utils/key-code';
import { LegacyDirective, scrollToElement } from 'app/utils/angular';
var KeyControlDirective = /** @class */ (function () {
    function KeyControlDirective($scope, $element) {
        'ngInject';
        var _this = this;
        this.$scope = $scope;
        this.$element = $element;
        this.itemCount = 0;
        this.selectionIndex = -1;
        this.keyEventHandlers = (_a = {},
            _a[arrowDown] = function () { return _this.moveSelection(1); },
            _a[arrowUp] = function () { return _this.moveSelection(-1); },
            _a[pageDown] = function () { return _this.moveSelection(10); },
            _a[pageUp] = function () { return _this.moveSelection(-10); },
            _a[enter] = function () { return _this.selectSelection(); },
            _a);
        var _a;
    }
    KeyControlDirective.prototype.$postLink = function () {
        var _this = this;
        this.$element.on('keydown', function (event) { return _this.keyPressed(event); });
        this.$scope.$watch(this.$element.attr('key-control') + '.length', function (items) { return _this.onItemsChange(items || 0); });
    };
    KeyControlDirective.prototype.onItemsChange = function (itemCount) {
        this.itemCount = itemCount;
        this.setSelection(-1);
    };
    KeyControlDirective.prototype.keyPressed = function (event) {
        var handler = this.keyEventHandlers[event.keyCode];
        if (handler) {
            event.preventDefault();
            handler();
        }
    };
    KeyControlDirective.prototype.moveSelection = function (offset) {
        this.setSelection(Math.max(Math.min(this.selectionIndex + offset, this.itemCount - 1), -1));
    };
    KeyControlDirective.prototype.setSelection = function (index) {
        this.selectionIndex = index;
        this.$scope.$parent.$broadcast('selectionMoved', this.selectionIndex);
    };
    KeyControlDirective.prototype.selectSelection = function () {
        this.$scope.$parent.$broadcast('selectionSelected', this.selectionIndex);
    };
    KeyControlDirective = __decorate([
        LegacyDirective({
            restrict: 'A'
        }),
        __metadata("design:paramtypes", [Object, Object])
    ], KeyControlDirective);
    return KeyControlDirective;
}());
export { KeyControlDirective };
var selectionClass = 'active';
var KeyControlSelectionDirective = /** @class */ (function () {
    function KeyControlSelectionDirective($scope, $element, $timeout) {
        'ngInject';
        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
    }
    KeyControlSelectionDirective.prototype.$postLink = function () {
        var _this = this;
        this.$scope.$on('selectionMoved', function (_event, selectionIndex) { return _this.update(selectionIndex); });
        this.$scope.$on('selectionSelected', function (_event, selectionIndex) {
            if (selectionIndex === _this.$scope.$index) {
                // do outside of digest cycle
                _this.$timeout(function () { return _this.$element.click(); });
            }
        });
    };
    KeyControlSelectionDirective.prototype.update = function (selectionIndex) {
        if (this.$scope.$index === selectionIndex) {
            this.$element.addClass(selectionClass);
            scrollToElement(this.$element, this.findParent());
        }
        else {
            this.$element.removeClass(selectionClass);
        }
    };
    KeyControlSelectionDirective.prototype.findParent = function () {
        var parent = this.$element.parent();
        if (parent.is('search-results')) {
            return parent.parent();
        }
        else {
            return parent;
        }
    };
    KeyControlSelectionDirective = __decorate([
        LegacyDirective({
            restrict: 'A'
        }),
        __metadata("design:paramtypes", [Object, Object, Function])
    ], KeyControlSelectionDirective);
    return KeyControlSelectionDirective;
}());
export { KeyControlSelectionDirective };
