var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SearchReferenceDataModal } from 'app/components/model/searchReferenceDataModal';
import { ViewReferenceDataModal } from 'app/components/model/viewReferenceDataModal';
import { TableDescriptor } from 'app/components/form/editableTable';
import { LanguageService } from 'app/services/languageService';
import { collectProperties, remove } from 'yti-common-ui/utils/array';
import { createExistsExclusion } from 'app/utils/exclusion';
import { LegacyComponent, modalCancelHandler } from 'app/utils/angular';
var EditableReferenceDataSelectComponent = /** @class */ (function () {
    function EditableReferenceDataSelectComponent($scope, searchReferenceDataModal, languageService, viewReferenceDataModal) {
        'ngInject';
        this.$scope = $scope;
        this.searchReferenceDataModal = searchReferenceDataModal;
        this.languageService = languageService;
        this.viewReferenceDataModal = viewReferenceDataModal;
    }
    EditableReferenceDataSelectComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.referenceData; }, function (referenceData) {
            _this.descriptor = new ReferenceDataTableDescriptor(referenceData, _this.model, _this.languageService.createLocalizer(_this.model), _this.viewReferenceDataModal);
        });
    };
    EditableReferenceDataSelectComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    EditableReferenceDataSelectComponent.prototype.addReferenceData = function () {
        var _this = this;
        var exclude = createExistsExclusion(collectProperties(this.referenceData, function (rd) { return rd.id.uri; }));
        this.searchReferenceDataModal.openSelectionForProperty(this.model, exclude).then(function (referenceData) {
            _this.expanded = true;
            _this.referenceData.push(referenceData);
        }, modalCancelHandler);
    };
    EditableReferenceDataSelectComponent = __decorate([
        LegacyComponent({
            bindings: {
                referenceData: '=',
                model: '='
            },
            require: {
                form: '?^form'
            },
            template: require('./editableReferenceDataSelect.html')
        }),
        __metadata("design:paramtypes", [Object, SearchReferenceDataModal,
            LanguageService,
            ViewReferenceDataModal])
    ], EditableReferenceDataSelectComponent);
    return EditableReferenceDataSelectComponent;
}());
export { EditableReferenceDataSelectComponent };
var ReferenceDataTableDescriptor = /** @class */ (function (_super) {
    __extends(ReferenceDataTableDescriptor, _super);
    function ReferenceDataTableDescriptor(referenceData, model, localizer, viewReferenceDataModal) {
        var _this = _super.call(this) || this;
        _this.referenceData = referenceData;
        _this.model = model;
        _this.localizer = localizer;
        _this.viewReferenceDataModal = viewReferenceDataModal;
        return _this;
    }
    ReferenceDataTableDescriptor.prototype.columnDescriptors = function () {
        var _this = this;
        // TODO: shared logic with referenceDatasView.ts
        var clickHandler = function (value) {
            if (value.isExternal()) {
                window.open(value.id.uri, '_blank');
            }
            else {
                _this.viewReferenceDataModal.open(value, _this.model);
            }
        };
        return [
            { headerName: 'Reference data name', nameExtractor: function (referenceData) { return _this.localizer.translate(referenceData.title); }, onClick: clickHandler },
            { headerName: 'Description', nameExtractor: function (referenceData) { return _this.localizer.translate(referenceData.description); } }
        ];
    };
    ReferenceDataTableDescriptor.prototype.values = function () {
        return this.referenceData;
    };
    ReferenceDataTableDescriptor.prototype.canEdit = function (_referenceData) {
        return false;
    };
    ReferenceDataTableDescriptor.prototype.edit = function (_referenceData) {
        throw new Error('Edit unsupported');
    };
    ReferenceDataTableDescriptor.prototype.remove = function (referenceData) {
        remove(this.values(), referenceData);
    };
    ReferenceDataTableDescriptor.prototype.canRemove = function (_referenceData) {
        return true;
    };
    ReferenceDataTableDescriptor.prototype.orderBy = function (referenceData) {
        return referenceData.identifier;
    };
    return ReferenceDataTableDescriptor;
}(TableDescriptor));
