var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LanguageService } from 'app/services/languageService';
import { isLocalizationDefined } from 'app/utils/language';
import { LegacyComponent } from 'app/utils/angular';
var EditableLabelComponent = /** @class */ (function () {
    function EditableLabelComponent($scope, $element, gettextCatalog, languageService) {
        'ngInject';
        this.$scope = $scope;
        this.$element = $element;
        this.gettextCatalog = gettextCatalog;
        this.languageService = languageService;
    }
    EditableLabelComponent.prototype.$onInit = function () {
        var _this = this;
        var key = this.title + ' info';
        this.$scope.$watch(function () { return _this.languageService.UILanguage; }, function () {
            var infoText = _this.gettextCatalog.getString(key);
            _this.infoText = isLocalizationDefined(key, infoText) ? infoText : '';
        });
    };
    EditableLabelComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(EditableLabelComponent.prototype, "forId", {
        get: function () {
            if (this.isEditing() && this.inputId) {
                return this.inputId;
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditableLabelComponent = __decorate([
        LegacyComponent({
            bindings: {
                title: '=',
                inputId: '=',
                required: '='
            },
            require: {
                form: '?^form'
            },
            template: "\n      <label ng-attr-for=\"{{$ctrl.forId}}\">{{$ctrl.title | translate}}\n         <span ng-show=\"$ctrl.infoText\" class=\"fas fa-info-circle info\" uib-tooltip=\"{{$ctrl.infoText}}\"></span>\n         <span ng-show=\"$ctrl.required && $ctrl.isEditing()\" class=\"fas fa-asterisk\" uib-tooltip=\"{{'Required' | translate}}\"></span>\n      </label>\n  "
        }),
        __metadata("design:paramtypes", [Object, Object, Object, LanguageService])
    ], EditableLabelComponent);
    return EditableLabelComponent;
}());
export { EditableLabelComponent };
