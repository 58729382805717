var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Uri } from 'app/entities/uri';
import { identity, requireDefined } from 'yti-common-ui/utils/object';
import { firstMatching, keepMatching } from 'yti-common-ui/utils/array';
import { technicalNamespaces } from 'app/help/services/entityCreatorService';
var EntityLoaderService = /** @class */ (function () {
    function EntityLoaderService($q, modelService, predicateService, classService, vocabularyService, helpVocabularyService, helpOrganizationService, entityCreatorService, resetService) {
        'ngInject';
        this.$q = $q;
        this.modelService = modelService;
        this.predicateService = predicateService;
        this.classService = classService;
        this.vocabularyService = vocabularyService;
        this.helpVocabularyService = helpVocabularyService;
        this.helpOrganizationService = helpOrganizationService;
        this.entityCreatorService = entityCreatorService;
        this.resetService = resetService;
    }
    EntityLoaderService.prototype.create = function (shouldReset) {
        return new EntityLoader(this.$q, this.modelService, this.predicateService, this.classService, this.vocabularyService, this.helpVocabularyService, this.helpOrganizationService, this.entityCreatorService, this.resetService, shouldReset);
    };
    return EntityLoaderService;
}());
export { EntityLoaderService };
var EntityLoader = /** @class */ (function () {
    function EntityLoader($q, modelService, predicateService, classService, vocabularyService, helpVocabularyService, helpOrganizationService, entityCreatorService, resetService, shouldReset) {
        'ngInject';
        this.$q = $q;
        this.modelService = modelService;
        this.predicateService = predicateService;
        this.classService = classService;
        this.vocabularyService = vocabularyService;
        this.helpVocabularyService = helpVocabularyService;
        this.helpOrganizationService = helpOrganizationService;
        this.entityCreatorService = entityCreatorService;
        this.actions = [];
        this.context = __assign({}, technicalNamespaces);
        var reset = shouldReset ? resetService.reset() : $q.when();
        var initialized = $q.defer();
        this.initialized = initialized.promise;
        reset.then(function () { return initialized.resolve(); });
    }
    EntityLoader.prototype.addAction = function (action, details) {
        var withDetails = action.then(identity, failWithDetails(this.$q, details));
        this.actions.push(withDetails);
        return withDetails;
    };
    Object.defineProperty(EntityLoader.prototype, "result", {
        get: function () {
            return this.$q.all(this.actions);
        },
        enumerable: true,
        configurable: true
    });
    EntityLoader.prototype.createModelWithResources = function (modelWithResources) {
        var _this = this;
        var modelPromise = this.createModel(modelWithResources.model);
        var promises = [modelPromise];
        for (var _i = 0, _a = Object.values(modelWithResources.attributes); _i < _a.length; _i++) {
            var attributeDetails = _a[_i];
            promises.push(this.createAttribute(modelPromise, attributeDetails));
        }
        for (var _b = 0, _c = Object.values(modelWithResources.associations); _b < _c.length; _b++) {
            var associationDetails = _c[_b];
            promises.push(this.createAssociation(modelPromise, associationDetails));
        }
        this.$q.all(promises).then(function () {
            for (var _i = 0, _a = Object.values(modelWithResources.classes); _i < _a.length; _i++) {
                var classDetails = _a[_i];
                promises.push(_this.createClass(modelPromise, classDetails));
            }
        });
        return this.$q.all(promises);
    };
    EntityLoader.prototype.createVocabulary = function (vocabulary) {
        if (!this.helpVocabularyService) {
            throw new Error('Vocabulary creation is only available to help');
        }
        return this.addAction(this.helpVocabularyService.createVocabulary(vocabulary), vocabulary);
    };
    EntityLoader.prototype.createConcept = function (vocabulary, details) {
        var _this = this;
        if (!this.helpVocabularyService) {
            throw new Error('Concept creation is only available to help');
        }
        var service = this.helpVocabularyService;
        return this.addAction(asPromise(vocabulary, function (id) { return _this.getVocabulary(id); })
            .then(function (v) { return service.createConcept(v, details); }), details);
    };
    EntityLoader.prototype.createVocabularyWithConcepts = function (details) {
        var vocabularyPromise = this.createVocabulary(details.vocabulary);
        var promises = [vocabularyPromise];
        for (var _i = 0, _a = details.concepts; _i < _a.length; _i++) {
            var conceptDetails = _a[_i];
            promises.push(this.createConcept(vocabularyPromise, conceptDetails));
        }
        return this.$q.all(promises);
    };
    EntityLoader.prototype.createOrganization = function (organization) {
        if (!this.helpOrganizationService) {
            throw new Error('Organization creation is only available to help');
        }
        return this.addAction(this.helpOrganizationService.createOrganization(organization), organization);
    };
    EntityLoader.prototype.createConceptSuggestion = function (details, modelPromise) {
        var _this = this;
        var result = modelPromise.then(function (model) {
            return _this.vocabularyService.createConceptSuggestion(model.vocabularies[0], details.label, details.comment, 'fi', model);
        });
        return this.addAction(result, details);
    };
    EntityLoader.prototype.getVocabulary = function (id) {
        return this.vocabularyService.getAllVocabularies().then(function (allVocabularies) { return requireDefined(firstMatching(allVocabularies, function (voc) { return voc.id.toString() === id; }), 'vocabulary not found'); });
    };
    EntityLoader.prototype.createModel = function (details) {
        var _this = this;
        var classificationsPromise = this.$q.all((details.classifications || []).map(asIdentifierPromise));
        var organizationsPromise = this.$q.all((details.organizations || []).map(function (resolvable) { return asUuidPromise(resolvable); }));
        var result = this.initialized.then(function () { return _this.$q.all([classificationsPromise, organizationsPromise]); })
            .then(function (_a) {
            var classifications = _a[0], organizations = _a[1];
            return _this.modelService.newModel(details.prefix, details.label['fi'], classifications, organizations, ['fi', 'en', 'sv'], details.type);
        }).then(function (model) {
            setDetails(model, details);
            _this.context[model.prefix] = model.namespace;
            var promises = [];
            for (var _i = 0, _a = details.vocabularies || []; _i < _a.length; _i++) {
                var vocabulary = _a[_i];
                promises.push(asPromise(vocabulary, function (id) { return _this.getVocabulary(id); })
                    .then(function (importedVocabulary) { return model.addVocabulary(importedVocabulary); }));
            }
            for (var _b = 0, _c = details.namespaces || []; _b < _c.length; _b++) {
                var namespace = _c[_b];
                if (isResolvable(namespace)) {
                    var fetchImportedNamespace = function (id) { return _this.modelService.getModelByUrn(id); };
                    promises.push(asPromise(namespace, fetchImportedNamespace)
                        .then(function (importedModel) { return _this.entityCreatorService.createImportedNamespace(importedModel); })
                        .then(function (ns) { return model.addImportedNamespace(ns); }));
                }
                else if (isExternalNamespace(namespace)) {
                    promises.push(_this.modelService.newNamespaceImport(namespace.namespace, namespace.prefix, namespace.label, 'fi')
                        .then(function (newImportedNamespace) { return model.addImportedNamespace(newImportedNamespace); }));
                }
                else {
                    throw new Error('Unknown namespace: ' + namespace);
                }
            }
            return _this.$q.all(promises)
                .then(function () { return _this.modelService.createModel(model); })
                .then(function () { return model; });
        });
        return this.addAction(result, details);
    };
    EntityLoader.prototype.assignClass = function (modelPromise, klass) {
        var _this = this;
        var classIdPromise = asIdPromise(klass, this.context);
        var result = this.$q.all([modelPromise, classIdPromise])
            .then(function (_a) {
            var model = _a[0], classId = _a[1];
            return _this.classService.assignClassToModel(classId, model);
        });
        return this.addAction(result, 'assign class');
    };
    EntityLoader.prototype.specializeClass = function (modelPromise, details) {
        var _this = this;
        var result = modelPromise.then(function (model) {
            var fetchClass = function (id) { return _this.classService.getClass(new Uri(id, _this.context), model); };
            return asPromise(details.class, fetchClass).then(function (klass) { return [model, klass]; });
        })
            .then(function (_a) {
            var model = _a[0], klass = _a[1];
            var fetchClass = function (id) { return _this.classService.getClass(new Uri(id, _this.context), model); };
            return _this.classService.newShape(klass, model, false, 'fi')
                .then(function (shape) {
                setDetails(shape, details);
                setId(shape, details);
                var promises = [];
                for (var _i = 0, _a = details.properties || []; _i < _a.length; _i++) {
                    var property = _a[_i];
                    promises.push(_this.createProperty(modelPromise, property).then(function (prop) {
                        shape.addProperty(prop);
                    }));
                }
                if (details.propertyFilter && details.properties) {
                    throw new Error('Shape cannot declare both properties and property filter');
                }
                if (details.propertyFilter) {
                    keepMatching(shape.properties, details.propertyFilter);
                }
                for (var _b = 0, _c = details.equivalentClasses || []; _b < _c.length; _b++) {
                    var equivalentClass = _c[_b];
                    promises.push(asIdPromise(equivalentClass, _this.context)
                        .then(function (id) { return shape.equivalentClasses.push(id); }));
                }
                if (details.constraint) {
                    shape.constraint.constraint = details.constraint.type;
                    shape.constraint.comment = details.constraint.comment;
                    for (var _d = 0, _e = details.constraint.shapes; _d < _e.length; _d++) {
                        var constraintShape = _e[_d];
                        promises.push(asPromise(constraintShape, fetchClass)
                            .then(function (item) { return shape.constraint.addItem(item); }));
                    }
                }
                return _this.$q.all(promises)
                    .then(function () { return _this.classService.createClass(shape); })
                    .then(function () { return shape; });
            });
        });
        return this.addAction(result, details);
    };
    EntityLoader.prototype.createClass = function (modelPromise, details) {
        var _this = this;
        var concept = details.concept;
        var conceptIdPromise = isConceptSuggestion(concept)
            ? this.createConceptSuggestion(concept, modelPromise)
            : this.$q.when(null);
        var result = this.$q.all([modelPromise, conceptIdPromise])
            .then(function (_a) {
            var model = _a[0], conceptId = _a[1];
            return _this.$q.all([model, _this.classService.newClass(model, details.label['fi'], conceptId, 'fi')]);
        })
            .then(function (_a) {
            var model = _a[0], klass = _a[1];
            var fetchClass = function (id) { return _this.classService.getClass(new Uri(id, _this.context), model); };
            setDetails(klass, details);
            setId(klass, details);
            var promises = [];
            for (var _i = 0, _b = details.properties || []; _i < _b.length; _i++) {
                var property = _b[_i];
                promises.push(_this.createProperty(modelPromise, property)
                    .then(function (prop) { return klass.addProperty(prop); }));
            }
            if (details.subClassOf) {
                promises.push(asIdPromise(details.subClassOf, _this.context)
                    .then(function (uri) { return klass.subClassOf = uri; }));
            }
            for (var _c = 0, _d = details.equivalentClasses || []; _c < _d.length; _c++) {
                var equivalentClass = _d[_c];
                promises.push(asIdPromise(equivalentClass, _this.context)
                    .then(function (uri) { return klass.equivalentClasses.push(uri); }));
            }
            if (details.constraint) {
                klass.constraint.constraint = details.constraint.type;
                klass.constraint.comment = details.constraint.comment;
                for (var _e = 0, _f = details.constraint.shapes; _e < _f.length; _e++) {
                    var constraintShape = _f[_e];
                    promises.push(asPromise(constraintShape, fetchClass)
                        .then(function (item) { return klass.constraint.addItem(item); }));
                }
            }
            return _this.$q.all(promises)
                .then(function () { return _this.classService.createClass(klass); })
                .then(function () { return klass; });
        });
        return this.addAction(result, details);
    };
    EntityLoader.prototype.assignPredicate = function (modelPromise, predicatePromise) {
        var _this = this;
        var result = this.$q.all([modelPromise, predicatePromise])
            .then(function (_a) {
            var model = _a[0], predicate = _a[1];
            return _this.predicateService.assignPredicateToModel(predicate.id, model).then(function () { return predicate; });
        });
        return this.addAction(result, 'assign predicate');
    };
    EntityLoader.prototype.createPredicate = function (modelPromise, type, details, mangler) {
        var _this = this;
        var concept = details.concept;
        var conceptIdPromise = isConceptSuggestion(concept)
            ? this.createConceptSuggestion(concept, modelPromise)
            : this.$q.when(null);
        var result = this.$q.all([modelPromise, conceptIdPromise])
            .then(function (_a) {
            var model = _a[0], conceptId = _a[1];
            return _this.predicateService.newPredicate(model, details.label['fi'], conceptId, type, 'fi');
        })
            .then(function (predicate) {
            setDetails(predicate, details);
            setId(predicate, details);
            var promises = [];
            if (details.subPropertyOf) {
                promises.push(asIdPromise(details.subPropertyOf, _this.context)
                    .then(function (uri) { return predicate.subPropertyOf = uri; }));
            }
            for (var _i = 0, _a = details.equivalentProperties || []; _i < _a.length; _i++) {
                var equivalentProperty = _a[_i];
                promises.push(asIdPromise(equivalentProperty, _this.context)
                    .then(function (uri) { return predicate.equivalentProperties.push(uri); }));
            }
            promises.push(mangler(predicate));
            return _this.$q.all(promises)
                .then(function () { return _this.predicateService.createPredicate(predicate); })
                .then(function () { return predicate; });
        });
        return this.addAction(result, details);
    };
    EntityLoader.prototype.createAttribute = function (modelPromise, details) {
        var _this = this;
        return this.createPredicate(modelPromise, 'attribute', details, function (attribute) {
            attribute.dataType = details.dataType || 'xsd:string';
            return _this.$q.when();
        });
    };
    EntityLoader.prototype.createAssociation = function (modelPromise, details) {
        var _this = this;
        return this.createPredicate(modelPromise, 'association', details, function (association) {
            if (details.valueClass) {
                return asIdPromise(details.valueClass, _this.context).then(function (uri) { return association.valueClass = uri; });
            }
            else {
                return _this.$q.when();
            }
        });
    };
    EntityLoader.prototype.createProperty = function (modelPromise, details) {
        var _this = this;
        var result = modelPromise.then(function (model) {
            var fetchPredicate = function (id) { return _this.predicateService.getPredicate(id, model); };
            return asPromise(details.predicate, fetchPredicate).then(function (predicate) { return [model, predicate]; });
        })
            .then(function (_a) {
            var model = _a[0], predicate = _a[1];
            if (predicate.normalizedType === 'property') {
                throw new Error('Type must not be property');
            }
            return _this.classService.newProperty(predicate, predicate.normalizedType, model);
        })
            .then(function (property) {
            setDetails(property, details);
            if (details.valueClass) {
                asIdPromise(details.valueClass, _this.context).then(function (classId) {
                    property.valueClass = classId;
                });
            }
            if (details.internalId) {
                property.internalId = Uri.fromUUID(details.internalId);
            }
            if (details.dataType) {
                property.dataType = details.dataType;
            }
            property.example = details.example ? [details.example] : [];
            property.minCount = details.minCount || null;
            property.maxCount = details.maxCount || null;
            property.pattern = details.pattern || null;
            return property;
        });
        return this.addAction(result, details);
    };
    return EntityLoader;
}());
export { EntityLoader };
function failWithDetails($q, details) {
    return function (error) {
        return $q.reject({ error: error, details: details });
    };
}
function setDetails(entity, details) {
    if (details.label) {
        entity.label = details.label;
    }
    if (details.comment) {
        entity.comment = details.comment;
    }
    if (details.status) {
        entity.status = details.status;
    }
}
function setId(entity, details) {
    if (details.id) {
        entity.id = entity.id.withName(details.id);
    }
}
function isPromise(obj) {
    return !!(obj && obj.then);
}
function isPromiseProvider(obj) {
    return typeof obj === 'function';
}
function isConceptSuggestion(obj) {
    return typeof obj === 'object';
}
function isExternalNamespace(obj) {
    return !!obj.label && !!obj.namespace && !!obj.prefix;
}
function isResolvable(obj) {
    return typeof obj === 'string' || isPromiseProvider(obj) || isPromise(obj);
}
function asMappedPromise(resolvable, mapper, fetchResource) {
    if (isPromiseProvider(resolvable)) {
        return resolvable().then(mapper);
    }
    else if (isPromise(resolvable)) {
        return resolvable.then(mapper);
    }
    else {
        var result = fetchResource(resolvable);
        if (isPromise(result)) {
            return result;
        }
        else {
            return Promise.resolve(result);
        }
    }
}
function asPromise(resolvable, fetchResource) {
    return asMappedPromise(resolvable, identity, fetchResource);
}
function asIdPromise(resolvable, context) {
    return asMappedPromise(resolvable, function (item) { return item.id; }, function (id) { return new Uri(id, context); });
}
function asUuidPromise(resolvable) {
    return asMappedPromise(resolvable, function (item) { return item.id.uuid; }, identity);
}
function asIdentifierPromise(resolvable) {
    return asMappedPromise(resolvable, function (item) { return item.identifier; }, identity);
}
