var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isDefined } from 'yti-common-ui/utils/object';
import { arrowDown, arrowUp, enter, esc, pageDown, pageUp, tab } from 'yti-common-ui/utils/key-code';
import { LegacyComponent } from 'app/utils/angular';
function parse(exp) {
    var match = exp.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)$/);
    if (!match) {
        throw new Error("Expected expression in form of '_item_ in _collection_' but got '" + exp + "'.");
    }
    if (!match[1].match(/^(?:(\s*[$\w]+))$/)) {
        throw new Error("'_item_' in '_item_ in _collection_' should be an identifier expression, but got '" + match[1] + "'.");
    }
    return {
        itemName: match[1],
        collection: match[2]
    };
}
// TODO: similarities with autocomplete
var IowSelectComponent = /** @class */ (function () {
    function IowSelectComponent($q, $scope, $parse) {
        'ngInject';
        var _this = this;
        this.$q = $q;
        this.$scope = $scope;
        this.$parse = $parse;
        this.selectedSelectionIndex = -1;
        this.keyEventHandlers = (_a = {},
            _a[arrowDown] = function () { return _this.openIfNotShown(function () { return _this.moveSelection(1); }); },
            _a[arrowUp] = function () { return _this.openIfNotShown(function () { return _this.moveSelection(-1); }); },
            _a[pageDown] = function () { return _this.openIfNotShown(function () { return _this.moveSelection(10); }); },
            _a[pageUp] = function () { return _this.openIfNotShown(function () { return _this.moveSelection(-10); }); },
            _a[enter] = function () { return _this.openIfNotShown(function () { return _this.selectSelection(); }); },
            _a[tab] = function () { return _this.selectSelection(); },
            _a[esc] = function () { return _this.close(); },
            _a);
        var _a;
    }
    IowSelectComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.options; }, function (optionsExp) {
            var result = parse(optionsExp);
            _this.popupItemName = result.itemName;
            _this.$q.when(_this.$parse(result.collection)(_this.$scope.$parent)).then(function (items) { return _this.popupItems = items; });
        });
    };
    IowSelectComponent.prototype.openIfNotShown = function (action) {
        if (!this.show) {
            this.open();
        }
        else {
            action();
        }
    };
    IowSelectComponent.prototype.keyPressed = function (event) {
        var handler = this.keyEventHandlers[event.keyCode];
        if (handler) {
            var preventDefault = handler();
            if (!isDefined(preventDefault) || preventDefault === true) {
                event.preventDefault();
            }
        }
    };
    IowSelectComponent.prototype.moveSelection = function (offset) {
        this.setSelection(Math.max(Math.min(this.selectedSelectionIndex + offset, this.popupItems.length - 1), -1));
    };
    IowSelectComponent.prototype.setSelection = function (index) {
        this.selectedSelectionIndex = index;
    };
    IowSelectComponent.prototype.isSelected = function (index) {
        return index === this.selectedSelectionIndex;
    };
    IowSelectComponent.prototype.toggleOpen = function () {
        if (this.show) {
            this.close();
        }
        else {
            this.open();
        }
    };
    IowSelectComponent.prototype.open = function () {
        this.show = true;
        this.selectedSelectionIndex = this.findSelectionIndex();
    };
    IowSelectComponent.prototype.close = function () {
        this.show = false;
        this.selectedSelectionIndex = -1;
    };
    IowSelectComponent.prototype.selectSelection = function () {
        if (this.selectedSelectionIndex >= 0) {
            this.ngModel = this.popupItems[this.selectedSelectionIndex];
        }
        this.close();
        return this.selectedSelectionIndex >= 0;
    };
    IowSelectComponent.prototype.findSelectionIndex = function () {
        for (var i = 0; i < this.popupItems.length; i++) {
            var item = this.popupItems[i];
            if (item === this.ngModel) {
                return i;
            }
        }
        return -1;
    };
    IowSelectComponent = __decorate([
        LegacyComponent({
            bindings: {
                ngModel: '=',
                options: '@',
                idPrefix: '<'
            },
            transclude: true,
            template: "\n      <div>\n        <div id=\"{{$ctrl.idPrefix}}_item_select\" class=\"btn btn-dropdown dropdown-toggle\" tabindex=\"0\" iow-select-input>\n          <iow-selection-transclude></iow-selection-transclude>\n        </div>\n        <input-popup id-prefix=\"$ctrl.idPrefix\" ctrl=\"$ctrl\"><iow-selectable-item-transclude></iow-selectable-item-transclude></input-popup>\n      </div>\n  "
        }),
        __metadata("design:paramtypes", [Function, Object, Function])
    ], IowSelectComponent);
    return IowSelectComponent;
}());
export { IowSelectComponent };
export var IowSelectInputDirective = function ($document) {
    'ngInject';
    return {
        restrict: 'A',
        require: '^iowSelect',
        link: function ($scope, element, _attributes, controller) {
            var iowSelectElement = element.closest('iow-select');
            var keyDownHandler = function (event) { return $scope.$apply(function () { return controller.keyPressed(event); }); };
            var clickHandler = function () { return $scope.$apply(function () { return controller.toggleOpen(); }); };
            var blurClickHandler = function (event) {
                var eventIowSelectElement = jQuery(event.target).closest('iow-select');
                if (eventIowSelectElement[0] !== iowSelectElement[0]) {
                    $scope.$apply(function () { return controller.close(); });
                    $document.off('click', blurClickHandler);
                }
            };
            var focusHandler = function () { return $document.on('click', blurClickHandler); };
            element.on('click', clickHandler);
            element.on('keydown', keyDownHandler);
            element.on('focus', focusHandler);
            $scope.$on('$destroy', function () {
                element.off('click', clickHandler);
                element.off('keydown', keyDownHandler);
                element.off('focus', focusHandler);
            });
            controller.element = element;
        }
    };
};
export var IowSelectSelectionTranscludeDirective = function () {
    return {
        link: function ($scope, element, _attribute, _controller, transclude) {
            var childScope;
            $scope.$watch(function () { return $scope.$ctrl.ngModel; }, function (item) {
                if (!childScope) {
                    transclude(function (clone, transclusionScope) {
                        childScope = transclusionScope;
                        transclusionScope[$scope.$ctrl.popupItemName] = item;
                        element.append(clone);
                    });
                }
                else {
                    childScope[$scope.$ctrl.popupItemName] = item;
                }
            });
        }
    };
};
export var IowSelectSelectableItemTranscludeDirective = function () {
    return {
        link: function ($scope, element, _attribute, _controller, transclude) {
            transclude(function (clone, transclusionScope) {
                transclusionScope[$scope.$ctrl.popupItemName] = $scope[$scope.$ctrl.popupItemName];
                element.append(clone);
            });
        }
    };
};
