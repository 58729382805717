import { expandContextWithKnownModels } from 'app/utils/entity';
import { index } from 'yti-common-ui/utils/array';
import { requireDefined } from 'yti-common-ui/utils/object';
import * as frames from 'app/entities/frames';
import { ModelPositions, DefaultVisualizationClass } from 'app/entities/visualization';
import { normalizeAsArray } from 'yti-common-ui/utils/array';
import { apiEndpointWithName } from './config';
var DefaultVisualizationService = /** @class */ (function () {
    function DefaultVisualizationService($http, $q, frameService) {
        'ngInject';
        this.$http = $http;
        this.$q = $q;
        this.frameService = frameService;
    }
    DefaultVisualizationService.prototype.getVisualization = function (model) {
        return this.$q.all([this.getVisualizationClasses(model), this.getModelPositions(model)])
            .then(function (_a) {
            var classes = _a[0], positions = _a[1];
            return new ClassVisualization(classes, positions);
        });
    };
    DefaultVisualizationService.prototype.getVisualizationClasses = function (model) {
        var _this = this;
        var params = {
            graph: model.id.uri
        };
        return this.$http.get(apiEndpointWithName('exportModel'), { params: params })
            .then(expandContextWithKnownModels(model))
            .then(function (response) {
            var data = response.data;
            // get all classes from exportModel and map properties
            var classes = data['@graph']
                .filter(function (graph) { return ['rdfs:Class', 'sh:NodeShape'].includes(graph['@type']); })
                .map(function (cls) {
                var propIds = normalizeAsArray(cls.property);
                cls.name = _this.mapLocalizedValues(cls.name);
                cls.description = _this.mapLocalizedValues(cls.description);
                cls.property = propIds.map(function (id) {
                    var property = data['@graph'].find(function (element) { return element['@id'] === id; });
                    if (!property) {
                        return null;
                    }
                    property.name = _this.mapLocalizedValues(property.name);
                    property.description = _this.mapLocalizedValues(property.description);
                    // delete memberOf because it is not needed in visualization
                    delete property.memberOf;
                    return Object.assign.apply(Object, [{}].concat(_this.removePrefixes(property)));
                })
                    .filter(Boolean);
                return Object.assign.apply(Object, [{}].concat(_this.removePrefixes(cls)));
            });
            try {
                return normalizeAsArray(classes).map(function (element) {
                    return new DefaultVisualizationClass(element, data['@context'], null);
                });
            }
            catch (error) {
                console.log(error);
                throw error;
            }
        });
    };
    DefaultVisualizationService.prototype.mapLocalizedValues = function (property) {
        if (!property) {
            return {};
        }
        var localizations = normalizeAsArray(property);
        var mappedLocalizations = {};
        localizations.map(function (localization) {
            mappedLocalizations[localization['@language']] = localization['@value'];
        });
        return mappedLocalizations;
    };
    // remove prefixes from keys, e.g. sh:order -> order
    DefaultVisualizationService.prototype.removePrefixes = function (obj) {
        return Object.keys(obj).map(function (key) {
            var newKey = key.replace(/^\w+:/, '');
            return _a = {}, _a[newKey] = obj[key], _a;
            var _a;
        });
    };
    DefaultVisualizationService.prototype.getModelPositions = function (model) {
        var _this = this;
        return this.$http.get(apiEndpointWithName('modelPositions'), { params: { model: model.id.uri } })
            .then(expandContextWithKnownModels(model))
            .then(function (response) { return _this.deserializeModelPositions(response.data); }, function (_err) { return _this.newModelPositions(model); });
    };
    DefaultVisualizationService.prototype.updateModelPositions = function (model, modelPositions) {
        return this.$http.put(apiEndpointWithName('modelPositions'), modelPositions.serialize(), { params: { model: model.id.uri } });
    };
    DefaultVisualizationService.prototype.newModelPositions = function (model) {
        var frame = frames.modelPositionsFrame({ '@context': model.context });
        return new ModelPositions([], frame['@context'], frame);
    };
    DefaultVisualizationService.prototype.deserializeModelPositions = function (data) {
        return this.frameService.frameAndMapArrayEntity(data, frames.modelPositionsFrame(data), function () { return ModelPositions; });
    };
    return DefaultVisualizationService;
}());
export { DefaultVisualizationService };
var ClassVisualization = /** @class */ (function () {
    function ClassVisualization(classes, positions) {
        this.positions = positions;
        this.classes = index(classes, function (klass) { return klass.id.toString(); });
    }
    Object.defineProperty(ClassVisualization.prototype, "size", {
        get: function () {
            return this.classes.size;
        },
        enumerable: true,
        configurable: true
    });
    ClassVisualization.prototype.removeClass = function (classId) {
        this.classes.delete(classId);
    };
    ClassVisualization.prototype.addOrReplaceClass = function (klass) {
        this.classes.set(klass.id.toString(), klass);
    };
    ClassVisualization.prototype.getClasses = function () {
        return Array.from(this.classes.values());
    };
    ClassVisualization.prototype.hasClass = function (classId) {
        return this.classes.has(classId);
    };
    ClassVisualization.prototype.getClassById = function (classId) {
        return requireDefined(this.classes.get(classId));
    };
    ClassVisualization.prototype.getClassIds = function () {
        return new Set(this.classes.keys());
    };
    ClassVisualization.prototype.getClassIdsWithoutPosition = function () {
        var _this = this;
        return Array.from(this.classes.values()).filter(function (c) { return !_this.positions.isClassDefined(c.id); }).map(function (c) { return c.id; });
    };
    ClassVisualization.prototype.addPositionChangeListener = function (listener) {
        this.positions.addChangeListener(listener);
    };
    return ClassVisualization;
}());
export { ClassVisualization };
