var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { localizableSerializer, stringSerializer, optional } from './serializer/serializer';
import { createConstantLocalizable } from '../utils/language';
import { init } from './mapping';
import { GraphNode } from './graphNode';
import { uriSerializer, entityAwareList, entity } from './serializer/entitySerializer';
import { Classification } from './classification';
import { normalizeDefinedByType } from '../utils/entity';
var DefinedBy = /** @class */ (function (_super) {
    __extends(DefinedBy, _super);
    function DefinedBy(graph, context, frame) {
        var _this = _super.call(this, graph, context, frame) || this;
        if (typeof graph === 'string' || graph instanceof String) {
            var str = (graph instanceof String) ? graph.valueOf() : graph;
            _this.id = uriSerializer.deserialize(str, _this);
            _this.label = createConstantLocalizable(_this.id.uri);
            _this.prefix = null;
        }
        else if (typeof graph === 'object') {
            init(_this, DefinedBy.definedByMapping);
        }
        else {
            throw new Error('Unsupported is defined sub-graph');
        }
        return _this;
    }
    Object.defineProperty(DefinedBy.prototype, "normalizedType", {
        get: function () {
            return normalizeDefinedByType(this.type);
        },
        enumerable: true,
        configurable: true
    });
    DefinedBy.definedByMapping = {
        id: { name: '@id', serializer: uriSerializer },
        label: { name: 'label', serializer: localizableSerializer },
        prefix: { name: 'preferredXMLNamespacePrefix', serializer: optional(stringSerializer) },
        classifications: { name: 'isPartOf', serializer: entityAwareList(entity(function () { return Classification; })) }
    };
    return DefinedBy;
}(GraphNode));
export { DefinedBy };
