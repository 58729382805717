var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { allMatching, normalizeAsArray } from 'yti-common-ui/utils/array';
import { requireDefined, valuesExcludingKeys } from 'yti-common-ui/utils/object';
var legacyComponentOptions = new Map();
var legacyDirectiveOptions = new Map();
export function LegacyComponent(options) {
    return function (constructor) {
        legacyComponentOptions.set(constructor, options);
    };
}
export function LegacyDirective(options) {
    return function (constructor) {
        legacyDirectiveOptions.set(constructor, options);
    };
}
export function componentDeclaration(constructor) {
    var options = requireDefined(legacyComponentOptions.get(constructor));
    return __assign({}, options, { controller: constructor });
}
export function directiveDeclaration(constructor) {
    var options = requireDefined(legacyDirectiveOptions.get(constructor));
    return function () { return (__assign({}, options, { controller: constructor })); };
}
export function hasFixedPositioningParent(e) {
    for (var p = e.parent(); p && p.length > 0 && !p.is('body'); p = p.parent()) {
        if (p.css('position') === 'fixed') {
            return true;
        }
    }
    return false;
}
export function isModalCancel(err) {
    return err === 'cancel' || err === 'escape key press';
}
export function modalCancelHandler(err) {
    // Handle cancellation so that angular doesn't report unhandled promise rejection error
    if (!isModalCancel(err)) {
        throw err;
    }
}
var doubleUuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
var uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
export function normalizeUrl(url) {
    return url.replace(/^#/, '')
        .replace(/:/g, '%3A')
        .replace(doubleUuidRegex, '')
        .replace(uuidRegex, '')
        .replace(/\/+$/, '');
}
export function nextUrl($location, next) {
    var base = formatApplicationBase($location, '' /* TODO parametrize base href here */);
    return next.substr(base.length + (next.indexOf('#/') !== -1 ? 2 : 0));
}
export function formatApplicationBase($location, baseHref) {
    var port = $location.port();
    var portString = (port === 80 || port === 443) ? '' : (':' + $location.port());
    return $location.protocol() + '://' + $location.host() + portString + baseHref;
}
export function isDifferentUrl(lhs, rhs) {
    return normalizeUrl(lhs) !== normalizeUrl(rhs);
}
export function extendNgModelOptions(ngModel, options) {
    if (ngModel.$options) {
        ngModel.$options = ngModel.$options.createChild(options);
    }
    else {
        ngModel.$options = options;
    }
}
export function scrollToElement(element, parentContainer) {
    var itemsHeight = parentContainer.height();
    var itemsTop = parentContainer.scrollTop();
    var itemsBottom = itemsHeight + itemsTop;
    var selectionOffsetTop = element.offset().top - parentContainer.offset().top + itemsTop;
    var selectionOffsetBottom = selectionOffsetTop + element.outerHeight();
    if (selectionOffsetBottom > itemsBottom) {
        parentContainer.animate({ scrollTop: Math.ceil(selectionOffsetBottom - itemsHeight) }, 0);
    }
    else if (selectionOffsetTop < itemsTop) {
        parentContainer.animate({ scrollTop: Math.floor(selectionOffsetTop) }, 0);
    }
}
export function formatWithFormatters(value, formatters) {
    var result = value;
    for (var _i = 0, _a = normalizeAsArray(formatters); _i < _a.length; _i++) {
        var formatter = _a[_i];
        result = formatter(result);
    }
    return result;
}
var ValidationResult = /** @class */ (function () {
    function ValidationResult(result) {
        this.result = result;
    }
    ValidationResult.prototype.isValid = function (value) {
        return this.result.get(value);
    };
    return ValidationResult;
}());
export { ValidationResult };
export function validateWithValidators($q, ngModelController, skipValidators, values) {
    var result = new Map();
    var validators = valuesExcludingKeys(ngModelController.$validators, skipValidators);
    var asyncValidators = valuesExcludingKeys(ngModelController.$asyncValidators, skipValidators);
    var validateSync = function (item) { return allMatching(validators, function (validator) { return validator(item); }); };
    var validateAsync = function (item) { return $q.all(asyncValidators.map(function (asyncValidator) { return asyncValidator(item); })); };
    var asyncValidationResults = [];
    var _loop_1 = function (value) {
        if (!validateSync(value)) {
            result.set(value, false);
        }
        else {
            asyncValidationResults.push(validateAsync(value).then(function () { return result.set(value, true); }, function (_err) { return result.set(value, false); }));
        }
    };
    for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
        var value = values_1[_i];
        _loop_1(value);
    }
    return $q.all(asyncValidationResults).then(function () { return new ValidationResult(result); });
}
export function ifChanged(cb) {
    return function (newItem, oldItem) {
        if (newItem !== oldItem) {
            cb(newItem, oldItem);
        }
    };
}
export function isTargetElementInsideElement(event, element) {
    var target = event.target;
    for (var e = target; !!e; e = e.parentElement) {
        if (e === element) {
            return true;
        }
    }
    return false;
}
