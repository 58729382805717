var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as _ from 'lodash';
import { LanguageService } from '../../services/languageService';
import { LocationService } from '../../services/locationService';
import { ConfirmationModal } from '../../components/common/confirmationModal';
import { SearchClassModal } from '../../components/editor/searchClassModal';
import { SearchClassTableModal, noClassExclude } from '../../components/editor/searchClassTableModal';
import { SearchPredicateModal } from '../../components/editor/searchPredicateModal';
import { EntityCreation } from '../../components/editor/searchConceptModal';
import { SearchClassType } from '../../types/component';
import { Uri } from '../../entities/uri';
import { comparingLocalizable } from '../../utils/comparator';
import { AddPropertiesFromClassModal } from '../../components/editor/addPropertiesFromClassModal';
import { LegacyComponent, modalCancelHandler } from '../../utils/angular';
import { combineExclusions, createClassTypeExclusion, createDefinedByExclusion, createExistsExclusion } from '../../utils/exclusion';
import { collectIds, glyphIconClassForType } from '../../utils/entity';
import { areEqual } from 'yti-common-ui/utils/object';
import { Predicate } from '../../entities/predicate';
import { Class } from '../../entities/class';
import { ExternalEntity } from '../../entities/externalEntity';
import { NotificationModal } from '../../components/common/notificationModal';
import { removeMatching } from 'yti-common-ui/utils/array';
import { AuthorizationManagerService } from '../../services/authorizationManagerService';
import { SearchPredicateTableModal, noPredicateExclude } from '../editor/searchPredicateTableModal';
var ModelPageComponent = /** @class */ (function () {
    function ModelPageComponent($scope, $location, $q, locationService, modelService, classService, predicateService, searchClassModal, searchClassTableModal, searchPredicateModal, searchPredicateTableModal, confirmationModal, notificationModal, addPropertiesFromClassModal, languageService, authorizationManagerService) {
        'ngInject';
        var _this = this;
        this.$q = $q;
        this.locationService = locationService;
        this.modelService = modelService;
        this.classService = classService;
        this.predicateService = predicateService;
        this.searchClassModal = searchClassModal;
        this.searchClassTableModal = searchClassTableModal;
        this.searchPredicateModal = searchPredicateModal;
        this.searchPredicateTableModal = searchPredicateTableModal;
        this.confirmationModal = confirmationModal;
        this.notificationModal = notificationModal;
        this.addPropertiesFromClassModal = addPropertiesFromClassModal;
        this.languageService = languageService;
        this.authorizationManagerService = authorizationManagerService;
        this.subscriptions = [];
        this.changeListeners = [];
        this.classes = [];
        this.associations = [];
        this.attributes = [];
        this.namespacesInUse = new Set();
        this.visualizationMaximized = false;
        this.activeTab = 0;
        this.tabs = [
            new Tab('class', function () { return _this.classes; }, this),
            new Tab('attribute', function () { return _this.attributes; }, this),
            new Tab('association', function () { return _this.associations; }, this)
        ];
        $scope.$watch(function () { return _this.model && _this.languageService.getModelLanguage(_this.model); }, function () {
            if (_this.model) {
                _this.sortAll();
            }
        });
        $scope.$watch(function () { return _this.propertyId; }, function (newId, oldId) {
            // Cope with situation where there is an entity under creation, but the currentSelection still has old values.
            if (_this.resource && _this.resource.id) {
                var current = _this.currentSelection.getValue();
                var curieMatches = false;
                try {
                    curieMatches = _this.resource.id.curie === current.resourceCurie;
                }
                catch (error) { }
                if (curieMatches && oldId === current.propertyId && oldId !== newId) {
                    _this.makeSelection({ resourceCurie: current.resourceCurie, propertyId: newId });
                }
            }
        });
        $scope.$watch(function () { return _this.resource; }, function () {
            _this.alignTabWithSelection();
            for (var _i = 0, _a = _this.changeListeners; _i < _a.length; _i++) {
                var changeListener = _a[_i];
                changeListener.onResize();
            }
        });
        $scope.$watch(function () { return _this.selectionWidth; }, function () {
            for (var _i = 0, _a = _this.changeListeners; _i < _a.length; _i++) {
                var changeListener = _a[_i];
                changeListener.onResize();
            }
        });
        $scope.$watch(function () { return _this.visualizationMaximized; }, function (maximized) {
            var body = jQuery('body');
            if (maximized) {
                body.addClass('visualization-maximized');
            }
            else {
                body.removeClass('visualization-maximized');
            }
        });
    }
    Object.defineProperty(ModelPageComponent.prototype, "visualizationWidth", {
        get: function () {
            return this.resource ? "calc(100% - " + this.selectionWidth + "px)" : '100%';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelPageComponent.prototype, "selectableItemComparator", {
        get: function () {
            return comparingLocalizable(this.localizerProvider(), function (selectableItem) { return selectableItem.item.label; });
        },
        enumerable: true,
        configurable: true
    });
    ModelPageComponent.prototype.$onInit = function () {
        var _this = this;
        this.subscriptions.push(this.currentSelection.subscribe(function (newModelAndSelection) {
            if (newModelAndSelection.model) {
                var modelChanged = !_this.model || _this.model.prefix !== newModelAndSelection.model.prefix;
                _this.model = newModelAndSelection.model;
                _this.obeySelectionChange(modelChanged, newModelAndSelection.resourceCurie, newModelAndSelection.propertyId);
            }
            else {
                // NOTE: This component will be destroyed instantaneously, and currently this.model is not optional. So let us do nothing.
            }
        }));
        this.subscriptions.push(this.modelService.contentExpired$.subscribe(function (modelId) {
            if (_this.model.id.uri === modelId) {
                _this.updateSelection();
            }
        }));
    };
    ModelPageComponent.prototype.$onDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    ModelPageComponent.prototype.addListener = function (listener) {
        this.changeListeners.push(listener);
    };
    ModelPageComponent.prototype.sortAll = function () {
        this.sortClasses();
        this.sortPredicates();
    };
    ModelPageComponent.prototype.sortClasses = function () {
        this.classes.sort(this.selectableItemComparator);
        setOverlaps(this.classes);
    };
    ModelPageComponent.prototype.sortPredicates = function () {
        this.associations.sort(this.selectableItemComparator);
        this.attributes.sort(this.selectableItemComparator);
        setOverlaps(this.associations);
        setOverlaps(this.attributes);
    };
    ModelPageComponent.prototype.isSelected = function (selection) {
        return selection.matchesIdentity(this.resource);
    };
    ModelPageComponent.prototype.isLoading = function (listItem) {
        return matchesIdentity(listItem, this.loadingResource) && !matchesIdentity(listItem, this.resource);
    };
    ModelPageComponent.prototype.canEdit = function () {
        return this.model && this.authorizationManagerService.canEditModel(this.model);
    };
    ModelPageComponent.prototype.selectionEdited = function (oldSelection, newSelection) {
        this.updateSelectables();
        for (var _i = 0, _a = this.changeListeners; _i < _a.length; _i++) {
            var changeListener = _a[_i];
            changeListener.onEdit(newSelection, oldSelection);
        }
        if (!oldSelection && newSelection) {
            this.selectResource(newSelection);
        }
    };
    ModelPageComponent.prototype.selectionDeleted = function (selection) {
        removeMatching(this.classes, function (item) { return matchesIdentity(item, selection); });
        removeMatching(this.attributes, function (item) { return matchesIdentity(item, selection); });
        removeMatching(this.associations, function (item) { return matchesIdentity(item, selection); });
        for (var _i = 0, _a = this.changeListeners; _i < _a.length; _i++) {
            var changeListener = _a[_i];
            changeListener.onDelete(selection);
        }
        this.makeSelection({});
    };
    ModelPageComponent.prototype.addEntity = function (type) {
        if (type === 'class' || type === 'shape') {
            if (this.model.isOfType('profile')) {
                // profiles can create multiple shapes of single class so exists exclusion is not wanted
                // profiles can create copy of shapes so type exclusion is not wanted
                return this.addClass(createDefinedByExclusion(this.model), noClassExclude);
            }
            else {
                return this.addClass(combineExclusions(createClassTypeExclusion(SearchClassType.Class), createDefinedByExclusion(this.model), createExistsExclusion(collectIds(this.classes))), createClassTypeExclusion(SearchClassType.Class));
            }
        }
        else {
            this.addPredicate(type, combineExclusions(createExistsExclusion(collectIds([this.attributes, this.associations])), createDefinedByExclusion(this.model)), noPredicateExclude);
        }
    };
    ModelPageComponent.prototype.selectNewlyCreatedOrAssignedEntity = function (entity) {
        this.loadingResource = undefined;
        this.loadingResourcePromise = undefined;
        if (!entity.unsaved) {
            this.updateSelectables();
            this.selectResource(entity);
            for (var _i = 0, _a = this.changeListeners; _i < _a.length; _i++) {
                var changeListener = _a[_i];
                changeListener.onAssign(entity);
            }
        }
        else {
            this.resource = entity;
        }
    };
    ModelPageComponent.prototype.createClass = function (conceptCreation) {
        var _this = this;
        this.classService.newClass(this.model, conceptCreation.entity.label, conceptCreation.conceptId, this.languageService.getModelLanguage(this.model))
            .then(function (klass) { return _this.selectNewlyCreatedOrAssignedEntity(klass); });
    };
    ModelPageComponent.prototype.createRelatedClass = function (relatedClass) {
        var _this = this;
        this.classService.newRelatedClass(this.model, relatedClass)
            .then(function (klass) { return _this.selectNewlyCreatedOrAssignedEntity(klass); });
    };
    ModelPageComponent.prototype.createShape = function (classOrExternal, external) {
        var _this = this;
        this.classService.newShape(classOrExternal, this.model, external, this.languageService.getModelLanguage(this.model))
            .then(function (shape) {
            if (shape.properties.length > 0) {
                return _this.$q.all([_this.$q.when(shape), _this.addPropertiesFromClassModal.open(shape, external ? 'external class' : 'scope class', _this.model)]);
            }
            else {
                return _this.$q.when([shape, shape.properties]);
            }
        })
            .then(function (_a) {
            var shape = _a[0], properties = _a[1];
            shape.properties = properties;
            _this.selectNewlyCreatedOrAssignedEntity(shape);
        });
    };
    ModelPageComponent.prototype.copyShape = function (shape) {
        if (!this.model.isOfType('profile')) {
            throw new Error('Shapes can be copied only to profile');
        }
        var copiedShape = shape.copy(this.model);
        this.selectNewlyCreatedOrAssignedEntity(copiedShape);
    };
    ModelPageComponent.prototype.assignClassToModel = function (klass) {
        var _this = this;
        return this.classService.assignClassToModel(klass.id, this.model)
            .then(function () { return _this.selectNewlyCreatedOrAssignedEntity(klass); });
    };
    ModelPageComponent.prototype.createPredicate = function (conceptCreation, type) {
        var _this = this;
        return this.predicateService.newPredicate(this.model, conceptCreation.entity.label, conceptCreation.conceptId, type, this.languageService.getModelLanguage(this.model))
            .then(function (predicate) { return _this.selectNewlyCreatedOrAssignedEntity(predicate); });
    };
    ModelPageComponent.prototype.createRelatedPredicate = function (relatedPredicate) {
        var _this = this;
        return this.predicateService.newRelatedPredicate(this.model, relatedPredicate)
            .then(function (predicate) { return _this.selectNewlyCreatedOrAssignedEntity(predicate); });
    };
    ModelPageComponent.prototype.assignPredicateToModel = function (predicate) {
        var _this = this;
        return this.predicateService.assignPredicateToModel(predicate.id, this.model)
            .then(function () { return _this.selectNewlyCreatedOrAssignedEntity(predicate); });
    };
    ModelPageComponent.prototype.selectResource = function (item) {
        // Here we (or sub components) initiate a selection change. The actual change happens through the routing stuff.
        if (item) {
            this.makeSelection({ resourceCurie: item.id.curie });
        }
        else {
            this.makeSelection({});
        }
    };
    ModelPageComponent.prototype.obeySelectionChange = function (modelChanged, resourceCurie, propertyId) {
        // Here we obey the instructions received from routing (through parent components), i.e., actually perform the change.
        var _this = this;
        var selectionChanged = !areEqual((this.resource && this.resource.id.curie), resourceCurie);
        var resourceNotFoundHandler = function () { return _this.notificationModal.openResourceNotFound(_this.model); };
        this.propertyId = propertyId; // NOTE: This does not cause loading, so we may just set the new value. (Even though it may not yet be found.)
        if (modelChanged || selectionChanged) {
            var promises = [];
            promises.push(this.resourceCurieToId(resourceCurie).then(function (idWithTypeOrUndefined) {
                if (idWithTypeOrUndefined) {
                    return _this.fetchResource(idWithTypeOrUndefined);
                }
                else {
                    return _this.$q.resolve(undefined);
                }
            }).then(function (value) {
                _this.resource = value;
            }));
            if (modelChanged) {
                promises.push(this.updateSelectables());
            }
            this.$q.all(promises).then(function () { return _this.updateLocation(); })
                .catch(function (err) {
                console.log('Selection change failed: ' + err);
                resourceNotFoundHandler();
            });
        }
    };
    ModelPageComponent.prototype.resourceCurieToId = function (resourceCurie) {
        if (this.model) {
            if (resourceCurie) {
                var resourceUri = new Uri(resourceCurie, this.model.context);
                var startsWithCapital = /^([A-Z]).*/.test(resourceUri.name);
                var selectionType_1 = startsWithCapital ? 'class' : 'predicate';
                if (resourceUri.resolves()) {
                    return this.$q.resolve({
                        id: resourceUri,
                        selectionType: selectionType_1
                    });
                }
                else {
                    var prefix = resourceCurie.split(':')[0];
                    return this.modelService.getModelByPrefix(prefix).then(function (model) { return ({
                        id: new Uri(resourceCurie, model.context),
                        selectionType: selectionType_1
                    }); });
                }
            }
            else if (this.model.rootClass) {
                return this.$q.resolve({ id: this.model.rootClass, selectionType: 'class' });
            }
        }
        return this.$q.resolve(undefined);
    };
    ModelPageComponent.prototype.updateLocation = function () {
        if (this.model) {
            this.locationService.atModel(this.model, this.resource ? this.resource : null);
        }
    };
    ModelPageComponent.prototype.addClass = function (exclusion, filterExclusion) {
        var _this = this;
        var isProfile = this.model.isOfType('profile');
        var textForSelection = function (klass) {
            if (isProfile) {
                if (klass && klass instanceof Class && klass.isOfType('shape')) {
                    return 'Copy shape';
                }
                else {
                    return 'Specialize class';
                }
            }
            else {
                return 'Use class';
            }
        };
        // OLD FEATURE: Search class modal
        // const searchClassModal = () => this.searchClassModal.open(this.model, exclusion, textForSelection);
        // CURRENT FEATURE: Search class table view modal - eJira issue: YTI-304 (old Jira: YTI-546)
        var searchClassModal = function () { return _this.searchClassTableModal.open(_this.model, exclusion, filterExclusion, textForSelection, collectIds(_this.classes)); };
        this.createOrAssignEntity(function () { return searchClassModal(); }, function (external) {
            if (isProfile) {
                _this.createShape(external, true);
            }
            else {
                _this.$q.reject('Library does not support external');
            }
        }, function (concept) { return _this.createClass(concept); }, function (klass) {
            if (klass instanceof Class) {
                if (klass.unsaved) {
                    _this.selectNewlyCreatedOrAssignedEntity(klass);
                }
                else if (klass.isOfType('shape')) {
                    _this.copyShape(klass);
                }
                else if (isProfile) {
                    _this.createShape(klass, klass.external);
                }
                else {
                    _this.assignClassToModel(klass).then(function () { return klass; });
                }
            }
            else {
                _this.createRelatedClass(klass);
            }
        });
    };
    ModelPageComponent.prototype.addPredicate = function (type, exclusion, filterExclusion) {
        // OLD FEATURE: Search predicate modal
        // const searchPredicateModal = () => this.searchPredicateModal.openAddPredicate(this.model, type, exclusion);
        var _this = this;
        // CURRENT FEATURE: Search predicate table view modal
        var searchPredicateModal = function () { return _this.searchPredicateTableModal.openAddPredicate(_this.model, type, exclusion, filterExclusion, collectIds([_this.attributes, _this.associations])); };
        this.createOrAssignEntity(function () { return searchPredicateModal(); }, function (_external) { return _this.$q.reject('Unsupported operation'); }, function (concept) { return _this.createPredicate(concept, type); }, function (predicate) {
            if (predicate instanceof Predicate) {
                _this.assignPredicateToModel(predicate);
            }
            else {
                _this.createRelatedPredicate(predicate);
            }
        });
    };
    ModelPageComponent.prototype.createOrAssignEntity = function (modal, fromExternalEntity, fromConcept, fromEntity) {
        this.askPermissionWhenEditing(function () {
            modal().then(function (result) {
                if (result instanceof EntityCreation) {
                    fromConcept(result);
                }
                else if (result instanceof ExternalEntity) {
                    fromExternalEntity(result);
                }
                else {
                    fromEntity(result);
                }
            }, modalCancelHandler);
        });
    };
    ModelPageComponent.prototype.findEditingViews = function () {
        return this.parent.editingViews();
    };
    ModelPageComponent.prototype.askPermissionWhenEditing = function (callback) {
        var editingViews = this.findEditingViews();
        if (editingViews.length > 0) {
            this.confirmationModal.openEditInProgress().then(function () {
                editingViews.forEach(function (view) { return view.cancelEditing(); });
                callback();
            }, modalCancelHandler);
        }
        else {
            callback();
        }
    };
    // TODO remove retrying when data is coherent
    ModelPageComponent.prototype.fetchResource = function (resourceIdAndType, isRetry) {
        var _this = this;
        if (isRetry === void 0) { isRetry = false; }
        if (matchesIdentity(this.loadingResource, resourceIdAndType) && this.loadingResourcePromise) {
            return this.loadingResourcePromise;
        }
        if (matchesIdentity(resourceIdAndType, this.resource) && this.resource) {
            this.loadingResource = undefined;
            this.loadingResourcePromise = undefined;
            return this.$q.resolve(this.resource);
        }
        // set selected item also here for showing selection before entity actually is loaded
        this.loadingResource = resourceIdAndType;
        return this.doFetchResource(resourceIdAndType)
            .catch(function (reason) {
            if (!isRetry) {
                return _this.fetchResource({
                    id: resourceIdAndType.id,
                    selectionType: resourceIdAndType.selectionType === 'class' ? 'predicate' : 'class'
                }, true);
            }
            else {
                return _this.$q.reject('resource not found');
            }
        }).finally(function () {
            _this.loadingResource = undefined;
            _this.loadingResourcePromise = undefined;
        });
    };
    ModelPageComponent.prototype.doFetchResource = function (resourceIdAndType) {
        return resourceIdAndType.selectionType === 'class'
            ? this.classService.getClass(resourceIdAndType.id, this.model)
            : this.predicateService.getPredicate(resourceIdAndType.id, this.model);
    };
    ModelPageComponent.prototype.alignTabWithSelection = function () {
        if (this.resource) {
            var tabType = this.resource instanceof Predicate ? this.resource.normalizedType : 'class';
            for (var i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i].type === tabType) {
                    this.activeTab = i;
                    break;
                }
            }
        }
        else {
            // do not change tab after, e.g., deletion
        }
    };
    ModelPageComponent.prototype.updateSelectables = function () {
        var _this = this;
        return this.$q.all([this.updateClasses(), this.updatePredicates()])
            .then(function () {
            var resources = _this.associations.concat(_this.attributes, _this.classes);
            _this.namespacesInUse.clear();
            for (var _i = 0, resources_1 = resources; _i < resources_1.length; _i++) {
                var resource = resources_1[_i];
                if (resource.definedBy) {
                    _this.namespacesInUse.add(resource.definedBy.id.uri);
                }
            }
            _this.updateNamespaces(_this.namespacesInUse);
        });
    };
    ModelPageComponent.prototype.updateSelection = function () {
        var _this = this;
        if (this.resource) {
            if (this.resource.selectionType === 'class') {
                this.classService.getClass(this.resource.id, this.model)
                    .then(function (resource) {
                    if (_this.resource instanceof Class && resource instanceof Class) {
                        _this.resource.status = resource.status;
                        _this.resource.properties = resource.properties;
                    }
                });
            }
            else {
                this.predicateService.getPredicate(this.resource.id, this.model)
                    .then(function (resource) {
                    if (_this.resource instanceof Predicate && resource instanceof Predicate) {
                        _this.resource.status = resource.status;
                    }
                });
            }
        }
    };
    ModelPageComponent.prototype.updateClasses = function () {
        var _this = this;
        return this.classService.getClassesAssignedToModel(this.model)
            .then(function (classes) {
            _this.classes = classes.map(function (klass) { return new SelectableItem(klass, _this); });
            _this.sortClasses();
        });
    };
    ModelPageComponent.prototype.updatePredicates = function () {
        var _this = this;
        return this.predicateService.getPredicatesAssignedToModel(this.model)
            .then(function (predicates) {
            _this.attributes = _.chain(predicates)
                .filter(function (predicate) { return predicate.isOfType('attribute'); })
                .map(function (attribute) { return new SelectableItem(attribute, _this); })
                .value();
            _this.associations = _.chain(predicates)
                .filter(function (predicate) { return predicate.isOfType('association'); })
                .map(function (association) { return new SelectableItem(association, _this); })
                .value();
            _this.sortPredicates();
        });
    };
    ModelPageComponent.prototype.localizerProvider = function () {
        return this.languageService.createLocalizer(this.model);
    };
    ModelPageComponent = __decorate([
        LegacyComponent({
            bindings: {
                currentSelection: '<',
                makeSelection: '&',
                updateNamespaces: '&',
                parent: '<'
            },
            template: require('./modelPage.html')
        }),
        __metadata("design:paramtypes", [Object, Object, Function, LocationService, Object, Object, Object, SearchClassModal,
            SearchClassTableModal,
            SearchPredicateModal,
            SearchPredicateTableModal,
            ConfirmationModal,
            NotificationModal,
            AddPropertiesFromClassModal,
            LanguageService,
            AuthorizationManagerService])
    ], ModelPageComponent);
    return ModelPageComponent;
}());
export { ModelPageComponent };
var Tab = /** @class */ (function () {
    function Tab(type, items, modelController) {
        this.type = type;
        this.items = items;
        this.addLabel = 'Add ' + type;
        this.glyphIconClass = glyphIconClassForType([type]);
        this.addNew = function () { return modelController.addEntity(type); };
    }
    return Tab;
}());
function matchesIdentity(lhs, rhs) {
    return areEqual(lhs, rhs, function (l, r) { return l.selectionType === r.selectionType && l.id.equals(r.id); });
}
function setOverlaps(items) {
    var previous;
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        if (previous && previous.rawLabel === item.rawLabel) {
            previous.hasOverlap = true;
            item.hasOverlap = true;
        }
        else {
            item.hasOverlap = false;
        }
        previous = item;
    }
}
var SelectableItem = /** @class */ (function () {
    function SelectableItem(item, modelController) {
        this.item = item;
        this.modelController = modelController;
        this.hasOverlap = false;
    }
    Object.defineProperty(SelectableItem.prototype, "id", {
        get: function () {
            return this.item.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectableItem.prototype, "rawLabel", {
        get: function () {
            return this.modelController.languageService.translate(this.item.label, this.modelController.model);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectableItem.prototype, "label", {
        get: function () {
            return this.rawLabel + (this.hasOverlap ? " (" + this.id.compact + ")" : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectableItem.prototype, "definedBy", {
        get: function () {
            return this.item.definedBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectableItem.prototype, "selectionType", {
        get: function () {
            return this.item.selectionType;
        },
        enumerable: true,
        configurable: true
    });
    SelectableItem.prototype.matchesIdentity = function (obj) {
        return matchesIdentity(this.item, obj);
    };
    return SelectableItem;
}());
