import { comparingLocalizable } from 'app/utils/comparator';
import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { filterAndSortSearchResults, defaultTitleComparator } from 'app/components/filter/util';
import { selectableStatuses } from 'yti-common-ui/entities/status';
import { ifChanged } from 'app/utils/angular';
var noExclude = function (_vocabulary) { return null; };
var ɵ0 = noExclude;
var SearchVocabularyModal = /** @class */ (function () {
    function SearchVocabularyModal($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
    }
    SearchVocabularyModal.prototype.open = function (context, exclude) {
        if (exclude === void 0) { exclude = noExclude; }
        return this.$uibModal.open({
            template: require('./searchVocabularyModal.html'),
            size: 'md',
            controller: SearchVocabularyController,
            controllerAs: '$ctrl',
            backdrop: true,
            resolve: {
                exclude: function () { return exclude; },
                context: function () { return context; }
            }
        }).result;
    };
    return SearchVocabularyModal;
}());
export { SearchVocabularyModal };
var SearchVocabularyController = /** @class */ (function () {
    function SearchVocabularyController($scope, $uibModalInstance, exclude, vocabularyService, languageService, context) {
        'ngInject';
        var _this = this;
        this.$uibModalInstance = $uibModalInstance;
        this.exclude = exclude;
        this.context = context;
        this.searchResults = [];
        this.vocabularies = [];
        this.searchText = '';
        this.contentMatchers = [
            { name: 'Label', extractor: function (vocabulary) { return vocabulary.title; } },
            { name: 'Description', extractor: function (vocabulary) { return vocabulary.description; } }
        ];
        this.contentExtractors = this.contentMatchers.map(function (m) { return m.extractor; });
        this.searchFilters = [];
        this.localizer = languageService.createLocalizer(context);
        this.loadingResults = true;
        vocabularyService.getAllVocabularies().then(function (vocabularies) {
            _this.vocabularies = vocabularies;
            _this.vocabularies.sort(comparingPrimitive(function (vocabulary) { return !!_this.exclude(vocabulary); })
                .andThen(comparingLocalizable(_this.localizer, function (vocabulary) { return vocabulary.title; })));
            _this.search();
            _this.loadingResults = false;
        });
        this.addFilter(function (vocabulary) {
            return !_this.showStatus || vocabulary.item.status === _this.showStatus;
        });
        $scope.$watch(function () { return _this.showStatus; }, ifChanged(function () { return _this.search(); }));
    }
    SearchVocabularyController.prototype.addFilter = function (filter) {
        this.searchFilters.push(filter);
    };
    Object.defineProperty(SearchVocabularyController.prototype, "items", {
        get: function () {
            return this.vocabularies;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchVocabularyController.prototype, "statuses", {
        get: function () {
            return selectableStatuses;
        },
        enumerable: true,
        configurable: true
    });
    SearchVocabularyController.prototype.search = function () {
        this.searchResults =
            filterAndSortSearchResults(this.vocabularies, this.searchText, this.contentExtractors, this.searchFilters, defaultTitleComparator(this.localizer, this.exclude));
    };
    SearchVocabularyController.prototype.selectItem = function (vocabulary) {
        if (!this.exclude(vocabulary)) {
            this.$uibModalInstance.close(vocabulary);
        }
    };
    SearchVocabularyController.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return SearchVocabularyController;
}());
export { ɵ0 };
