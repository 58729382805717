var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { hasLocalization } from 'app/utils/language';
import { LegacyComponent } from 'app/utils/angular';
var VisualizationPopoverComponent = /** @class */ (function () {
    function VisualizationPopoverComponent($scope, $timeout, $element) {
        'ngInject';
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$element = $element;
        this.style = {};
    }
    VisualizationPopoverComponent.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch(function () { return _this.details; }, function (details) {
            // Hide by keeping off screen absolute position
            _this.style = { top: -10000 + 'px', left: -10000 + 'px' };
            if (details && hasLocalization(details.comment)) {
                _this.style.comment = details.comment;
                // Let the comment render before accessing calculated dimensions
                _this.$timeout(function () {
                    var dimensions = _this.getDimensions();
                    var offset = _this.visualizationOffset();
                    _this.style.top = (details.coordinate.y - offset.y - (dimensions.height / 2)) + 'px';
                    _this.style.left = (details.coordinate.x - offset.x - dimensions.width - 15) + 'px';
                });
            }
        });
    };
    VisualizationPopoverComponent.prototype.getDimensions = function () {
        var popoverElement = this.$element.find('.popover');
        return {
            width: popoverElement.outerWidth(),
            height: popoverElement.outerHeight()
        };
    };
    VisualizationPopoverComponent.prototype.visualizationOffset = function () {
        var classVisualizationElement = this.$element.closest('class-visualization');
        return {
            x: classVisualizationElement.offset().left,
            y: classVisualizationElement.offset().top
        };
    };
    Object.defineProperty(VisualizationPopoverComponent.prototype, "arrowTopOffset", {
        get: function () {
            return this.getDimensions().height / 2;
        },
        enumerable: true,
        configurable: true
    });
    VisualizationPopoverComponent = __decorate([
        LegacyComponent({
            bindings: {
                details: '=',
                context: '='
            },
            template: "\n       <div class=\"popover fade show bs-popover-left\" ng-style=\"$ctrl.style\">\n         <div class=\"arrow\" ng-style=\"{ top: $ctrl.arrowTopOffset }\"></div>\n         <div class=\"popover-header\">\n           <h4 class=\"mb-0\">{{$ctrl.details.heading | translateValue: $ctrl.context}}</h4>\n         </div>\n         <div class=\"popover-body\">\n           {{$ctrl.details.comment | translateValue: $ctrl.context}}\n         </div>\n       </div>\n  "
        }),
        __metadata("design:paramtypes", [Object, Function, Object])
    ], VisualizationPopoverComponent);
    return VisualizationPopoverComponent;
}());
export { VisualizationPopoverComponent };
