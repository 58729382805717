var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DisplayItemFactory } from './displayItemFactory';
import { LegacyComponent } from 'app/utils/angular';
import { isExternalLink } from 'app/components/form/href';
var NonEditableComponent = /** @class */ (function () {
    function NonEditableComponent($scope, $parse, displayItemFactory) {
        'ngInject';
        this.$scope = $scope;
        this.$parse = $parse;
        this.displayItemFactory = displayItemFactory;
    }
    NonEditableComponent.prototype.$onInit = function () {
        var _this = this;
        // we need to know if handler was set or not so parse ourselves instead of using scope '&'
        var clickHandler = this.$parse(this.onClick);
        var onClick = this.onClick ? function (value) { return clickHandler(_this.$scope.$parent, { value: value }); } : undefined;
        this.item = this.displayItemFactory.create({
            context: function () { return _this.context; },
            value: function () { return _this.value; },
            link: function () { return _this.link; },
            valueAsLocalizationKey: this.valueAsLocalizationKey,
            hideLinks: function () { return _this.isEditing(); },
            onClick: onClick
        });
    };
    NonEditableComponent.prototype.isEditing = function () {
        return this.form && this.form.editing;
    };
    Object.defineProperty(NonEditableComponent.prototype, "style", {
        get: function () {
            if (this.isEditing()) {
                return { 'margin-bottom': '33px' };
            }
            else {
                return {};
            }
        },
        enumerable: true,
        configurable: true
    });
    NonEditableComponent.prototype.isExternalLink = function (link) {
        return isExternalLink(link);
    };
    NonEditableComponent = __decorate([
        LegacyComponent({
            bindings: {
                title: '@',
                value: '=',
                link: '=',
                onClick: '@',
                valueAsLocalizationKey: '@',
                context: '=',
                clipboard: '='
            },
            require: {
                form: '?^form'
            },
            template: require('./nonEditable.html')
        }),
        __metadata("design:paramtypes", [Object, Function, DisplayItemFactory])
    ], NonEditableComponent);
    return NonEditableComponent;
}());
export { NonEditableComponent };
